import React from 'react';
import { Table, Spin,message,Tree,Modal} from 'antd';
import history from '../history';

const { TreeNode } = Tree;
class Privilege extends React.Component{
  
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			treeList:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
            loading:false,
			visible: false,
			expandedKeys: [],
		}
	}
		//获取列表数据的接口
		onload(){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			let content = {start:this.state.start,limit:this.state.pageSize,
				name:this.state.name,code:this.state.code,status:"0"
			};
			fetch('/api/userCenter/roleList',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
                                array=data.result;    
                                total=data.total;
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                    this.state.params=array;
                    this.state.total=total;
                    this.state.selectedRowKeys = [];
                    this.setState({ loading: false });
                });
		 }
		 componentWillMount() {
			this.onload()
			this.getTree();//获取菜单及按钮树
			this.showBtn(this.props.buttonList)//控制按钮显示
		}
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_save"){
						this.state.btn_save = true
				  }
			 }
		}

		valueChange(type,e) {
			if(type == "name"){
					this.state.name=e.target.value
			}
			if(type == "code"){
				this.state.code=e.target.value
			}
		}
		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
		
		pageChange(page,pageSize){//翻页
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
	 }
    //配置
	 configure(data){
		this.state.roleId = data.id
		let array = [];
		fetch('/api/userCenter/searchPrivilegeByRole',{
			method: 'post',
			credentials: 'include',
				headers: {
						'Content-Type': 'application/json'
				},
				body: JSON.stringify({roleId:this.state.roleId}),
				}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
						return res.json();
				}).then(function (data) {
						if (data.code == 0) {
							 array=data.result;    
						}else  {
							message.error(data.message);
								if(data.code == 2003){
									history.push({pathname:'/'});
							}
						}
				}).then( (e) => {
					this.state.checkedKeys=array;
					this.state.expandedKeys = array;
					this.setState({
						visible: true
					});
			 });
	 } 
	 //保存
     handleOk () {
		 	this.setState({
				loading: true
			});
		let content = {roleId:this.state.roleId,privilegeList:this.state.checkedKeys};
		fetch('/api/userCenter/savePrivilege',{
			method: 'post',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'      
			},
			body: JSON.stringify(content),
			}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
					return res.json();
			}).then(function (data) {
					if (data.code == 0) {
						message.success("保存成功！")   
					}else  {
						message.error(data.message);
					}
			}).then( (e) => {
				this.onload();
				this.setState({
					visible: false,
					loading: false
				});
			});
	  };
	
	  handleCancel () {
		this.setState({
			visible: false
		});
	  };

	  //关于树的js
	  getTree(){
		let array=[]
		let content = {};
		fetch('/api/userCenter/getTree',{
			method: 'post',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'      
			},
			body: JSON.stringify(content),
			}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
					return res.json();
			}).then(function (data) {
					if (data.code == 0) {
						array=data.result;    
					}else  {
						message.error(data.message);
					}
			}).then( (e) => {
				this.state.treeList = array;
				// this.setState({ treeList: array });
			});
	 }
	 renderTreeNodes = data =>
	    data.map(item => {
			if (item.childMenu == null?item.childMenu = []:item.childMenu) {
				return (
					<TreeNode title={item.menuName} key={item.id} dataRef={item}>
					{this.renderTreeNodes(item.childMenu)}
					</TreeNode>
				);
			}
		return <TreeNode {...item} />;
	});
	onExpand = expandedKeys => {
		// console.log('onExpand', expandedKeys);
		this.setState({
		  expandedKeys,
		  autoExpandParent: false,
		});
	  };
	
	  onCheck = checkedKeys => {
		// console.log('onCheck', checkedKeys);
		this.setState({ checkedKeys });
	  };
 

    render(){
		  
		const treeData = this.state.treeList;

			
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title: '角色名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '角色编码',
					dataIndex: 'code',
					key: 'code',
				},
				{
					title: '描述',
					dataIndex: 'description',
					key: 'description',        
                },
				{
					title: '状态',
					dataIndex: 'status',
					key: 'status',
					render: (text,record,index) =>text == "0"?"启用":"禁用" 
					
				},
				{
					title: '操作',
					dataIndex: '',
                    key: '',
                    render: (text,record,index) =>{
                        if(text.status == '0'){
                          return <a style={{display:this.state.btn_save == true?"inline-block":"none"}} onClick={this.configure.bind(this,text)} href="javascript:;">配置</a> 
                        }
                    } 
				}
				
			];
			const dataSource = this.state.params;
			const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
								<div class="page-content-info">
									<div class="toolbar-group">
										<div class="toolbar-right" style={{width:'100%'}}>
											<Table size={"small"} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
										</div>
									</div>
								</div>
              </Spin>
              <Modal title="配置" visible={this.state.visible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
				<Tree
						checkable
						defaultExpandAll = {true}
						checkStrictly = {false}
						onExpand={this.onExpand}
						expandedKeys={this.state.expandedKeys}
						checkedKeys={this.state.checkedKeys}
						onCheck={this.onCheck}
						>

						{this.renderTreeNodes(treeData)}
					</Tree>
              </Modal>
            </div>    
        )
    }
}
export default Privilege;
import React from 'react';
import { Input,Select,Button,Spin,message} from 'antd';
import history from '../history';
const { Option } = Select;
class RoleDetails extends React.Component{
    constructor(props) {
      super(props);  
      this.state={
        params:{},
        loading:false,
        rightList:[],
        leftList:[],
        isfalse: null,
        userList:[],
        esfalse:false
      }
    }
    userData(array){
        var allList = array;//所有运营用户
        var rightList = [];
        if(this.state.rightList.length == 0 ||this.props.params==null){//角色下没有用户的时候或者点击新增跳转来的
            this.setState({leftList:allList});
            return;
        }else if(allList.length>0 && this.state.rightList.length>0){//角色下有用户的时候
           for(let i = 0; i<allList.length; i++){
              for(let x=0;x<this.state.rightList.length;x++){
                 if(allList[i].id == this.state.rightList[x].userId){
                    rightList.push(allList[i]);
                    allList.splice(i,1);
                 }
              }
           }
        //    this.state.rightList = rightList;
           this.setState({leftList:allList,rightList});
        }

    }
    //根据角色获取用户
    onload(roleId){
            let array = [];
            let content = {roleId:roleId}
            fetch('/api/userCenter/getUserByRole',{
                method: 'post',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        array = data.result;
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.setState({rightList:array});
                    this.getUserList()
                    // this.userData(this.props.userList);//关于角色分配用户的数据处理
                });
    }
    //获取用户列表
  getUserList(){
    let content = { userSource: '0' };
    let array = [];
    fetch('/api/userCenter/userList', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error('网络不可用！');
        }
        return res.json();
      })
      .then(function(data) {
        if (data.code == 0) {
          array = data.result;
        }
      })
      .then(e => {
        this.setState({ userList: array });
        this.userData(this.state.userList);//关于角色分配用户的数据处理
      });
  }

    componentWillMount() {
       if(this.props.params != null){
            this.state.isfalse = this.props.isfalse;
            this.state.params = this.props.params;
            this.onload(this.state.params.id);//获取角色下的用户   
       }
       this.showBtn(this.props.buttonList)//控制按钮显示
       this.getUserList(); //获取用户列表
    }
    //控制按钮显示
    showBtn(array){
         for(var i= 0; i<array.length;i++){
              if(array[i].buttonCode == "btn_save"){
                    this.state.btn_save = true
              }
              if (array[i].buttonCode == "btn_update") {
                this.state.btn_update = true;
              }
              if (array[i].buttonCode == "btn_cancel") {
                this.state.btn_cancel = true;
              }
         }
    }
    componentWillReceiveProps(nextProps) { 
        if(nextProps != null ){
            if(nextProps.params != null){
                this.state.isfalse = nextProps.isfalse;
                // this.state.userList=nextProps.userList
                // this.getUserList(); //获取用户列表
                this.state.esfalse=false
                this.setState({
                    params: nextProps.params     
                });
                this.onload(nextProps.params.id);//获取角色下的用户
            }else{
                this.state.rightList=[];
                this.state.isfalse=null
                this.setState({
                    params: {}
                });
                this.getUserList(); //获取用户列表
            }
            
        }else{
            this.state.isfalse=null
            this.setState({
                params: {}
            });
            // this.userData(this.props.userList);//关于角色分配用户的数据处理
           
        }
       
    }

    handleChange(type,e) {
        let params=this.state.params
        if(type == "code"){
            params.code = e.target.value
        }
        if(type == "name"){
            params.name = e.target.value
        }
        if(type == "description"){
            params.description = e.target.value
        }
        this.setState({params})
    }
    //保存
    save(){
        // if(this.state.params.name == undefined || this.state.params.code== undefined|| this.state.params.status== undefined||this.state.params.name == "" || this.state.params.code== ""|| this.state.params.status== ""){
        //     message.error("存在必填项未填写，请核实！")
        //     return;
        // };
        if(this.state.params.name == undefined ||this.state.params.name == "" ){
            message.error("角色名称未填写，请核实！")
            return;
        };
        if( this.state.params.code== undefined|| this.state.params.code== ""){
            message.error("角色编码未填写，请核实！")
            return;
        };
        if( this.state.params.status== undefined||this.state.params.status== ""){
            message.error("状态未填写，请核实！")
            return;
        };
        this.setState({ loading: true });
        let content = {id:this.state.params.id,name:this.state.params.name,code:this.state.params.code,status:this.state.params.status,
            description:this.state.params.description,userList:this.state.rightList}
            fetch('/api/userCenter/saveRole',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then( (data)=> {
                    if (data.code == 0) {
                        message.success("保存成功！");
                        // if(data.result.id!=null){
                        //     this.onload(data.result.id)
                        //   }
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    if(this.state.params.id == null){//新增的时候清空列表
                        this.state.params.name = "";  
                        this.state.params.code= "";
                        this.state.params.status= "";
                        this.state.params.description="";
                    }
                    this.setState({ loading: false,isfalse: true, esfalse: false,rightList:this.state.rightList });

                });
    }
    selectChange(type,value){
        let params=this.state.params
        if(type == "status"){
         params.status = value
        }
        this.setState({params})
    }

    leftRoleList(id){//左边用户列表的点击事件
        var leftList = this.state.leftList;
        leftList.map((item,index)=>{
            if(item.id == id){
                this.state.rightList.push(item);//右加
                leftList.splice(index,1);//左删
            }
        })
        this.setState({leftList:leftList})
    }
    rightRoleList(id){//右边用户列表的点击事件
        var rightList = this.state.rightList;
        rightList.map((item,index)=>{
             if(item.id == id){
                this.state.leftList.push(item);//左加
                rightList.splice(index,1);//右删
             }
        })
        this.setState({rightList:rightList})
    }
    cencel(type) {
        //修改还是取消
        if (type == 'write') {
            this.setState({
                isfalse: false,
                esfalse:true
            });
        } else {
            this.setState({
                isfalse: true,
                esfalse: false,
            });
        }
    }

    render(){
        let { isfalse ,esfalse} = this.state
        return(
            <div className = "userDetails" key={(this.state.params != null && this.state.params.id == '-1')?this.state.params.id:this.state.params.id}>
                <Spin spinning={this.state.loading} tip="Loading...">
                <Input hidden={true} defaultValue={this.state.params.id}/>
                    <div style = {{margin:"0 0 18px 30px"}}>
                        <Button style={{display:this.state.btn_save == true?"inline-block":"none"}} type="primary" onClick = {this.save.bind(this)} disabled={isfalse}>保存</Button> 
                        <Button style={{display:this.state.btn_update == true ? "inline-block" : "none",marginLeft: 20 }}onClick={this.cencel.bind(this, 'write')} disabled={esfalse}> 修改</Button>
                        <Button style={{display:this.state.btn_cancel == true ? "inline-block" : "none",marginLeft: 20}}onClick={this.cencel.bind(this)} disabled={isfalse}> 取消 </Button>
                        <Button style = {{marginLeft:20}} onClick = {this.props.callback.bind(this,"1",null,null)}>返回</Button>
                    </div> 
                    
                    <p>角色名称: 
                        <span style={{color:"#b94a48",fontWeight:900}}>*</span>
                    <Input disabled={isfalse} value={this.state.params.name} placeholder = "请输入角色名称" onChange={this.handleChange.bind(this,"name")}/></p>
                    {/* <p>用户归属:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                        <Select defaultValue={this.state.params.userSource}  style={{width:"78%",float:"right"}} onChange={this.selectChange.bind(this,"userSource")}>
                            <Option value="">--请选择用户归属--</Option>
                            <Option value="0">运营人员</Option>
                            <Option value="1">影院人员</Option>
                        </Select>
                    </p> */}
                    <p>角色编码: 
                        <span style={{color:"#b94a48",fontWeight:900}}>*</span>
                    <Input disabled={isfalse} value={this.state.params.code} placeholder = "请输入角色编码" onChange={this.handleChange.bind(this,"code")}/></p>
                    <p>状态：<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                        <Select disabled={isfalse} value={this.state.params.status}  style={{width:"78%",float:"right"}} onChange={this.selectChange.bind(this,"status")}>
                            <Option value="">--请选择状态--</Option>
                            <Option value="0">启用</Option>
                            <Option value="1">禁用</Option>
                        </Select>
                    </p>
                    <p>描述:
                        {/* <span style={{color:"#b94a48",fontWeight:900}}>*</span> */}
                        <Input disabled={isfalse} value={this.state.params.description}  placeholder = "请输入对应的角色描述" onChange={this.handleChange.bind(this,"description")}/></p>
                    
                        <div className="role-content">
                        <div>角色归属用户</div>
                        <div>
                            <div className="role-list">
                            <span className="title">未拥有该角色的用户</span>
                                <ul>
                                    {this.state.leftList&&this.state.leftList.map((item)=>{
                                         return(
                                            <li onClick={this.leftRoleList.bind(this,item.id)}>{item.name}</li>
                                        )
                                    })
                                    }
                                </ul>
                            </div> 
                            <div className="role-lists">
                                <div> &gt; </div>
                                <div> &lt; </div>
                            </div>
                            <div className="role-list">
                            <span className="title">已拥有该角色的用户</span>
                                <ul>
                                    {this.state.rightList.map((item)=>{
                                             return(
                                                <li onClick={this.rightRoleList.bind(this,item.id)}>{item.name}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div> 
                        </div>  
                    </div>
                    
                   
                </Spin>    
            </div>    
        )
    }
}
export default RoleDetails;
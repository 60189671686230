import React from 'react';
import {Tabs} from 'antd';
import OrderList from './orderList.js';
import OrderDetial from './orderDetial';

const { TabPane } = Tabs;
class OrderMange extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isfalse:true
      }
    }
    callback(activeKey,type,params){
        if(type == "details"){
          this.state.params = params
          this.state.isfalse=true
        }
        if(type == "add"){
          this.state.params = null
        }
      this.setState({activeKey:activeKey})
    }
    
    render(){
      let {isfalse}=this.state
        return(
          <div>
              <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
              <TabPane tab="密钥订单列表" key="1">
                <OrderList buttonList={this.props.buttonList}  callback = {this.callback.bind(this)}/>
              </TabPane>
              <TabPane tab="密钥订单详情" key="2">
                 <OrderDetial buttonList={this.props.buttonList}  isfalse={isfalse} params = {this.state.params} callback = {this.callback.bind(this)}/>
              </TabPane>
            </Tabs>
          </div>
        )
    }
}
export default OrderMange;
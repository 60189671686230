import React from 'react';
import { Table, Spin,Button,message,Tabs,Modal,Select} from 'antd';
import history from '../history';

const { confirm } = Modal;
const {Option}=Select;
class NodeList extends React.Component{
  
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
			loading:false
		}
	}
		//获取列表数据的接口
		onload(ty){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			if(ty=="1"){
				this.state.start=0
				}else{
				this.state.start=this.state.page;
				}
			let content = {start:this.state.start,limit:this.state.pageSize,
				name:this.state.name,status:this.state.status
			};
			fetch('/api/message/queryNodeList',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
                                array=data.result;    
                                total=data.total;
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                    this.state.params=array;
                    this.state.total=total;
					this.state.selectedRowKeys = [];
					this.state.selectedRow = [];
                    this.setState({ loading: false });
                });
		 }
		 componentWillMount() {
			this.onload()
			this.showBtn(this.props.buttonList)//控制按钮显示
		}
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_search"){
						this.state.btn_search = true
				  }
				  if(array[i].buttonCode == "btn_add"){
					this.state.btn_add = true
				  }
				if(array[i].buttonCode == "btn_delete"){
					this.state.btn_delete = true
				}
			 }
		}

		valueChange(type,e) {
			if(type == "name"){
				this.state.name=e.target.value
			}
			if(type=='status'){
				this.state.status=e;
			}
		}
		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
		
		pageChange(page,pageSize){//翻页
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
		}
		//启用 禁用
		statusClick (type,obj){
		let str='';
		if(this.state.selectedRow.length == 0&&obj==null){
			message.error("尚未选中数据！");
			return;
		}else if(obj!=null){
			obj.status=type;
			this.state.selectedRow[0]=obj;
		}else if(this.state.selectedRow.length>0){
            for(var i=0;i<this.state.selectedRow.length;i++){
				this.state.selectedRow[i].status=type;
			}
		}
		if(type=='0'){
			str='启用成功';
		}else{
			str='禁用成功';
		}
		confirm({
		  title: type=='0'?'确认启用?':type=='1'?'确认禁用?':'',
		  content: '',
		  okText: '确定',
		  cancelText:"取消",
		  onOk:()=> {
			// this.setState({ loading: true });
			let array=this.state.selectedRow;
			fetch('/api/message/updateNodeInfo',{
				method: 'post',
				credentials: 'include',
                headers: {
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify(array),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                    return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        message.success(str)
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.onload();
            });
		  },
		  onCancel() {
		  },
		});
	  }
	  

    render(){
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title: '代码',
					dataIndex: 'code',
					key: 'code',
				},
				{
					title: '服务节点',
					dataIndex: 'name',
                    key: 'name',
				
				},
				{
					title: '状态',
					dataIndex: 'status',
                    key: 'status', 
                    render: (text,record) =>  text == '0'?"启用":text=='1'?"禁用":'',
                       
				},
				{
					title: '备注',
					dataIndex: 'description',
					key: 'description',
                },
                {
					title: '操作',
					dataIndex: '',
                    key: '',
                    render:(text,record,index)=><div><Button type="primary" disabled={record.status=='1'?false:true} onClick={this.statusClick.bind(this,'0',record)}>启用</Button><Button type="primary" style={{marginLeft:'6px'}} disabled={record.status=='0'?false:true} onClick={this.statusClick.bind(this,'1',record)}>禁用</Button></div>
				}
				
			];
			const dataSource = this.state.params;
			const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
								<div class="page-content-info">
									<div class="toolbar-group">
										<div class="toolbar-left">
											<div class="form-main">
												<input class="form-control" onChange={this.valueChange.bind(this,"name")} placeholder="请输入服务节点"/>
											</div>
                                            <div class="form-main">
                                                <Select style={{width:'167px'}} placeholder='请选择全部状态' vlaue={this.state.type} onChange={this.valueChange.bind(this,'status')}>
                                                    <Option value=''>--全部状态--</Option>
                                                    <Option value='0'>启用</Option>
                                                    <Option value='1'>禁用</Option>
                                                </Select>
                                            </div>
											<button style={{display:this.state.btn_search == true?"inline-block":"none"}} onClick={this.onload.bind(this,'1')} class="btn btn-default btn-search" type="button">检索</button>
										</div>
										<div>
											<Button style={{marginLeft:10}} type="primary" onClick={this.statusClick.bind(this,'0',null)}>启用</Button>
										    <Button style={{marginLeft:10}} type="danger" onClick={this.statusClick.bind(this,'1',null)}>禁用</Button>
										</div>
										<div class="toolbar-right" style={{width:'100%'}}>
											<Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
										</div>
									</div>
								</div>
              </Spin>
            </div>    
        )
    }
}
export default NodeList;
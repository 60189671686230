import React from 'react';
import {Tabs,message} from 'antd';
import OperationRecordList from './OperationRecordList.js';
const { TabPane } = Tabs;
class OperationRecord extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
       
      }
    }  

    render(){
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey} >
            <TabPane tab="操作记录" key="1">
              <OperationRecordList  buttonList={this.props.buttonList} />
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default OperationRecord;
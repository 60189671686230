import React from 'react';
import { Table, Spin,Button,message,Select,Modal} from 'antd';
import history from '../history';

const {Option}=Select;
class FilmBagRecordList extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:[],
        page:0,
        pageSize:10,
        selectedRowKeys: [],
        selectedRow:[],
        total:0,
        loading:false
      }
    }
    componentWillMount(){
      this.onload();
    }
    //获取列表数据接口
    onload(ty){
      this.setState({loading:true});
      let array=[];
      let total=0;
      if(ty=='1'){
        this.state.start=0;
      }else{
        this.state.start=this.state.page;
      }
      let content={start:this.state.start,limit:this.state.pageSize,filmBagName:this.state.filmBagName,userCode:this.state.userCode,useStatus:this.state.useStatus};
      fetch('/api/filmBag/queryDistributeList',{
        method:'post',
        credentials:'include',
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify(content),
      }).then(function(res){
        if(!res.ok){
          message.error('网络不可用！');
        }
        return res.json();
      }).then(function(data){
        if(data.code==0){
          array=data.result;
          total=data.total;
        }else{
          message.error(data.message);
          if(data.code==2003){
            history.push({pathname:'/'})
          }
        }
      }).then(e=>{
        this.setState({total:total,params:array,loading:false,selectedRowKeys:[],selectedRow:[]});
      })
    }
    //获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
    //翻页
    pageChange(page,pageSize){
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
		}
    //值
    changeEvent(type,e){
      if(type=="filmBagName"){
        this.state.filmBagName=e.target.value;
      }
      if(type=="userCode"){
        this.state.userCode=e.target.value;
      }
      if(type=="useStatus"){
        this.state.useStatus=e;
      }
    }
    render(){
      const {selectedRowKeys,selectedRow}=this.state;
      const columns=[
        {
          title:'序号',
          render:(text,record,index)=>`${index+1}`
        },
        {
          title:'片包名称',
          dataIndex:'filmBagName',
          key:'filmBagName',
        },
        {
          title:'卡包编号',
          dataIndex:'filmBagCode',
          key:'filmBagCode',
        },
        {
          title:'卡包有效期',
          dataIndex:'validity',
          key:'validity',
        },
        {
          title:'分配用户',
          dataIndex:'userCode',
          key:'userCode',
        },
        {
          title:'状态',
          dataIndex:'useStatus',
          key:'useStatus',
          render:(text)=>text=='0'?'未使用':text=='1'?'使用中':text=='2'?'已使用':text=='3'?'已过期':''
        }
      ]
        return(
          <div>
            <Spin spinning={this.state.loading} tip="Loading">
              <div className="page-content-info">
                <div className="toolbar-group">
                  <div class="toolbar-left">
                    <div class="form-main">
                      <input class="form-control" placeholder="片包名称" onChange={this.changeEvent.bind(this,'filmBagName')}></input>
                    </div>
                    <div class="form-main">
                      <input class="form-control" placeholder="用户名" onChange={this.changeEvent.bind(this,'userCode')}></input>
                    </div>
                    <div class="form-main">
                      <Select class="form-control" placeholder="状态" style={{width:'167px'}} onChange={this.changeEvent.bind(this,'useStatus')}>
                        <Option value="">状态</Option>
                        <Option value="0">未使用</Option>
                        <Option value="1">使用中</Option>
                        <Option value="2">已使用</Option>
                        <Option value="3">已过期</Option>
                      </Select>
                    </div>
                    <button  class="btn btn-default btn-search" type="button" onClick={this.onload.bind(this,'1')}>检索</button>
                  </div>
                  <div class="toolbar-right" style={{width:'100%'}}>
						        <Table columns={columns} dataSource={this.state.params} pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
						      </div>
                </div>
              </div>
            </Spin>
          </div>
        )
    }
}
export default FilmBagRecordList;
import React from 'react';
import { Spin, message, Table, Button, Input, Modal } from 'antd';
import history from '../../components/history';
const Search = Input.Search;

class FlimList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: [],
      dataParams: [],
      selectedRowKeys: [],
      selectedRow: [],
      page: 0,
      pageSize: 10,
      total: 0,
      display: "none",
      sort: "heat_rate",
      visible: false,
      showFilms:false,
      type: "",
      start: ''
    }
  }
  componentWillMount() {
    this.onload()//查询影片列表
  }
 
  onload() {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    // if (ty == "1") {
    //   this.state.start = 0
    // } else {
      this.state.start = this.state.page
    // }
    let content = {
    };
    fetch('/api/cinema/film/queryBookingList', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        array = data.result;
        total = data.total;
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.state.params = array;
      this.state.total = total;
      this.state.selectedRowKeys = [];
      this.state.isFake='';
      this.setState({ loading: false });
    });
  }
  showFilms(filmId){
    let params = {filmId:filmId}
    fetch("/api/cinema/film/bookingDetails", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data)=> {
        if (data.code == 0) {
            this.state.dataParams = data.result;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({showFilms: true});
      });
  }
  showFilmsOk(){
    this.setState({showFilms: false});
  }
  // pageChange(page, pageSize) {//翻页
  //   page = (page - 1) * pageSize
  //   this.state.page = page;
  //   this.onload();
  // }
  
  render() {
    const columns = [
      {
        title: '序号',
        render: (text, record, index) => `${index + 1}`,
      },
      {
        title: '影片名称',
        dataIndex: 'filmName',
        key: 'filmName',

      },
      {
        title: '数量',
        dataIndex: 'count',
        key: 'count',
      },
      {
				title: '操作',
				render: (text, record) => (
					<a href="javascript:;"onClick={this.showFilms.bind(this,record.filmId)}>详情</a>
				)
			}
    ];
    const dataSource = this.state.params;
    // const { selectedRowKeys } = this.state;
    // const { selectedRow } = this.state;


    const columnsDetail = [
      {
        title: '序号',
        render: (text, record, index) => `${index + 1}`,
      },
      {
        title: '影院名称',
        dataIndex: 'cinemaName',
        key: 'cinemaName',

      }
    ];
    const dataSourceDetails = this.state.dataParams;
   
    return (
    <div>
      <div style={{margin:'10px'}}>
				<Button  type="primary" style={{ width: '80px' }} onClick={this.onload.bind(this)}>刷新</Button>
      </div>
       <Table size={"small"} columns={columns} dataSource={dataSource}  />
    
        <Modal title="列表" okText="确认" footer={null} cancelText="取消" width="500px"  style={{marginTop:"20px"}} visible={this.state.showFilms}  onOk={this.showFilmsOk.bind(this)} onCancel={this.showFilmsOk.bind(this)}>
              <div style={{width:'100%',textAlign:"center"}}>
                  <Table size={"small"} columns={columnsDetail} dataSource={dataSourceDetails}  pagination/>
              </div>
        </Modal>
    </div>
  
    )
  }
}
export default FlimList;
import React from 'react';
import {Tabs,message} from 'antd';
import UserList from './userList.js';
import UserDetails from './userDetails.js';
import history from '../history';

const { TabPane } = Tabs;
const basics = {}
class UserManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isfalse:null,
        flag:null,
        basics:{}
      }
    }
    callback(activeKey,type,params){
        if(type == "details"){
          this.state.isfalse=true;
          this.state.flag=true;
         this.state.params=params;
        }
        if(type == "add"){
          this.state.isfalse=false;
          this.state.params = null;
          this.state.flag=false;
          // console.log(this,this.state.roleList)
          // this.state.basics.roleList=this.state.roleList;
         
        }
      this.setState({activeKey:activeKey})
    }
    componentWillMount() {
      this.getCinemaList();//获取影院列表
      this.getTheatreList();//获取院线列表
      // this.getRoleList();//获取角色列表
      this.getDepartment();
    }
    //获取影院列表
    getCinemaList(){
      let content = {};
      let array = [];
      fetch('/api/cinema/list',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(content),
					}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								 array=data.result;    
							}
					}).then( (e) => {
            this.state.cinemaList=array;
				 });
    }

    //获取院线列表
    getTheatreList(){
      let content = {};
      let array = [];
      fetch('/api/cinema/theatreList',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(content),
					}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								 array=data.result;    
							}
					}).then( (e) => {
            this.state.theatreList=array;
				 });
    }
    //获取部门列表
    getDepartment(){
      let content = {};
      let array = [];
      fetch('/api/system/queryDepartmentList',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(content),
					}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								 array=data.result;    
							}
					}).then( (e) => {
            this.state.queryDepartmentList=array;
				 });
    }  
    
    render(){
     
      this.state.basics.cinemaList = this.state.cinemaList;
      this.state.basics.theatreList = this.state.theatreList;
      this.state.basics.queryDepartmentList = this.state.queryDepartmentList;
      // this.state.basics.roleList = this.state.roleList;
     
        return(
          <div>
              <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
              <TabPane tab="用户列表" key="1">
                <UserList buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
              </TabPane>
              <TabPane tab="用户详情" key="2">
                <UserDetails buttonList={this.props.buttonList} isfalse={this.state.isfalse} flag={this.state.flag} basics={this.state.basics} params = {this.state.params} callback = {this.callback.bind(this)}/>
              </TabPane>
            </Tabs>
          </div>
        )
    }
}
export default UserManage;
import React from 'react';

class Go extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:[]
      }
    }

    render(){
        return(
            <div>
                <a onClick={this.props.goRoute.bind(this,"123456")}></a>
            </div>   
            //首页 
        )
    }
}
export default Go;
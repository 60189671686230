import React from 'react';
import { Table, Spin, Row, message, Col, Modal } from 'antd';
import history from '../history';

const { confirm } = Modal;
class UnsettledDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: [],
            page: 0,
            pageSize: 10,
            total: 0,
            loading: false,
            enumArray: [],
        }
    }
    //获取列表数据的接口
    onload(id, filmId) {
        this.setState({ loading: true });
        let array = []
        let total = 0;
        let content = {
            id: id,
            isSettlement: '0',
            issuerId: this.state.params.issuerId, beginTime: this.state.params.playTime+"-01 00:00:00", endTime: this.state.params.playTime+"-31 24:00:00",
            filmId: this.state.filmId
        };
        fetch('/api/settlement/queryDataDetails', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.total = total;
            this.state.dataSource = array;
            this.setState({ loading: false });
        });
    }
    searchEnum() { //获取影片接口
        let enumArray = [];
        let content = { isSettlement: '0', issuerId: this.state.params.issuerId, beginTime: this.state.params.playTime+"-01 00:00:00", endTime: this.state.params.playTime+"-31 24:00:00" }
        fetch('/api/settlement/queryFilmByData', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                enumArray = data.result;
            } else {

            }
        }).then((e) => {
            this.setState({ enumArray: enumArray });
        });
    }

    componentWillMount() {
        if (this.props.params != null) {
            this.state.params = this.props.params;
            this.searchEnum();//查询影片枚举值
            this.onload(this.state.params.id);
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps != null) {
            if (nextProps.params != null) {
                this.state.params = nextProps.params
                this.onload(this.state.params.id);
            } else {
                this.setState({
                    params: {}
                });
            }

        } else {
            this.setState({
                params: {}
            });
        }
    }
    // pageChange(page, pageSize) {//翻页
    //     page = (page - 1) * pageSize
    //     this.state.page = page;
    //     this.onload(this.state.params.id);
    // }
    screen(condition, obj, e) {//筛选
        this.state.page = 0;
        var filmClassArray = e.target.parentNode.children
        for (var i = 1; i < filmClassArray.length; i++) {
            filmClassArray[i].className = "screening-world"
        }
        e.target.className += " active"
        if (condition == "filmId") {//全部影片
            if (obj == "") {
                this.state.filmId = ''
            } else {
                this.state.filmId = obj.filmId
            }
        }
        this.onload();
    }
    render() {
        const columns = [
            {
                title: '序号',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '影片名称',
                dataIndex: 'filmName',
                key: 'filmName',
            },
            {
                title: '价格',
                dataIndex: 'externalSellingPrice',
                key: 'externalSellingPrice',
            },
            {
                title: '类型',
                dataIndex: 'filmSellType',
                key: 'filmSellType',
                render: (text) => text == "0" ? '包场' : text == "1" ? '包月' : ""
            },
            {
                title: '放映时间',
                dataIndex: 'playTime',
                key: 'playTime',
            },
            {
                title: '影院',
                dataIndex: 'cinemaName',
                key: 'cinemaName',
            },
            {
                title: '影厅',
                dataIndex: 'hallName',
                key: 'hallName',
            },
            {
                title: '实际售价',
                dataIndex: 'money',
                key: 'money',
                render:(text,record)=><span>{text=(record.settlementCost/(record.settlementPercent*1/100)).toFixed(2)}</span>
            },
            {
                title: '分账比例',
                dataIndex: 'settlementPercent',
                key: 'settlementPercent',
                render:(text)=><span>{text=text+'%'}</span>
            },
            {
                title: '收益(元)',
                dataIndex: 'settlementCost',
                key: 'settlementCost',
            },
        ];
        const dataSource = this.state.dataSource;
        return (
            <div>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <div class="page-content-info">
                        <div class="toolbar-group">
                            <div style={{ width: '100%', height: '80px', border: '1px solid #ccc' }}>
                                <Row style={{ margin: '10px 0 10px 10px' }}>
                                    <Col span={6}>发行公司：{this.state.params.isuserName}</Col><Col span={6}>结算月份：{this.state.params.playTime}</Col>
                                </Row>
                                <Row style={{ margin: '0 10px 0 10px' }}>
                                    <Col span={6}>费用(元)：{this.state.params.monthUnsettled.toFixed(2)}</Col><Col span={6}>场次(场)：{this.state.params.unsettledPlayCount}</Col><Col span={6}>月份(个月)：{this.state.params.unmonthPlayCount}</Col>
                                </Row>
                            </div>
                            <div style={{ width: '100%', height: '100px', border: '1px solid #ccc', margin: '10px 0 10px 0' }}>
                                <p style={{ fontSize: '20px', margin: '10px 0 0 10px' }}>影片</p>
                                <div className="screening-main"> 
                                    <a href="javascript:;" style={{ marginLeft: '10px' }} onClick={this.screen.bind(this, "filmId", "")} class="screening-style">全部</a>
                                    {(this.state.enumArray.length == 0) ? null : this.state.enumArray.map((item, index) => {
                                        return (
                                            <a href="javascript:;" style={{ marginLeft: '5px' }} onClick={this.screen.bind(this, "filmId", item)} class="screening-world">{item.filmName}</a>
                                        )
                                    }, this)}
                                </div>
                            </div>
                            <div class="toolbar-right" style={{ width: '100%' }}>
                                <Table size={"small"} columns={columns} dataSource={dataSource} />
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}
export default UnsettledDetails;
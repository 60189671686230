import React from 'react';
import { Table, Spin, message, Tabs, Modal, Select } from 'antd';
import history from '../history';

const { Option } = Select;
class SettledList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: [],
            page: 0,
            pageSize: 10,
            total: 0,
            loading: false,
            enumArray: [],
            monthArray: [],
            issuerId: ''
        }
    }
    //获取列表数据的接口
    onload(ty, settlementMonth) {
        this.setState({ loading: true });
        let array = []
        let total = 0;
        if (ty == "1") {
            this.state.start = 0
        } else {
            this.state.start = this.state.page
        }
        let content = {
            isSettlement: '1', issuerId: this.state.issuerId, settlementMonth: settlementMonth ? settlementMonth : this.state.settlementMonth
        };
        fetch('/api/settlement/querySettlementList', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.params = array;
            this.state.total = total;
            this.setState({ loading: false });
        });
    }
    //获取发行公司接口
    searchEnum() {
        let enumArray = [];
        let content = {}
        fetch('/api/settlement/queryIssueCompany', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                enumArray = data.result;
            }
        }).then((e) => {
            this.setState({ enumArray: enumArray });
        });
    }
    //获取结算月份接口
    monthEnum(issuerId) {
        let monthArray = [];
        let content = { issuerId: issuerId ? issuerId : '', isSettlement: '1' }
        fetch('/api/settlement/queryMonthByCompany', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                monthArray = data.result;
            }
        }).then((e) => {
            this.setState({ monthArray: monthArray });
        });
    }
    componentWillMount() {
        this.searchEnum();//查询发行公司枚举值
        this.monthEnum();//查询结算月份枚举值
        this.onload();
        this.showBtn(this.props.buttonList);//控制按钮显示
    }
    //控制按钮显示
    showBtn(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_search") {
                this.state.btn_search = true
            }
            if (array[i].buttonCode == "btn_add") {
                this.state.btn_add = true
            }
            if (array[i].buttonCode == "btn_delete") {
                this.state.btn_delete = true
            }
        }
    }

    // pageChange(page, pageSize) {//翻页
    //     page = (page - 1) * pageSize
    //     this.state.page = page;
    //     this.onload();
    // }
    //筛选发行公司
    screen(condition, obj, e) {
        this.state.page = 0;
        var filmClassArray = e.target.parentNode.children
        for (var i = 1; i < filmClassArray.length; i++) {
            filmClassArray[i].className = "screening-world"
        }
        e.target.className += " active"
        if (condition == "issuerId") {//全部发行公司
            if (obj == "") {
                this.state.issuerId = ''
            } else {
                this.state.issuerId = obj.id
            }
        }
        this.onload();
        this.monthEnum(this.state.issuerId);
    }
    //日期改变
    change(type, e) {
        if (type == "settlementMonth") {
            this.setState({ settlementMonth: e })
            this.onload('', e);
        }

    }
    render() {
        const columns = [
            {
                title: '序号',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '发行公司',
                dataIndex: 'isuserName',
                key: 'isuserName',
                render: (text, record) => <a href="javascript:;" onClick={this.props.callback.bind(this, "2", "details", record)}>{text}</a>,
            },
            {
                title: '结算月份',
                dataIndex: 'settlementMonth',
                key: 'settlementMonth',
            },
            {
                title: '费用（元）',
                dataIndex: 'settlementCost',
                key: 'settlementCost',
                render: (text) => <>{text? text = text.toFixed(2) : 0}</>
            },
            {
                title: '场次（场）',
                dataIndex: 'playCount',
                key: 'playCount',
            },
            {
                title: '月数（个月）',
                dataIndex: 'monthPlayCount',
                key: 'monthPlayCount',
            },
           
            {
                title: '结算发起人',
                dataIndex: 'sponsor',
                key: 'sponsor',
            },
            {
                title: '结算发起时间',
                dataIndex: 'sponsorTime',
                key: 'sponsorTime',
            },
            {
                title: '结算确认人',
                dataIndex: 'confirmedPeople',
                key: 'confirmedPeople',
            },
            {
                title: '结算确认时间',
                dataIndex: 'confirmedTime',
                key: 'confirmedTime',
            }
        ];
        const dataSource = this.state.params;
        return (
            <div>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <div class="page-content-info">
                        <div class="toolbar-group">
                            <div style={{ width: '100%', height: '100px' }}>
                                <p style={{ fontSize: '20px' }}>发行公司</p>
                                <div className="screening-main">
                                    <a href="javascript:;"  style={{color:'#ff5b22'}} onClick={this.screen.bind(this, "issuerId", "")} class="screening-style">全部</a>
                                    {(this.state.enumArray.length == 0) ? null : this.state.enumArray.map((item, index) => {
                                        return (
                                            <a href="javascript:;" style={{ marginLeft: '5px', }} onClick={this.screen.bind(this, "issuerId", item)} class="screening-world">{item.name}</a>
                                        )
                                    }, this)}
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '100px' }}>
                                <p style={{ fontSize: '20px' }}>结算月份</p>
                                <Select style={{ width: '167px' }}
                                    onChange={this.change.bind(this, 'settlementMonth')}
                                    value={this.state.settlementMonth}
                                    placeholder="请输入结算月份"
                                >
                                    <Option value=''>--选择月份--</Option>
                                    {
                                        this.state.monthArray && this.state.monthArray.map((item, index) => {
                                            return <Option value={item.playTime}>{item.playTime}</Option>
                                        })
                                    }
                                </Select>

                            </div>
                            <div class="toolbar-right" style={{ width: '100%' }}>
                                <Table size={"small"} columns={columns} dataSource={dataSource} />
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}
export default SettledList;
import React from 'react';
import { Input,Select,Button,Spin,message,InputNumber } from 'antd';
import history from '../history';


const { Option } = Select;
class FilmDetails extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:{},
        isfalse: null,
        loading:false,
        esfalse:false
      }
     
    }
    componentWillMount() {
        if(this.props.params != null){
            this.state.params = this.props.params;
            this.state.isfalse = this.props.isfalse;
            this.onload(this.state.params.id);
        }
        this.showBtn(this.props.buttonList)//控制按钮显示
    }
    //控制按钮显示
    showBtn(array){
         for(var i= 0; i<array.length;i++){
              if(array[i].buttonCode == "btn_save"){
                    this.state.btn_save = true
              }
              if (array[i].buttonCode == "btn_update") {
                this.state.btn_update = true;
              }
              if (array[i].buttonCode == "btn_cancel") {
                this.state.btn_cancel = true;
              }
         }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps!=null){
            if(nextProps.params != null){
                this.state.params=nextProps.params
                this.state.isfalse = nextProps.isfalse;
                this.onload(this.state.params.id);
                this.state.esfalse=false
            }else{
                this.state.isfalse = null
                this.setState({
                    params: {}
                  });
            }
        }else{
            this.state.isfalse = null
            this.setState({
                params: {}
              });
        }   
    }

     //修改还是取消
    cencel(type) {
        if (type == 'write') {
            this.setState({
                isfalse: false,
                esfalse:true
            });
        } else {
            this.setState({
                isfalse: true,
                esfalse: false,
            });
        }
    }
   //获取列表详情的接口
    onload(id){
        let params = {};
        this.setState({ loading: true });
        let content = {id:id}
            fetch('/api/cinema/film/details',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        params = data.result;
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.state.params = params;
                    this.setState({ loading: false });
                });
    }


    //保存函数
    save(){
        if(this.state.params.filmName == undefined){
            message.error("影片名称未填写，请核实！")
            return;
        };
        if( this.state.params.filmLength== undefined){
            message.error("影片时长未填写，请核实！")
            return;
        };
        if( this.state.params.heatRate== undefined){
            message.error("影片热度未填写，请核实！")
            return;
        };
        if(this.state.params.rank== undefined){
            message.error("影片评分未填写，请核实！")
            return;
        };
        this.setState({ loading: true });
        let content = {id:this.state.params.id,filmName:this.state.params.filmName,filmLength:this.state.params.filmLength,
                      heatRate:this.state.params.heatRate,rank:this.state.params.rank
        }
            fetch('/api/cinema/film/save',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then( (data) =>{
                    if (data.code == 0) {
                        message.success("保存成功！");
                        console.log(data.result,data.result==null)
                        if(data.result.id!=null){
                            this.onload(data.result.id)
                          }
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.setState({ loading: false,isfalse: true, esfalse: false });
                });
    }
    //input框值
    handleChange(type,e) {
        let params= this.state.params
        if(type == "filmName"){
            params.filmName = e.target.value
        }
        this.setState({params})
    }
    //select框值
    selectChange(type,value){
        if(type == "userSource"){
            this.state.params.userSource = value
        }
        if(type == "sex"){
            this.state.params.sex = value
        }
        if(type == "departmentid"){
            this.state.params.departmentid = value
        }
        if(type == "cutomerid"){
            this.state.params.cutomerid = value
        }
        if(type == "theaterid"){
            this.state.params.theaterid = value
        }  
    }
    numberChange(type,value){
        let params= this.state.params
        if(type == "filmLength"){
            params.filmLength = value
        }
        if(type == "heatRate"){
            params.heatRate =value
        }
        if(type == "rank"){
           params.rank = value
        }
        this.setState({params})
    }
    render(){
        let { isfalse,esfalse } = this.state
        return(
            <div style={{background:'#fff',padding:'10px'}} className = "userDetails" key={(this.state.params != null && this.state.params.id == '-1')?this.state.params.id:this.state.params.id}>
                <Spin spinning={this.state.loading} tip="Loading...">  
                    <div style = {{margin:"0px 0 18px 30px"}}>
                        <Button type="primary" onClick = {this.save.bind(this)} disabled={isfalse}>保存</Button> 
                        <Button style={{marginLeft: 20 }}onClick={this.cencel.bind(this, 'write')} disabled={esfalse}> 修改</Button>
                        <Button style={{marginLeft: 20}}onClick={this.cencel.bind(this)} disabled={isfalse}> 取消 </Button>
                        <Button style={{marginLeft:20}} onClick = {this.props.callback.bind(this,"1",null,null)}>返回</Button>
                    </div>
                    <Input hidden={true} defaultValue={this.state.params.id}/>
                    <p>影片名称: <span style={{color:"#b94a48",fontWeight:900}}>*</span><Input disabled={isfalse} value = {this.state.params.filmName} placeholder = "请输入影片名称" onChange={this.handleChange.bind(this,"filmName")}/></p>
                    <p>影片时长: <span style={{color:"#b94a48",fontWeight:900}}>*</span><InputNumber disabled={isfalse} value = {this.state.params.filmLength} placeholder = "请输入影片时长,例：120" onChange={this.numberChange.bind(this,"filmLength")} style={{width:"78%",float:"right"}}/></p>
                    <p>热度: <span style={{color:"#b94a48",fontWeight:900}}>*</span><InputNumber disabled={isfalse} value = {this.state.params.heatRate}  placeholder = "请输入影片热度，例：1326" onChange={this.numberChange.bind(this,"heatRate")} style={{width:"78%",float:"right"}}/></p>
                    <p>评分: <span style={{color:"#b94a48",fontWeight:900}}>*</span><InputNumber disabled={isfalse} value = {this.state.params.rank}  placeholder = "请输入影片评分，例：8.5" onChange={this.numberChange.bind(this,"rank")} style={{width:"78%",float:"right"}}/></p>
                </Spin>    
            </div>    
        )
    }
}
export default FilmDetails;
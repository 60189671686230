import React from 'react';
import { Table, Spin, Button, message, Tabs, Modal } from 'antd';
import history from '../history';

const { confirm } = Modal;
class ToBeConfirmedList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: [],
            page: 0,
            pageSize: 10,
            total: 0,
            loading: false,
            enumArray: [],
            monthArray: [],
            issuerId: '',
            visible: false
        }
    }
    //获取列表数据的接口
    onload() {
        this.setState({ loading: true });
        let array = []
        let total = 0;
        // if (ty == "1") {
        //     this.state.start = 0
        // } else {
        //     this.state.start = this.state.page
        // }
        let content = {
            isSettlement: '0', issuerId: this.state.issuerId, settlementMonth: this.state.settlementMonth
        };
        fetch('/api/settlement/querySettlementList', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.params = array;
            this.state.total = total;
            this.setState({ loading: false });
        });
    }
    //获取发行公司接口
    searchEnum() {
        let enumArray = [];
        let content = {}
        fetch('/api/settlement/queryIssueCompany', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                enumArray = data.result;
            } else {

            }
        }).then((e) => {
            this.setState({ enumArray: enumArray });
        });
    }
    //获取结算月份接口
    monthEnum(issuerId) {
        let monthArray = [];
        let content = { issuerId, isSettlement: '2' }
        fetch('/api/settlement/queryMonthByCompany', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                monthArray = data.result;
            } else {

            }
        }).then((e) => {
            this.setState({ monthArray: monthArray });
        });
    }
    componentWillMount() {
        this.searchEnum();//查询发行公司枚举值
        this.monthEnum();//查询结算月份枚举值
        this.onload();
        this.showBtn(this.props.buttonList);//控制按钮显示
    }
    //控制按钮显示
    showBtn(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_search") {
                this.state.btn_search = true
            }
            if (array[i].buttonCode == "btn_add") {
                this.state.btn_add = true
            }
            if (array[i].buttonCode == "btn_delete") {
                this.state.btn_delete = true
            }
        }
    }
    //发行公司筛选
    screen(condition, obj, e) {
        this.state.page = 0;
        var filmClassArray = e.target.parentNode.children
        for (var i = 1; i < filmClassArray.length; i++) {
            filmClassArray[i].className = "screening-world"
        }
        e.target.className += " active"
        if (condition == "issuerId") {//全部发行公司
            if (obj == "") {
                this.state.issuerId = ''
            } else {
                this.state.issuerId = obj.id
            }
        }
        this.onload();
        this.monthEnum(this.state.issuerId);
    }
    //筛选计算月份
    screens(condition, obj, e) {
        this.state.page = 0;
        var filmClassArray = e.target.parentNode.children
        for (var i = 1; i < filmClassArray.length; i++) {
            filmClassArray[i].className = "screening-world"
        }
        e.target.className += " active"
        if (condition == "settlementMonth") {//计算月份
            if (obj == "") {
                this.state.settlementMonth = '';
            } else {
                this.state.settlementMonth = obj.playTime;
            }
        }
        this.onload();
    }
    //确认弹框
    btnClick(ind) {
        this.setState({ visible: true, ind })
    }
    //弹框确定取消
    handleOk() {
        this.okClicks(this.state.ind)
        this.setState({ visible: false })
    }
    actorCancel() {
        this.setState({ visible: false })
    }
    //确认事件
    okClicks(item) {
        let content = { id: item.id, issuerId: item.issuerId, beginTime: item.settlementMonth + '-01 00:00:00', endTime: item.settlementMonth + '-31 24:00:00' }
        fetch('/api/settlement/toConfirmed', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then((data) => {
            if (data.code == 0) {
                message.success('确认成功');
                this.onload();
            } else {
                message.error('确认失败')
            }
        })

    }
    render() {
        const columns = [
            {
                title: '序号',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '发行公司',
                dataIndex: 'isuserName',
                key: 'isuserName',
                render: (text, record) => <a href="javascript:;" onClick={this.props.callback.bind(this, "2", "details", record)}>{text}</a>,
            },
            {
                title: '结算月份',
                dataIndex: 'settlementMonth',
                key: 'settlementMonth',
            },
            {
                title: '费用（元）',
                dataIndex: 'settlementCost',
                key: 'settlementCost',
                render: (text) => <>{text ? text = text.toFixed(2) : 0}</>
            },
            {
                title: '场次（场）',
                dataIndex: 'playCount',
                key: 'playCount',
            },
            {
                title: '月数（个月）',
                dataIndex: 'monthPlayCount',
                key: 'monthPlayCount',
            },
           
            {
                title: '结算发起人',
                dataIndex: 'sponsor',
                key: 'sponsor',
            },
            {
                title: '结算发起时间',
                dataIndex: 'sponsorTime',
                key: 'sponsorTime',
            },
            {
                title: '操作',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text, record) => <Button onClick={this.btnClick.bind(this, record)}>确认</Button>
            },


        ];
        const dataSource = this.state.params;
        return (
            <div>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <div class="page-content-info">
                        <div class="toolbar-group">
                            <div style={{ width: '100%', height: '100px' }}>
                                <p style={{ fontSize: '20px' }}>发行公司</p>
                                <div className="screening-main">
                                    <a href="javascript:;"  style={{color:'#ff5b22'}} onClick={this.screen.bind(this, "issuerId", "")} class="screening-style">全部</a>
                                    {(this.state.enumArray.length == 0) ? null : this.state.enumArray.map((item, index) => {
                                        return (
                                            <a href="javascript:;" style={{ marginLeft: '5px', color: '#000000' }} onClick={this.screen.bind(this, "issuerId", item)} class="screening-world">{item.name}</a>
                                        )
                                    }, this)}
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '100px' }}>
                                <p style={{ fontSize: '20px' }}>结算月份</p>
                                <div className="screening-main">
                                    <a href="javascript:;"  style={{color:'#ff5b22'}} onClick={this.screens.bind(this, "settlementMonth", "")} class="screening-style">全部</a>
                                    {(this.state.monthArray.length == 0) ? null : this.state.monthArray.map((item, index) => {
                                        return (
                                            <a href="javascript:;" style={{ marginLeft: '5px', }} onClick={this.screens.bind(this, "settlementMonth", item)} class="screening-world">{item.playTime}</a>
                                        )
                                    }, this)}
                                </div>
                            </div>
                            <div class="toolbar-right" style={{ width: '100%' }}>
                                <Table size={"small"} columns={columns} dataSource={dataSource} />
                            </div>
                        </div>
                    </div>
                </Spin>
                <Modal title="提示" okText="确认" cancelText="取消" width="300px" style={{ marginTop: "20px" }} visible={this.state.visible} onOk={this.handleOk.bind(this)} onCancel={this.actorCancel.bind(this)}>
                    <div style={{ width: '100%', textAlign: "center", height: "5px", lineHeight: "5px" }}>
                        <b>是否确认?</b>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default ToBeConfirmedList;
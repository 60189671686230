import React from "react";
import { Table, Spin, Button, message, Select, Modal, DatePicker } from "antd";
import history from "../history";

const { confirm } = Modal;
const { Option } = Select;
class DeviceManageList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      cinemaList: []
    };
  }

  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if(ty=="1"){
      this.state.start=0
      }else{
      this.state.start=this.state.page
      }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      installTime: this.state.installTime,
      // status: this.state.status,
      deviceType: this.state.deviceType,
      cinemaId: this.state.cinemaId,
      deviceName: this.state.deviceName,
    };
    // console.log(content)
    fetch("/api/cinema/list/searchDevice", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;

          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.setState({ loading: false });
      });
  }
  componentWillMount() {
    // this.state.cinemaList = this.props.basics.cinemaList;
    this.onload();
    this.getCinemaList();//获取影院列表
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  //获取影院列表
  getCinemaList() {
    let content = {};
    let array = [];
    fetch('/api/cinema/list', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        array = data.result;
      }
    }).then((e) => {
      this.setState({
        cinemaList: array
      })
    });
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true;
      }
      if (array[i].buttonCode == "btn_add") {
        this.state.btn_add = true;
      }
      if (array[i].buttonCode == "btn_delete") {
        this.state.btn_delete = true;
      }
    }
  }


  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }

  pageChange(page, pageSize) {
    //翻页
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
  showConfirm = () => {
    if (this.state.selectedRow.length == 0) {
      message.error("尚未选中数据！");
      return;
    }
    confirm({
      title: "确定删除?",
      content: "",
      okText: '确定',
      cancelText:"取消",
      onOk: () => {
        this.setState({ loading: true });
        let array = this.state.selectedRow.map((item, index) => { return { id: this.state.selectedRow[index].id } }); //判断id
        fetch("/api/device/deleteDevice", {
          method: "post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(array)
        })
          .then(function (res) {
            if (!res.ok) {
              message.error("网络不可用！");
            }
            return res.json();
          })
          .then(function (data) {
            if (data.code == 0) {
              message.success("删除成功");
            } else {
              message.error(data.message);
              if (data.code == 2003) {
                history.push({ pathname: "/" });
              }
            }
          })
          .then(e => {
            this.state.selectedRow.length=0;
            this.onload();
          });
      },
      onCancel() { }
    });
  };

  OnChange(type, date, dateString) {
    if (type == "installTime") {
      this.state.installTime = dateString
    }

  }
  
  valueChange(type, e) {
    if (type == "deviceName") {
      this.state.deviceName = e.target.value
    }
  }
  handleChange(type, value) {
    if (type == "status") {
     this.state.status = value
    }

    if (type == "cinemaId") {
      this.state.cinemaId = value
    }

    if (type == "deviceType") {
     this.state.deviceType = value
    }
   
  }
  render() {
    const columns = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "设备名称",
        dataIndex: "deviceName",
        key: "deviceName",
        render: (text, record) => (
          <a
            href="javascript:;"
            onClick={this.props.callback.bind(this, "2", "details", record)}
          >
            {text}
          </a>
        )
      },
      {
        title: "设备编码",
        dataIndex: "deviceCode",
        key: "deviceCode",
      },
      {
        title: "设备类型",
        dataIndex: "deviceType",
        key: "deviceType",
      },
      // {
      //   title: "密钥类型",
      //   dataIndex: "devicePlayType",
      //   key: "devicePlayType",
      // },
      {
        title: "设备安装时间",
        dataIndex: "installTime",
        key: "installTime",
      },
      {
        title: "设备安装位置",
        dataIndex: "location",
        key: "location",
        render:(text,record)=>text==null?text=record.posHallName:text
            },
      {
        title: "运行状态",
        dataIndex: "status",
        key: "status",
        render:(text,record)=>text=="0"?"运行中":"未运行"
      },
      {
        title: "备注",
        dataIndex: "description",
        key: "description",
      }
    ];
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this), //获取选中值
      getCheckboxProps: record => ({
        disabled: record.deviceType === 'Box',
        name: record.deviceType,
      })
    };
    return (
      <div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div className="page-content-info">
            <div className="toolbar-group">
              <div className="toolbar-left">
                <div style={{ width: "100%", marginBottom: 5 }}>
                 
                </div>
                <div className="form-main">
                  <input className="form-control" onChange={this.valueChange.bind(this, "deviceName")} placeholder="请输入设备名称" />
                </div>
                <div className="form-main">
                  <DatePicker
                    onChange={this.OnChange.bind(this, 'installTime')}
                    placeholder="请输入时间"
                  />
                </div>
                {/* <div className="form-main">
                  <Select
                    // labelInValue
                    style={{ width: 150, height: 50 }}
                    onChange={this.handleChange.bind(this, "status")}
                    defaultValue="running"
                  >
                    <Option value="0">运行中</Option>
                    <Option value="1">未运行</Option>
                  </Select>
                </div> */}
                <div className="form-main">
                  <Select
                    style={{ width: 167, height: 50, }}
                    onChange={this.handleChange.bind(this, "deviceType")}
                    placeholder="请选择设备类型"
                  >
                    <Option value="">--请选择--</Option>
                    <Option value="Projector">Projector</Option>
                    <Option value="CentralContrl">CentralContrl</Option>
                    <Option value="Box">Box</Option>
                  </Select>
                </div>
                <div className="form-main">
                  <Select
                    showSearch
                    style={{ width: 167, height: 50 }}
                    placeholder="请输入影院"     
                    optionFilterProp="children"
                    onChange={this.handleChange.bind(this, "cinemaId")}
                   
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="">--影院--</Option>
                    {this.state.cinemaList.map((item, ind) => {
                      return <Option value={item.id}>{item.name}</Option>
                    })}
                  </Select>
                </div>
                <button
                  style={{
                    display:
                      this.state.btn_search == true ? "inline-block" : "none"
                  }}
                  onClick={this.onload.bind(this,'1')}
                  className="btn btn-default btn-search"
                  type="button"
                >
                  检索
                </button>
                <Button
                    style={{ marginLeft: 10 ,display:this.state.btn_add==true? "inline-block" : "none"}}
                    type="primary"
                    onClick={this.props.callback.bind(this, "2", "add", null)}
                  >
                    新增
                  </Button>
                  <Button
                    style={{ marginLeft: 10,display:this.state.btn_delete==true? "inline-block" : "none"}}
                    type="danger"
                    onClick={this.showConfirm.bind(this, this)} //传id
                  >
                    删除
                  </Button>
              </div>

              <div className="toolbar-right" style={{ width: "100%" }}>
                <Table
                  size={"small"}
                  rowKey={record=>record.id}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
export default DeviceManageList;

import React from 'react';
import { Table, Spin, Button, message, Select, Modal } from 'antd';
import history from '../history';

const { confirm } = Modal;
const { Option } = Select;
class SetFilmList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: [],
            page: 0,
            pageSize: 10,
            selectedRowKeys: [],
            selectedRow: [],
            total: 0,
            loading: false
        }
    }
    //获取列表数据的接口
    onload(ty) {
        this.setState({ loading: true });
        let array = []
        let total = 0;
        if (ty == "1") {
            this.state.start = 0
        } else {
            this.state.start = this.state.page
        }
        let content = {
            start: this.state.start, limit: this.state.pageSize,
            makeFilmStatus: this.state.makeFilmStatus, filmName: this.state.filmName
        };
        fetch('/api/cinema/film/searchByCinema', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.params = array;
            this.state.total = total;
            this.state.selectedRowKeys = [];
            this.state.selectedRow = [];
            this.setState({ loading: false });
        });
    }
    componentWillMount() {
        this.onload()
        this.showBtn(this.props.buttonList)//控制按钮显示
    }
    //控制按钮显示
    showBtn(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_search") {
                this.state.btn_search = true
            }
            if (array[i].buttonCode == "btn_add") {
                this.state.btn_add = true
            }
            if (array[i].buttonCode == "btn_delete") {
                this.state.btn_delete = true
            }
        }
    }

    valueChange(type, e) {
        if (type == "makeFilmStatus") {
            this.state.makeFilmStatus = e;
        }
        if (type == "filmName") {
            this.state.filmName = e.target.value;
        }
    }
    //获取选中值
    onSelectChange(selectedRowKeys, selectedRow) {
        this.state.selectedRow = selectedRow
        this.setState({ selectedRowKeys });
    };

    pageChange(page, pageSize) {//翻页
        page = (page - 1) * pageSize
        this.state.page = page;
        this.onload();
    }
    
    render() {
        const columns = [
            {
                title: '序号',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '影片名称',
                dataIndex: 'filmName',
                key: 'filmName',
                render: (text, record) => <a href="javascript:;" onClick={this.props.callback.bind(this, "2", "details", record)}>{text}</a>,
            },
            {
                title: '生成时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '状态',
                dataIndex: 'makeFilmStatus',
                key: 'makeFilmStatus',
                render: (text) => text == '0' ? "未定级定价" : text == '1' ? '定级定价中' : text == '2' ? '定级定价完成' : ''
            },
            {
                title: '完成时间',
                dataIndex: 'makePriceTime',
                key: 'makePriceTime',
            },
            {
                title: '操作人',
                dataIndex: 'makePricePeople',
                key: 'makePricePeople',
            }

        ];
        const dataSource = this.state.params;
        const { selectedRowKeys } = this.state;
        const { selectedRow } = this.state;
        const rowSelection = {
            selectedRowKeys,
            selectedRow,
            onChange: this.onSelectChange.bind(this),//获取选中值
        };
        return (
            <div>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <div class="page-content-info">
                        <div class="toolbar-group">
                            <div class="toolbar-left">
                                <div class="form-main">
                                    <input class="form-control" onChange={this.valueChange.bind(this, "filmName")} placeholder="请输入影片名称" />
                                </div>
                                <div class="form-main">
                                    <Select style={{width:'176px',height:'36px'}}  onChange={this.valueChange.bind(this, 'makeFilmStatus')} placeholder="请输入状态">
                                        <Option value="">--全部--</Option>
                                        <Option value="0">未定级定价</Option>
                                        <Option value="1">定级定价中</Option>
                                        <Option value="2">定级定价完成</Option>
                                    </Select>
                                </div>
                                <button style={{ display: this.state.btn_search == true ? "inline-block" : "none" }} onClick={this.onload.bind(this, '1')} class="btn btn-default btn-search" type="button">检索</button>
                            </div>
                            <div class="toolbar-right" style={{ width: '100%' }}>
                                <Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource} pagination={{ onChange: this.pageChange.bind(this), total: this.state.total }} />
                            </div>
                        </div>
                    </div>
                </Spin>
            </div>
        )
    }
}
export default SetFilmList;
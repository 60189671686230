import React from 'react';
import { Table, Spin, Button, message, Row, Modal, Select } from 'antd';
import history from '../history';
import {formatFileSize} from '../../utils/formatFile';
const { Option } = Select
class DownloadTaskList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: [],
            page: 0,
            pageSize: 10,
            selectedRowKeys: [],
            selectedRows: [],
            total: 0,
            loading: false,
            nums: [],
            list:[],
            visible:false,
            type:""
        }
    }
   //列表的接口
    onload(ty) {
        // this.setState({ loading: true });
        let array = []
        let total = 0;
        if(ty=="1"){
            this.state.start=0
            }else{
            this.state.start=this.state.page
            }
            // console.log(ty,this.state.start)
        let content = {
            start: this.state.start,
            limit: this.state.pageSize,
             status: this.state.status, type: this.state.type,
             filmName: this.state.filmName,
             modCinemaOrderNum: this.state.modCinemaOrderNum,
             cinemaName: this.state.cinemaName,
             boxSerialNumber: this.state.boxSerialNumber,  
        };
        fetch('/api/downloadTask/queryList', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.params = array;
            this.state.total = total;
            this.setState({ loading: false });
        });
    }
    //生命周期
    componentWillMount() {
        this.onload();
        this.timer=setInterval(() => {
            this.onload();
            }, 6000);
        this.showBtn(this.props.buttonList)//控制按钮显示
    }
     componentWillUnmount(){
      clearInterval(this.timer)
  }
    //控制按钮显示
    showBtn(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_search") {
                this.state.btn_search = true
            }
            if (array[i].buttonCode == "btn_begin") {
                this.state.btn_begin = true
            }
            if (array[i].buttonCode == "btn_suspend") {
                this.state.btn_suspend = true
            }
            if (array[i].buttonCode == "btn_cancel") {
                this.state.btn_cancel = true
            }
        }
    }
    //修改是变化的值
    valueChange(type, e) {
        if (type == "modCinemaOrderNum") {
            this.state.modCinemaOrderNum = e.target.value
        }
        if (type == "filmName") {
            this.state.filmName = e.target.value
        }
        if (type == "cinemaName") {
            this.state.cinemaName = e.target.value
        }
        if (type == "boxSerialNumber") {
            this.state.boxSerialNumber = e.target.value
        }
    }
    //获取选中值
    onSelectChange(selectedRowKeys, selectedRows) {
        this.state.selectedRows = selectedRows
        this.setState({ selectedRowKeys });
    };

     //翻页
    pageChange(page, pageSize) {
        page = (page - 1) * pageSize;
        this.state.page = page;
        this.onload();
      }

    handleChange(type, value) {
        if (type == "status") {
            this.state.status = value
        }
        if (type == "type") {
            this.state.type = value
        }
    }

    // loading = () => {
    //     this.onload()
    // }
     //弹框确定取消
    handleOk(type){
        this.btnActive(type)
        this.setState({visible:false})
      }
    actorCancel(){
        this.setState({visible:false})
    }
    btnClick(type){
        if(this.state.selectedRows.length == 0){
            message.error("请选择数据，再进行操作");
            return;
          }
        this.setState({visible:true,type})
    }
   //调用按钮的接口
    btnActive(type){
        for(var i=0;i<this.state.selectedRows.length;i++){
            this.state.list[i]={serialNumber:this.state.selectedRows[i].boxSerialNumber,
                filmId:this.state.selectedRows[i].filmId,modKeyOrderId:this.state.selectedRows[i].keyOrderId}
        }
        this.setState({ loading: true });
        let content={handle:type,list:this.state.list}
        fetch("/api/device/boxControl", {
          method: "post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(content)
        })
          .then(function(res) {
            if (!res.ok) {
              message.error("网络不可用！");
            }
            return res.json();
          }) 
          .then(function(data) {
            if (data.code == 0) {
              if(type==1000){
                message.success("开始成功！");
              }else if(type==1001){
                message.success("暂停成功！");
              }else if(type==1002){
                message.success("取消成功！");
              }
             
            } else {
              message.error(data.message);
              if (data.code == 2003) {
                history.push({ pathname: "/" });
              }
            }
          })
          .then(e => {
            this.setState({loading: false });
          });
    }

    render() {
        const columns = [
            {
                title: '序号',
                render:(text,record,index)=>`${index+1}`
                
            },
            {
                title: '影片名称',
                dataIndex: 'filmName',
                key: 'filmName',
                render:(text,record,index)=>text
            },
            {
                title: '下载申请单ID',
                dataIndex: 'modCinemaOrderNum',
                key: 'modCinemaOrderNum'
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                render: (text) => text == '0' ? "DCP" : "密钥"
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    if (text == "0") {
                        return <span>未开始</span>
                    }
                    if (text == "1") {
                        return <span>下载中</span>
                    }
                    if (text == "2") {
                        return <span>下载完成待校验</span>
                    }
                    if (text == "3") {
                        return <span>校验中</span>
                    }
                    if (text == "4") {
                        return <span>下载成功(校验完成)</span>
                    }
                    if (text == "5") {
                        return <span>下载失败</span>
                    }
                    if (text == "6") {
                        return <span>取消</span>
                    }
                    if (text == "7") {
                        return <span>暂停</span>
                    }
                }
            }, {
                title: '进度',
                dataIndex: 'percent',
                key: 'percent',
                render:(text)=><span>{text==null?text='0.00'+"%":(text*1).toFixed(2)+"%"}</span>
            },{
                title: '序列号',
                dataIndex: 'boxSerialNumber',
                key: 'boxSerialNumber',
            }, {
                title: '院线',
                dataIndex: 'theaterChainName',
                key: 'theaterChainName',
            },{
                title: '影院',
                dataIndex: 'cinemaName',
                key: 'cinemaName'
            }

        ];
        const dataSource = this.state.params;
        const { selectedRowKeys } = this.state;
        const { selectedRows } = this.state;
        const rowSelection = {
            selectedRowKeys,
            selectedRows,
            onChange: this.onSelectChange.bind(this),//获取选中值
        };    
        const expandedRowRender = (record) => {  //子表单
            const columns=[
            //   { title: '子任务ID', dataIndex: 'id', key: 'id'},
              { title: '文件名', dataIndex: 'filmName', key: 'filmName'},
              { title: '文件大小', dataIndex: 'totalSize', key: 'totalSize',width:"150px",render:(text)=><span>{formatFileSize(text)}</span>},
              { title: 'MD5', dataIndex: 'sourceMd5Value', key: 'sourceMd5Value', ellipsis: true },
              { title: '任务状态', dataIndex: 'status', key: 'status',width:"240px",
               render:(text)=>{
                if(text=="0"){
                    return <span>未开始</span>
                  }
                  if(text=="1"){
                    return <span>下载中</span>
                  }
                  if(text=="2"){
                    return  <span>下载完成待校验</span>
                  }
                  if(text=="3"){
                    return  <span>校验中</span>
                  }
                  if(text=="4"){
                    return  <span>下载成功（校验完成）</span>
                  }
                  if(text=="5"){
                    return  <span>下载失败</span>
                  }
                  if(text=="6"){
                    return  <span>取消</span>
                  }
                  if(text=="7"){
                    return  <span>暂停</span>
                  }
               }
            },
              { title: '进度', dataIndex: 'percent', key: 'percent',width:"150px",
                 render:(text)=><span>{text=(text*1).toFixed(2)+"%"}</span>
            },
            ]
            const data=record.fileList
            return <Table size={"small"} rowKey={record=>record.id} columns={columns} dataSource={data} pagination={false} />
        }
        return (
            <div>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <div className="page-content-info">
                        <div className="toolbar-group">
                            <div className="toolbar-left">
                                <div style={{width:'100%',marginBottom:'20px'}}>
                                <Row>
                                <Button type="primary" style={{marginLeft:10,display:this.state.btn_begin == true?"inline-block":"none"}} onClick={this.btnClick.bind(this,'1000')}>开始</Button>
                                <Button type="primary" style={{marginLeft:20,display:this.state.btn_suspend == true?"inline-block":"none"}} onClick={this.btnClick.bind(this,'1001')}>暂停</Button>
                                <Button type="primary" style={{marginLeft:20,display:this.state.btn_cancel == true?"inline-block":"none"}} onClick={this.btnClick.bind(this,'1002')}>取消</Button>
                                 </Row>
                                </div>
                                <div className="form-main">
                                    <input style={{width:167}} className="form-control" onChange={this.valueChange.bind(this, "filmName")} placeholder="请输入影片名称"/>
                                </div>
                                <div className="form-main">
                                    类型:
                                    <Select
                                        style={{ width: 167, height: 50, margin: '0 0px 0 10px' }}
                                        onChange={this.handleChange.bind(this, "type")}
                                        placeholder='请选择类型'
                                    >
                                        <Option value="">--类型--</Option>
                                        <Option value="0">DCP</Option>
                                        <Option value="1">密钥</Option>
                                    </Select>
                                </div><div className="form-main">
                                    状态:
                                    <Select
                                        style={{ width: 167, height: 50, margin: '0 5px 0 5px' }}
                                        onChange={this.handleChange.bind(this, "status")}
                                        placeholder='请选择状态'
                                    >
                                        <Option value="">--状态--</Option>
                                        <Option value="0">未开始</Option>
                                        <Option value="1">下载中 </Option>
                                        <Option value="2">下载完成待校验</Option>
                                        <Option value="3">校验中</Option>
                                        <Option value="4">下载成功（校验完成）</Option>
                                        <Option value="5">下载失败</Option>
                                        <Option value="6">取消</Option>
                                        <Option value="7">暂停</Option>
                                    </Select>
                                </div>
                                <div className="form-main">
                                    <input style={{width:167}} className="form-control" onChange={this.valueChange.bind(this, "boxSerialNumber")} placeholder="请输入序列号" />
                                </div>
                                <div className="form-main">
                                    <input style={{width:167}} className="form-control" onChange={this.valueChange.bind(this, "modCinemaOrderNum")} placeholder="请输入下载单ID" />
                                </div>
                                <div className="form-main">
                                    <input style={{width:167}} className="form-control" onChange={this.valueChange.bind(this, "cinemaName")} placeholder="请输入影院" />
                                </div>
                                <button style={{ display: this.state.btn_search == true ? "inline-block" : "none" }}
                                 onClick={this.onload.bind(this,'1')} className="btn btn-default btn-search" type="button">检索</button>
                            </div>
                            <div className="toolbar-right" style={{ width: '100%' }}>
                                <Table columns={columns}   expandedRowRender={expandedRowRender}  
                                rowSelection={rowSelection} dataSource={dataSource} rowKey={record=>record.id}
                                pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}
                                />                          
                            </div>
                          
                        </div>
                    </div>
                </Spin>
                <Modal title="提示" okText="确认"  cancelText="取消" width="300px"  style={{marginTop:"20px"}} visible={this.state.visible}  onOk={this.handleOk.bind(this,this.state.type)} onCancel={this.actorCancel.bind(this)}>
                  <div style={{width:'100%',textAlign:"center",height:"5px",lineHeight:"5px"}}>
                  <b>{this.state.type=="1000"?"是否开始?":this.state.type=="1001"?"是否暂停?":"是否取消?"}</b>
                  </div>
                </Modal>
            </div>
        )
    }
}
export default DownloadTaskList;
    
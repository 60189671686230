import React from 'react';
import {Tabs,message} from 'antd';
import history from '../history';
import FilmBagUseRecordList from './FilmBagUseRecordList.js';

const { TabPane } = Tabs;
class FilmBagUseRecord extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        isfalse:null,
      }
    }
   
    render(){
      const {isfalse}=this.state;
      let basics = {};
      basics.innerPlayList = this.state.innerPlayList;
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey}>
            <TabPane tab="消费记录列表" key="1">
              <FilmBagUseRecordList  buttonList={this.props.buttonList}/>
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default FilmBagUseRecord;
import React from 'react';
import {Tabs} from 'antd';
import FilmList from './filmList.js'; 
import FilmDetails from './filmDetails.js';
import AppointmentAndStatistics from './appointmentAndStatistics.js';

const { TabPane } = Tabs;
class FilmManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isfalse:null,
        type:0,
      }
    }
    callback(activeKey,type,params){
        if(type == "details"){
          this.state.isfalse=true
          this.state.params = params
        }
        if(type == "add"){
          this.state.isfalse=false
          this.state.params = null
        }
      this.setState({activeKey:activeKey})
    }
    
    render(){
      let {isfalse}=this.state
        return(
          <div>
              <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
              <TabPane tab="影片列表" key="1">
                <FilmList type={this.state.type} buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
              </TabPane>
              <TabPane tab="影片详情" key="2">
                <FilmDetails buttonList={this.props.buttonList} isfalse={isfalse} params = {this.state.params} callback = {this.callback.bind(this)}/>
              </TabPane>
              <TabPane tab="预约统计" key="3">
                <AppointmentAndStatistics buttonList={this.props.buttonList} isfalse={isfalse} params = {this.state.params} callback = {this.callback.bind(this)}/>
              </TabPane>
            </Tabs>
          </div>
        )
    }
}
export default FilmManage;
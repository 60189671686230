import React from 'react';
import { Table, Spin,Button,message,Tabs,Modal,Select,Input} from 'antd';
import history from '../history';

const { TabPane } = Tabs;
const { confirm } = Modal;
const {Option} = Select;
class UserList extends React.Component{
  
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
			loading:false
		}
	}
		//获取列表数据的接口
		onload(ty){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			if(ty=="1"){
				this.state.start=0
			  }else{
				this.state.start=this.state.page
			  }
			let content = {start:this.state.start,limit:this.state.pageSize,
				name:this.state.name,telephone:this.state.telephone,
				userSource:this.state.userSource
			};
			fetch('/api/userCenter/userList',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(content),
					}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								 array=data.result;    
								 total=data.total;
							}else  {
								message.error(data.message);
									if(data.code == 2003){
										history.push({pathname:'/'});
								}
							}
					}).then( (e) => {
						this.state.params=array;
						this.state.total=total;
						this.state.selectedRowKeys = [];
						this.state.selectedRow = [];
						this.setState({ loading: false });
				 });
		 }
		 componentWillMount() {
			this.onload()
			this.showBtn(this.props.buttonList)//控制按钮显示
		}
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_search"){
						this.state.btn_search = true
				  }
				  if(array[i].buttonCode == "btn_add"){
					this.state.btn_add = true
				  }
				  if(array[i].buttonCode == "btn_delete"){
					this.state.btn_delete = true
				  }
			 }
		}
       //input框的值
		valueChange(type,e) {
			if(type == "name"){
				this.state.name=e.target.value;
			}
			if(type == "telephone"){
				this.state.telephone=e.target.value;
			}
			if(type == "userSource"){
				this.state.userSource=e;
			}
		}
		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
		
		pageChange(page,pageSize){//翻页
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
	 }
	 //删除
	  showConfirm = () => {
		if(this.state.selectedRow.length == 0){
			message.error("尚未选中数据！");
			return;
		}
		confirm({
		  title: '确定删除?',
		  content: '',
		  okText: '确定',
		  cancelText:"取消",
		  onOk:()=> {
			this.setState({ loading: true });
			let array=this.state.selectedRow
			fetch('/api/userCenter/delUser',{
				method: 'post',
				credentials: 'include',
					headers: {
							'Content-Type': 'application/json'
					},
					body: JSON.stringify(array),
					}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								message.success("删除成功")
							}else  {
								message.error(data.message);
									if(data.code == 2003){
										history.push({pathname:'/'});
								}
							}
					}).then( (e) => {
						this.state.selectedRow.length=0;
						this.onload();
				 });
		  },
		  onCancel() {
		  },
		});
	  }
    render(){
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title:'用户名',  
					dataIndex:'code',
					key:'code',
					render: (text,record) => <a href="javascript:;" onClick = {this.props.callback.bind(this,"2","details",record)}>{text}</a>,
					},
				{
					title: '姓名',
					dataIndex: 'name',
					key: 'name',
					
				},
				
				{
					title: '联系电话',
					dataIndex: 'telephone',
					key: 'telephone',
				},
				{
					title: '用户归属',
					dataIndex: 'userSource',
					key: 'userSource',
					render:(text,record,index)=>{
						if(text == 0){
							return '运营人员'
						}
						if(text == 1){
							return '影院人员'
						}
					}             
				},
				{
					title: '创建时间',
					dataIndex: 'createTime',
					key: 'createTime',
				}
				
			];
			const dataSource = this.state.params;
			const {selectedRowKeys,selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
								<div className="page-content-info">
									<div className="toolbar-group">
										<div className="toolbar-left">
											<div className="form-main">
												<Input className="form-control" onChange={this.valueChange.bind(this,"name")} placeholder="请输入用户姓名"/>
											</div>
											<div className="form-main">
												<Input className="form-control" onChange={this.valueChange.bind(this,"telephone")} placeholder="请输入用户手机号"/>
											</div>
											<div className="form-main">
												<Select style={{width:167}}  onChange={this.valueChange.bind(this,"userSource")} placeholder="请选择用户归属">
													<Option value="">--用户归属--</Option>
													<Option value="0">运营人员</Option>
													<Option value="1">影院人员</Option>
												</Select>
											</div>
											<button style={{display:this.state.btn_search == true?"inline-block":"none"}} onClick={this.onload.bind(this,'1')} className="btn btn-default btn-search" type="button">检索</button>
										</div>
										<div><Button style={{display:this.state.btn_add == true?"inline-block":"none",marginLeft:10}} type="primary" onClick = {this.props.callback.bind(this,"2","add",null)}>新增</Button>
										     <Button style={{display:this.state.btn_delete == true?"inline-block":"none",marginLeft:10}} type="danger" onClick = {this.showConfirm.bind(this,this)}>删除</Button>
										</div>
										<div className="toolbar-right" style={{width:'100%'}}>
											<Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
										</div>
									</div>
								</div>
              </Spin>
            </div>    
        )
    }
}
export default UserList;
import React from 'react';
import { Table, Spin, message, Select, Modal,Input,Button,Icon,Upload} from 'antd';
import history from '../history';
import { getFileItem } from 'antd/lib/upload/utils';
const {Option} = Select;
const { confirm } = Modal;
const {TextArea} =Input;
class FilmAreaDetails extends React.Component{
    constructor(props) {
      super(props);  
      this.state={
        params:{},
        page:0,
        pageSize:10,
        total:0,
        loading:false,
        isfalse: null,
        esfalse:false,
        fileList:[],
        dataSource: [],
        filmList:[],
        previewVisible: false,
        previewImage:'',
      }
    }
    componentWillMount() {
      if(this.props.params != null){
           this.state.isfalse = this.props.isfalse;
           this.state.params = this.props.params;
           this.onload(this.state.params.id);
      }else{
        this.state.fileList=[];
        this.state.dataSource=[];
      }
      this.getFile();
      this.showBtn(this.props.buttonList)//控制按钮显示
   }
   //控制按钮显示
   showBtn(array){
    for(var i= 0; i<array.length;i++){
         if(array[i].buttonCode == "btn_save"){
           this.state.btn_save = true
         }
         if (array[i].buttonCode == "btn_update") {
           this.state.btn_update = true;
         }
         if (array[i].buttonCode == "btn_cancel") {
           this.state.btn_cancel = true;
         }
      }
    }
    componentWillReceiveProps(nextProps) {
      if(nextProps != null ){
          if(nextProps.params != null){
              this.state.isfalse = nextProps.isfalse;
              this.state.params = nextProps.params;
              this.onload(this.state.params.id);
              this.state.esfalse=false;
              this.getFile();
          }else{
            this.state.fileList=[];
              this.state.isfalse=null
              this.setState({
                  params: {},
                  dataSource:[]
              });
          }
          
      }else{
        this.state.fileList=[];
          this.state.isfalse=null
          this.setState({
              params: {},
              dataSource:[]
          });
      }
    }
    onload(id){
      let dataParams={};
      this.setState({ loading: true });
      let content = {id:id};
      fetch('/api/zone/zoneDetails',{
        method: 'post',
				credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
      },
        body: JSON.stringify(content),
      }).then(function (res) {             
      if(!res.ok){                 
          message.error("网络不可用！");}
          return res.json();
      }).then(function (data) {
        if (data.code == 0) {
          dataParams = data.result;
        }else {
          message.error(data.message);
        if(data.code == 2003){
            history.push({pathname:'/'});
          }
        }  
      }).then((e)=>{
        this.state.fileList = [];
        if(this.state.params.address!=null){
          var imgPath = {
            uid: dataParams.id,
            url:this.state.params.address
          }
            this.state.fileList.push(imgPath);
        }
        this.setState({ fileList: this.state.fileList,params:dataParams,loading:false,dataSource:dataParams.datZoneFilmList});
      });        
    }
    //获取影片名称
    getFile(){
      let dataParams={};
      this.setState({ loading: true });
      let content = {};
      fetch('/api/cinema/film/searchByCinema',{
        method: 'post',
				credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
      },
        body: JSON.stringify(content),
      }).then(function (res) {             
      if(!res.ok){                 
          message.error("网络不可用！");}
          return res.json();
      }).then(function (data) {
        if (data.code == 0) {
          dataParams = data.result;
        }else {
          message.error(data.message);
        if(data.code == 2003){
            history.push({pathname:'/'});
          }
        }  
      }).then((e)=>{
        this.setState({filmList:dataParams,loading:false});
      });     
    }
    save(){
      if(this.state.params.name == undefined || this.state.params.name== ''||this.state.params.name==null){
        message.error("专区名称未填写，请核实！");
        return;
      };
      if(this.state.dataSource.length==0){
        message.error("未新增子表数据，请核实！");
        return;
      }
      if(this.state.dataSource.some(item=>{return item.filmId==null||item.rank==null||item.filmId==''||item.rank==''})){
        message.error("影片名称或序列值未填写，请核实！")
        return;
      }
      this.state.params.datZoneFilmList=this.state.dataSource;
      this.state.params.address=this.state.url;
      this.setState({ loading: true });
      fetch('/api/zone/saveZone',{
        method: 'post',
				credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
      },
        body: JSON.stringify(this.state.params),              
      }).then(function(res){
        if(!res.ok){
          message.error('网络不可用！');
        }
        return res.json();
      }).then((data)=>{
        if(data.code == 0){
          message.success('保存成功');
          if(data.result.id!=null){
            this.onload(data.result.id)
          }
          this.setState({loading:false,isfalse: true, esfalse: false})
        }else{
          message.error(data.message);
          if(data.code == 2003){
            history.push({pathname:'/'});
          }
        }
      }).then(e=>{
        this.setState({loading:false})
      })
    }
    cencel(type) {
      //修改还是取消
      if (type == 'write') {
          this.setState({
              isfalse: false,
              esfalse:true
          });
      } else {
          this.setState({
              isfalse: true,
              esfalse: false,
          });
      }
    }
    handleChange(type,e){
      let {params}=this.state;
      if(type == 'name'){
        params.name=e.target.value;
      }
      if( type == 'description'){
        params.description=e.target.value;
      }
      this.setState({params});
    }
    valueEvent(type,index,e){
      let { dataSource,params}=this.state;
      if (type == 'filmId') {
        dataSource[index].filmId = e;
        dataSource[index].zoneId = params.id;
      }
      if(type == 'rank'){
        dataSource[index].rank = e.target.value;
      }
     
      this.setState({ dataSource });
    }
    //获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
    //新增
    handleAdd = () => { 
      const { count } = this.state;
      this.state.dataSource.unshift({});
      this.setState({
        count: count + 1
      });
  
    }
    //点击删除
    deleteData(index) {
      let arrs = this.state.dataSource
      arrs.splice(index, 1);
      this.setState({
        dataSource: [...arrs]
      });
    }
    //关于js上传的
    handleCancel = () => this.setState({ previewVisible: false });
    handlePreview = async file => {
      if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
      }
      this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      });
    };
    imgChange = ({ fileList }) => this.setState({ fileList });
    getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
      render(){
        let { isfalse ,esfalse,previewVisible,fileList,previewImage} = this.state;
        const columns = [
          {
            title: '序号',
            render:(text,record,index)=>`${index+1}`,
          },
          {
            title: '影片名称',
            dataIndex: 'filmId',
            key: 'filmId',
            render: (text,record,index) =>{return <Select value={text}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
             onChange={this.valueEvent.bind(this,'filmId',index)} disabled={isfalse} style={{width:"200px"}}>

              <Option value=''>请选择</Option>
              {this.state.filmList.map(item=>{
                return <Option value={item.id}>{item.filmName}</Option>
              })}
            </Select>},
          },
        
          {
            title: '序列值',
            dataIndex: 'rank',
            key: 'rank',
            render:(text,record,index)=><Input disabled={isfalse} style={{width:200}} value={text} onChange={this.valueEvent.bind(this,'rank',index)}/>
          },
          {
            title: '操作',
            dataIndex: '',
            key: '',
            render: (text,record,index) =><Button disabled={isfalse} onClick={this.deleteData.bind(this, index)}>删除</Button>,
          }

        ];
        if(fileList.length==0){
          this.state.url = null;
        }else if(fileList.length>0&&fileList[0].status == "done"){
          this.state.url = fileList[0].response.result
        }else {
          this.state.url = fileList[0].url;
        }
        
        
        const uploadButtons = (
          <div style={{ height: "87.99px", width: "103.99" }}>
              <Icon type="plus" />
              <div className="ant-upload-text">上传图片</div>
          </div>
      );
        return(
            <div className = "FimAreaDetails" key={(this.state.params != null && this.state.params.id == '-1')?this.state.params.id:this.state.params.id}>
                <Spin spinning={this.state.loading} tip="Loading...">
                  <div style = {{margin:"0 0 18px 0px"}}>
                        <Button  type="primary" disabled={isfalse} onClick = {this.save.bind(this)}>保存</Button> 
                        <Button style={{marginLeft: 20 }} disabled={esfalse}  onClick={this.cencel.bind(this, 'write')}> 修改</Button>
                        <Button style={{marginLeft: 20}} disabled={isfalse} onClick={this.cencel.bind(this)}> 取消 </Button>
                        <Button style={{marginLeft:20}} onClick = {this.props.callback.bind(this,"1",null,null)}>返回</Button>
                    </div> 
                    <Input hidden={true} value={this.state.params.id}/>
                    <p>名称:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                      <Input style={{width:240,marginLeft:10}} disabled={isfalse} value={this.state.params.name} placeholder = "请输入名称" onChange={this.handleChange.bind(this,"name")}/>
                    </p>
                    <div><span>介绍:</span>
                    {/* <span style={{color:"#b94a48",fontWeight:900}}>*</span>  */}
                      <div>
                      <TextArea style={{width:240,margin:'-19px 0 0 51px',height:'80px'}}  placeholder="请输入专区介绍" disabled={isfalse} value={this.state.params.description} onChange={this.handleChange.bind(this,"description")}></TextArea>
                      </div>
                    </div>
                    <div>图片: 
                    <div style={{ marginLeft: 47, marginTop: 10 }}>
                                <Upload
                                    disabled={isfalse}
                                    action="/api/zone/zoneImage"//上传地址
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={this.handlePreview}
                                    onChange={this.imgChange}
                                >
                                    {fileList.length >= 1 ? null : uploadButtons} 
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="example" style={{ width: '100%' }}  src={previewImage}/>
                                </Modal>
                            </div>
                    </div>
                    <div style={{margin:'20px 0 10px 0'}}><Button disabled={isfalse} type="primary" onClick={this.handleAdd}  >新增</Button></div>
                    <Table style={{marginBottom:'50px'}} size={"small"}  columns={columns}  dataSource={this.state.dataSource}  rowKey={record => record.id} pagination={false}/>
                    {/* rowSelection={rowSelection} */}
                </Spin>    
            </div>    
        )
    }
}
export default FilmAreaDetails;
import React from 'react';
import {Select,message,Button,Spin,Row,Col,Input} from 'antd';
import history from '../history';
const { Option } = Select;

class FilmBagDetails extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:{},
        loading:false,
        isfalse:null
      }
    } 
    componentWillMount(){
      if (this.props.params != null) {
        this.state.isfalse=this.props.isfalse;
        this.state.params = this.props.params;
        this.onload(this.state.params.id);
      }
      // this.showBtn(this.props.buttonList); //控制按钮显示
      this.state.validityList=this.props.basics.validityList;
      this.state.innerPlayList=this.props.basics.innerPlayList;
      this.state.numberOfSeatsList=this.props.basics.numberOfSeatsList;
      this.state.buyTypeList=this.props.basics.buyTypeList;
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps != null) {
        if (nextProps.params != null) {
          this.state.params=nextProps.params;
          this.state.isfalse=nextProps.isfalse;
          this.onload(this.state.params.id);
          this.state.esfalse=false;
        } else {
          this.state.isfalse=null;
          this.setState({
            params: {}
          });
        }
      } else {
        this.state.isfalse=null;
        this.state.validityList=this.props.basics.validityList;
        this.state.innerPlayList=this.props.basics.innerPlayList;
        this.state.numberOfSeatsList=this.props.basics.numberOfSeatsList;
        this.state.buyTypeList=this.props.basics.buyTypeList;
        this.setState({
          params: {}
        });
      }
    }
    onload(id){
      let dataParams = {};
      this.setState({ loading: true });
      let content = {id:id}
      fetch('/api/filmBag/filmBagDetails',{
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(content),
        }).then(function (res) { 
          if(!res.ok){                 
            message.error("网络不可用！");             
          }
          return res.json();
        }).then( (data)=> {
          if (data.code == 0) {
            dataParams = data.result;
          }else {
            message.error(data.message);
            if(data.code == 2003){
              history.push({pathname:'/'});
            }
          }
        }).then( (e) => {
          this.setState({ params:dataParams,loading: false});
        });
  }
  //值
  changeEvent(type,e){
    let {params}=this.state;
    if(type=="name"){
      params.name=e.target.value;
    }
    if(type=="saleOrderNum"){
      params.saleOrderNum=e.target.value;
    }
    if(type=="validity"){
      params.validity=e;
    }
    if(type=="innerPlay"){
      params.innerPlay=e;
    }
    if(type=="numberOfSeats"){
      params.numberOfSeats=e;
    }
    if(type=="buyType"){
      params.buyType=e;
    }
    if(type=="isSubsidy"){
      params.isSubsidy=e;
    }
    if(type=="subsidyNumber"){
      params.subsidyNumber=e.target.value;
    }
    if(type=="saleProperties"){
      params.saleProperties=e;
    }
    if(type=="isInnerSettle"){
      params.isInnerSettle=e;
    }
    if(type=="singlePrice"){
      params.singlePrice=e.target.value;
    }
    if(type=="erpCode"){
      params.erpCode=e;
    }
    this.setState({params});
  }
   //修改还是取消
  cencel(type) { 
    if (type == 'write') {
        this.setState({
            isfalse: false,
            esfalse:true
        });
    } else {
        this.setState({
            isfalse: true,
            esfalse: false,
        });
    }
  }
  //保存
  save(){
    let {params}=this.state;
    if(params.name==''||params.name==undefined||params.name==null){
      return message.error('片包名称未填写！')
    }
    if(params.saleOrderNum==''||params.saleOrderNum==undefined||params.saleOrderNum==null){
      return message.error('销售订单号未填写！')
    }
    if(params.validity==''||params.validity==undefined||params.validity==null){
      return message.error('有效期未选择！')
    }
    if(params.innerPlay==''||params.innerPlay==undefined||params.innerPlay==null){
      return message.error('内置场次未选择！')
    }
    if(params.numberOfSeats==''||params.numberOfSeats==undefined||params.numberOfSeats==null){
      return message.error('座位数未选择！')
    }
    if(params.buyType==''||params.buyType==undefined||params.buyType==null){
      return message.error('购买对象未选择！')
    }
    if(params.isSubsidy==''||params.isSubsidy==undefined||params.isSubsidy==null){
      return message.error('是否过期场次补贴未选择！')
    }
    if(params.isInnerSettle==''||params.isInnerSettle==undefined||params.isInnerSettle==null){
      return message.error('是否内部结算未选择！')
    }
    if(params.singlePrice==''||params.singlePrice==undefined||params.singlePrice==null){
      return message.error('单次销售未填写！')
    }
    this.setState({ loading: true });
    let content = this.state.params;
    fetch('/api/filmBag/saveFilmBag',{
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
      }).then(function (res) { 
        if(!res.ok){                 
          message.error("网络不可用！");             
        }
        return res.json();
      }).then( (data)=> {
        if (data.code == 0) {
          message.success('保存成功');
          if(data.result.id!=null){
            this.onload(data.result.id)
          }
        }else {
          message.error(data.message);
          if(data.code == 2003){
            history.push({pathname:'/'});
          }
        }
      }).then( (e) => {
        this.setState({loading: false,isfalse:true,esfalse:false});
      });
  }
    render(){
      const {isfalse,esfalse,params}=this.state;
        return(
          <div>
            <Spin spinning={this.state.loading} tip="Loading...">
              <div>
                {
                  params.saleProperties=='2'?<><Button type="primary" style={{marginLeft:10}} disabled={true} onClick={this.save.bind(this)}>保存</Button>
                  <Button type="primary" style={{marginLeft:20}} disabled={true} onClick={this.cencel.bind(this,'write')}>修改</Button>
                  <Button type="primary" style={{marginLeft:20}} disabled={true} onClick={this.cencel.bind(this)}>取消</Button></>:
                  <><Button type="primary" style={{marginLeft:10}} disabled={isfalse} onClick={this.save.bind(this)}>保存</Button>
                  <Button type="primary" style={{marginLeft:20}} disabled={esfalse} onClick={this.cencel.bind(this,'write')}>修改</Button>
                  <Button type="primary" style={{marginLeft:20}} disabled={isfalse} onClick={this.cencel.bind(this)}>取消</Button></>
                }
                <Button type="primary" style={{marginLeft:20}} onClick = {this.props.callback.bind(this,"1",null,null)}>返回</Button>
              </div>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                片包名称:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}>
                <Input  disabled={isfalse} style={{width:'200px'}} value={this.state.params.name} onChange={this.changeEvent.bind(this,'name')}></Input>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                  销售属性:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}> 
                  <Select  disabled={isfalse} style={{width:'200px'}} value={params.saleProperties=="2"?'退次':params.saleProperties=="0"?'出售':params.saleProperties=="1"?'赠送':''} onChange={this.changeEvent.bind(this,'saleProperties')}>
                    <Option value="0">出售</Option>
                    <Option value="1">赠送</Option>
                  </Select>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                销售订单号:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}>
                <Input  disabled={isfalse} style={{width:'200px'}} value={this.state.params.saleOrderNum}  onChange={this.changeEvent.bind(this,'saleOrderNum')}></Input>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                  有效期:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}> 
                  <Select  disabled={isfalse} style={{width:'200px'}}value={this.state.params.validity} onChange={this.changeEvent.bind(this,'validity')}>
                    {
                      this.state.validityList&&this.state.validityList.map(item=>{
                        return <Option value={item.enumName}>{item.enumName}</Option>
                      })
                    }
                  </Select>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                  内置场次:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}> 
                  <Select  disabled={isfalse} style={{width:'200px'}} value={this.state.params.innerPlay} onChange={this.changeEvent.bind(this,'innerPlay')}>
                  {
                      this.state.innerPlayList&&this.state.innerPlayList.map(item=>{
                        return <Option value={item.enumName}>{item.enumName}</Option>
                      })
                    }
                  </Select>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                  座位数:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}> 
                  <Select  disabled={isfalse} style={{width:'200px'}} value={this.state.params.numberOfSeats} onChange={this.changeEvent.bind(this,'numberOfSeats')}>
                  {
                      this.state.numberOfSeatsList&& this.state.numberOfSeatsList.map(item=>{
                        return <Option value={item.enumName}>{item.enumName}</Option>
                      })
                    }
                  </Select>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                  购买对象:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}> 
                  <Select  disabled={isfalse} style={{width:'200px'}} value={this.state.params.buyType=='1'?'客户':params.buyType=='2'?'代理商':params.buyType=='3'?'经销商':''} onChange={this.changeEvent.bind(this,'buyType')}>
                    {
                      this.state.buyTypeList&&this.state.buyTypeList.map(item=>{
                        return <Option value={item.enumValue}>{item.enumName}</Option>
                      })
                    }
                  </Select>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                  是否过期场次补贴:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}> 
                  <Select  disabled={isfalse} style={{width:'200px'}} value={this.state.params.isSubsidy} onChange={this.changeEvent.bind(this,'isSubsidy')}>
                    <Option value="0">否</Option>
                    <Option value="1">是</Option>
                  </Select>
                </Col>
              </Row>
              {
                this.state.params.isSubsidy=='1'?<Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                补贴场次:
                </Col>
                <Col span={1}></Col>
                <Col span={5}>
                <Input  disabled={isfalse} style={{width:'200px'}} value={this.state.params.subsidyNumber} onChange={this.changeEvent.bind(this,'subsidyNumber')}></Input>
                </Col>
              </Row>:''
              }
               <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                  是否过内部结算:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}> 
                  <Select  disabled={isfalse} style={{width:'200px'}} value={this.state.params.isInnerSettle} onChange={this.changeEvent.bind(this,'isInnerSettle')}>
                    <Option value="0">否</Option>
                    <Option value="1">是</Option>
                  </Select>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                单次售价:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}>
                <Input  disabled={isfalse} style={{width:'200px'}} value={this.state.params.singlePrice} onChange={this.changeEvent.bind(this,'singlePrice')}></Input>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                物料编码:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </Col>
                <Col span={1}></Col>
                <Col span={5}> 
                  <Select  disabled={isfalse} style={{width:'200px'}} value={this.state.params.erpCode} onChange={this.changeEvent.bind(this,'erpCode')}>
                    <Option value="0901002">CL20-01-10</Option>
                    <Option value="0901003">CL20-01-30</Option>
                    <Option value="0901004">CL20-01-60</Option>
                    <Option value="0901005">CL20-01-80</Option>
                    <Option value="0901006">CL50-03-10</Option>
                    <Option value="0901007">CL50-03-30</Option>
                    <Option value="0901008">CL50-03-60</Option>
                    <Option value="0901009">CL50-03-80</Option>
                    <Option value="0901031">CL01-01-01</Option>
                  </Select>
                </Col>
              </Row>
              <Row style={{marginTop:'20px'}}>
                <Col span={3} style={{textAlign:'right'}}>
                定价:
                </Col>
                <Col span={1}></Col>
                <Col span={5}>
                <Input  disabled={true} style={{width:'200px'}} value={this.state.params.price}></Input>
                </Col>
              </Row>
            </Spin>
          </div>
        )
    }
}
export default FilmBagDetails;
import React from 'react';
import { Table, Spin,Input,message,Select,Modal,Button} from 'antd';
import history from '../history';
const {Option}=Select;
class FilmBagUseRecordList extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:[],
        page:0,
        pageSize:10,
        selectedRowKeys: [],
        selectedRow:[],
        total:0,
        loading:false,
        isShow:false
      }
    }
    componentWillMount(){
      this.onload();
      this.searchEnum2();
    }
    //获取列表数据接口
    onload(ty){
      this.setState({loading:true});
      let array=[];
      let total=0;
      if(ty=='1'){
        this.state.start=0;
      }else{
        this.state.start=this.state.page;
      }
      let content={start:this.state.start,
        limit:this.state.pageSize,
        cinemaName:this.state.cinemaName,
        filmBagCode:this.state.filmBagCode,
        orderNum:this.state.orderNum,
        filmName:this.state.filmName
      };
      fetch('/api/filmBag/queryConsumeList',{
        method:'post',
        credentials:'include',
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify(content),
      }).then(function(res){
        if(!res.ok){
          message.error('网络不可用！');
        }
        return res.json();
      }).then(function(data){
        if(data.code==0){
          array=data.result;
          total=data.total;
        }else{
          message.error(data.message);
          if(data.code==2003){
            history.push({pathname:'/'})
          }
        }
      }).then(e=>{
        this.setState({total:total,params:array,loading:false,selectedRowKeys:[],selectedRow:[]});
      })
    }
    searchEnum2(){
      let array=[];
      let content={type:'5'};
      fetch('/api/common/searchEnum',{
      method:'post',
      credentials:'include',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify(content),
    }).then(function(res){
      if(!res.ok){
        message.error('网络不可用！');
      }
      return res.json();
    }).then(function(data){
      if(data.code==0){
        array=data.result;
      }else{
        message.error(data.message);
        if(data.code==2003){
          history.push({pathname:'/'})
        }
      }
    }).then(e=>{
      this.setState({innerPlayList:array});
    })
   }
    //翻页
    pageChange(page,pageSize){
      page = (page - 1) * pageSize;
      this.state.page = page;
      this.onload();
		}
    //值
    inputEvent(type,e){
      let {name,innerPlay}=this.state;
      if(type=="name"){
        name=e.target.value;
      }
      if(type=="innerPlay"){
        innerPlay=e;
      }
      this.setState({name,innerPlay});
    }
    valueChange(type,e){
      let {cinemaName,filmBagCode,orderNum,filmName}=this.state;
      if(type=="cinemaName"){
        cinemaName=e.target.value;
      }
      if(type=="filmBagCode"){
        filmBagCode=e.target.value;
      }
      if(type=="orderNum"){
        orderNum=e.target.value;
      }
      if(type=="filmName"){
        filmName=e.target.value;
      }
      this.setState({cinemaName,filmBagCode,orderNum,filmName});
    }
    //退次
    clickEvent(record,type){
      this.setState({filmBagId:record.filmBagId,userId:record.userId,isShow:type});
    }
    //弹框确定
    ok(){
      let filmBagInfo={name:this.state.name,innerPlay:this.state.innerPlay}
      let content={filmBagId:this.state.filmBagId,filmBagInfo,userId:this.state.userId};
      fetch('/api/filmBag/backFilmBag',{
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(content),
        }).then(function (res) {
          if(!res.ok){
            message.error("网络不可用！");
          }
          return res.json();
        }).then( (data)=> {
          if (data.code == 0) {
            message.success("退次成功");
          }else  {
            message.error(data.message);
            if(data.code == 2003){
              history.push({pathname:'/'});
            }
          }
        }).then(e=>{
          this.setState({isShow:false,name:'',innerPlay:''});
          this.onload();
        })
     
    }
    //弹框取消
    cancel(type){
      this.setState({isShow:type,name:'',innerPlay:''});
    }
    //数据导出
    exportEvent(){
      let content={
        cinemaName:this.state.cinemaName,
        filmBagCode:this.state.filmBagCode,
        orderNum:this.state.orderNum,
        filmName:this.state.filmName
      };
      fetch('/api/filmBag/exportConsumeList', {
        method: 'post',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'  
        },
        body: JSON.stringify(content),
    }).then(response => {
        response.blob().then(blob => {
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a'); //获取a标签元素
          let filename='useRecordList.xls';
          a.href = blobUrl; //设置a标签路径
          a.download =filename;
          a.click();
          window.URL.revokeObjectURL(blobUrl);
          message.success("导出成功");
        });
      })
      .catch(error => {
        message.error("导出失败", error);
      });
    }
    render(){
      const {selectedRowKeys,selectedRow,isShow}=this.state;
      const columns=[
        {
          title:'序号',
          render:(text,record,index)=>`${index+1}`
        },
      
        {
          title:'片包名称',
          dataIndex:'filmBagName',
          key:'filmBagName',
        },
        {
          title:'片包编号',
          dataIndex:'filmBagCode',
          key:'filmBagCode',
        },
        {
          title:'销售类型',
          dataIndex:'saleProperties',
          key:'saleProperties',
          render:(text)=><span>{text==0?'有价':text==1?'赠送':''}</span>
        },
        {
          title:'影院名称',
          dataIndex:'cinemaName',
          key:'cinemaName',
         
        },
        {
          title:'片包总场次数',
          dataIndex:'innerPlay',
          key:'innerPlay',
        },
        {
          title:'剩余数',
          dataIndex:'remainingTimes',
          key:'remainingTimes',
        },
        {
          title:'消耗次数',
          dataIndex:'playCount',
          key:'playCount',
        },
        {
          title:'订单号',
          dataIndex:'orderNum',
          key:'orderNum',
         
        },
        {
          title:'影片名称',
          dataIndex:'filmName',
          key:'filmName',
        },
        {
          title:'是否上传密钥',
          dataIndex:'isUploadKey',
          key:'isUploadKey',
          render:(text)=><span>{text==0?'否':text==1?'是':''}</span>
        },
      
        // {
        //   title:'操作',
        //   dataIndex:'',
        //   key:'',
        //   render:(text,record,index)=><a href="javascript:;" onClick={this.clickEvent.bind(this,record,true)}>退次</a>
        // },
      ]
        return(
          <div>
            <Spin spinning={this.state.loading} tip="Loading">
              <div className="page-content-info">
                <div className="toolbar-group">
                  <div className="toolbar-left">
                  <div className="form-main">
                      <input style={{width:167}} className="form-control" onChange={this.valueChange.bind(this, "filmBagCode")} placeholder="请输入片包编码"/>
                    </div> 
                    <div className="form-main">
                      <input style={{width:167}} className="form-control" onChange={this.valueChange.bind(this, "cinemaName")} placeholder="请输入影院名称"/>
                    </div> 
                    <div className="form-main">
                      <input style={{width:167}} className="form-control" onChange={this.valueChange.bind(this, "orderNum")} placeholder="请输入订单号"/>
                    </div> 
                    <div className="form-main">
                      <input style={{width:167}} className="form-control" onChange={this.valueChange.bind(this, "filmName")} placeholder="请输入影片名称"/>
                    </div>               
                    <button onClick={this.onload.bind(this,'1')} className="btn btn-default btn-search" type="button">检索</button>
                   
                  </div>
                  <div>
				            <Button style={{marginLeft:10}} type="primary" onClick={this.exportEvent.bind(this)}>导出</Button>
					        </div> 
                  <div class="toolbar-right" style={{width:'100%'}}>
						        <Table columns={columns} dataSource={this.state.params}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
						      </div>
                </div>
              </div>
            </Spin>
            <Modal visible={isShow} title="是否补次" okText="确定" cancelText="取消" onOk={this.ok.bind(this)} onCancel={this.cancel.bind(this,false)}>
              片包名称：<Input placeholder="片包名称" style={{width:'260px'}} value={this.state.name} onChange={this.inputEvent.bind(this,'name')}></Input><br/>
              内置场次：<Select style={{width:'260px',marginTop:'10px'}} onChange={this.inputEvent.bind(this,'innerPlay')} value={this.state.innerPlay}>
              {
                      this.state.innerPlayList&&this.state.innerPlayList.map(item=>{
                        return <Option value={item.enumName}>{item.enumName}</Option>
                      })
                    }
              </Select>
            </Modal>
          </div>
        )
    }
}
export default FilmBagUseRecordList;
import React from "react";
import { Table, Spin, message, Select, Modal, DatePicker,Button,Input,Icon} from "antd";
import history from "../history";

const { Option } = Select;
const {TextArea}=Input;
class InvoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      cinemaList: [],
      startValue: null,//日期定义
      endValue: null,
      approBeginTime:null,
      approEndTime:null,
      endOpen: false,
      visible:false,
      list:[],
      orderBy:'create_time',
      orderMethod:'desc',
    };
  }
  //获取列表数据的接口
  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if(ty=="1"){
      this.state.start=0
      }else{
      this.state.start=this.state.page
      }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize, 
      beginTime: this.state.beginTime,
      endTime: this.state.endTime,
      userName:this.state.userName,
      num:this.state.num,
      invoiceRoute:this.state.invoiceRoute,
      status:this.state.status,
      auditStatus:this.state.auditStatus,
      orderBy:this.state.orderBy,
      orderMethod:this.state.orderMethod
    };
    fetch("/api/invoice/queryInvoiceList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.auditOpinion='';
        this.state.selectedRowKeys = [];
        this.setState({ loading: false });
      });
  }
  componentWillMount() {
    this.onload();
    this.showBtn(this.props.buttonList); //控制按钮显示
  }

  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search= true;
      }
      if (array[i].buttonCode == "btn_cannel_pay") {
        this.state.btn_cannel_pay = true;
      }
    }
  }


  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }

  //翻页
  pageChange(page, pageSize) {
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
  //选择日期
  OnChange(type, date, dateString) {
    if (type == "createTime") {
      this.state.createTime = dateString
      console.log(this.state.createTime)
    }

  }
  //input框的值
  valueChange(type, e) {
    if (type == "userName") {
      this.state.userName = e.target.value
    }
    if (type == "num") {
        this.state.num = e.target.value
    }
    if (type == "invoiceRoute") {
      this.state.invoiceRoute = e.target.value
    }
    if (type == "status") {
      this.state.status = e
    }
    if (type == "auditStatus") {
      this.state.auditStatus = e
    }
  }
 
  //关于日期
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };

  onStartChange = (value, vs) => {
    this.state.beginTime = vs
    this.onChange('startValue', value);
  };
    
  onEndChange = (value, vs) => {
    this.state.endTime = vs
    this.onChange('endValue', value);
  };
  // StartChange = (value, vs) => {
  //   this.onChange('approvalBeginTime', vs);
  // };
  // EndChange = (value, vs) => {
  //   this.state.approvalEndTime = value
  //   this.onChange('approvalEndTime', vs);
  // };
  //弹框按钮的通过、不通过
  handle(type){
    this.setState({visible:false});
    this.audit(type);
  }
  canCel(){
    this.setState({visible:false})
}
 //点击按钮
  eventClick(type,list){
    const content=[];
   if(type=='1'){
     if(this.state.selectedRow.length==0){
       return message.error('未选中数据');
      }else{
        this.state.list=list;
      }
    }
    if(type=='0'){
      this.state.list.push(list)
    }
   this.setState({visible:true,list:this.state.list}) //弹框
  }
//审核意见
textChange(e){
  this.setState({auditOpinion:e.target.value});
}
//审核接口
audit(type){
  this.state.list.map(item=>item.auditOpinion=this.state.auditOpinion);
  this.state.list.map(item=>item.auditStatus=type);
  const content=this.state.list;
 fetch("/api/invoice/auditInvoice", {
  method: "post",
  credentials: "include",
  headers: {
    "Content-Type": "application/json"
  },
  body: JSON.stringify(content)
})
  .then(function (res) {
    if (!res.ok) {
      message.error("网络不可用！");
    }
    return res.json();
  })
  .then(function (data) {
    if (data.code == 0) {
      if(type=='1'){
        message.success('审核通过');
      }
      if(type=='2'){
        message.success('审核不通过');
      }
    
    } else {
      message.error(data.message);
      if (data.code == 2003) {
        history.push({ pathname: "/" });
      }
    }
  })
  .then(e => {
    this.setState({ loading: false ,auditOpinion:''});
    this.state.list=[];
    this.onload();
  });
}

//数据排序
clickEvents(method,type){
  this.state.orderBy=type;
  this.state.orderMethod=method;
  this.onload();
}

  render() {
    const { startValue, endValue } = this.state;
    const columns = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "发票号单",
        dataIndex: "num",
        key: "num",
      },
      {
        title: <div style={{display:'flex',alignItems:'center'}}><span>申请时间</span><p style={{display:'flex',flexDirection:'column'}}><Icon type="caret-up" style={{marginTop:'12px'}} onClick={this.clickEvents.bind(this,'desc','create_time')}></Icon><Icon type="caret-down" onClick={this.clickEvents.bind(this,'asc','create_time')}></Icon></p></div>,
        dataIndex: "createTime",
        key: "createTime",
      },
      {
        title: "订单数量",
        dataIndex: "",
        key: "", 
      render:(text,record)=> <span>{record.bList.length}</span>
      },
      {
        title: "订单号",
        dataIndex: "", 
        key: "",
       render:(text,record)=><div>{record.bList.map(item=>{return <span>{item.modKeyOrderNum},</span>})}</div>
      },
      {
        title: <div style={{display:'flex',alignItems:'center'}}><span>开票金额</span><p style={{display:'flex',flexDirection:'column'}}><Icon type="caret-up" style={{marginTop:'12px'}} onClick={this.clickEvents.bind(this,'desc','price')}></Icon><Icon type="caret-down" onClick={this.clickEvents.bind(this,'asc','price')}></Icon></p></div>,
        dataIndex: "price",
        key: "price",
       
      },
      {
        title: "开票状态",
        dataIndex: "status",
        key: "status",
        render:(text)=>text=='0'?'开票中':text=='1'?'开票成功':text=='2'?'开票失败':''
      },
      {
        title: "审核状态",
        dataIndex: "auditStatus",
        key: "auditStatus",
        render:(text)=>text=='0'?'未审核':text=='1'?'审核通过':text=='2'?'审核不通过':''
      },
      {
        title:'开票类型',
        dataIndex:'type',
        key:'type',
        render:(text)=>text=='0'?'企业电子普票':text=='1'?'个人电子普票':''
      },
      {
         title: "据数",
         dataIndex: "",
         key: "",
         render:(text,record)=> <span>{record.bList.length}</span>
      },
      {
         title: "票据追踪",
         dataIndex: "invoiceRoute",
         key: "invoiceRoute",
      },
      {
         title: "邮箱",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "手机号",
         dataIndex: "phone",
         key: "phone",
      },
      {
         title: "申请人",
         dataIndex: "userName",
         key: "userName",
      },
      {
         title: "审核人",
         dataIndex: "auditPerson",
         key: "auditPerson",
      },
      {
         title: "审核意见",
         dataIndex: "auditOpinion",
         key: "auditOpinion",
      },
      {
         title: "操作",
         dataIndex: "",
         key: "",
  
    render:(text,record,index)=><Button onClick={this.eventClick.bind(this,'0',record)}>审核</Button>
      }, 
    ];
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this) //获取选中值
    };
    return (
      <div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div className="page-content-info">
            <div className="toolbar-group">
              <div className="toolbar-left"> 
              <div className="form-main">
                    申请时间：
                  <DatePicker
                    format="YYYY-MM-DD"
                    value={startValue}
                    placeholder="请选择开始时间"
                    onChange={this.onStartChange}
                  />
                  -
                    <DatePicker
                    format="YYYY-MM-DD"
                    value={endValue}
                    placeholder="请选择结束时间"
                    onChange={this.onEndChange}
                  />
                </div>
                <div className="form-main" style={{width:'167px'}}> 
                  <input className="form-control" onChange={this.valueChange.bind(this, "userName")} placeholder="请输入申请人" />
                </div>
                <div className="form-main"  style={{width:'167px'}}>
                  <input className="form-control" onChange={this.valueChange.bind(this, "num")} placeholder="请输入发票号单" />
                </div>
                <div className="form-main"  style={{width:'167px'}}>
                  <input className="form-control" onChange={this.valueChange.bind(this, "invoiceRoute")} placeholder="请输入快递单号" />
                </div>
                
                <div className="form-main">
                  <Select
                    style={{ width: '167px'}}
                    onChange={this.valueChange.bind(this, "status")}
                    placeholder="请选择开票状态"
                  >
                    <Option value="">--开票状态--</Option>
                    <Option value="0">开票中</Option>
                    <Option value="1">开票成功</Option>
                    <Option value="2">开票失败</Option> 
                  </Select>
                  </div>
                  <div className="form-main">
                  <Select
                    style={{ width: '167px',margin:'10px 0 10px 0'}}
                    onChange={this.valueChange.bind(this, "auditStatus")}
                    placeholder="请选择审核状态"
                  >
                    <Option value="">--审核状态--</Option>
                    <Option value="0">未审核</Option>
                    <Option value="1">审核通过</Option>
                    <Option value="2">审核不通过</Option>
                  </Select>
                  <button  
                  onClick={this.onload.bind(this,'1')}
                  className="btn btn-default btn-search"
                  type="button"
                >
                  检索
                </button>
               
                <Button style={{marginLeft:'10px'}} onClick={this.eventClick.bind(this,'1',this.state.selectedRow)}>审核</Button>
               
                </div>
             
              </div>
              <div className="toolbar-right" style={{ width: "100%" }}>
                <Table
                  size={"small"}
                  rowKey={record=>record.id}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange:this.pageChange.bind(this),
                    total:this.state.total
                  }}
                />
              </div>
            </div>
          </div>
        </Spin>
        <Modal  width="300px" footer={[<Button onClick={this.handle.bind(this,'2')}>不通过</Button>,<Button type="primary" onClick={this.handle.bind(this,'1')}>通过</Button>]} closable={false} style={{marginTop:"20px"}} visible={this.state.visible}   onCancel={this.canCel.bind(this)}>
                  <div style={{width:'100%',textAlign:"center"}} >
                   <TextArea value={this.state.auditOpinion} rows={4} placeholder="审核意见:" onChange={this.textChange.bind(this)}></TextArea>
                  </div>
        </Modal>
      </div>
    );
  }
}
export default InvoiceList;



export function formatFileSize(fileSize) {
    if (fileSize < 1024) {
        return (fileSize*1).toFixed(2) + 'B';
    } else if (fileSize < (1024*1024)) {
        var temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
    } else if (fileSize < (1024*1024*1024)) {
        var temp = fileSize / (1024*1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
    } else if(fileSize < (1024*1024*1024*1024)){
        var temp = fileSize / (1024*1024*1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
    }else{
        var temp = fileSize / (1024*1024*1024*1024);
        temp = temp.toFixed(2);
        return temp + 'TB';
    }
}
// if (fileSize < 1024) {
//     return fileSize + 'B';
// } else if (fileSize < (1024*1024)) {
//     var temp = fileSize / 1024;
//     temp = temp.toFixed(2);
//     return temp + 'KB';
// } else if (fileSize < (1024*1024*1024)) {
//     var temp = fileSize / (1024*1024);
//     temp = temp.toFixed(2);
//     return temp + 'MB';
// } else {
//     var temp = fileSize / (1024*1024*1024);
//     temp = temp.toFixed(2);
//     return temp + 'GB';
// }
import React from 'react';
import {Tabs,message} from 'antd';
import HardDiskList from './HardDiskList.js';
const { TabPane } = Tabs;
class HardDiskManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:'1'
      }
    }   
    render(){

        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey}>
            <TabPane tab="硬盘列表" key="1">
              <HardDiskList  buttonList={this.props.buttonList} />
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default HardDiskManage;
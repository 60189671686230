import React from 'react';
import { Table, Spin,message,Select} from 'antd';
import history from '../history';
const {Option}=Select;
class EditList extends React.Component{
  
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
			loading:false
		}
	}
		//获取列表数据的接口
		onload(){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			let content = {start:this.state.page,limit:this.state.pageSize,
				editStatus:this.state.editStatus,filmName:this.state.filmName
			};
			fetch('/api/cinema/film/editList',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
                                array=data.result;    
                                total=data.total;
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                    this.state.params=array;
                    this.state.total=total;
                    this.state.selectedRowKeys = [];
                    this.setState({ loading: false });
                });
		 }
		 componentWillMount() {
			this.onload()
			this.showBtn(this.props.buttonList)//控制按钮显示
		}
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_search"){
						this.state.btn_search = true
				  }
			 }
		}
        //修改input改变的值
		valueChange(type,e) {
			if(type == "editStatus"){
					this.state.editStatus=e;
            }
            if(type == "filmName"){
                this.state.filmName =e.target.value;
            }
		}
		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
		//翻页
		pageChange(page,pageSize){
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
	 }

    render(){
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title: '影片名称',
					dataIndex: 'filmName',
					key: 'filmName',
					render: (text,record) => <a href="javascript:;" onClick = {this.props.callback.bind(this,"2","details",record)}>{text}</a>,
				},
				{
					title: '认领时间',
					dataIndex: 'receiptTime',
					key: 'receiptTime',
				},
				{
					title: '编目状态',
					dataIndex: 'editStatus',
                    key: 'editStatus', 
                    render: (text,record) => text == "1"?"编目中":text == "2"?"编目完成":'',       
				},
				{
					title: '编目完成时间',
					dataIndex: 'editCompleTime',
					key: 'editCompleTime',
                },
                {
					title: '编目人',
					dataIndex: 'editor',
					key: 'editor',
				}
				
			];
			const dataSource = this.state.params;
			const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
								<div class="page-content-info">
									<div class="toolbar-group">
										<div class="toolbar-left">
											<div class="form-main">
												<input class="form-control" onChange={this.valueChange.bind(this,"filmName")} placeholder="请输入影片名称"/>
											</div>
											<div class="form-main">
												<Select style={{width:'176px'}} placeholder="请选择"  onChange={this.valueChange.bind(this,"editStatus")}>
													<Option value="">--请选择--</Option>
													<Option value="1">编目中</Option>
													<Option value="2">编目完成</Option>
												</Select>
											</div>
											<button style={{display:this.state.btn_search == true?"inline-block":"none"}} onClick={this.onload.bind(this)} class="btn btn-default btn-search" type="button">检索</button>
										</div>
										{/* <div><Button style={{marginLeft:20}} type="primary" onClick = {this.props.callback.bind(this,"2","add",null)}>新增</Button>
										     <Button style={{marginLeft:10}} type="danger" onClick = {this.showConfirm.bind(this,this)}>删除</Button>
										</div> */}
										<div class="toolbar-right" style={{width:'100%'}}>
											<Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
										</div>
									</div>
								</div>
              </Spin>
			  
            </div>    
        )
    }
}
export default EditList;
import React from 'react';
import { Table, Spin, Button, message, Tabs, Modal } from 'antd';
import history from '../history';

const { confirm } = Modal;
class UnsettledList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: [],
            page: 0,
            pageSize: 10,
            total: 0,
            loading: false,
            enumArray: [],
            monthArray: [],
            visible: false,
            type: ''
        }
    }
    //获取列表数据的接口
    onload() {
        this.setState({ loading: true });
        let array = []
        let total = 0;
        // if (ty == "1") {
        //     this.state.start = 0
        // } else {
        //     this.state.start = this.state.page
        // }
        let content = {
            // start: this.state.start, limit: this.state.pageSize,
            issuerId: this.state.issuerId, beginTime: this.state.beginTime, endTime: this.state.endTime
        };
        fetch('/api/settlement/queryDataByUnsettled', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.params = array;
            this.state.total = total;
            this.setState({ loading: false });
        });
    }
    //获取发行公司接口
    searchEnum() {
        let enumArray = [];
        let content = {}
        fetch('/api/settlement/queryIssueCompany', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                enumArray = data.result;
            } else {

            }
        }).then((e) => {
            this.setState({ enumArray: enumArray });
        });
    }
    //获取结算月份接口
    monthEnum(issuerId) {
        let monthArray = [];
        let content = { issuerId, isSettlement: '0' }
        fetch('/api/settlement/queryMonthByCompany', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                monthArray = data.result;
            } else {

            }
        }).then((e) => {
            this.setState({ monthArray: monthArray });
        });
    }
    componentWillMount() {
        this.searchEnum();//查询发行公司枚举值
        this.monthEnum();//查询结算月份枚举值
        this.onload();
        this.showBtn(this.props.buttonList);//控制按钮显示
    }
    //控制按钮显示
    showBtn(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_search") {
                this.state.btn_search = true
            }
            if (array[i].buttonCode == "btn_add") {
                this.state.btn_add = true
            }
            if (array[i].buttonCode == "btn_delete") {
                this.state.btn_delete = true
            }
        }
    }

    // pageChange(page, pageSize) {//翻页
    //     page = (page - 1) * pageSize
    //     this.state.page = page;
    //     this.onload();
    // }
    //筛选发行公司
    screen(condition, obj, e) {
        this.state.page = 0;
        var filmClassArray = e.target.parentNode.children
        for (var i = 1; i < filmClassArray.length; i++) {
            filmClassArray[i].className = "screening-world"
        }
        e.target.className += " active"
        if (condition == "issuerId") {//全部发行公司
            if (obj == "") {
                this.state.issuerId = ''
            } else {
                this.state.issuerId = obj.id
            }
        }
        this.onload();
        this.monthEnum(this.state.issuerId);
    }
    //筛选结算月份
    screens(condition, obj, e) {
        this.state.page = 0;
        var filmClassArray = e.target.parentNode.children
        for (var i = 1; i < filmClassArray.length; i++) {
            filmClassArray[i].className = "screening-world"
        }
        e.target.className += " active"
        if (condition == "playTime") {//全部月份
            if (obj == "") {
                this.state.beginTime = '';
                this.state.endTime = '';
            } else {
                this.state.beginTime = obj.playTime + '-01 00:00:00';
                this.state.endTime = obj.playTime + '-31 24:00:00';
            }
        }
        this.onload();
    }
    //结算弹框
    btnClick(ind, type) {
        this.setState({ visible: true, ind, type })
    }
    //弹框确定取消
    handleOk(type) {
        if (type == '1') {
            this.toUn(this.state.ind)  //结算
        } else if (type == "2") {
            this.onDown(this.state.ind) //下载
        }

        this.setState({ visible: false })
    }
    actorCancel() {
        this.setState({ visible: false })
    }
    //结算功能
    toUn(item) {
        let content = {
            isSettlement: '0', issuerId: item.issuerId, isuserName: item.isuserName,
            settlementCost: item.monthUnsettled, monthPlayCount: item.unmonthPlayCount, playCount: item.unsettledPlayCount,
            playTime: item.playTime + '-01 00:00:00', beginTime: item.playTime + '-01 00:00:00', endTime: item.playTime + '-31 24:00:00'
        }
        fetch('/api/settlement/toUnconfirmed', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then((data) => {
            if (data.code == 0) {
                message.success('结算成功');
                this.onload();
            } else {
                message.error(data.message)
            }
        })

    }
    //下载功能
    onDown(item) {
        let content = { issuerId: item.issuerId, beginTime: item.playTime };
        fetch("/api/settlement/downloadExcel", {
            method: "post",
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(content),
        })
            .then(response => {
                response.blob().then(blob => {
                    if (blob.type == "") {
                        message.error("文件不存在！");
                        return
                    }
                    let blobUrl = window.URL.createObjectURL(blob);
                    let aElement = document.getElementById("downloadUn"); //获取a标签元素
                    let fileName = response.headers.get('Content-Disposition').split('=')[1];
                    let iconv = require('iconv-lite'); 
                    iconv.skipDecodeWarning = true;//忽略警告
                    fileName = iconv.decode(fileName, 'UTF-8');

                    // let filename = 'boxLog.zip'; //设置文件名称
                    aElement.href = blobUrl; //设置a标签路径
                    aElement.download = fileName;
                    aElement.click();
                    window.URL.revokeObjectURL(blobUrl);
                    message.success("下载中,请稍等");
                });
            })
            .catch(error => {
                console.log("文件下载失败", error);
            });
    }
    render() {
        const columns = [
            {
                title: '序号',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '发行公司',
                dataIndex: 'isuserName',
                key: 'isuserName',
                render: (text, record) => <a href="javascript:;" onClick={this.props.callback.bind(this, "2", "details", record)}>{text}</a>,
            },
            {
                title: '结算月份',
                dataIndex: 'playTime',
                key: 'playTime',
            },
            {
                title: '费用（元）',
                dataIndex: 'monthUnsettled',
                key: 'monthUnsettled',
                render: (text) => <>{text ? text = text.toFixed(2) : 0}</>
            },
            {
                title: '场次（场）',
                dataIndex: 'unsettledPlayCount',
                key: 'unsettledPlayCount',
            },
            {
                title: '月数（个月）',
                dataIndex: 'unmonthPlayCount',
                key: 'unmonthPlayCount',
            },
          
            {
                title: '操作',
                dataIndex: 'createTime',
                key: 'createTime',
                render: (text, record, index) => <div><Button onClick={this.btnClick.bind(this, record, '1')}>结算</Button>  <Button style={{ marginLeft: '10px' }} ><a href="javascript:;" onClick={this.btnClick.bind(this, record, '2')}>下载</a></Button> </div>
            },


        ];
        const dataSource = this.state.params;
        const Anchor = props => {
            return (
                <a {...props}>{props.children}</a>
            );
        };
        return (
            <div>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <Anchor id="downloadUn" style={{ display: 'none' }}></Anchor>
                    <div class="page-content-info">
                        <div class="toolbar-group">
                            <div style={{ width: '100%', height: '100px' }}>
                                <p style={{ fontSize: '20px' }}>发行公司</p>
                                <div className="screening-main">
                                    <a href="javascript:;" style={{ color: '#ff5b22' }} onClick={this.screen.bind(this, "issuerId", "")} class="screening-style">全部</a>
                                    {(this.state.enumArray.length == 0) ? null : this.state.enumArray.map((item, index) => {
                                        return (
                                            <a href="javascript:;" style={{ marginLeft: '5px', }} onClick={this.screen.bind(this, "issuerId", item)} class="screening-world">{item.name}</a>
                                        )
                                    }, this)}
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '100px' }}>
                                <p style={{ fontSize: '20px' }}>结算月份</p>
                                <div className="screening-main">
                                    <a href="javascript:;" style={{ color: '#ff5b22' }} onClick={this.screens.bind(this, "playTime", "")} class="screening-style">全部</a>
                                    {(this.state.monthArray.length == 0) ? null : this.state.monthArray.map((item, index) => {
                                        return (
                                            <a href="javascript:;" style={{ marginLeft: '5px' }} onClick={this.screens.bind(this, "playTime", item)} class="screening-world">{item.playTime}</a>
                                        )
                                    }, this)}
                                </div>
                            </div>
                            <div class="toolbar-right" style={{ width: '100%' }}> 
                                <Table size={"small"} columns={columns} dataSource={dataSource} />
                            </div>
                        </div>
                    </div>
                </Spin>
                <Modal title="提示" okText="确认" cancelText="取消" width="300px" style={{ marginTop: "20px" }} visible={this.state.visible} onOk={this.handleOk.bind(this, this.state.type)} onCancel={this.actorCancel.bind(this)}>
                    <div style={{ width: '100%', textAlign: "center", height: "5px", lineHeight: "5px" }}>
                        <b>{this.state.type == '1' ? "是否结算?" : this.state.type == '2' ? "是否下载?" : ''}</b>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default UnsettledList;
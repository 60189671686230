import React from 'react';
import {Tabs} from 'antd';
import FilmList from '../filmManage/filmList'; 
// import FilmDetails from './filmDetails.js';
import ListAuthorityDetail from '../ListAuthority/ListAuthorityDetail.js';
import FilmAdd from './FilmAdd.js';
const { TabPane } = Tabs;
class FilmManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isfalse:null,
        type:'1',
        num:'0',
      }
    }
    callback(activeKey,type,params,num){
        if(type == "details"){
          this.state.isfalse=true;
          this.state.params = params;
          this.state.num=num;
        }
        if(type == "add"){
          this.state.isfalse=false;
          this.state.params = params;
          this.state.num=num;
        }
      this.setState({activeKey:activeKey})
    }
    
    render(){
      let {isfalse}=this.state;
        return(
          <div>
              <Tabs type="card"  activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
              <TabPane tab="影片列表" key="1">
                <FilmList type={this.state.type} buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
              </TabPane>
              {
                this.state.num=='1'? <TabPane tab='新增' key="3">
                <FilmAdd buttonList={this.props.buttonList} params = {this.state.params} callback = {this.callback.bind(this)}/>
                </TabPane>: this.state.num=='2'?
               <TabPane tab='黑白名单详情' key="2">
               <ListAuthorityDetail buttonList={this.props.buttonList}  isfalse={isfalse} params = {this.state.params} callback = {this.callback.bind(this)}/>
               </TabPane>:''
              }
             
            </Tabs>
          </div>
        )
    }
}
export default FilmManage;
import React from "react";
import { Radio, Select,Spin,Button, Switch,message,Table,Input,InputNumber } from "antd";
import history from '../history';
const {TextArea} =Input;
const {Option} = Select;
class RecommendList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params:{},
            page:0,
            pageSize:10,
            total:0,
            loading:false,
            isfalse: true,
            esfalse:false,
            dataSource: [],
            dataSource1: [],
            dataSources: [],
            areaList:[],
            switchChecked:false,
            userName:''
        }
    }
    componentWillMount(){
        this.getArea();//专区
        this.onload()
    }
    //控制按钮显示
    showBtn(array){
        for(var i= 0; i<array.length;i++){
            if(array[i].buttonCode == "btn_search"){
                this.state.btn_search = true
            }
        }
    }
    userList(id){
      this.setState({ loading: true });
			let array={}
			let content = {id:id};
			fetch('/api/userCenter/userDetails',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(content),
					}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								 array=data.result;    
							}else  {
								message.error(data.message);
									if(data.code == 2003){
										history.push({pathname:'/'});
								}
							}
					}).then( (e) => {
						this.state.userName=array.name;
						this.setState({ loading: false });
				 });
    }
    getArea(){
        let dataParams={};
        this.setState({ loading: true });
        let content = {};
        fetch('/api/zone/list',{
          method: 'post',
                  credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
        },
          body: JSON.stringify(content),
        }).then(function (res) {             
        if(!res.ok){                 
            message.error("网络不可用！");}
            return res.json();
        }).then(function (data) {
          if (data.code == 0) {
            dataParams = data.result;
          }else {
            message.error(data.message);
          if(data.code == 2003){
              history.push({pathname:'/'});
            }
          }  
        }).then((e)=>{
          this.setState({areaList:dataParams,loading:false});
        });        
    }
    onload(ty,id){
      let dataParams={};
      this.setState({ loading: true });
      let array={};
      let content = {id:id};
      fetch('/api/zoneRecommend/zoneRecommendDetails',{
        method: 'post',
				credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
      },
        body: JSON.stringify(content),
      }).then(function (res) {             
      if(!res.ok){                 
          message.error("网络不可用！");}
          return res.json();
      }).then(function (data) {
        if (data.code == 0) {
          array=data;
          dataParams = data.result;
        }else {
          message.error(data.message);
        if(data.code == 2003){
            history.push({pathname:'/'});
          }
        }  
      }).then((e)=>{
        this.setState({params:dataParams,loading:false,dataSource:dataParams.datZoneRecommendListList,total:array.total});
        this.userList(dataParams.modifier);
        if(this.state.type==1){
          this.setState({dataSource:this.state.dataSource1,total:this.state.dataSource1.length})
        }else{
          this.setState({dataSource1:dataParams.datZoneRecommendListList})
        }
      });  
    }
     //新增
     handleAdd = () => {
        this.state.dataSource.unshift({});
        this.setState({
          dataSource: [...this.state.dataSource],
        });
      }
    //点击删除
    deleteData(index) {
        let arrs = this.state.dataSource;
        arrs.splice(index, 1);
        this.setState({
          dataSource: [...arrs],
          dataSource1: [...arrs]
        });
      }
      cencel(type) {
        //修改还是取消
        if (type == 'write') {
            this.setState({
                isfalse: false,
                esfalse:true
            });
        } else {
            this.setState({
                isfalse: true,
                esfalse: false,
            });
        }
      }
      //保存
    save(){
      let {params,dataSource,dataSources,dataSource1,type}=this.state;
      if(params.webDisplayMode==null){
        message.error("网页展示方式未选择，请核实")
      }
      if(params.appletDisplayMode==null){
        message.error("小程序展示方式未选择，请核实")
      }
      if(dataSource.length==0){
        message.error("未新增子表数据，请核实！");
        return;
      }
      if(type==1){
        if(dataSource1.some(item=>{return item.zoneId==null||item.zoneId==""||item.rank==null||item.rank==""})){
          message.error("专区名称或序列值未填写，请核实！");
          return;
        }
      }else{
        // for(var i=0;i<dataSources.length;i++){
        //   for(var j=0;j<dataSource.length;j++){
        //     if(dataSources[i].zoneId==dataSource[j].zoneId){
        //       dataSources[i]==dataSource[j]
        //     }
        //   }
        // }
        if(dataSource.some(item=>{return item.zoneId==null||item.zoneId==""||item.rank==null||item.rank==""})){
          message.error("专区名称或序列值未填写，请核实！");
          return;
        }
      }
      params.datZoneRecommendListList=dataSource;
      this.setState({ loading: true });
      fetch('/api/zoneRecommend/saveZoneRecommend',{
        method: 'post',
				credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
      },
        body: JSON.stringify(params),              
      }).then(function(res){
        if(!res.ok){
          message.error('网络不可用！');
        }
        return res.json();
      }).then((data)=>{
        if(data.code == 0){
          message.success('保存成功');
          if(data.result.id!=null){
            this.onload('',data.result.id)
            this.setState({loading:false,isfalse: true, esfalse: false})
          }
        }else{
          message.error(data.message);
          if(data.code == 2003){
            history.push({pathname:'/'});
          }
        }
      }).then(e=>{
        this.setState({loading:false})
      })
    }
    valueEvent(type,index,e){
      let {dataSource,params,displayText} = this.state;
        if(type == 'rank'){
          dataSource[index].rank = e;
        }
        if(type == 'zoneId'){
          dataSource[index].zoneId = e;
          dataSource[index].zoneRecommendId = params.id;
        }
        if(type == "displayText"){
          dataSource[index].displayText= e.target.value;
        }
      
      this.setState({ dataSource });
    }
    wchange(e){
      let {params}= this.state;
      params.webDisplayMode=e.target.value;
      this.setState({params});
    }
    xchange(e){
      let {params}= this.state;
      params.appletDisplayMode =e.target.value;
      this.setState({params});
    }
    handleChange(type,e){
      let {params} = this.state;
      if( type == 'description'){
        params.description=e.target.value;
      }
      this.setState({params});
    }
    //开关
    switchChange(e){
    let {params,switchChecked} = this.state;
    if(e== true){
      params.isEnable='1';
    }else{
      params.isEnable='0';
    }
    switchChecked=e;
    this.setState({params,switchChecked});
    }
    
    render(){
        let { isfalse ,esfalse,params,dataSource,dataSource1,type} = this.state;
        const columns=[
            {
                title: '序号',
                render:(text,record,index)=>`${index+1}`,
            },
            {
                title: '专区名称',
                dataIndex: 'zoneId',
                key: 'zoneId',
                render: (text,record,index) =>{return <Select value={text}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
                 onChange={this.valueEvent.bind(this,'zoneId',index)} disabled={isfalse} style={{width:"200px"}}>
    
                  <Option value=''>请选择</Option>
                  {this.state.areaList.map(item=>{
                    return <Option value={item.id}>{item.name}</Option>
                  })}
                </Select>},
              },
              {
                title: '介绍',
                dataIndex: 'displayText',
                key: 'displayText',
                render:(text,record,index)=><TextArea disabled={isfalse} style={{width:200}} value={text} onChange={this.valueEvent.bind(this,'displayText',index)}/>
              },
            {
                title: '序列值',
                dataIndex: 'rank',
                key: 'rank',
                render:(text,record,index)=><InputNumber min={0}  disabled={isfalse} style={{width:200}} value={text} onChange={this.valueEvent.bind(this,'rank',index)}/>
            },
            // !(esfalse&&switchChecked)
            {
                title: '操作',
                dataIndex: '',
                key: '',
                render: (text,record,index) =><Button disabled={isfalse} onClick={this.deleteData.bind(this, index)}>删除</Button>,
            }
        ];
        return(
            <div>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <div style = {{margin:"0 0 18px 0px"}}>
                        <Button  type="primary" disabled={isfalse} onClick = {this.save.bind(this)}>保存</Button> 
                        <Button style={{marginLeft: 20 }} disabled={esfalse}  onClick={this.cencel.bind(this, 'write')}> 修改</Button>
                        <Button style={{marginLeft: 20}} disabled={isfalse} onClick={this.cencel.bind(this)}> 取消 </Button>
                    </div>
                    <div>是否启用推荐专区：<Switch disabled={isfalse} checked={params.isEnable=='1'?true:false} onChange={this.switchChange.bind(this)}/></div>
                    <div style={{margin:'20px 0'}}>网页展示方式： <span style={{color:"#b94a48",fontWeight:900}}></span><Radio.Group style={{marginLeft:'15px'}} onChange={this.wchange.bind(this)} value={params.webDisplayMode!=undefined?params.webDisplayMode.toString():params.webDisplayMode}><Radio disabled={isfalse} value="0">跑马灯</Radio><Radio disabled={isfalse} value="1">上下翻转</Radio></Radio.Group></div>
                    <div>小程序展示方式： <span style={{color:"#b94a48",fontWeight:900}}></span><Radio.Group onChange={this.xchange.bind(this)} value={params.appletDisplayMode!=undefined?params.appletDisplayMode.toString():params.appletDisplayMode}><Radio disabled={isfalse} value="0">跑马灯</Radio><Radio disabled={isfalse} value="1">上下翻转</Radio></Radio.Group></div>
                    <div style={{marginTop:'25px'}}><span>描述说明:</span>
                    {/* <span style={{color:"#b94a48",fontWeight:900}}>*</span>  */}
                      <div>
                        <TextArea style={{width:240,margin:'-19px 0 0 71px',height:'80px'}}  placeholder="请输入描述说明" disabled={isfalse} value={this.state.params.description} onChange={this.handleChange.bind(this,"description")}></TextArea>
                      </div>
                    </div>
                    <div style={{margin:'25px 0 10px 0'}}><Button disabled={isfalse} type="primary" onClick={this.handleAdd}  >新增</Button></div>
                    <Table size={"small"}  columns={columns}  dataSource={dataSource}  rowKey={record => record.id} pagination={false}/>
                    <div style={{marginTop:'20px',display:'flex',marginBottom:'50px'}}>
                        <span>修改人：{this.state.userName}</span>
                        <span style={{marginLeft:'20px'}}>修改时间：{params.modifyTime}</span>
                    </div>
                </Spin>
            </div>
        )
    }
}
export default RecommendList;
import React from 'react';
import {Tabs} from 'antd';
import BoxManageList from './boxManageList.js';
import BoxManageDetail from './boxManageDetail.js';
import BoxJournal from './boxJournal.js';
import Project from './project.js';

const { TabPane } = Tabs;
class BoxManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isfalse:null,
      }
    }
    componentWillMount(){
      sessionStorage.removeItem('serialNumber');
    }
    callback(activeKey,type,params){
        if(type == "details"){
          this.state.params = params
          this.state.isfalse=true;
        }
        if(type == "add"){
          this.state.params = null
          this.state.isfalse=false;
        }
        if(type == "journal"){
          this.state.params = params
        }
      this.setState({activeKey:activeKey})
    }
    
    render(){
        return(
          <div>
              <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
             <TabPane tab="设备列表" key="1">
                <BoxManageList buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
              </TabPane>
               <TabPane tab="设备详情" key="2">
                <BoxManageDetail buttonList={this.props.buttonList} isfalse={this.state.isfalse} params = {this.state.params} callback = {this.callback.bind(this)}/>
              </TabPane>
              <TabPane tab="实时日志" key="3">
              <BoxJournal params = {this.state.params} callback = {this.callback.bind(this)}></BoxJournal>
              </TabPane>
              <TabPane tab="放映机按钮" key="4">
              <Project callback = {this.callback.bind(this)}></Project>
              </TabPane>
            </Tabs>
          </div>
        )
    }
}
export default BoxManage;
import React from 'react';
import {Radio,Spin,Button,Icon,message,Select,Input, Checkbox,Table,Modal, notification} from 'antd';
import history from '../history';

const { Option } = Select;
class MsgTemplatDetail extends React.Component{ 
    constructor(props){
        super(props);
        this.state={
           params:{
             
           },
           loading:false,
           getMessageList:[],//模板列表
           getNodeList:[],
           nodeListName:'',
           messType:'0',//模板类型
           mailList:[],//邮件
           shortMessList:[],//短信
           wechatList:[],//微信
           dataSource:[],//消息通知数据
           visible:false,
           list:[],//放用户或角色的数据
           notification:[],//返回模板
           methodList:[{type:'0',name:'邮件',checkType:false},{type:'1',name:'短信',checkType:false},{type:'2',name:'微信',checkType:false}],//通知方式
           mailsList:[],
           shortsMessList:[],
           wesList:[],
           bList:[],
           status:'0',
           loseNum:1,
          }
    }
    componentWillMount(){
        if (this.props.params != null) {
            this.state.params = this.props.params;
            this.state.isfalse = this.props.isfalse;
            this.onload(this.state.params.id);
          } 
          this.getNodeList() //消息通知
          this.getMessageList(); //模板
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps != null) {
            if (nextProps.params != null) {
              this.state.params = nextProps.params;
              this.state.isfalse = this.props.isfalse;
              this.state.esfalse = false;
              this.onload(this.state.params.id);
            } else {
              this.setState({
                params: {},
                methodList:[{type:'0',name:'邮件',checkType:false},{type:'1',name:'短信',checkType:false},{type:'2',name:'微信',checkType:false}],//通知方式
                bList:[],
                content:'',
                shortcontent:'',
                wecontent:'',
                isfalse: false,
                code:'',
                shortcode:'',
                wecode:'',
                notification:[],
                mailsList:[],
                shortsMessList:[],
                wesList:[],
                mailList:[],
                shortMessList:[],
                weList:[],
              });
              this.getNodeList() //消息通知
              this.getMessageList(); //模板
            }

          } else {
            this.setState({
              params: {},
              methodList:[{type:'0',name:'邮件',checkType:false},{type:'1',name:'短信',checkType:false},{type:'2',name:'微信',checkType:false}],//通知方式
              bList:[],
              content:'',
              shortcontent:'',
              wecontent:'',
              isfalse: false,
              code:'',
              shortcode:'',
              wecode:'',
              notification:[],
              mailsList:[],
              shortsMessList:[],
              wesList:[],
              mailList:[],
              shortMessList:[],
              weList:[],
            });
          
          }

    }
    //获取详情数据
      onload(id) {
        let {content,shortcontent,wecontent,code,shortcode,wecode, notification}=this.state;
        let dataParams = {};
        let array=[];
        let amailsList=[];
        let ashortsMessList=[];
        let awesList=[];
        this.setState({ loading: true });
        let contents = { id: id }
        fetch('/api/message/notificationDetails', {
          method: 'post',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(contents),
        }).then(function (res) {
          if (!res.ok) { message.error("网络不可用！"); }
          return res.json();
        }).then((data) => {
          if (data.code == 0) {
            dataParams = data.result;
          } else {
            message.error(data.message);
            if (data.code == 2003) {
              history.push({ pathname: '/' });
            } 
          }
        }).then((e) => {
          if(dataParams.method!=null){
            array=dataParams.method.split(',')||dataParams.method.split('')
            for(var i=0;i<array.length;i++){
              for(var j=0;j<this.state.methodList.length;j++){
                if(array[i]==this.state.methodList[j].type){
                  this.state.methodList[j].checkType=true;
                }
              }
            }
          }
         
         notification=JSON.parse(dataParams.notification);
          for(var i=0;i<notification.length;i++){
            if(notification[i].type=='0'){
             content=notification[i].content;
             code=notification[i].code;
             amailsList.push(notification[i]);
            }else if(notification[i].type=='1'){
            shortcontent=notification[i].content;
            shortcode=notification[i].code;
            ashortsMessList.push(notification[i]);
            }else if(notification[i].type=='2'){
            wecontent=notification[i].content;
            wecode=notification[i].code;
            awesList.push(notification[i]);
            }
          }
          this.setState({ params: dataParams,loading: false,methodList:this.state.methodList,content,shortcontent,wecontent,code,shortcode,wecode,
            bList:dataParams.bList,status:dataParams.status,mailsList:amailsList,shortsMessList:ashortsMessList,wesList:awesList});
        });
    }

    //选择消息通知节点  
    getNodeList(){
        let array=[];
        let content = {};
        fetch('/api/message/queryNodeList',{
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'      
            },
            body: JSON.stringify(content),
            }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                    return res.json();
            }).then(function (data) {
                    if (data.code == 0) {
                            array=data.result;    
                    }else  {
                        message.error(data.message);
                        if(data.code == 2003){
                            history.push({pathname:'/'});
                        }
                    }
            }).then( (e) => {
                this.setState({ loading: false,getNodeList:array });
            });
    }
    //查询模板
    getMessageList(){
      let ashortMessList=[];
      let amailList=[];
      let awechatList=[];
      let content = {};
      let array = [];
      fetch('/api/message/getMessageList',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json' 
							},
							body: JSON.stringify(content),
					}).then(function (res) {            
                         if(!res.ok){                 
                             message.error("网络不可用！");            
                            }
							return res.json();
					}).then( (data)=> {
             if(data.code == 0) {
              array=data.result;    
              }
              if(data.code == -1){
                this.getMessageList();
              }
							
					}).then( (e) => {
            for(var i=0;i<array.length;i++){
              if(array[i].type=='0'){
                amailList.push(array[i]);
              }else if(array[i].type=='1'){ 
                ashortMessList.push(array[i]);
              }else if(array[i].type=='2'){
                awechatList.push(array[i]);
              }
            } 
            this.setState({ getMessageList:array,mailList:amailList,shortMessList:ashortMessList,wechatList:awechatList});
				 });
    }
  
    //修改取消
    cencel(type){
      if (type == 'write') {
        this.setState({
          isfalse: false,
          esfalse: true
        });
      } else {
        this.setState({
          isfalse: true,
          esfalse: false,
        });
      }
    }
    
    //选择
    SelectsChange(type,e,obj){
      let {params}=this.state;
      params.nodeListName=obj.props.children;
      params.nodeListId=e;
      this.setState({params})
    }
    //修改
    valueChange(type,e){
      let {params}=this.state;
     if(type=='name'){
       params.name=e.target.value;
       
     }
     this.setState({params});
    }
    
    //保存
    save(){
    let str="";
    let trueList=[];
    let notification=[];
    let {params}=this.state;
    notification=this.state.mailsList.concat(this.state.shortsMessList,this.state.wesList);
    
    for(var s=0;s<this.state.methodList.length;s++){
      if(this.state.methodList[s].checkType==true){
        str+=this.state.methodList[s].type+','
        trueList.push(this.state.methodList[s]);
      }
    }
    if(notification.length!=trueList.length){
     return message.error('选择模板方式和模板不匹配');
    }
    for(var i=0;i<notification.length;i++){
      var type=notification[i].type;
      var isExist=false;
      for(var j=0;j<trueList.length;j++){
        var types=trueList[i].type;
        if(type!=types){
          isExist=true; 
        }
      }
     
      if(isExist){
        return message.error('选择模板方式和模板不匹配');
      }
    }
    if(params.name==''||params.name==null||params.name==undefined){
    return message.error('模板名称未填写,请核实!');
    }
    if(notification.length==0){
    return message.error('模板和模板方式未选择,请核实!');
    }
    if(params.nodeListName==''||params.nodeListName==null||params.nodeListName==undefined){
      return message.error('消息通知节点未填写,请核实!');
    }
    if(str==''||str==null||str==undefined){
      return message.error('消息通知方式未选择,请核实!');
    }
    if(this.state.bList.length==0){
      return message.error('消息通知目标未填写,请核实!');
    }
    params.notification=JSON.stringify(notification); 
    params.bList=this.state.bList;
    params.method=str;
    params.status=this.state.status;
    this.setState({ loading: true });
    fetch("/api/message/saveMessageNotification", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state.params)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data) => {
        if (data.code == 0) {
          message.success("保存成功！");
          if (data.result != null) {
            this.onload(data.result.id)
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({ loading: false, isfalse: true, esfalse: false });
      });
    }
     //选择启用和禁用
     radiosChange(e){
      let {status}=this.state;
      status=e.target.value;
      this.setState({status});
    }
    //点击信息按钮
    messChange(type){
    this.setState({messType:type});
    }
    //点击增加
    handleAdd () { 
      this.setState({visible:true});
    }
    //用户和角色的数据选择
  useTypeChange(e,obj){
    this.setState({usetypeValue:obj.props.children,usetypeId:e});
 }
    //确认增加
    handleOk(){
      this.state.bList.push({type:this.state.useValue,targetName:this.state.usetypeValue,targetId:this.state.usetypeId});
      this.setState({visible:false,bList:this.state.bList,useValue:'',targetName:'',usetypeValue:'',usetypeId:''});
    }
    //增加取消
    handleCancel(){
      this.setState({visible:false,useValue:'',targetName:'',usetypeValue:'',usetypeId:''})
    }  
    //信息删除
    messdelete(index){
    this.state.bList.splice(index,1);
    this.setState({bList:this.state.bList});
    }
    //选择角色和个人
    useChange(e){
     this.setState({useValue:e});
     if(e=='1'){
       let array=[];
      fetch('/api/userCenter/userList',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify({}),
					}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								 array=data.result;    
							}else  {
								message.error(data.message);
									if(data.code == 2003){
										history.push({pathname:'/'});
								}
							}
					}).then( (e) => {
				  this.setState({list:array});
				 });
     }
     if(e=='0'){
       let array=[];
      fetch('/api/userCenter/roleList',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify({}),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
                                array=data.result;    
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                   this.setState({list:array});
                });
     }else if(e=='2'){
       this.setState({list:[]})
     }
    }
  //选择邮件模板
  SelectChange(type,e){
    let{code,shortcode,wecode,content,shortcontent,wecontent,mailsList,shortsMessList,wesList}=this.state;
   if(type=='code'){
     code=e;
     if(e==''){
      content='';
      mailsList=[];
    }
    else if(e!=''&&this.state.mailList.length>0 ){
     for(var i=0;i<this.state.mailList.length;i++){
       if(code==this.state.mailList[i].code){
         content=this.state.mailList[i].content; 
         mailsList.push(this.state.mailList[i]);
       }
     }
    }
   }
   if(type=='shortcode'){
    shortcode=e;
    if(e==''){
      shortcontent='';
      shortsMessList=[];
    }
    else if(e!=''&&this.state.shortMessList.length>0 ){
    for(var i=0;i<this.state.shortMessList.length;i++){
      if(shortcode==this.state.shortMessList[i].code){
        shortcontent=this.state.shortMessList[i].content;
        shortsMessList.push(this.state.shortMessList[i]);
      }
    }
   }
  }
   if(type=='wecode'){
    wecode=e;
    if(e==''){
      wecontent='';
      wesList=[];
    }
    else if(e!=''&&this.state.wechatList.length>0 ){
      for(var i=0;i<this.state.wechatList.length;i++){
        if(wecode==this.state.wechatList[i].code){
          wecontent=this.state.wechatList[i].content;
          wesList.push(this.state.wechatList[i]);
        }
      }
    }
    }
   this.setState({code,shortcode,wecode,content,wecontent,shortcontent,mailsList,shortsMessList,wesList});
  }
  
  //多选通知方式
  methodChange(index,e){
    let {methodList}=this.state;
    methodList[index].checkType=e.target.checked;
    this.setState({methodList});
  }
  
    render(){
        let {isfalse,esfalse,params,}=this.state;
        let columns=[
          {
             title:'序号',
             render:(text,record,index)=>`${index+1}`,
          },
          {
            title: "通知目标类型",
            dataIndex: "type",
            key: "type",
            render:(text)=>text=='0'?'角色':text=='1'?'用户':text=='2'?'自己':''
          },
          {
            title: "消息通知目标",
            dataIndex: "targetName",
            key: "targetName",
           
          },
          {
            title: "操作",
            dataIndex: "",
            key: "",
            render:(text,record,index)=><div><Button type="primary" disabled={isfalse} onClick={this.messdelete.bind(this,index)}>删除</Button></div>
          }
        
        ]
        return(
            <div 
            className="ListAuthorityDetails"
            key={ 
                this.state.params != null && this.state.params.id == "-1"
                  ? this.state.params.id
                  : this.state.params.id
              }
            >
            <Spin spinning={this.state.loading} tip="Loading...">
              <div style={{ marginLeft: '25px' }}>
                <Button
                style={{
                    marginLeft: 10
                }}
                onClick={this.save.bind(this)}
                type="primary"
                disabled={isfalse}
                >
                保存
                </Button>
                <Button style={{marginLeft: 20 }} disabled={esfalse} onClick={this.cencel.bind(this, 'write')}>
                修改
                </Button>
                <Button
                style={{marginLeft: 20 }} disabled={isfalse} onClick={this.cencel.bind(this, true)}>
                取消
                </Button>
                <Button style={{ marginLeft: 20 }} onClick={this.props.callback.bind(this, "1", null, null)}>返回</Button>
              </div>
              <div style={{marginLeft:'25px',marginTop:'25px'}}>
                  <div style={{marginTop:'25px',display:'flex'}}>
                      <span style={{width:'31px',height:'31px',background:'#1990FF',color:'#fff',display:'inline-block',borderRadius:'50%',textAlign:'center',lineHeight:'31px',marginRight:'20px'}}>1</span>
                      <Input disabled={isfalse} placeholder="模板名称" style={{width:'200px'}} value={this.state.params.name}  onChange={this.valueChange.bind(this,'name')}></Input>
                  </div>
                  <div style={{margin:'10px',height:'50px',borderLeft:'1px solid #1990FF'}}></div>
                  <div style={{marginTop:'25px',display:'flex'}}>
                    <span style={{width:'31px',lineHeight:'31px',background:'#1990FF',color:'#fff',display:'inline-block',borderRadius:'50%',textAlign:'center',marginRight:'20px'}}>2</span>
                    <Select  style={{width:'300px'}}
                      disabled={isfalse} 
                      showSearch 
                      placeholder='选择消息通知节点'
                      value={this.state.params.nodeListName==''?'选择消息通知节点':this.state.params.nodeListName}
                      onChange={this.SelectsChange.bind(this,'id')}
                      optionFilterProp="children" 
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      >
                        {
                          this.state.getNodeList&&this.state.getNodeList.map((item,index)=>{
                            return <Option value={item.id} key={index}>{item.name}</Option>
                          })
                        }
                      </Select>
                  </div>
                  <div style={{margin:'10px',height:'50px',borderLeft:'1px solid #1990FF'}}></div> 
                  <div style={{marginTop:'25px',display:'flex'}}>
                    <span style={{width:'31px',height:'31px',lineHeight:'31px',background:'#1990FF',color:'#fff',display:'inline-block',borderRadius:'50%',textAlign:'center',marginRight:'20px'}}>3</span>
                    <div>
                      消息通知方式:{
                        this.state.methodList.map((item,index)=>{
                        return <Checkbox disabled={isfalse} onChange={this.methodChange.bind(this,index)} checked={item.checkType}>{item.name}</Checkbox>
                        })
                      }
                    </div>
                  </div> 
                  <div style={{margin:'10px',height:'50px',borderLeft:'1px solid #1990FF'}}></div> 
                  <div style={{marginTop:'25px',display:'flex'}}>
                    <span style={{width:'31px',lineHeight:'31px',background:'#1990FF',color:'#fff',height:'31px',borderRadius:'50%',textAlign:'center',marginRight:'20px'}}>4</span>
                    <div style={{margin:'40px 20px 0px -38px',borderLeft:'1px solid #1990FF'}}></div> 
                    <div style={{marginLeft:'20px'}}>
                      消息通知目标:<br></br>
                      <div style={{width:'1200px',border:'1px solid #ccc',marginTop:'20px'}}>
                      <Button style={{margin:'10px'}} disabled={isfalse} onClick={this.handleAdd.bind(this)}>增加</Button>
                      <Table
                        rowKey={record => record.id}
                        dataSource={this.state.bList}
                        columns={columns}
                      />
                      </div>
                    </div>
                  </div>
                  
                  <div style={{marginTop:'25px',display:'flex'}}>
                    <span style={{height:'31px',width:'31px',lineHeight:'31px',background:'#1990FF',color:'#fff',display:'inline-block',borderRadius:'50%',textAlign:'center',marginRight:'20px'}}>5</span>
                    <div style={{margin:'40px 20px 0px -38px',borderLeft:'1px solid #1990FF'}}></div> 
                    <div style={{marginLeft:'20px'}}>
                      消息模板<br></br>
                      <div style={{border:'1px solid #ccc',width:'1200px'}}>
                      <div style={{margin:'10px'}}>
                      <Button disabled={isfalse} type={this.state.messType=='0'?'primary':''} onClick={this.messChange.bind(this,'0')}>邮件</Button>
                      <Button disabled={isfalse} type={this.state.messType=='1'?'primary':''} onClick={this.messChange.bind(this,'1')}>短信</Button>
                      <Button disabled={isfalse} type={this.state.messType=='2'?'primary':''} onClick={this.messChange.bind(this,'2')}>微信</Button>
                      </div>
                      {
                        this.state.messType=='0'?<div><Select  style={{width:'200px',marginLeft:'10px'}}
                        disabled={isfalse} 
                        showSearch  
                        placeholder="选择邮件模板"
                        value={this.state.code==''?'选择邮件模板':this.state.code}
                        onChange={this.SelectChange.bind(this,'code')}
                        optionFilterProp="children" 
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        >
                          <Option value=''>无</Option>
                          {
                            this.state.mailList&&this.state.mailList.map((item,index)=>{
                              return <Option value={item.code}>{item.code}</Option>
                            })
                          }
                        </Select>
                        <div style={{margin:'10px',height:'50px',border:'1px solid #ccc'}}>
                          {
                            this.state.content 
                          }
                        </div>
                        </div>:this.state.messType=='1'?<div><Select  style={{width:'200px',marginLeft:'10px'}}
                      disabled={isfalse} 
                      showSearch 
                      placeholder="选择短信模板"
                      value={this.state.shortcode=''?'选择短信模板':this.state.shortcode}
                      onChange={this.SelectChange.bind(this,'shortcode')}
                      optionFilterProp="children" 
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      >
                        <Option value=''>无</Option>
                        {
                          this.state.shortMessList&&this.state.shortMessList.map((item,index)=>{
                            return <Option value={item.code}>{item.code}</Option>
                          })
                        }
                      </Select>
                      <div style={{margin:'10px',height:'50px',border:'1px solid #ccc'}}>
                          {
                            this.state.shortcontent
                          }
                        </div>
                      </div>:this.state.messType=='2'?<div> <Select  style={{width:'200px',marginLeft:'10px'}}
                      disabled={isfalse} 
                      showSearch 
                      placeholder="选择微信模板"
                      value={this.state.wecode==''?'选择微信模板':this.state.wecode}
                      onChange={this.SelectChange.bind(this,'wecode')}
                      optionFilterProp="children" 
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      >
                        <Option value=''>无</Option>
                        {
                          this.state.wechatList&&this.state.wechatList.map((item,index)=>{
                            return <Option value={item.code}>{item.code}</Option>
                          })
                        }
                      </Select>
                      <div style={{margin:'10px',height:'50px',border:'1px solid #ccc'}}>
                          {
                            this.state.wecontent
                          }
                        </div>
                      </div>
                      :''
                      }
                    </div>
                      </div>
                  </div>
                  {/* <div style={{margin:'10px',height:'50px',borderLeft:'1px solid #1990FF'}}></div>  */}
                  <div style={{marginTop:'25px',display:'flex'}}>
                    <span style={{width:'31px',height:'31px',lineHeight:'31px',background:'#1990FF',color:'#fff',display:'inline-block',borderRadius:'50%',textAlign:'center',marginRight:'20px'}}>6</span>
                    <Radio.Group style={{marginLeft:'20px'}} onChange={this.radiosChange.bind(this)}>
                        <Radio disabled={isfalse} checked={this.state.status=='0'?true:false} value={0}>启用</Radio>
                        <Radio disabled={isfalse} checked={this.state.status=='1'?true:false} value={1}>禁用</Radio>
                    </Radio.Group>
                  </div> 
              </div>
            </Spin>  
            <Modal title="消息通知目标"  cancelText="取消"  okText="确认" style={{marginTop:"20px"}} visible={this.state.visible}  
            onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
                <div>
                  <span style={{height:'6px',border:'1px solid #ccc'}}></span>
                  <span style={{margin:'0 10px'}}>选择通知目标类型</span>
                  <Select style={{width:'200px'}} onChange={this.useChange.bind(this)} value={this.state.useValue}>
                    <Option value={0}>角色</Option>
                    <Option value={1}>用户</Option>
                    <Option value={2}>自己</Option>
                  </Select>
                </div>
                <div style={{marginTop:'10px'}}>
                  <span style={{height:'6px',border:'1px solid #ccc'}}></span>
                  <span style={{margin:'0 40px 0 10px'}}>选择通知目标</span>
                  <Select style={{width:'200px'}} onChange={this.useTypeChange.bind(this)} value={this.state.usetypeValue}
                  disabled={isfalse} 
                  showSearch 
                  optionFilterProp="children" 
                  filterOption={(input,option)=>
                     option.props.children
                       .toLowerCase()
                       .indexOf(input.toLowerCase())>=0
                  }
                    
                  >
                  {
                     this.state.list&&this.state.list.map((item,index)=>{
                      return <Option value={item.id}>{item.name}</Option>
                     })
                  }
                  </Select>
                </div>
            </Modal>  
            </div>
            )
    }
   
}
export default MsgTemplatDetail;
import React from "react";
import { Table, Spin, Button, message, Row, Col, Modal, Select, Input, Upload, Icon, DatePicker } from "antd";
import history from "../history";
// import Modalevent from '../../utils/modalevent'
import moment from "moment";
const { Option } = Select;
const { confirm } = Modal;
class BoxVersionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      display: 'none',
      visible: false,
      type: "",
      tlist: [],
      isshow: '0',
      slite: false,
      releaseTimes: ''
    };
  }
  //版本列表接口
  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if (ty == "1") {
      this.state.start = 0
    } else {
      this.state.start = this.state.page
    }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      versionNum: this.state.versionNum,
      versionName: this.state.versionName,
      releaseTime: this.state.releaseTime,
      status: this.state.status,
      uploadTime: this.state.uploadTime,
      isUpgradeversion: this.state.isUpgradeversion
    };
    fetch("/api/boxVersion/queryBoxVersionList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.setState({ loading: false });
      });
  }
  componentWillMount() {
    this.onload();
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  //控制按钮显示
  showBtn(array) {
    console.log(array)
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_enable") {
        this.state.btn_enable = true;
      }
      if (array[i].buttonCode == "btn_add") {
        this.state.btn_add = true;
      }
      if (array[i].buttonCode == "btn_delete_line") {
        this.state.btn_delete_line = true;
      }
      if (array[i].buttonCode == "btn_disenable") {
        this.state.btn_disenable = true;
      }
      if (array[i].buttonCode == "btn_set_up_upgrade") {
        this.state.btn_set_up_upgrade = true;
      }
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true;
      }
    }
  }


  // 搜索input select 的值
  valueChange(type, e) {
    if (type == "status") {
      this.state.status = e;
    }
    if (type == "versionNum") {
      this.state.versionNum = e.target.value;
    }
    if (type == "isUpgradeversion") {
      this.state.isUpgradeversion = e;

    }
  }
  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }
   //翻页
  pageChange(page, pageSize) {
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
  //删除
  showConfirm = () => {
    if (this.state.selectedRow.length == 0) {
      message.error("尚未选中数据！");
      return;
    }
    confirm({
      title: "确定删除?",
      content: "",
      okText: '确定',
      cancelText: "取消",
      onOk: () => {
        this.setState({ loading: true });
        let array = this.state.selectedRow;
        fetch("/api/boxVersion/deleteBoxVersion", {
          method: "post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(array)
        })
          .then(function (res) {
            if (!res.ok) {
              message.error("网络不可用！");
            }
            return res.json();
          })
          .then(function (data) {
            if (data.code == 0) {
              message.success("删除成功");
            } else {
              message.error(data.message);
              if (data.code == 2003) {
                history.push({ pathname: "/" });
              }
            }
          })
          .then(e => {
            this.state.selectedRow.length = 0;
            this.onload();
          });
      },
      onCancel() { }
    });
  };

  //新建
  addClick(type) {
    let display = this.state.display;
    if (type == "none") {
      display = "block"
      this.state.params.versionNum='';this.state.params.versionName='';
      this.state.params.status='';this.state.params.filePath='';
      this.state.params.versionMd5='';this.state.params.isUpgradeversion='';
      this.state.params.uploadTime='';this.state.releaseTimes=''
    } else if (type == "block") {
      display = "none"
    }
    this.setState({ display,params:this.state.params })
  }
  //新建的确定
  okClick() {
    if (this.state.params.filePath == undefined||this.state.params.filePath ==''||this.state.params.filePath ==null) {
      message.error('文件没有上传')
      return;
    }
    this.addClick('block');
    this.setState({ loading: true });
    this.state.params.releaseTimes=this.state.releaseTimes==null||this.state.releaseTimes==''?null:moment(this.state.releaseTimes).format('YYYY-MM-DD HH:mm:ss');
    const content = {
      versionNum: this.state.params.versionNum, versionName: this.state.params.versionName,
      releaseTime: this.state.params.releaseTimes, status: this.state.params.status, filePath: this.state.params.filePath,
      versionMd5: this.state.params.versionMd5, isUpgradeversion: this.state.params.isUpgradeversion,
      uploadTime: this.state.params.uploadTime
    } 
    console.log(content)
    
    fetch("/api/boxVersion/addBoxVersion", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data) => {
        if (data.code == 0) {
          message.success("新建成功");
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params.versionNum='';this.state.params.versionName='';
        this.state.params.status='';this.state.params.filePath='';
        this.state.params.versionMd5=''; this.state.params.isUpgradeversion='';
        this.state.params.uploadTime=''
        this.setState({ loading: false,releaseTimes:'' });
        this.onload();

      });
  }

  //弹框按钮的确定 取消
  handleOk(type, isshow) {
    if (isshow == 0) {
      this.btnActive(type, isshow)
    } else if (isshow == 1) {
      this.btnSatate(type)
    }
    this.setState({ visible: false })
  }
  actorCancel() {
    this.setState({ visible: false })
  }
  //点击启用还是不启用
  eventClick(type, isshow) {
    if (this.state.selectedRow.length == 0) {
      message.error("尚未选中数据！");
      return;
    }

    this.setState({ visible: true, type, isshow }) //弹框
  }
  //启用  不启用 通过type进项判断  
  btnActive(type) {
    let content = this.state.selectedRow.map((item) => {
      return { id: item.id, status: type, versionNum: item.versionNum }
    })
    fetch("/api/boxVersion/updateBoxVersion", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          if (type == "0") {
            message.success("启用成功！");
          } else if (type == "1") {
            message.success("设置不可用成功！");
          }
        } else {
          message.error(data.message)
          return;
        }
      })
      .then(e => {
        this.onload()
        this.state.selectedRow = []
      });
  }
  //设置升级版本按钮
  btnClick(type, isshow) {
    if (this.state.selectedRow.length == 0) {
      message.error("尚未选中数据！");
      return;
    }
    this.state.params.forEach((item, index) => {
      if (item.isUpgradeversion == "1") {
        this.state.slite = true
      } else {
        this.state.slite = false
      }
    })
    this.setState({ visible: true, type, isshow }) //弹框
  }

  //设置升级版本的接口   
  btnSatate(type) {
    let content = this.state.selectedRow
    console.log(content)
    fetch("/api/boxVersion/setUpgradeVersion", {  //调用接口
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          if (type == "0") {
            message.success("设置版本升级成功！");
          }
        } else {
          message.error(data.message)
          return;
        }
      })
      .then(e => {
        this.onload()
      });
  }

  //新建的input的值
  handleChange(type, e) {
    let params = this.state.params
    if (type == "status") {
      params.status = e;
    }
    if (type == "versionNum") {
      params.versionNum = e.target.value;
    }
    if (type == "versionName") {
      params.versionName = e.target.value;
    }
    if (type == "versionMd5") {
      params.versionMd5 = e.target.value;
    }
    // if(type=="description"){      //备注
    //   params.description=e.target.value
    // }
    this.setState({ params })
  }

  //日期
  onChanges = (date, dateString) => {
    let releaseTimes = this.state.releaseTimes
    releaseTimes = date;
   
    this.setState({ releaseTimes })
  }

  //上传版本
  miChange(info) { 
    this.setState({ loading: true});
    if (info.file.status === 'done') {
      this.state.params.filePath = info.file.response.result;
      this.state.params.uploadTime = moment().format('YYYY-MM-DD HH:mm:ss');
      message.success('上传成功');
    }
    if (info.file.status === 'error') {
      message.success('上传失败');
    }
    this.setState({ loading: false});
  }

  render() {
    const columns = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "版本号",
        dataIndex: "versionNum",
        key: "versionNum",
      },
      {
        title: "版本名称",
        dataIndex: "versionName",
        key: "versionName"
      },
      {
        title: "版本发布日期",
        dataIndex: "releaseTime",
        key: "releaseTime",
      },
      {
        title: "上传日期",
        dataIndex: "uploadTime",
        key: "uploadTime"
      },
      {
        title: 'MD5',
        dataIndex: 'versionMd5',
        key: 'versionMd5'
      },
      {
        title: "是否可用",
        dataIndex: "status",
        key: "status",
        render: (text) =>  text == "0" ? "是" :text == "1" ?  "否":''
      }, {
        title: '升级版本',
        dataIndex: 'isUpgradeversion',
        key: 'isUpgradeversion',
        render: (text) => text == "0" ? "否" :text == "1" ?  "是":''
      },
      //  {
      //   title: '备注', //备注字段
      //   dataIndex: 'description',
      //   key: 'description'
      // }
    ];

    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this) //获取选中值
    };
    return (
      <div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div className="page-content-info">
            <div className="toolbar-group">
              <div className="toolbar-left">
                <div style={{ marginBottom: '20px' }}>
                  <Button
                    style={{
                      display: this.state.btn_add == true ? "inline-block" : "none",
                      marginLeft: 10,
                    }}
                    type="primary"
                    onClick={this.addClick.bind(this, 'none')}
                  >
                    新建
                </Button>
                  <Button
                    style={{
                      display:
                        this.state.btn_delete_line == true ? "inline-block" : "none",
                      marginLeft: 10
                    }}
                    type="primary"
                    onClick={this.showConfirm.bind(this, this)}
                  >
                    删除
                </Button>
                  <Button
                    style={{
                      display:
                        this.state.btn_enable == true ? "inline-block" : "none",
                      marginLeft: 10
                    }}
                    type="danger"
                    onClick={this.eventClick.bind(this, "0", "0")}
                  >
                    启用
                </Button>
                  <Button
                    style={{
                      display:
                        this.state.btn_disenable == true ? "inline-block" : "none",
                      marginLeft: 10
                    }}
                    type="danger"
                    onClick={this.eventClick.bind(this, "1", "0")}
                  >
                    设置不可用
                </Button>
                  <Button
                    style={{
                      display:
                        this.state.btn_set_up_upgrade == true ? "inline-block" : "none",
                      marginLeft: 10
                    }}
                    type="danger"
                    onClick={this.btnClick.bind(this, "1", "1")}
                  >
                    设置升级版本
                </Button>
                </div>
                <div className="form-main">
                  <input
                    className="form-control"
                    onChange={this.valueChange.bind(this, "versionNum")}
                    placeholder="请输入版本号"
                  />
                </div>

                <div className="form-main">
                  <Select
                    style={{ width: '167px' }}
                    onChange={this.valueChange.bind(this, "status")}
                    placeholder="请选择是否可用"
                  
                  >
                    <Option value="">--是否可用--</Option>
                    <Option value="0">是</Option>
                    <Option value="1">否</Option>
                  </Select>
                </div>
                <div className="form-main">
                  <Select
                    style={{ width: '167px' }}
                    onChange={this.valueChange.bind(this, "isUpgradeversion")}
                    placeholder="请选择升级版本"
                  >
                    <Option value="">--是否升级--</Option>
                    <Option value="0">是</Option>
                    <Option value="1">否</Option>
                  </Select>
                </div>
                <button
                  style={{
                    display: this.state.btn_search == true ? "inline-block" : "none"
                  }}
                  onClick={this.onload.bind(this, '1')}
                  className="btn btn-default btn-search"
                  type="button"
                >
                  检索
                </button>
              </div>

              <div className="toolbar-right" style={{ width: "100%" }}>
                <Table
                  size={"small"}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
       
        <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.3)', position: 'fixed', top: 0, left: 0, display: this.state.display, zIndex: '999' }} >
          <div class="tanbox" style={{ width: '60%', position: 'fixed', top: '40%', left: '20%', background: '#fff', display: this.state.display, border: '1px solid #ccc' }}>
            <Row style={{ margin: '50px 5px 5px 30px' }}>
              <Col span={12}><span style={{ width: '105px', display: 'inline-block' }}> 版本号：</span><Input
                style={{ width: 240 }}
                value={this.state.params.versionNum}
                placeholder="请输入版本号"
                onChange={this.handleChange.bind(this, "versionNum")}
              /> </Col>
              <Col span={12}><span style={{ width: '105px', display: 'inline-block' }}> 版本名称：</span><Input
                style={{ width: 240, marginLeft: '2px' }}
                value={this.state.params.versionName}
                placeholder="请输入版本号名称"
                onChange={this.handleChange.bind(this, "versionName")}
              /></Col>
            </Row>
            <Row style={{ margin: '30px 5px 5px 30px' }}>
              <Col span={12}> <span style={{ width: '105px', display: 'inline-block' }}>版本发布日期：</span>
                <DatePicker format="YYYY-MM-DD HH:mm:ss" showTime style={{ width: 240 }} placeholder="请输入版本发布日期" value={this.state.releaseTimes} onChange={this.onChanges} />
              </Col>
              <Col span={12}><span style={{ width: '105px', display: 'inline-block' }}> 版本是否可用：</span> <Select
                style={{ width: 240 }}
                onChange={this.handleChange.bind(this, "status")}
                placeholder="是否可用"
                value={this.state.params.status}
              >
                <Option value="">--是否可用--</Option>
                <Option value="0">是</Option>
                <Option value="1">否</Option>
              </Select>
              </Col>
            </Row>
            <Row style={{ marginLeft: '30px', marginTop: '30px' }}>
              <Col span={3}>
                 <Upload name="file" action="/api/common/uploadFile"
                  data={{ type: '4', cinemaId: null, filmId: null, modKeyOrderFilmId: null }}
                  onChange={e => this.miChange(e)}
                >
                  <Button type="primary"><Icon type="upload" />上传版本</Button>
                </Upload>
              </Col>
              < Col span={12}>
                <Input placeholder="md5值" style={{ width: 240, marginLeft: '15px' }}
                  value={this.state.params.versionMd5}
                  onChange={this.handleChange.bind(this, 'versionMd5')}
                ></Input>
              </Col>
            </Row>
            {/* <Row style={{margin:'30px 5px 5px 20px',height:'32px',lineHeight:'32px'}}>
          <Col span={25}>
            <span style={{display:'inline-block',width:'105px'}}>备注：</span><TextArea   rows={1} style={{width:'75%'}}
              onChange={this.handleChange.bind(this,'description)}
              placeholder="备注"></TextArea>
          </Col>
      </Row> */}
            <Row style={{ margin: '10px 0' }}>
              <Button type="primary" style={{ marginRight: '50px', marginLeft: '65%', width: '10%' }} onClick={this.okClick.bind(this)}>确定</Button>
              <Button type="primary" style={{ width: '10%' }} onClick={this.addClick.bind(this, 'block')}>取消</Button>
            </Row>
          </div>
        </div>
        </Spin>
        <Modal title="提示" width="300px" cancelText="取消" okText="确认" style={{ marginTop: "20px" }} visible={this.state.visible} onOk={this.handleOk.bind(this, this.state.type, this.state.isshow)} onCancel={this.actorCancel.bind(this)}>
          <div style={{ width: '100%', textAlign: "center", height: "5px", lineHeight: "5px" }}>
            {this.state.isshow == 0 ? <b>{this.state.type == "0" ? "是否启用?" : "是否设置不可用?"}</b> : <b>{this.state.slite == true ? "已存在其他升级版本,是否确定该数据升级版本?" : "是否升级版本?"}</b>}
          </div>
        </Modal>
      </div>
    );
  }
}
export default BoxVersionList;

import React from 'react';
import { Upload, Icon, Input,Select,Button,Spin,message,Modal} from 'antd';
import history from '../history';

const { Option } = Select;
class EnumMangeDetails extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:{},
        loading:false,
        isfalse: null,
        esfalse:false
      }
     
    }
    componentWillMount() {
       if(this.props.params != null){
            this.state.isfalse = this.props.isfalse;
            this.state.params = this.props.params;
            this.onload(this.state.params.id);
       }else{
        this.state.fileList=[];
       }
       this.showBtn(this.props.buttonList)//控制按钮显示
    }
    //控制按钮显示
    showBtn(array){
         for(var i= 0; i<array.length;i++){
              if(array[i].buttonCode == "btn_save"){
                this.state.btn_save = true
              }
              if (array[i].buttonCode == "btn_update") {
                this.state.btn_update = true;
              }
              if (array[i].buttonCode == "btn_cancel") {
                this.state.btn_cancel = true;
              }
         }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps != null ){
            if(nextProps.params != null){
                this.state.isfalse = nextProps.isfalse;
                this.state.params = nextProps.params
                this.onload(this.state.params.id);
                this.state.esfalse=false
            }else{
                this.state.fileList=[];
                this.state.isfalse=null
                this.setState({
                    params: {}
                });
            }
            
        }else{
            this.state.isfalse=null
            this.setState({
                params: {}
            });
        }
    }
    //获取详情数据的接口
    onload(id){
        let dataParams = {};
        this.setState({ loading: true });
        let content = {id:id}
            fetch('/api/cinema/queryTheatreDetails',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        dataParams = data.result;
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.state.fileList = [];
                    this.state.params = dataParams;
                    console.log(dataParams)
                    this.state.loading = false;
                    this.setState({ fileList: this.state.fileList });
                });
    }
    //input改变的值
    handleChange(type,e) {
        let params=this.state.params;
        if(type == "name"){
           params.name = e.target.value
        }
        this.setState({params})
    }
    //保存
    save(){
        if(this.state.params.name == undefined || this.state.params.name == "" ){
            message.error("存在必填项未填写，请核实！")
            return;
        };
        this.setState({ loading: true });
            fetch('/api/cinema/saveTheatre',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.params),
                }).then(function (res) {           
                      if(!res.ok){   
                        message.error("网络不可用！");             
                    }
                        return res.json();
                }).then( (data)=> {
                    if (data.code == 0) {
                        message.success("保存成功！");
                        if(data.result.id!=null){
                            this.onload(data.result.id)
                          }
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                 let params=this.state.params;
                   params.name = ""
                    this.setState({ loading: false,params,isfalse: true, esfalse: false });
                });
    }
     cencel(type) {
        //修改还是取消
        if (type == 'write') {
            this.setState({
                isfalse: false,
                esfalse:true
            });
        } else {
            this.setState({
                isfalse: true,
                esfalse: false,
            });
        }
    }
    render(){
        let { isfalse ,esfalse} = this.state
        return(
            <div className = "TheatreChainDetails" key={(this.state.params != null && this.state.params.id == '-1')?this.state.params.id:this.state.params.id}>
                <Spin spinning={this.state.loading} tip="Loading...">
                <div style = {{margin:"0 0 18px 30px"}}>
                        <Button  type="primary" disabled={isfalse} onClick = {this.save.bind(this)}>保存</Button> 
                        <Button style={{marginLeft: 20 }} disabled={esfalse}  onClick={this.cencel.bind(this, 'write')}> 修改</Button>
                        <Button style={{marginLeft: 20}} disabled={isfalse} onClick={this.cencel.bind(this)}> 取消 </Button>
                        <Button style={{marginLeft:20}} onClick = {this.props.callback.bind(this,"1",null,null)}>返回</Button>
                    </div> 
                    <Input hidden={true} value={this.state.params.id}/>
                   
                    <p>枚举字段名称:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                      <Input style={{width:240}} disabled={isfalse} value={this.state.params.name} placeholder = "请输入枚举字段名称" onChange={this.handleChange.bind(this,"name")}/>
                    </p>
                    <p>枚举键:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                      <Input style={{width:240}} disabled={isfalse} value={this.state.params.name} placeholder = "请输入院线名称" onChange={this.handleChange.bind(this,"name")}/>
                    </p>
                    <p>枚举值:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                      <Input style={{width:240}} disabled={isfalse} value={this.state.params.name} placeholder = "请输入院线名称" onChange={this.handleChange.bind(this,"name")}/>
                    </p>
                    <p>排序:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                      <Input style={{width:240}} disabled={isfalse} value={this.state.params.name} placeholder = "请输入院线名称" onChange={this.handleChange.bind(this,"name")}/>
                    </p>
                </Spin>    
            </div>    
        )
    }
}
export default EnumMangeDetails;
import React from 'react';
import { Table, Spin,Button,message,Select,Modal,Input} from 'antd';
import history from '../history';
const { Option } = Select;
const {confirm}=Modal;
class FilmBagList extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:[],
        page:0,
        pageSize:10,
        selectedRowKeys: [],
        selectedRow:[],
        total:0,
        loading:false,
        isShow:false,
        ind:0,
      }
    }
    componentWillMount(){
      this.onload();
      this.userEvent();
      //
    }
    //获取列表数据接口
    onload(ty){
      this.setState({loading:true});
      let array=[];
      let total=0;
      if(ty=='1'){
        this.state.start=0;
      }else{
        this.state.start=this.state.page;
      }
      let content={start:this.state.start,limit:this.state.pageSize,name:this.state.name,status:this.state.status};
      fetch('/api/filmBag/queryFilmBagList',{
        method:'post',
        credentials:'include',
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify(content),
      }).then(function(res){
        if(!res.ok){
          message.error('网络不可用！');
        }
        return res.json();
      }).then(function(data){
        if(data.code==0){
          array=data.result;
          total=data.total;
        }else{
          message.error(data.message);
          if(data.code==2003){
            history.push({pathname:'/'})
          }
        }
      }).then(e=>{
        this.setState({total:total,params:array,loading:false,selectedRowKeys:[],selectedRow:[]});
      })
    }
    userEvent(){
      let array=[];
      fetch('/api/userCenter/userList',{
        method:'post',
        credentials:'include',
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify({}),
      }).then(function(res){
        if(!res.ok){
          message.error('网络不可用！');
        }
        return res.json();
      }).then(function(data){
        if(data.code==0){
          array=data.result;
        }else{
          message.error(data.message);
          if(data.code==2003){
            history.push({pathname:'/'})
          }
        }
      }).then(e=>{
        this.setState({userList:array});
      })
    }
    //获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow;
			this.setState({ selectedRowKeys });
		};
    //翻页
    pageChange(page,pageSize){
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
		}
    //删除
    showConfirm = () => {
      if(this.state.selectedRow.length == 0){
        message.error("尚未选中数据！");
        return;
      }
      confirm({
        title: '确定删除?',
        content: '',
        okText: '确定',
        cancelText:"取消",
        onOk:()=> {
        this.setState({ loading: true });
        let array=this.state.selectedRow;
        fetch('/api/filmBag/deleteFilmBag',{
          method: 'post',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(array),
          }).then(function (res) {
            if(!res.ok){
              message.error("网络不可用！");
            }
            return res.json();
          }).then(function (data) {
            if (data.code == 0) {
              message.success("删除成功");
            }else  {
              message.error(data.message);
              if(data.code == 2003){
                history.push({pathname:'/'});
              }
            }
          }).then( (e) => {
            this.onload();
          });
        },
      });
    }
    //查询的值
    ChangeEvent(type,e){
      let {name,status}=this.state;
      if(type=='name'){
        name=e.target.value;
      }
      if(type=='status'){
        status=e;
      }
      this.setState({name,status});
    }
     //用户名改变值
     userChange(type,e){
      if(type=="userId"){
        this.state.userId=e;
      }
      this.setState({userId:this.state.userId});
    }
    //点击分配
    clickEvent(record,type){
      this.setState({ind:record.id,isShow:type});
    }
    //弹框取消
    cancel(type){
      this.setState({isShow:type,userId:''});
    }
    //弹框确认分配
    ok(){
      this.setState({isShow:false});
      let content={id:this.state.ind,userId:this.state.userId};
      fetch('/api/filmBag/distributeFilmBag',{
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(content),
        }).then(function (res) {
          if(!res.ok){
            message.error("网络不可用！");
          }
          return res.json();
        }).then( (data)=> {
          if (data.code == 0) {
            message.success("分配成功");
          }else  {
            message.error(data.message);
            if(data.code == 2003){
              history.push({pathname:'/'});
            }
          }
        }).then(e=>{
          this.setState({userId:''});
          this.onload();
        })
    }
   
    render(){
      const {selectedRowKeys,selectedRow,isShow}=this.state;
      const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
      const columns=[
        {
          title:'序号',
          render:(text,record,index)=>`${index+1}`
        },
        {
          title:'片包名称',
          dataIndex:'name',
          key:'name',
          render: (text,record) => <a href="javascript:;" onClick = {this.props.callback.bind(this,"2","details",record)}>{text}</a>,
        },
        {
          title:'片包状态',
          dataIndex:'status',
          key:'status',
          render:(text)=>text=='0'?'未分配':text=='1'?'已分配':text=='2'?'已过期':''
        },
        {
          title:'有效期',
          dataIndex:'validity',
          key:'validity',
        },
        {
          title:'操作',
          dataIndex:'',
          key:'',
          render:(text,record,index)=><a href="javascript:;" onClick={this.clickEvent.bind(this,record,true)}>分配</a>
        },
      ]
        return(
          <div>
            <Spin spinning={this.state.loading} tip="Loading">
              <div className="page-content-info">
                <div className="toolbar-group">
                  <div class="toolbar-left">
                    <div class="form-main">
                      <input class="form-control" placeholder="片包名称" onChange={this.ChangeEvent.bind(this,'name')}></input>
                    </div>
                    <div class="form-main">
                      <Select class="form-control" style={{width:'167px'}} placeholder="片包状态" onChange={this.ChangeEvent.bind(this,'status')}>
                        <Option value="">片包状态</Option>
                        <Option value="0">未分配</Option>
                        <Option value="1">已分配</Option>
                        <Option value="2">已过期</Option>
                      </Select>
                    </div>
                    <button  class="btn btn-default btn-search" type="button" onClick={this.onload.bind(this,'1')}>检索</button>
                  </div>
                  <div>
				            <Button style={{marginLeft:10}} type="primary" onClick = {this.props.callback.bind(this,"2","add",null)}>新增</Button>
						        <Button style={{marginLeft:10}} type="danger" onClick = {this.showConfirm.bind(this,this)}>删除</Button>
					        </div> 
                  <div class="toolbar-right" style={{width:'100%'}}>
						        <Table  rowSelection={rowSelection} columns={columns} dataSource={this.state.params}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
						      </div>
                </div>
              </div>
            </Spin>
            <Modal  visible={isShow} title="分配" okText="分配" cancelText="取消" onOk={this.ok.bind(this)} onCancel={this.cancel.bind(this,false)}>
              <div>
                用户名：<Select style={{width:'200px'}} value={this.state.userId} onChange={this.userChange.bind(this,'userId')}>
                {this.state.userList&&this.state.userList.map(item=>{
                  return <Option value={item.id}>{item.name}</Option>
                })}
               </Select>
              </div>
            </Modal>
          </div>
        )
    }
}
export default FilmBagList;
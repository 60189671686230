import React from 'react';
import { Table, Spin,Button,message,Tabs,Modal,Select} from 'antd';
import history from '../history';

const { confirm } = Modal;
const {Option}=Select;
class ActorList extends React.Component{
  
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
			loading:false,
			value:''
		}
    }
    componentWillMount() {
        this.onload()
        this.showBtn(this.props.buttonList)//控制按钮显示
    }
		//获取列表数据的接口
		onload(ty){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			if(ty=="1"){
				this.state.start=0
				}else{
				this.state.start=this.state.page
				}
			let content = {start:this.state.start,limit:this.state.pageSize,
				filmName:this.state.filmName,type:this.state.type
			};
			fetch('/api/filmListAuthority/authorityFilmList',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
                                array=data.result;    
                                total=data.total;
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                    this.state.params=array;
                    this.state.total=total;
					this.state.selectedRowKeys = [];
					this.state.selectedRow = [];
                    this.setState({ loading: false });
                });
		 }
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_search"){
						this.state.btn_search = true
				  }
				  if(array[i].buttonCode == "btn_add"){
					this.state.btn_add = true
				  }
				if(array[i].buttonCode == "btn_delete"){
					this.state.btn_delete = true
				}
			 }
		}

		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
		//翻页
		pageChange(page,pageSize){
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
		}
		//删除
	  showConfirm = () => {
		if(this.state.selectedRow.length == 0){
			message.error("尚未选中数据！");
			return;
		}
		confirm({
		  title: '确定删除?',
		  content: '',
		  okText: '确定',
		  cancelText:"取消",
		  onOk:()=> {
			this.setState({ loading: true });
			let array=this.state.selectedRow;
			console.log(array);
			fetch('/api/filmListAuthority/deleteAuthorityList',{
				method: 'post',
				credentials: 'include',
                headers: {
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify(array),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                    return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        message.success("删除成功")
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.onload();
            });
		  },
		  onCancel() {
		  },
		});
	  }
    //筛选的黑白名单状态
    ChangeEvent(type,e){
        if(type=='type'){
        this.state.type=e;
        }
        if(type=='filmName'){
           this.state.filmName = e.target.value;
		}
		this.setState({type:this.state.type,filmName:this.state.filmName});
    }
    //按钮的启用禁用
    startUp(num){
		let str=''
		if(this.state.selectedRow.length == 0){
			message.error("尚未选中数据！"); 
			return;
		}
		if(num==0){
			str='启用成功';
		}else{
			str='禁用成功';
		}
		confirm({
		  title:num=='0'?'确定启用?':'确定禁用?' ,
		  content: '',
		  okText: '确定',
		  cancelText:"取消",
		  onOk:()=> {
			let array=[];
			this.setState({ loading: true });
			for(var i=0;i<this.state.selectedRow.length;i++){
				this.state.selectedRow[i].status=num;
				array.push({id:this.state.selectedRow[i].id,status:this.state.selectedRow[i].status})
			}
			
			fetch('/api/filmListAuthority/updateAuthorityList',{
				method: 'post',
				credentials: 'include',
                headers: {
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify(array),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                    return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        message.success(str)
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.onload();
            });
		  },
		  onCancel() {
		  },
		});
	}

    render(){
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title: '影片名称',
					dataIndex: 'filmName',
					key: 'filmName',
					render: (text,record) => <a href="javascript:;" onClick = {this.props.callback.bind(this,"2","details",record)}>{text}</a>,
				},
				{
					title: '名单类型',
					dataIndex: 'type',
                    key: 'type',
                    render: (text,record) => text == '0'?"白名单":text=='1'?"黑名单":'',
				
				},
				{
					title: '状态',
					dataIndex: 'status',
                    key: 'status', 
                    render: (text,record) =>text == '0'?"启用":"禁用",
				},
				{
					title: '创建时间',
					dataIndex: 'createTime',
					key: 'createTime',
				},
				{
					title: '修改时间',
					dataIndex: 'modifyTime',
					key: 'modifyTime',
                },
                {
					title: '操作人',
					dataIndex: 'operator',
					key: 'operator',
				}
			];
			const dataSource = this.state.params;
			const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
				               <div class="page-content-info">
									<div class="toolbar-group">
										<div class="toolbar-left">
											<div class="form-main">
												<input class="form-control" onChange={this.ChangeEvent.bind(this,"filmName")} placeholder="请输入影片名称"/>
											</div>
                                            <div class="form-main">
                                                <Select style={{width:'167px'}} placeholder='请选择名单类型' vlaue={this.state.type} onChange={this.ChangeEvent.bind(this,'type')}>
                                                    <Option value=''>--全部--</Option>
                                                    <Option value='1'>黑名单</Option>
                                                    <Option value='0'>白名单</Option>
                                                </Select>
                                            </div>
                                            {/* style={{display:this.state.btn_search == true?"inline-block":"none"}} */}
											<button  onClick={this.onload.bind(this,'1')} class="btn btn-default btn-search" type="button">检索</button>
										</div>
										<div>
                                            <Button style={{marginLeft:10}} type="primary" onClick = {this.startUp.bind(this,'0')}>启用</Button>
											<Button style={{marginLeft:10}} type="primary" onClick = {this.startUp.bind(this,'1')}>禁用</Button>
										    <Button style={{marginLeft:10}} type="danger"  onClick = {this.showConfirm.bind(this,this)}>删除</Button>
										</div>
										<div class="toolbar-right" style={{width:'100%'}}>
											<Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
										</div>
									</div>
								</div>
              </Spin>
            </div>    
        )
    }
}
export default ActorList;
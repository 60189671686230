import React from 'react';
import { Table, Spin,DatePicker,message,Modal} from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import history from '../history';

class CertificateDownload extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:[],
        page:0,
        pageSize:10,
        total:0,
        loading:false
      }
    }
    pageChange(page,pageSize){
        page=(page-1)*pageSize
        this.state.page=page;
        this.onload();
     }
    valueChange(type,e) {
        this.state.page=0;
        if(type == "userName"){
            this.state.userName=e.target.value
        }
      }
    //   onChange(date, dateString) {
    //     this.state.page=0;
    //     this.state.createTime=date
    //    }
       onload(ty){
        this.setState({ loading: true });
        let array=[]
        let total=0;
        if(ty=="1"){
          this.state.start=0
          }else{
          this.state.start=this.state.page
          }
        let content = {start:this.state.start,limit:this.state.pageSize,
          userName:this.state.userName
        };
        fetch('/api/logger/certificateDownloadLog',{
          method: 'post',
          credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
            }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                return res.json();
            }).then(function (data) {
                if (data.code == 0) {
                   array=data.result;    
                   total=data.total;
                }else  {
                  message.error(data.message);
                    if(data.code == 2003){
                      history.push({pathname:'/'});
                  }
                }
            }).then( (e) => {
              this.state.params=array;
              this.state.total=total;
              this.setState({ loading: false });
           });
       }
       componentWillMount() {
        this.onload()
        this.showBtn(this.props.buttonList)//控制按钮显示
      }
      //控制按钮显示 
      showBtn(array){
           for(var i= 0; i<array.length;i++){
                if(array[i].buttonCode == "btn_search"){
                      this.state.btn_search = true
                }
           }
      }


    render(){
        const columns = [
            {
              title: '序号',
              render:(text,record,index)=>`${index+1}`,
            },
            {
              title: '用户名',
              dataIndex: 'userName',
              key: 'userName',
            },
            {
              title: '证书编码',
              dataIndex: 'certificateCode',
              key: 'certificateCode'
            },
            {
              title: '下载时间',
              dataIndex: 'downTime',
              key: 'downTime'           
            },
            {
              title: '下载途径',
              dataIndex: 'source',
              key: 'source',
              render: (text, record) => {
                  if(text == "1"){
                       return "国际端"
                  }else{
                    return "运营端"
                  }
               }
            },
            {
                title: '用户ip',
                dataIndex: 'ip',
                key: 'ip'           
            }
            
          ];
          const dataSource = this.state.params;

        return(
            <div>
            <Spin spinning={this.state.loading} tip="Loading...">
					<div class="page-content-info">
						<div class="toolbar-group">
							<div class="toolbar-left">
								<div class="form-main">
									<input class="form-control" onChange={this.valueChange.bind(this,"userName")} placeholder="请输入用户名"/>
								</div>
								{/* <div class="form-main">
									<div class="input-group date form-datetime" data-date="2019-05-10T05:25:07Z" data-date-format="dd MM yyyy - HH:ii p" data-link-field="dtp_input1">
								  	<DatePicker locale={locale}  onChange={this.onChange.bind(this)} placeholder="请选择申请时间"/>
                                    </div>
								</div> */}
                    <button style={{display:this.state.btn_search == true?"inline-block":"none"}} onClick={this.onload.bind(this,'1')} class="btn btn-default btn-search" type="button">检索</button>
							</div>
							<div class="toolbar-right" style={{width:'100%'}}>
                     <Table size={"small"} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
							</div>
						</div>
					</div>
                    </Spin>
				</div>       
        )
    }
}
export default CertificateDownload;
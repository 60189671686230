import React from 'react';
import {Tabs} from 'antd';
import AvailableList from './AvailableList.js';


const { TabPane } = Tabs;
class Available extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
      }
    }
    callback(activeKey,type,params){
        if(type == "details"){
          this.state.params = params
        }
        if(type == "add"){
          this.state.params = null
        }
      this.setState({activeKey:activeKey})
    }
    
    render(){
        return(
          <div>
              <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
                <TabPane tab="可用列表" key="1">
               <AvailableList buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
              </TabPane>
            </Tabs>
          </div>
        )
    }
}
export default Available;
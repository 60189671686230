import React from 'react';
import { Table, Spin,Button,message,Tabs,Modal,Select} from 'antd';
import history from '../history';

const {Option}=Select;
class RoleList extends React.Component{
   
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
			loading:false
		}
	}
		//获取列表数据的接口
		onload(ty){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			if(ty=="1"){
				this.state.start=0
				}else{
				this.state.start=this.state.page
				}
			let content = {start:this.state.start,limit:this.state.pageSize,
				name:this.state.name,code:this.state.code,status:this.state.status
			};
			fetch('/api/userCenter/roleList',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
                                array=data.result;    
                                total=data.total;
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                    this.state.params=array;
                    this.state.total=total;
                    this.state.selectedRowKeys = [];
                    this.setState({ loading: false });
                });
		 }
		 componentWillMount() {
			this.onload()
			this.showBtn(this.props.buttonList)//控制按钮显示
		}
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_search"){
						this.state.btn_search = true
				  }
				  if(array[i].buttonCode == "btn_add"){
					this.state.btn_add = true
				  }
			 }
		}

		valueChange(type,e) {
			if(type == "name"){
					this.state.name=e.target.value;
			}
			if(type == "code"){
				this.state.code=e.target.value;
			}
			if(type == "status"){
				this.state.status=e;
			}
		}
		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
		
		pageChange(page,pageSize){//翻页
			page=(page-1)*pageSize;
			this.state.page=page;
			this.onload();
	 }
	  

    render(){
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title: '角色名称',
					dataIndex: 'name',
					key: 'name',
					render: (text,record) => <a href="javascript:;" onClick = {this.props.callback.bind(this,"2","details",record)}>{text}</a>,
				},
				{
					title: '角色编码',
					dataIndex: 'code',
					key: 'code',
				},
				{
					title: '描述',
					dataIndex: 'description',
					key: 'description',        
                },
				{
					title: '状态',
					dataIndex: 'status',
					key: 'status',
					render: (text,record,index) =>text == "0"?"启用":"禁用" 
					
				},
				{
					title: '创建时间',
					dataIndex: 'createTime',
					key: 'createTime',
				}
				
			];
			const dataSource = this.state.params;
			const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
								<div class="page-content-info">
									<div class="toolbar-group">
										<div class="toolbar-left">
											<div class="form-main">
												<input class="form-control" onChange={this.valueChange.bind(this,"name")} placeholder="请输入角色名称"/>
											</div>
											<div class="form-main">
												<input class="form-control" onChange={this.valueChange.bind(this,"code")} placeholder="请输入角色编码"/>
											</div>
											<div class="form-main">
												<Select style={{width:167}}  onChange={this.valueChange.bind(this,"status")}  placeholder="请选择状态">
													<Option value="">--请选择状态--</Option>
													<Option value="0">启用</Option>
													<Option value="1">禁用</Option>
												</Select>
											</div>
											<button style={{display:this.state.btn_search == true?"inline-block":"none"}} onClick={this.onload.bind(this,'1')} class="btn btn-default btn-search" type="button">检索</button>
										</div>
										<div>
                                             <Button style={{display:this.state.btn_add == true?"inline-block":"none",marginLeft:10}} type="primary" onClick = {this.props.callback.bind(this,"2","add",null)}>新增</Button>
										     {/* <Button style={{marginLeft:10}} type="danger" onClick = {this.showConfirm.bind(this,this)}>删除</Button> */}
										</div>
										<div class="toolbar-right" style={{width:'100%'}}>
											<Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
										</div>
									</div>
								</div>
              </Spin>
            </div>    
        )
    }
}
export default RoleList;
import React from "react";
import {
  Input,
  Select,
  Button,
  Spin,
  message,
  Row,
  Col,
  Modal,
  TimePicker
} from "antd";
import history from "../history";
import moment from 'moment';
const { Option } = Select;
class StrategyDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      loading: false,
      visible: false,
      isfalse: true,
      augmentArr: [],
      values: null,
      esfalse: false
    };
  }
  componentDidMount() {
    this.onload();
    this.showBtn(this.props.buttonList)//控制按钮显示
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_save") {
        this.state.btn_save = true;
      }
      if (array[i].buttonCode == "btn_update") {
        this.state.btn_update = true;
      }
      if (array[i].buttonCode == "btn_cancel") {
        this.state.btn_cancel = true;
      }
    }
  }
  cencel(type) {
    //修改还是取消
    if (type == 'write') {
      this.setState({
        isfalse: false,
        esfalse: true
      });
    } else {
      this.setState({
        isfalse: true,
        esfalse: false,
      });
    }
  }
  onload() {
    this.setState({ loading: true });
    let array = {};
    let content = {};
    fetch("/api/strategy/queryStrategy", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          if (data.result !== null) {
            array = data.result;
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        if (this.state.params.downloadTime) {
          this.setState({
            loading: false,
            augmentArr: eval(this.state.params.downloadTime)
          });
        }
        else {
          this.setState({
            loading: false,
            augmentArr: [],
          });
        }
      });
  }
  //input框值
  handleChange(type, e) {
    let params = this.state.params;
    if (type == "version") {
      params.version = e.target.value;
    }
    if (type == "localDir") {
      params.localDir = e.target.value;
    }
    if (type == "localSecretDir") {
      params.localSecretDir = e.target.value;
    }
    if (type == "dcpCorePoolSize") {
      params.dcpCorePoolSize = e.target.value;
    }
    if (type == "secretKeyCorePoolSize") {
      params.secretKeyCorePoolSize = e.target.value;
    }
    if (type == "forCount") {
      params.forCount = e.target.value;
    }
    if (type == "reDownloadCount") {
      params.reDownloadCount = e.target.value;
    }
    if (type == "domainOfBucket") {
      params.domainOfBucket = e.target.value;
    }
    // if (type == "ispIp") {
    //   params.ispIp = e.target.value;
    // }
    // if (type == "mqIp") {
    //   params.mqIp = e.target.value;
    // }
    if (type == "sleep") {
      params.sleep = e.target.value;
    }
    if (type == "spaceWarningTotal") {
      params.spaceWarningTotal = e.target.value;
    }
    if (type == "feedbackType") {
      params.feedbackType = e;
    }
    if (type == "secretKeyMd5CorePoolSize") {
      params.secretKeyMd5CorePoolSize = e.target.value;
    }
    if (type == "dcpMd5CorePoolSize") {
      params.dcpMd5CorePoolSize = e.target.value;
    }
    this.setState({
      params
    });
  }
  //关于日期
  beginTimeChange = (time, timeString, key) => {
    const { augmentArr } = this.state;
    augmentArr[key].beginTime = timeString;
    this.setState({
      augmentArr
    });
  };
  endTimeChange(time, timeString, key) {
    const { augmentArr } = this.state;
    augmentArr[key].endTime = timeString;
    this.setState({
      augmentArr
    });
  }
  rateChange(e, key) {
    const { augmentArr } = this.state;
    augmentArr[key].networkSpeed = e.target.value;
    this.setState({
      augmentArr
    });
  }
  //保存
  save() {
    const { params, augmentArr } = this.state;
    const flag = augmentArr.every(item => {
      return item.beginTime && item.endTime;
    })
    if (flag == false || params.version == "" || params.localDir == "" || params.localSecretDir == "" || params.dcpCorePoolSize == "" || params.secretKeyCorePoolSize == "" || params.forCount == "" ||
      params.reDownloadCount == "" || params.domainOfBucket == "" || params.sleep == "" || params.spaceWarningTotal == "" ||
      params.feedbackType == "" || params.secretKeyMd5CorePoolSize == "" || params.dcpMd5CorePoolSize == "") {
      message.error("存在必填项未填写，请核实！");
      return;
    }
    
    this.setState({ loading: true });
    params.downloadTime = JSON.stringify(augmentArr);
    let that = this;
    fetch("/api/strategy/saveStrategy", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state.params)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          message.success("保存成功！");
          that.onload(data.result.id);
          that.setState({
            isfalse: true
          });
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({ loading: false, isfalse: true, esfalse: false });
      });
  }
   //添加行
  addClick = () => {
    let { augmentArr } = this.state;
    augmentArr.push({});
    this.setState({
      augmentArr
    });
  };
  //删除行
  delClick = key => {
    let { augmentArr } = this.state;
    augmentArr.filter((item, index) => {
      if (index === key) {
        augmentArr.splice(index, 1);
      }
    });
    this.setState({
      augmentArr
    });
  };
  //弹框 确认和取消
  handleOk(type) {
    this.save(type)
    this.setState({ visible: false })
  }
  actorCancel() {
    this.setState({ visible: false })
  }
  devClick(type) {
    this.setState({ visible: true })
  }
  render() {
    let { isfalse, augmentArr, params, esfalse } = this.state;
    return (
      <div className="StrategyDetails">
        <Spin spinning={this.state.loading} tip="Loading...">
          <div>
            <Button style={{ display: this.state.btn_save == true ? "inline-block" : "none" }} onClick={this.devClick.bind(this)} disabled={isfalse}>保存</Button>
            <Button
              style={{ display: this.state.btn_update == true ? "inline-block" : "none", marginLeft: "10px" }}
              onClick={this.cencel.bind(this, 'write')}
              disabled={esfalse}
            >
              修改
            </Button>
            <Button
              style={{ display: this.state.btn_cancel == true ? "inline-block" : "none", marginLeft: "10px" }}
              onClick={this.cencel.bind(this)}
              disabled={isfalse}
            >
              取消
            </Button>
          </div>
          <Row style={{ margin: "10px 5px 5px 0" }}>
            <Col span={9}>
              <span style={{ display: "inline-block", width: "170px" }}>
                版本号：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                placeholder="请输入版本号"
                value={params.version}
                onChange={this.handleChange.bind(this, "version")}
              />
            </Col>
          </Row>
          <Row style={{ margin: '10px 5px 5px 0' }}>
            <Col span={14}>
              <span style={{ display: 'inline-block', width: '170px' }}>
                DCP计算MD5线程数：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input style={{ width: 240 }} disabled={isfalse}
                value={this.state.params.dcpMd5CorePoolSize}
                placeholder="请输入DCP计算MD5线程数" onChange={this.handleChange.bind(this, "dcpMd5CorePoolSize")}>
              </Input>
            </Col>
            <Col span={9}>
              <span style={{ display: 'inline-block', width: '170px' }}>
                秘钥计算md5线程数：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input style={{ width: 240 }} disabled={isfalse}
                value={this.state.params.secretKeyMd5CorePoolSize}
                placeholder="请输入秘钥算MD5线程数" onChange={this.handleChange.bind(this, "secretKeyMd5CorePoolSize")}>
              </Input>
            </Col>
          </Row>
          <Row style={{ margin: "10px 5px 5px 0" }}>
            <Col span={14}>
              <span style={{ display: "inline-block", width: "170px" }}>
                DCP下载路径：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.localDir}
                placeholder="请输入"
                onChange={this.handleChange.bind(this, "localDir")}
              ></Input>
            </Col>
            <Col span={9}>
              <span style={{ display: "inline-block", width: "170px" }}>
                秘钥下载路径：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.localSecretDir}
                placeholder="请输入"
                onChange={this.handleChange.bind(this, "localSecretDir")}
              ></Input>
            </Col>
          </Row>
          <Row style={{ margin: "10px 5px 5px 0" }}>
            <Col span={14}>
              <span style={{ display: "inline-block", width: "170px" }}>
                DCP下载任务线程数：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.dcpCorePoolSize}
                placeholder="请输入"
                onChange={this.handleChange.bind(this, "dcpCorePoolSize")}
              ></Input>
            </Col>
            <Col span={9}>
              <span style={{ display: "inline-block", width: "170px" }}>
                密钥下载任务线程数：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.secretKeyCorePoolSize}
                placeholder="请输入"
                onChange={this.handleChange.bind(this, "secretKeyCorePoolSize")}
              ></Input>
            </Col>
          </Row>
          <Row style={{ margin: "10px 5px 5px 0" }}>
            <Col span={14}>
              <span style={{ display: "inline-block", width: "170px" }}>
                循环下载次数：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.forCount}
                placeholder="请输入"
                onChange={this.handleChange.bind(this, "forCount")}
              ></Input>
            </Col>
            <Col span={9}>
              <span style={{ display: "inline-block", width: "170px" }}>
                重新下载次数：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.reDownloadCount}
                placeholder="请输入"
                onChange={this.handleChange.bind(this, "reDownloadCount")}
              ></Input>
            </Col>
          </Row>
          <Row style={{ margin: "10px 5px 5px 0" }}>
            <Col span={14}>
              <span style={{ display: "inline-block", width: "170px" }}>
                domainOfBucket：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.domainOfBucket}
                placeholder="请输入"
                onChange={this.handleChange.bind(this, "domainOfBucket")}
              ></Input>
            </Col>
            <Col span={9}>
              <span style={{ display: "inline-block", width: "170px" }}>
                反馈类型：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Select
                style={{ width: 240 }}
                disabled={isfalse}
                placeholder="请输入反馈类型"
                onChange={this.handleChange.bind(this, "feedbackType")}
                value={this.state.params.feedbackType}
              >
                <Option value="0">即时反馈</Option>
                <Option value="1">按进度比例反馈</Option>
              </Select>
            </Col>
          </Row>
          {/* <Row style={{ margin: "10px 5px 5px 0" }}>
            <Col span={14}>
              <span style={{ display: "inline-block", width: "170px" }}>
                ISP服务端地址：<span style={{color:"#b94a48",fontWeight:900}}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.ispIp}
                placeholder="请输入"
                onChange={this.handleChange.bind(this, "ispIp")}
              ></Input>
            </Col>
            <Col span={9}>
              <span style={{ display: "inline-block", width: "170px" }}>
                MQ服务端地址：<span style={{color:"#b94a48",fontWeight:900}}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.mqIp}
                placeholder="请输入"
                onChange={this.handleChange.bind(this, "mqIp")}
              ></Input>
            </Col>
          </Row> */}
          <Row style={{ margin: "10px 5px 5px 0" }}>
            <Col span={14}>
              <span style={{ display: "inline-block", width: "170px" }}>
                睡眠时间：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.sleep}
                placeholder="请输入"
                suffix="ms"
                onChange={this.handleChange.bind(this, "sleep")}
              ></Input>
            </Col>
            <Col span={9}>
              <span style={{ display: "inline-block", width: "170px" }}>
                剩余空间预警：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
              <Input
                style={{ width: 240 }}
                disabled={isfalse}
                value={this.state.params.spaceWarningTotal}
                placeholder="请输入"
                suffix="GB"
                onChange={this.handleChange.bind(this, "spaceWarningTotal")}

              ></Input>
              
            </Col>
          </Row>
          <Row style={{ margin: "10px 5px 5px 0" }}>
            <Col span={3}>
              <span style={{ display: "inline-block", width: "160px", marginTop: '16px' }}>
                下载任务执行时间：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              </span>
            </Col>
            <Col span={20} style={{ marginLeft: "-6px" }}>
              {augmentArr && augmentArr.map((item, key) => (
                <div key={key} style={{ marginTop: "10px", marginLeft: '24px' }}>
                  <Row>
                    <Col span={6}>
                      <TimePicker
                        disabled={isfalse}
                        style={{ width: 240 }}
                        onChange={(time, timeString) =>
                          this.beginTimeChange(time, timeString, key)
                        }
                        placeholder="开始时间"
                        value={moment(
                          item.beginTime && item.beginTime
                            ? item.beginTime
                            : "00:00:00",
                          "HH:mm:ss"
                        )}
                      />
                    </Col>
                    <Col span={1} style={{ marginLeft: '24px' }}>——</Col>
                    <Col span={6}>
                      <TimePicker
                        disabled={isfalse}
                        style={{ width: 240 }}
                        onChange={(time, timeString) =>
                          this.endTimeChange(time, timeString, key)
                        }
                        value={moment(
                          item.endTime && item.endTime
                            ? item.endTime
                            : "23:59:59",
                          "HH:mm:ss"
                        )}
                      />
                    </Col>
                    <Col span={6}>
                      <span
                        style={{ display: "inline-block", marginLeft: "16px" }}
                      >
                        限速:
                      </span>
                      <Input
                        style={{ width: 150 }}
                        disabled={isfalse}
                        value={item.networkSpeed}
                        onChange={e => this.rateChange(e, key)}
                        suffix="KB/s"
                      ></Input>
                    </Col>
                    {isfalse ? null : (
                      <Col span={4}>
                        <div className="count" style={{ display: "flex" }}>
                          {key > 0 ? (
                            <div
                              className="jian"
                              onClick={() => this.delClick(key)}
                              style={{
                                width: "30px",
                                height: "30px",
                                border: "1px solid #ccc",
                                textAlign: "center",
                                lineHeight: "28px",
                                borderRadius: "50%",
                                marginLeft: '15px'
                              }}

                            >
                              -
                            </div>
                          ) : (
                              <div
                                className="jia"
                                onClick={() => this.addClick()}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  border: "1px solid #ccc",
                                  textAlign: "center",
                                  lineHeight: "28px",
                                  marginRight: "5px",
                                  marginLeft: '15px',
                                  borderRadius: "50%"
                                }}

                              >
                                +
                            </div>
                            )}
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              ))}
            </Col>
          </Row>
        </Spin>
        <Modal title="提示" okText="确认" cancelText="取消" width="300px" style={{ marginTop: "20px" }} visible={this.state.visible} onOk={this.handleOk.bind(this)} onCancel={this.actorCancel.bind(this)}>
          <div style={{ width: '100%', textAlign: "center", height: "5px", lineHeight: "5px" }}>
            <b>
              是否保存?
            </b>
          </div>
        </Modal>
      </div>
    );
  }
}

export default StrategyDetail;

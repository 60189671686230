import React from 'react';
import { Table, Spin,Button,message,Tabs,Modal,Icon,Select} from 'antd';
import history from '../history';
let {Option}=Select
class PendingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
            loading:false,
            list:[],
            orderBy:'',
            visible:false,
            pushList:[]
         };
    }
    componentWillMount() {
        this.onload()
        this.showBtn(this.props.buttonList)//控制按钮显示
        console.log(this.props.buttonList)
    }
    //控制按钮显示
    showBtn(array) {
        console.log(array)
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_search") {
                this.state.btn_search = true
            }
            if (array[i].buttonCode == "btn_export") {
                this.state.btn_export = true
            }
        }
    }
    onload(ty){
        this.setState({ loading: true });
        let array = []
        let total = 0;
        if(ty=="1"){
            this.state.start=0
          }else{
            this.state.start=this.state.page
          }
        let content = {
            start: this.state.start, limit: this.state.pageSize,
            exportStatus:'0',cimemaName:this.state.cinemaName,
            orderBy:this.state.orderBy,orderNum:this.state.orderNum,
            payStatus:'1'
        };
        fetch('/api/keyOrder/queryKeyOrderList', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'  
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } 
            else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.params = array;
            this.state.total = total;
            // this.state.pushlist=this.state.selectedRow
            this.state.selectedRow=[]
            this.setState({ loading: false});
        });
    }
    //获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
            this.state.selectedRow=selectedRow
            this.setState({ selectedRowKeys });
		};
	//翻页
		pageChange(page,pageSize){
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
        }
    //val值
    valChange(type,e){
        let state=this.state
     if(type=="orderNum"){
        state.orderNum= e.target.value
     }
     if(type=="cinemaName"){
       state.cinemaName= e.target.value
    }
   if(type=="orderBy"){
       state.orderBy=e
   }
   this.setState({
       state
   })
    }  
    //弹框
    handleOk(type){
        this.exportEvent(type)
        this.setState({visible:false})
      }
    actorCancel(){
        this.setState({visible:false})
    }
    btnClick(type){
        if(this.state.selectedRow.length == 0){
            message.error("请选择数据，再进行操作");
            return;
          }
        this.setState({visible:true,type})
    }
    //导出
    exportEvent(){
        let array={}
        for (var i = 0; i < this.state.selectedRow.length; i++) {
            this.state.list[i] = { id: this.state.selectedRow[i].id}
        }
        console.log(this.state.list)
        fetch('/api/keyOrder/orderExport', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'  
            },
            body: JSON.stringify(this.state.list),
        }).then(response => {
           if(response.data==null){
            response.blob().then(blob => {
                let blobUrl = window.URL.createObjectURL(blob);
                let a = document.createElement('a'); //获取a标签元素
                let filename = 'filename.zip'; //设置文件名称
                a.href = blobUrl; //设置a标签路径
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(blobUrl);
                message.success("导出成功");
                this.onload()
              });
           }else if(response.data.code==-1){
            message.success('存在已导出的订单,请核实')
           }
            
          })
          .catch(error => {
            message.error("导出失败", error);
          });
    }
    
    render() {
        const columns = [
            {
                title: '序号',
                render:(text,record,index)=>`${index+1}`,
            },
            {
                title: '订单号',
                dataIndex: 'orderNum',
                key: 'orderNum',
            },
            {
                title: '价格(元)',
                dataIndex: 'price',
                key: 'price',
            
            },
            {
                title: '影院名称',
                dataIndex: 'cinemaName',
                key: 'cinemaName',
            },
            {
                title: '下单时间',
                dataIndex: 'createTime',
                key: 'createTime', 
            }
            
        ];
        const dataSource = this.state.params;
        const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
            };

        return (
            <div>
            <Spin spinning={this.state.loading} tip="Loading...">
                              <div class="page-content-info pending">
                                  <div class="toolbar-group">
                                      <div class="toolbar-left">
                                          <div class="form-main">
                                              <input class="form-control" placeholder="请输入订单号" onChange={this.valChange.bind(this,'orderNum')}/> 
                                          </div>
                                          <div class="form-main">
                                              <input class="form-control" placeholder="请输入影院名称" onChange={this.valChange.bind(this,'cinemaName')}/> 
                                          </div>
                                         
                                          <div class="form-main" style={{marginLeft:'10px'}}>
                                            <Select style={{width:'176px',height:'36px'}} onChange={this.valChange.bind(this,'orderBy')} placeholder="下单时间排序">
                                                <Option value="">--下单时间排序--</Option>
                                                <Option value="asc">正序</Option>
                                                <Option value="desc">倒序</Option>
                                            </Select>
                                          </div>
                                          
                                          <button onClick={this.onload.bind(this,'1')} class="btn btn-default btn-search" type="button" style={{display: this.state.btn_search == true ? "inline-block" : "none" }}>检索</button>
                                          <Button style={{ display: this.state.btn_export == true ? "inline-block" : "none" ,marginLeft:'10px',marginRight:'10px'}} onClick={this.btnClick.bind(this)}>导出</Button>
                                      </div>
                                      <div class="toolbar-right" style={{width:'100%'}}>
                                          <Table size={"small"} rowSelection={rowSelection} rowKey={record=>record.id} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
                                      </div>
                                  </div>
                              </div>
            </Spin>
            <Modal title="提示" okText="确认"  cancelText="取消" width="300px"  style={{marginTop:"20px"}} visible={this.state.visible}  onOk={this.handleOk.bind(this)} onCancel={this.actorCancel.bind(this)}>
                  <div style={{width:'100%',textAlign:"center",height:"5px",lineHeight:"5px"}}>
                  <b>是否导出?</b>
                  </div>
            </Modal>
          </div>   
        );
    }
}

export default PendingList;
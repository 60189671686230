import React from 'react';
import { Table, Spin, Button, message, Tabs, Modal, Select, Input } from 'antd';
import history from '../history';
const { Option } = Select;
class SecretOrderExportedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      list:[]
    };
  }
  componentWillMount() {
    this.onload();
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == 'btn_search') {
        this.state.btn_search = true;
      }
      if (array[i].buttonCode == 'btn_secret_confirm') {
        this.state.btn_secret_confirm = true;
      }
    }
  }
  onload(ty) {
    this.setState({ loading: true });  
    let array = [];
    let total = 0;
    if(ty=="1"){
      this.state.start=0
      }else{
      this.state.start=this.state.page
      }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      status:'0',
      exportType:'0',
      exportNum:this.state.exportNum,
    };
    // console.log(content)
    fetch('/api/exportOrder/queryExportOrderList', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error('网络不可用！');
        }
        return res.json();
      })
      .then(function(data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: '/' });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.setState({ loading: false });
      });
  }
  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }
  //翻页
  pageChange(page, pageSize) {
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
  //val值
  valChange(type, e) {
    if (type == 'exportNum') {
      this.state.exportNum = e.target.value;
    }
  }
  //弹框
  handleOk(){
    this.exportEvent()
    this.setState({visible:false})
  }
actorCancel(){
    this.setState({visible:false})
}
btnClick(){
    if(this.state.selectedRow.length == 0){
        message.error("请选择数据，再进行操作");
        return;
      }
    this.setState({visible:true})

}
//确认
 exportEvent(){
  if (this.state.selectedRow.length == 0) {
    message.error("尚未选中数据！");
    return;
  }
        for (var i = 0; i < this.state.selectedRow.length; i++) {
            this.state.list[i] = { id: this.state.selectedRow[i].id}
        }
        fetch('/api/exportOrder/operation', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'  
            },
            body: JSON.stringify(this.state.list),
        }).then(function(res) {
          if (!res.ok) {
            message.error('网络不可用！');
          }
          return res.json();
        }).then((data)=> {
          console.log(data)
          if (data.code == 0) {
           message.success('确认成功')
           this.onload()
          }
          else {
            message.error(data.message);
            if (data.code == 2003) {
              history.push({ pathname: '/' });
            }
          }
        })
 }
  render() {
    const columns = [
      {
        title: '序号',
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: '导出单号',
        dataIndex: 'exportNum',
        key: 'exportNum',
        render: (text, record) => (
          <a
            href="javascript:;"
            onClick={this.props.callback.bind(this, '2', 'details', record)}>
            {text}
          </a>
        )
      },
      {
        title: '导出时间',
        dataIndex: 'exportTime',
        key: 'exportTime'
      },
      {
        title: '导出操作人员',
        dataIndex: 'exportPeople',
        key: 'exportPeople'
      }
    ];
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this) //获取选中值
    };
    return (
      <div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div class="page-content-info">
            <div class="toolbar-group">
              <div class="toolbar-left">
                <div class="form-main">
                  <Input
                    class="form-control"
                    placeholder="请输入导出单号"
                    onChange={this.valChange.bind(this, 'exportNum')}
                  />
                </div>
                  <button
                  style={{display: this.state.btn_search == true ? "inline-block" : "none" }}
                    onClick={this.onload.bind(this,'1')}
                    class="btn btn-default btn-search"
                    type="button">
                    检索
                  </button>
                <Button
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                    height: '34px',
                   display: this.state.btn_secret_confirm == true ? "inline-block" : "none" 
                  }}
                  onClick={this.btnClick.bind(this)}
                  >
                  密钥导出单确认
                </Button>
              </div>
              <div class="toolbar-right" style={{ width: '100%' }}>
                <Table
                  size={'small'}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
        </Spin>
        <Modal title="提示" okText="确认"  cancelText="取消" width="300px"  style={{marginTop:"20px"}} visible={this.state.visible}  onOk={this.handleOk.bind(this)} onCancel={this.actorCancel.bind(this)}>
                  <div style={{width:'100%',textAlign:"center",height:"5px",lineHeight:"5px"}}>
                  <b>是否确认?</b>
                  </div>
            </Modal>
      </div>
    );
  }
}

export default SecretOrderExportedList;

import React from 'react';
import {Tabs} from 'antd';
import ToConfirmedList from './ToConfirmedList.js';
const { TabPane } = Tabs;
class ToConfirmed extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1"
      }
    }
      callback(activeKey,type,params){
          if(type == "add"){
            this.state.params = null
          }
        this.setState({activeKey:activeKey})
      }
    render(){
      const {isfalse}=this.state
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
            <TabPane tab="待确认列表" key="1">
              <ToConfirmedList  buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default ToConfirmed;
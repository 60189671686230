
import React from "react";
import {
  Input,
  Select,
  Button,
  Spin,
  Table,
  Row,
  Col,
  message,
  Icon,
  Upload
} from "antd";
import history from "../history";
class DcpOrderExportedDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          params: {},
          param:{},
          loading: false,
          disbtn:'none',
          ites:{},
          fileList:[]
         };
    }
    componentWillMount() {
      if (this.props.params != null) {
        this.state.params = this.props.params;
        this.onload(this.state.params);
      } 
      this.showBtn(this.props.buttonList); //控制按钮显示
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps != null) {
        if (nextProps.params != null) {
          this.state.params = nextProps.params
          this.onload(this.state.params);
        } else {
          this.setState({
            params: {}
          });
        }
      } else {
        this.setState({
          params: {}
        });
      }
    }
    //控制按钮显示
    showBtn(array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].buttonCode == "btn_dcp_confirm") {
          this.state.btn_dcp_confirm = true;
        }
      }
    }
    //请求详情的列表
  onload(params) {
    let dataParams = {};
    this.setState({ loading: true });
    let content = { id: params.id }
    fetch('/api/exportOrder/queryExportOrderDetails', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then((data) => {
      if (data.code == 0) {
        dataParams = data.result;
        dataParams.bList.map(item=>{
         item.disbtn="none"
        })
       console.log(dataParams)
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.setState({ param: dataParams, loading: false });
    });
  }
 
   clickbtn(type){
    let {param}=this.state
    if(param.bList[type].disbtn=="none"){
      param.bList[type].disbtn="block"
      // ites.disbtn="block"
    }else if( param.bList[type].disbtn="block"){
      param.bList[type].disbtn="none"
      // ites.disbtn="block"
    }
    this.setState({param})
     }

     //关于上传的js
   handleCancel = () => this.setState({ previewVisible: false });
   handlePreview = async file => {
       if (!file.url && !file.preview) {
       file.preview = await this.getBase64(file.originFileObj);
       }
       this.setState({
       previewImage: file.url || file.preview,
       previewVisible: true,
       });
   };
   imgChange = ({ fileList,file }) =>{
    if(fileList[0].status === "done"){
      // message.success('上传成功')
      if(file.response.code==-1){
        message.error(file.response.message)
        fileList=[]
        // this.onload()
      }else if(file.response.code==0){
        message.success('确认成功')
        fileList=[]
        this.onload()
      }
    }else if(fileList[0].status === "error"){
      message.error('上传失败')
      fileList=[]
    }
    this.setState({ fileList })
   } ;
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
    render() {
      const columns=[
        // {
        //   title: '序号',
        //   render: (text, record, index) => `${index + 1}`
        // },
        {
          title: '省份',
          dataIndex: 'province',
          key: 'province'
        },
        {
          title: '城市',
          dataIndex: 'city',
          key: 'city'
        },
        {
          title: '院线',
          dataIndex: 'bosTheaterChainName',
          key: 'bosTheaterChainName'
        },
        {
          title: '影城名称',
          dataIndex: 'cinemaName',
          key: 'cinemaName'
        },
        {
          title: '影城编码',
          dataIndex: 'cinemaCode',
          key: 'cinemaCode'
        },
        {
          title: '厅号',
          dataIndex: 'hallName',
          key: 'hallName'
        },
        {
          title: '服务器序列号',
          dataIndex: 'serverSerialNumber',
          key: 'serverSerialNumber'
        },
        {
          title: '开始日期',
          dataIndex: 'beginDate',
          key: 'beginDate'
        },
        {
          title: '开始时间',
          dataIndex: 'beginTime',
          key: 'beginTime'
        },
        {
          title: '结束日期',
          dataIndex: 'endDate',
          key: 'endDate'
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
          key: 'endTime'
        },
        {
          title: '影城联系人',
          dataIndex: 'cinemaContactsName',
          key: 'cinemaContactsName'
        },
        {
          title: '联系电话',
          dataIndex: 'cinemaContactsPhone',
          key: 'cinemaContactsPhone'
        },
        {
          title: '是否中数设备',
          dataIndex: 'isOwner',
          key: 'isOwner',
          render:(text)=>text=="0"?"否":text=="1"?"是":''
        }
      ]
        return (
      <div
        className="DcpOrderExportedDetail"
        key={
          this.state.params != null && this.state.params.id == "-1"
            ? this.state.params.id
            : this.state.params.id
        }
      >
        <Spin spinning={this.state.loading} tip="Loading...">
            <Row>
              <Col span={7}>密钥导出单号：{this.state.params.exportNum}</Col>
              <Col span={7}>导出时间：{this.state.params.exportTime}</Col>
              <Col span={5}>导出操作人员：{this.state.params.exportPeople}</Col>
              <Col span={3}>
                <Upload
                 style={{ height: '34px',marginLeft:'10px',display: this.state.btn_dcp_confirm == true ? "inline-block" : "none" }}
                 action="/api/exportOrder/dcpOperation"
                 onChange={(e) => this.imgChange(e)}
                 fileList={this.state.fileList}
                 data={{id:this.state.param.id}}
              >
              <Button   style={{display: this.state.btn_dcp_confirm == true ? "inline-block" : "none" ,border:'1px solid #ccc',height:'34px',lineHeight:'34px',marginTop:'-6px'}}>DCP导出单确认</Button>
             </Upload></Col>
            </Row>
            {
             this.state.param.bList&&this.state.param.bList.map((item,index)=>{
               return  <div style={{width:'100%'}}>
               <div style={{width:'100%',height:'40px',border:'1px solid #ddd',lineHeight:'40px',background:'#ccc'}} onClick={this.clickbtn.bind(this,index)}><Row><Col style={{marginLeft:'10px'}} span={22}>{item.filmName}</Col><Col span={1}><Icon type="down"></Icon></Col></Row></div>
               <div style={{width:'100%',display:item.disbtn}}>
               <Table 
                  size={'small'}
                  columns={columns}
                  dataSource={item.exportList}
                  pagination={false}
                  // pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}
                />
               </div>
             </div>
             })
            }
           
        </Spin>
      </div>
        );
    }
}

export default DcpOrderExportedDetail;
import React from "react";
import { Table, Spin, Button, message, Row,Col, Modal,Select,Input,Upload,Icon,DatePicker } from "antd";
import history from "../history";
const { Option } = Select;
class AlreadyPresentedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,   
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      display:'none',    
    };
  }
  //列表接口
  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if(ty=="1"){
      this.state.start=0
      }else{
      this.state.start=this.state.page
      }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      code: this.state.code,
      name: this.state.name,
      type: this.state.type,
      category: this.state.category,
      cinemaName:this.state.cinemaName
    };
    fetch("/api/coupon/giveHistory", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function(data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.setState({ loading: false });
      });
  }
  componentWillMount() {
    this.onload();
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true;
      }
    }
  }

 
// 搜索input、select 的值
  valueChange(type, e) {
    if (type == "code") {
      this.state.code = e.target.value;
    }
    if (type == "name") {
      this.state.name = e.target.value;
    }
    if (type == "type") {
      this.state.type = e;
    }
    if (type == "category") {
      this.state.category = e;
    }
    if (type == "cinemaName") {
      this.state.cinemaName = e.target.value;
    }
  }
  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }

  pageChange(page, pageSize) { 
    //翻页
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
 
  render() {
    const columns = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "劵编码",
        dataIndex: "code",
        key: "code",
      },
      {
        title: "优惠券名称",
        dataIndex: "name",
        key: "name",
      },
      {
        title:'优惠券满减',
        dataIndex:'conditions',
        key:'conditions',
      render:(text,record)=><span>满{record.conditions}减{record.discount}</span>
      },
      {
        title: "优惠券类型",
        dataIndex: "type",
        key: "type",
        render:text=>text=text==0?"满减券":text==1?"折扣券":text==2?"现金券":"免场次券"
      },
      {
        title: "优惠券品类",
        dataIndex: "category",
        key: "category",
        render:text=>text==0?"通用券":""

      },
      {
        title:'发行方补贴比列',
        dataIndex:'subsidyRatioOfIssuers',
        key:'subsidyRatioOfIssuers',
        render:(text)=>text==undefined?'0'+'%':text+'%'
      },
      {
        title:'优惠券有效期', 
        dataIndex:'effectiveBegin',
        key:'effectiveBegin',//text=(record.effectiveBegin).slice(0,1)+'-'+(record.effectiveEnd)
        // render:(text,record)=>text=(text)+'-'+(record.effectiveEnd)
        render:(text,record)=>text=text!=undefined?(text).slice(0,10)+'-'+(record.effectiveEnd).slice(0,10):text
      },
      {
        title: "创建人",
        dataIndex: "creator",
        key: "creator",
      },{
        title:'赠送操作人', 
        dataIndex:'operator',
        key:'operator',
        
      },{
        title:'赠送用户名',
        dataIndex:'cinemaName',
        key:'cinemaName'
      },{
        title:'是否使用', 
        dataIndex:'isUse',
        key:'isUse',
        render:(text)=>text=text=="0"?"否":text=='1'?"是":""
      },{
        title:'使用日期', 
        dataIndex:'useDate',
        key:'useDate',
      },{
        title:'订单号', 
        dataIndex:'modKeyOrderNum', 
        key:'modKeyOrderNum'
      }
    ]; 
    
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this) //获取选中值
    };
    return (
      <div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div className="page-content-info">
            <div className="toolbar-group">
              <div className="toolbar-left">
                <div className="form-main">
                  <input
                    className="form-control"
                    onChange={this.valueChange.bind(this, "code")}
                    placeholder="请输入券编码"
                  />
                </div>
                <div className="form-main">
                  <input
                    className="form-control"
                    onChange={this.valueChange.bind(this, "name")}
                    placeholder="请输入优惠券名称"
                  />
                </div> 
                <div className="form-main">
                  <Select
                    style={{ width: '167px'}}
                    onChange={this.valueChange.bind(this, "type")}
                    placeholder="请选择优惠券类型"
                  >
                    <Option value="">--优惠券类型--</Option>
                    <Option value="0">满减券</Option>
                    <Option value="1">折扣券</Option>
                    <Option value="2">现金券</Option>
                    <Option value="3">免场次券</Option>
                  </Select>
                </div>
                <div className="form-main">
                  <Select
                    style={{ width: '167px'}}
                    onChange={this.valueChange.bind(this, "category")}  
                    placeholder="请选择优惠券品类"
                  >
                    <Option value="">--优惠券品类--</Option>
                    <Option value="0">通用券</Option>
                  </Select>
                </div>
                <div className="form-main">
                  <input
                    className="form-control"
                    onChange={this.valueChange.bind(this, "cinemaName")}
                    placeholder="请输入赠送用户名"
                  />
                </div>
                <button        //还没有设置按钮   
                  style={{    
                      display:this.state.btn_search == true ? "inline-block" : "none"
                  }}
                  onClick={this.onload.bind(this,'1')}
                  className="btn btn-default btn-search"
                  type="button"
                >
                  检索
                </button>
              </div>
             
              <div className="toolbar-right" style={{ width: "100%" }}>
                <Table
                  size={"small"}
                  // rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                  onChange: this.pageChange.bind(this),
                  total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
        </Spin>  
      </div>
    );        
  }
}
export default AlreadyPresentedList;

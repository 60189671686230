import React from "react";
import {
  Input,
  Select,
  Button,
  Spin,
  Row,
  Col,
  message,
  Modal,
  TimePicker,
  DatePicker,
  Table
} from "antd";
import history from "../history";
import moment from "moment";
import { formatFileSize } from "../../utils/formatFile";
import { formatSpeedSize } from "../../utils/fromwang";
const { Option } = Select;

class BoxManageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      loading: false,
      visible: false,
      type: "",
      isfalse: true,
      augmentArr: [{}],
      strategyBox: {},
      isbtn: "none",
      ischange: false,
      upchange: true,
      upchanges: '',
      ischanges: false,
      dataList: [],
      vals:'0',
      lists:{},
      changeType:1,
      changelist:{},
      asynclist:null,
     dowvis:false,
     ycevent:'none',
     command:'',
     sendList:null,
     efalse:false,
     showFilms:false,
     boxHaveFilmList:[]
    };
    
  }

  componentWillMount() {
    if (this.props.params != null) {
      this.state.params = this.props.params;
      this.state.isfalse = this.props.isfalse;
      this.showBtn(this.props.buttonList);
      this.onload(this.state.params.id);
      this.timer=setInterval(() => {
        this.onloading(this.state.params);
        }, 5000); 
      sessionStorage.removeItem('serialNumber');
      sessionStorage.setItem('serialNumber',this.state.params.serialNumber);
    } else {
      this.state.fileList = [];
    }
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps != null) {
      if (nextProps.params != null) {
        this.state.params = nextProps.params;
        this.state.isfalse = this.props.isfalse;
        this.onload(this.state.params.id);
        this.state.esfalse=false
      } else {
        this.setState({
          params: {}
        });
      }
    } else {
      this.setState({
        params: {}
      });
    }
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_save") {
        this.state.btn_save = true;
      }
      if (array[i].buttonCode == "btn_update") {
        this.state.btn_update = true;
      }
      if (array[i].buttonCode == "btn_cancel") {
        this.state.btn_cancel = true;
      }
      if (array[i].buttonCode == "btn_restart_machine") {
        this.state.btn_restart_machine = true;
      }
      if (array[i].buttonCode == "btn_start_application") {
        this.state.btn_start_application = true;
      }
      if (array[i].buttonCode == "btn_stop_application") {
        this.state.btn_stop_application = true;
      }
      if (array[i].buttonCode == "btn_upgrade_application") {
        this.state.btn_upgrade_application = true;
      }
      if (array[i].buttonCode == "btn_download_log") {
        this.state.btn_download_log = true;
      }
      if (array[i].buttonCode == "btn_remote_contro") {
        this.state.btn_remote_contro= true;
      }
      if (array[i].buttonCode == "btn_restart_application") {
        this.state.btn_restart_application = true;
      }
    }
  }
  //日志定时器
  startTimesr=()=>{
    this.timesr=setInterval(() => {
      this.asyncing();
       message.success('等待请求数据')
      }, 3000);
  }
  cleraTimesr=()=>{
    clearInterval(this.timesr)
  }
  //发送定时器
  startTimesend=(id)=>{
    this.timesend=setInterval(() => {
      this.asyncSend(id); 
      }, 20000);
  }
  clearTimesend=()=>{
    clearInterval(this.timesend)
  }
  //销毁定时刷新
  componentWillUnmount(){
     clearInterval(this.timer)
     this.clearTimesend()
     this.cleraTimesr()
  }
  //定时刷新
  onloading(params) {
    let dataParams = {};
    let content = { id: params.id };
    fetch(" /api/device/boxDetails", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(data => {
        if (data.code == 0) {
          dataParams = data.result;
          if (dataParams.strategyBox == null) {
            this.setState({
              strategyBox: {
                version: this.state.params.version,
                localDir: this.state.params.localDir,
                localSecretDir: this.state.params.localSecretDir,
                dcpCorePoolSize: this.state.params.dcpCorePoolSize,
                dcpCorePoolSize: this.state.params.dcpCorePoolSize,
                secretKeyCorePoolSize: this.state.params.secretKeyCorePoolSize,
                forCount: this.state.params.forCount,
                reDownloadCount: this.state.params.reDownloadCount,
                domainOfBucket: this.state.params.domainOfBucket,
                // ispIp: this.state.params.ispIp,
                sleep: this.state.params.sleep,
                spaceWarningTotal: this.state.params.spaceWarningTotal,
                feedbackType: this.state.params.feedbackType,
                secretKeyMd5CorePoolSize: this.state.params.secretKeyMd5CorePoolSize,
                dcpMd5CorePoolSize: this.state.params.dcpMd5CorePoolSize,
                downloadTime: this.state.params.downloadTime
              }
            });
          } else {
            this.setState({ strategyBox: dataParams.strategyBox});
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({ params: dataParams });
      });
  }
  //请求详情 筛选的接口
  onload(id) {
    let dataParams = {};
    this.setState({ loading: true });
    let content = { id: id };
    fetch(" /api/device/boxDetails", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(data => {
        if (data.code == 0) {
          dataParams = data.result;
          if (dataParams.strategyBox == null) {
            this.setState({
              strategyBox: {
                version: this.state.params.version,
                localDir: this.state.params.localDir,
                localSecretDir: this.state.params.localSecretDir,
                dcpCorePoolSize: this.state.params.dcpCorePoolSize,
                dcpCorePoolSize: this.state.params.dcpCorePoolSize,
                secretKeyCorePoolSize: this.state.params.secretKeyCorePoolSize,
                forCount: this.state.params.forCount,
                reDownloadCount: this.state.params.reDownloadCount,
                domainOfBucket: this.state.params.domainOfBucket,
                // ispIp: this.state.params.ispIp,
                sleep: this.state.params.sleep,
                spaceWarningTotal: this.state.params.spaceWarningTotal,
                feedbackType: this.state.params.feedbackType,
                secretKeyMd5CorePoolSize: this.state.params.secretKeyMd5CorePoolSize,
                dcpMd5CorePoolSize: this.state.params.dcpMd5CorePoolSize,
                downloadTime: this.state.params.downloadTime  
              }
            });
          } else {
            this.setState({ strategyBox: dataParams.strategyBox,augmentArr: eval(dataParams.strategyBox.downloadTime)});
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({ params: dataParams, loading: false});
      });
  }
  //按钮的修改还是取消
  cencel(type) {
    if (type == 'write') {
        this.setState({
            isfalse: false,
            esfalse:true
        });
    } else {
        this.setState({
            isfalse: true,
            esfalse: false,
        });
    }
}

  //input值
  handleChange(type, e) {
    let {changelist,changeType} = this.state;
    if (type == "version") {
      changelist.version = e.target.value;
      changeType=1
    }
    if (type == "localDir") {
      changelist.localDir = e.target.value;
      this.state.changeType2=1
    }
    if (type == "localSecretDir") {
      changelist.localSecretDir = e.target.value;
      this.state.changeType3=1
    }
    if (type == "dcpCorePoolSize") {
      changelist.dcpCorePoolSize = e.target.value;
      this.state.changeType4=1
    }
    if (type == "secretKeyCorePoolSize") {
      changelist.secretKeyCorePoolSize = e.target.value;
      this.state.changeType5=1
    }
    if (type == "forCount") {
      changelist.forCount = e.target.value;
      this.state.changeType6=1
    }
    if (type == "reDownloadCount") {
      changelist.reDownloadCount = e.target.value;
      this.state.changeType7=1
    }
    if (type == "domainOfBucket") {
      changelist.domainOfBucket = e.target.value;
      this.state.changeType8=1
     }
    // if (type == "ispIp") {
    //   changelist.ispIp = e.target.value;
    //   this.state.changeType9=1
    // }
    // if (type == "mqIp") {
    //   changelist.mqIp = e.target.value;
    //   this.state.changeType10=1
    // }
    if (type == "sleep") {
      changelist.sleep = e.target.value;
      this.state.changeType11=1
    }
    if (type == "spaceWarningTotal") {
      changelist.spaceWarningTotal = e.target.value;
      this.state.changeType15=1
    }
    if (type == "feedbackType") {
      changelist.feedbackType = e;
      this.state.changeType12=1
    }
    if (type == "secretKeyMd5CorePoolSize") {
      changelist.secretKeyMd5CorePoolSize = e.target.value;
      this.state.changeType13=1
    }
    if (type == "dcpMd5CorePoolSize") {
      changelist.dcpMd5CorePoolSize = e.target.value;
      this.state.changeType14=1
    }
    this.setState({changeType});
  }
  beginTimeChange = (time, timeString, key) => {
    const { augmentArr } = this.state;
    augmentArr[key].beginTime = timeString;
    this.setState({
      augmentArr
    });
  };
  endTimeChange(time, timeString, key) {
    const { augmentArr } = this.state;
    augmentArr[key].endTime = timeString;
    this.setState({augmentArr});
  }
  rateChange(e, key) {
    const { augmentArr } = this.state;
    augmentArr[key].networkSpeed = e.target.value;
    this.setState({augmentArr});
  }
  //保存
  save() {
    const { params, augmentArr, strategyBox ,changelist,changeType} = this.state;
    // const flag = augmentArr.every(item => {
    //   return item.beginTime && item.endTime 
    // });
    // console.log(flag)
    // if (
    //   flag == false ||
    //   strategyBox.version == "" ||
    //   strategyBox.localDir == "" || 
    //   strategyBox.localSecretDir == "" ||
    //   strategyBox.dcpCorePoolSize == "" ||
    //   strategyBox.secretKeyCorePoolSize == "" ||
    //   strategyBox.forCount == "" ||
    //   strategyBox.reDownloadCount == "" ||
    //   strategyBox.domainOfBucket == "" ||
    //   strategyBox.ispIp == "" ||
    //   strategyBox.mqIp == "" ||
    //   strategyBox.sleep == "" ||
    //   strategyBox.spaceWarningTotal == "" ||
    //   strategyBox.feedbackType == "" ||
    //   strategyBox.secretKeyMd5CorePoolSize == "" ||
    //   strategyBox.dcpMd5CorePoolSize == "" ||
    //   strategyBox.downloadTime == null || 
    //   strategyBox.version == null ||
    //   strategyBox.localDir == null ||
    //   strategyBox.localSecretDir == null ||
    //   strategyBox.dcpCorePoolSize == null ||
    //   strategyBox.secretKeyCorePoolSize == null ||
    //   strategyBox.forCount == null ||
    //   strategyBox.reDownloadCount == null ||
    //   strategyBox.domainOfBucket == null ||
    //   strategyBox.ispIp == null ||
    //   strategyBox.mqIp == null ||
    //   strategyBox.sleep == null ||
    //   strategyBox.spaceWarningTotal == null ||
    //   strategyBox.feedbackType == null ||
    //   strategyBox.secretKeyMd5CorePoolSize == null ||
    //   strategyBox.dcpMd5CorePoolSize == null
    // ) {
    //   message.error("存在必填项未填写，请核实！");
    //   return;
    // }
    this.setState({ loading: true });
    strategyBox.downloadTime = JSON.stringify(augmentArr);
    params.strategyBox={
      version:changeType==1?changelist.version:strategyBox.version,
      dcpMd5CorePoolSize:this.state.changeType14==1?changelist.dcpMd5CorePoolSize:strategyBox.dcpMd5CorePoolSize,
      secretKeyMd5CorePoolSize:this.state.changeType13==1?changelist.secretKeyMd5CorePoolSize:strategyBox.secretKeyMd5CorePoolSize,
      localDir:this.state.changeType2==1?changelist.localDir:strategyBox.localDir,
      localSecretDir:this.state.changeType3==1?changelist.localSecretDir:strategyBox.localSecretDir,
      dcpCorePoolSize:this.state.changeType4==1?changelist.dcpCorePoolSize:strategyBox.dcpCorePoolSize,
      secretKeyCorePoolSize:this.state.changeType5==1?changelist.secretKeyCorePoolSize:strategyBox.secretKeyCorePoolSize,
      forCount:this.state.changeType6==1?changelist.forCount:strategyBox.forCount,
      reDownloadCount:this.state.changeType7==1?changelist.reDownloadCount:strategyBox.reDownloadCount,
      domainOfBucket:this.state.changeType8==1?changelist.domainOfBucket:strategyBox.domainOfBucket,
      feedbackType:this.state.changeType12==1?changelist.feedbackType:strategyBox.feedbackType,
      // ispIp:this.state.changeType9==1?changelist.ispIp:strategyBox.ispIp,
      // mqIp:this.state.changeType10==1?changelist.mqIp:strategyBox.mqIp,
      sleep:this.state.changeType11==1?changelist.sleep:strategyBox.sleep,
      spaceWarningTotal:this.state.changeType15==1?changelist.spaceWarningTotal:strategyBox.spaceWarningTotal,
      downloadTime:strategyBox.downloadTime
    }
    params.isUpdateStrategy = "1";
    this.setState({ params });
    fetch("/api/device/updateBox", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data)=> {
        if (data.code == 0) {
          message.success("保存成功！");
          if(data.result!=null){
            this.onload(data.result.id)
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({ loading: false,isfalse: true, esfalse: false });
      });
  }
  //增行
  addClick = () => {
    let { augmentArr } = this.state;
    augmentArr.push({ id: augmentArr.length, });
    this.setState({
      augmentArr
    });
  };
  delClick = key => {
    let { augmentArr } = this.state;
    augmentArr.filter((item, index) => {
      if (index === key) {
        augmentArr.splice(index, 1);
      }
    });
    this.setState({
      augmentArr
    });
  };
 //所拥有影片
  showFilms(){
    let params = {serialNumber:this.state.params.serialNumber}
    fetch("/api/device/boxHaveFilmList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data)=> {
        if (data.code == 0) {
            this.state.boxHaveFilmList = data.result;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({showFilms: true});
      });
  }
  showFilmsOk(){
    this.setState({showFilms: false});
  }

  //弹框 确认和取消
  handleOk(type) {
    if (type) {
      this.btnActive(type);
    } else {
      this.save(type);
    }
    this.setState({ visible: false });
  }
  actorCancel() {
    this.setState({ visible: false });
  }
  devClick(type) {
    this.setState({ visible: true, type });
  }
  //点击应用按钮 "proxy": "http://218.241.209.227:9090",
  btnActive(type) {
    let content = "";
    this.setState({ loading: true });
    if (type == "4") {
      content = {
        handle: type,
        list: [{ serialNumber: this.state.params.serialNumber }]
      };
    } else {
      content = { handle: type, serialNumber: this.state.params.serialNumber };
    }
    // console.log(content);
    // return;
    fetch("/api/device/boxControl", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function(data) {
        if (data.code == 0) {
          if (type == "0") {
            message.success("启动应用成功！");
          } else if (type == "1") {
            message.success("停止应用成功！");
          } else if (type == "2") {
            message.success("重启应用成功！");
          } else if (type == "3") {
            message.success("重启设备成功！");
          } else if (type == "4") {
            message.success("升级应用成功！");
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({ loading: false });
      });
  }
  
  //弹框显示隐藏
  btnClick(isbtn) {
    if (isbtn == "block") {
      this.setState({ isbtn });
    } else {
      this.setState({ isbtn });
    }
  }
  //单选框
  inpCheck(type) {
    let { ischange, ischanges, upchange, upchanges } = this.state;
    if (type == 0) {
      if (ischanges == true) {
        this.setState({
          ischange: !ischange,
          upchange: !upchange,
          ischanges: false,
          upchanges: 0,
          downBegin:'',
          downEnd:''
        });
      }
      this.setState({ ischange: !ischange, upchange: !upchange, upchanges: 0 });
    } else if (type == 1) {
      if (ischange == true) {
        this.setState({
          ischange: false,
          upchange: true,
          ischanges: !ischanges,
          upchanges: 1,
          downBegin:'',
          downEnd:''
        });
      } else {
        this.setState({ ischanges: !ischanges, upchanges: 1 });
      }
    }
  }
  //下载确认
  determine=()=>{
    this.setState({downvis:true})
  }
  downCancel() {
    this.setState({ downvis: false });
  }
  //下载确认中的确认
   downOk= () => {
    let dataParams = [];
    let content={}
    this.setState({downvis: false})
    if(this.state.upchanges==0){
        content = {
          boxId: this.state.params.id,
          beginTime: this.state.downBegin,
          endTime: this.state.downEnd
        };
    }else if(this.state.upchanges==1){
      content={boxId: this.state.params.id,searcherType:'1'}
    } 
    // console.log(content)
    fetch("/api/device/queryLog", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(data => {
        if (data.code == 0) {
          if(this.state.upchanges==0){
            dataParams = data.result;
           if(dataParams==null) {
              message.error("日志不存在");
              this.setState({
                ischange: false,
                upchange: true,
                ischanges: false,
                downBegin:'',
                downEnd:'',
                downvis:false,
                isbtn:"none"
              }) 
            }else if (dataParams.length!=null||dataParams.length>0) {
               this.downZip(dataParams);
              this.setState({
              ischange: false,
              upchange: true,
              ischanges: false,
              downBegin:'',
              downEnd:'',
              downvis:false,
              isbtn:"none"
            })
          } 
          }else if(this.state.upchanges==1){
            // this.asyncing()
            this.setState({ischanges:false,isbtn:'none'})
            this.startTimesr()
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      });
  };
  //异步下载
  asyncing=()=>{
    fetch("/api/device/cmsGetDayLog", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({boxId:this.state.params.id})
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data) =>{
        if (data.code == 0) {
        if(data.result==null||data.result.length==0){
          return 
          }else if(data.result.length>0){
            this.state.asynclist=data.result
            this.cleraTimesr()
            this.downZip(this.state.asynclist)
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
  }
  //下载包
  downZip = dataParams => {
    // console.log(dataParams)
    let content = { type: 0,dataList:dataParams};
    fetch("/api/common/downloadZip" ,{
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content),
    })
      .then(response => {
        response.blob().then(blob => {
          let blobUrl = window.URL.createObjectURL(blob);
          let aElement = document.getElementById("downloadDiv"); //获取a标签元素
          let filename = 'boxLog.zip'; //设置文件名称
          aElement.href = blobUrl; //设置a标签路径
          aElement.download = filename;
          aElement.click();
          window.URL.revokeObjectURL(blobUrl);
          message.success("下载中,请稍等");
        });
      })
      .catch(error => {
        console.log("文件下载失败", error);
      });
  };

  //下载日期
  // dateChang(type, date, dateString) {
  //   if (type == "downBegin") {
  //    this.setState({downBegin:dateString})
  //     // this.state.downBegin = dateString;
  //   }
  //   if (type == "downEnd") {
  //     this.setState({downEnd:dateString})
  //     //  this.state.downEnd = dateString
  //   }
  // }
  //周期时间
  cycle=(e)=>{
    if(e==0){
     this.state.downBegin=moment().add(-1,'day').format('YYYY-MM-DD HH:mm:ss')
     this.state.downEnd=moment().locale('zn-cn').format('YYYY-MM-DD HH:mm:ss') 
    }
    else if(e==1){
      this.state.downBegin=moment().add(-3,'day').format('YYYY-MM-DD HH:mm:ss')
      this.state.downEnd=moment().format('YYYY-MM-DD HH:mm:ss')
    }else if(e==2){
      
      this.state.downBegin=moment().add(-7,'day').format('YYYY-MM-DD HH:mm:ss')
      this.state.downEnd=moment().format('YYYY-MM-DD HH:mm:ss') 
    }
    else{
      this.state.downBegin=moment().format('2020-01-01 00:00:00')
      this.state.downEnd=moment().format('YYYY-MM-DD HH:mm:ss')
    }
    this.downOk.bind(this)
  }
//远程控制的发送
ycClick(type){
  if(type=='block'){
    this.setState({ycevent:type})
  }
}
//发送的值
inpVal(type,e){
  this.setState({command:e.target.value})
}
//发送按钮
sendEvent(){
  fetch("/api/device/sendLinuxCommand", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({serialNumber:this.state.params.serialNumber,command:this.state.command})
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then((data) =>{
      if (data.code == 0) {
        this.setState({command:'',sendList:data.result.linuxReasult})
        if(this.state.sendList==null){
         this.startTimesend(data.result.id)
        }else  if(this.state.sendList!=null){  
          this.clearTimesend() 
          // this.state.sendList=data.result.linuxReasult
        }
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: "/" });
        }
      }
    })
}
//异步请求
asyncSend=(sendId)=>{
  fetch("/api/device/queryLinuxResult", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({requestId:sendId})
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then((data) =>{
      if (data.code == 0) {
        this.setState({sendList:data.result.linuxResult}) 
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: "/" });
        }
      }
    })
}
//清空
clearEvent(){
  this.setState({sendList:null}) 
}
//取消
quEvent(){
  this.setState({ycevent:'none'})
  this.setState({sendList:null})
  this.clearTimesend() 
}
filmControl(handle,params){
  fetch("/api/device/boxControl", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({serialNumber:params.serialNumber,handle:handle,filmId:params.filmId})
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then((data) =>{
      if (data.code == 0) {
        message.success("操作成功!")
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: "/" });
        }
      }
    })

}
  render() {
    const props = {
      headers: {
        authorization: "authorization-text"
      }
    };
    let {esfalse, isfalse, augmentArr, params, strategyBox ,changelist,changeType} = this.state;
    const Anchor = props => {
      return (
        <a {...props}>{props.children}</a>
      );
    };
    const datas = this.state.boxHaveFilmList;
    const cols=[{
      title: '序号',
      render: (text, record, index) => `${index + 1}`,
    },
		{
			title: '影片名称',
			dataIndex: 'filmName',
			key: 'filmName'
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <a href="javascript:;" onClick={this.filmControl.bind(this, "2000", record)}>加载DCP</a>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <a href="javascript:;" onClick={this.filmControl.bind(this, "2001", record)}>加载密钥</a>
      )
    },
    {
      title: '操作',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <a href="javascript:;" onClick={this.filmControl.bind(this, "2002", record)}>播放</a>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <a href="javascript:;" onClick={this.filmControl.bind(this, "2003", record)}>暂停</a>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <a href="javascript:;" onClick={this.filmControl.bind(this, "2004", record)}>停止</a>
      )
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (text, record) => (
        <a href="javascript:;" onClick={this.filmControl.bind(this, "2005", record)}>onlyPlay</a>
      )
    }]
    return (
      <div
        id="boxManageDetails"
        key={
          this.state.params != null && this.state.params.id == "-1"
            ? this.state.params.id
            : this.state.params.id
        }
      >
        <Spin spinning={this.state.loading} tip="Loading...">
        <Anchor id="downloadDiv" style={{display: 'none'}}></Anchor>
          <div  style={{ marginLeft: "40px", fontSize: "14px" }}>
            <div>
              <Button onClick={this.devClick.bind(this, "")} disabled={isfalse}>保存</Button>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={this.cencel.bind(this, 'write')}
                disabled={esfalse}
              >
                修改
              </Button>
              <Button
                style={{ marginLeft: "10px"}}
                onClick={this.cencel.bind(this)}
                disabled={isfalse}
              >
                取消
              </Button>
            </div>
            <Row
              style={{
                margin: "10px 5px 5px 0",
                borderBottom: "1px solid #ccc"
              }}
            >
              <span
                style={{
                  width: "2px",
                  height: "15px",
                  background: "red",
                  display: "inline-block",
                  margin: "10px 10px 0 0"
                }}
              ></span>
              <b>监控信息</b>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0px" }}>
              <Col span={15}>
                激活状态：
                <span>
                  {this.state.params.status == "0" ? "未激活" : "已激活"}
                </span>
              </Col>
              <Col span={8}>
               
                运行状态：
                <span>
                  {this.state.params.runningStatus == "0"
                    ? "正常"
                    : this.state.params.runningStatus == "1"
                    ? "预警"
                    :params.runningStatus=='2'? "错误":''}
                </span>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0px" }}>
              <Col span={15}>
               
                工作状态：
                <span>
                  {this.state.params.workStatus == "0" ? "空闲" :params.workStatus == "1"? "工作":''}
                </span>
              </Col>
              <Col span={8}>
                {" "}
                在线状态：
                <span>
                  {this.state.params.isRunning == "0" ? "离线" :params.isRunning == "1"?"在线":""}
                </span>{" "}
              </Col>
            </Row>

            <Row style={{ margin: "10px 5px 5px 0px" }}>
              <Col span={15}>
                {" "}
                剩余存储空间：
                <span>
                  {formatFileSize(this.state.params.surplusSpace)}
                </span>{" "}
              </Col>
              <Col span={8}>
                {" "}
                当前传输速率：
                <span>
                  {this.state.params.speed + "KB/S"}
                </span>{" "}
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0px" }}>
              <Col span={17}>
                {" "}
                <Button
                  style={{
                    width: "100px",
                    marginLeft: "94%",
                    display:
                      this.state.btn_restart_machine == true
                        ? "inline-block"
                        : "none"
                  }}
                  type="danger"
                  onClick={this.devClick.bind(this, "3")}
                >
                  重启设备
                </Button>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0px" }}>
              <Col span={4}>
                {" "}
                <Button
                  type="danger"
                  style={{
                    width: "100px",
                    display:
                      this.state.btn_start_application == true
                        ? "inline-block"
                        : "none"
                  }}
                  onClick={this.devClick.bind(this, "0")}
                >
                  启动应用
                </Button>
              </Col>
              <Col span={4}>
                {" "}
                <Button
                  type="danger"
                  style={{
                    width: "100px",
                    display:
                      this.state.btn_stop_application == true
                        ? "inline-block"
                        : "none"
                  }}
                  onClick={this.devClick.bind(this, "1")}
                >
                  停止应用
                </Button>
              </Col>
              <Col span={4}>
                {" "}
                <Button
                  type="danger"
                  style={{
                    width: "100px",
                    display:
                      this.state.btn_restart_application == true
                        ? "inline-block"
                        : "none"
                  }}
                  onClick={this.devClick.bind(this, "2")}
                >
                  重启应用
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  type="danger"
                  style={{ width: "100px",display: this.state.btn_download_log == true ? "inline-block" : "none" }}
                  onClick={this.btnClick.bind(this, "block")}
                >
                  下载日志
                </Button>
              </Col>
              <Col span={4}>
                <Button
                  type="danger"
                  style={{ width: "100px",display: this.state.btn_remote_contro== true ? "inline-block" : "none" }}
                  onClick={this.ycClick.bind(this,'block')}
                >
                  远程控制
                </Button>
              </Col>
              <Col span={4}> 
                <Button
                  type="danger"
                  style={{
                    width: "100px",
                    display:
                      this.state.btn_upgrade_application == true
                        ? "inline-block"
                        : "none"
                  }}
                  onClick={this.devClick.bind(this, "4")}
                >
                  升级应用
                </Button>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0px" }}>
              <Col span={4}>
              <Button type="danger" style={{width:'100px'}} onClick={this.devClick.bind(this, "3002")}>开机</Button>
              </Col>
              <Col span={4}>
              <Button type="danger" style={{width:'100px'}} onClick={this.devClick.bind(this, "3003")}>关机</Button>
              </Col>
              <Col span={4}>
              <Button type="danger" style={{width:'100px'}} onClick={this.devClick.bind(this, "4000")}>停止播放</Button>
              </Col>
            </Row>
          </div>
          <div style={{ margin: "20px 0 0 30px", fontSize: "14px" }}>
            <Row
              style={{
                margin: "10px 5px 5px 0",
                borderBottom: "1px solid #ccc",
                fontSize: "14"
              }}
            >
              <span
                style={{
                  width: "2px",
                  height: "15px",
                  background: "red",
                  display: "inline-block",
                  margin: "10px 10px 0 0"
                }}
              ></span>
              <b>商务信息</b>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={12}>
                {" "}
                合同编号：<span>{this.state.params.contractCode}</span>{" "}
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={16}>
                所属院线：<span>{this.state.params.theaterChainName}</span>{" "}
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={16}>
                {" "}
                <span>所属影院：</span>
                <span>{this.state.params.cinemaName}</span>{" "}
              </Col>
            </Row>
          </div>
          <div style={{ margin: "20px 0 0 30px", fontSize: "14px" }}>
            <Row
              style={{
                margin: "10px 5px 5px 0",
                borderBottom: "1px solid #ccc"
              }}
            >
              <Col>
                <span
                  style={{
                    width: "2px",
                    height: "15px",
                    background: "red",
                    display: "inline-block",
                    margin: "10px 10px 0 0"
                  }}
                ></span>{" "}
                <b>设备信息</b>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={15}>
                序列号：<span>{this.state.params.serialNumber}</span>
              </Col>
              <Col span={8}>
                名称：<span>{this.state.params.deviceName}</span>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={15}>
                型号：<span>{this.state.params.modelNum}</span>
              </Col>
              <Col span={8}>
                CPU：<span>{this.state.params.cpuMsg}</span>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={15}>
                存储：<span>{formatFileSize(this.state.params.totalSize)}</span>
              </Col>
              <Col span={8}>
                系统：<span>{this.state.params.systemEdition}</span>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={15}>
              cpu使用率：<span>{this.state.params.cpuUsage}</span>
              </Col>
              <Col span={8}>
              内存使用率：<span>{this.state.params.memoryUsage}</span>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={15}>
              操作系统：<span>{this.state.params.sysName}</span>
              </Col>
              <Col span={8}>
              操作系统版本：<span>{this.state.params.sysVersion}</span>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={15}>
              操作系统位数：<span>{this.state.params.sysBit}</span>
              </Col>
              <Col span={8}>
                <a onClick={this.showFilms.bind(this)}>所拥有影片</a>
              </Col>
            </Row>
          </div>
          <div style={{ margin: "20px 0 0 30px", fontSize: "14px" }}>
            <Row
              style={{
                margin: "10px 5px 5px 0",
                borderBottom: "1px solid #ccc"
              }}
            >
              <Col>
                <span
                  style={{
                    width: "2px",
                    height: "15px",
                    background: "red",
                    display: "inline-block",
                    margin: "10px 10px 0 0"
                  }}
                ></span>
                <b>设备策略</b>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={9}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  版本号：
                 
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  placeholder="请输入版本号"
                  value={changeType==1?changelist.version:strategyBox.version}
                  onChange={this.handleChange.bind(this, "version")}
                ></Input>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={14}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  DCP计算MD5线程数：
                
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType14==1?changelist.dcpMd5CorePoolSize:strategyBox.dcpMd5CorePoolSize}
                  placeholder="请输入DCP计算MD5线程数"
                  onChange={this.handleChange.bind(this, "dcpMd5CorePoolSize")}
                ></Input>
              </Col>
              <Col span={9}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  秘钥算MD5线程数：
                 
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType13==1?changelist.secretKeyMd5CorePoolSize:strategyBox.secretKeyMd5CorePoolSize}
                  placeholder="请输入秘钥计算MD5线程数"
                  onChange={this.handleChange.bind(
                    this,
                    "secretKeyMd5CorePoolSize"
                  )}
                ></Input>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={14}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  DCP下载路径：
                 
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType2==1?changelist.localDir:strategyBox.localDir}
                  placeholder="请输入"
                  onChange={this.handleChange.bind(this, "localDir")}
                ></Input>
              </Col>
              <Col span={9}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  秘钥下载路径：
                
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType3==1?changelist.localSecretDir:strategyBox.localSecretDir}
                  placeholder="请输入"
                  onChange={this.handleChange.bind(this, "localSecretDir")}
                ></Input>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={14}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  DCP下载任务线程数：
                
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType4==1?changelist.dcpCorePoolSize:strategyBox.dcpCorePoolSize}
                  placeholder="请输入"
                  onChange={this.handleChange.bind(this, "dcpCorePoolSize")}
                ></Input>
              </Col>
              <Col span={9}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  密钥下载任务线程数：
                 
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType5==1?changelist.secretKeyCorePoolSize:strategyBox.secretKeyCorePoolSize}
                  placeholder="请输入"
                  onChange={this.handleChange.bind(
                    this,
                    "secretKeyCorePoolSize"
                  )}
                ></Input>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={14}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  循环下载次数：
               
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType6==1?changelist.forCount:strategyBox.forCount}
                  placeholder="请输入"
                  onChange={this.handleChange.bind(this, "forCount")}
                ></Input>
              </Col>
              <Col span={9}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  重新下载次数：
                  
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType7==1?changelist.reDownloadCount:strategyBox.reDownloadCount}
                  placeholder="请输入"
                  onChange={this.handleChange.bind(this, "reDownloadCount")}
                ></Input>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={14}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  domainOfBucket：
               
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType8==1?changelist.domainOfBucket:strategyBox.domainOfBucket}
                  placeholder="请输入"
                  onChange={this.handleChange.bind(this, "domainOfBucket")}
                ></Input>
              </Col>
              <Col span={9}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  反馈类型：
                
                </span>
                <Select
                  style={{ width: 240 }}
                  disabled={isfalse}
                  placeholder="请输入反馈类型"
                  onChange={this.handleChange.bind(this, "feedbackType")}
                  value={this.state.changeType12==1?changelist.feedbackType:strategyBox.feedbackType}
                >
                  <Option value="0">即时反馈</Option>
                  <Option value="1">按进度比例反馈</Option>
                </Select>
              </Col>
            </Row>
            {/* <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={14}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  ISP服务端地址：
                  <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType9==1?changelist.ispIp:strategyBox.ispIp}
                  placeholder="请输入"
                  onChange={this.handleChange.bind(this, "ispIp")}
                ></Input>
              </Col>
              <Col span={9}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  MQ服务端地址：
                  <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType10==1?changelist.mqIp:strategyBox.mqIp}
                  placeholder="请输入"
                  onChange={this.handleChange.bind(this, "mqIp")}
                ></Input>
              </Col>
            </Row> */}
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={14}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  睡眠时间：
                 
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType11==1?changelist.sleep:strategyBox.sleep}
                  placeholder="请输入"
                  suffix="ms"
                  onChange={this.handleChange.bind(this, "sleep")}
                ></Input>
              </Col>
              <Col span={9}>
                <span style={{ display: "inline-block", width: "170px" }}>
                  剩余空间预警：
                
                </span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.changeType15==1?changelist.spaceWarningTotal:strategyBox.spaceWarningTotal}
                  placeholder="请输入"
                  suffix="GB"
                  onChange={this.handleChange.bind(this, "spaceWarningTotal")}
                ></Input>
              </Col>
            </Row>
            <Row style={{ margin: "10px 5px 5px 0" }}>
              <Col span={3}>
                <span
                  style={{
                    display: "inline-block",
                    width: 160,
                    marginTop: "16px"
                  }}
                >
                  下载任务执行时间：
                  
                </span>
              </Col>
              <Col span={20} style={{ marginLeft: "-6px" }}>
                {augmentArr &&
                  augmentArr.map((item, key) => (
                    <div
                      key={key}
                      style={{ marginTop: "10px", marginLeft: "26px" }}
                    >
                      <Row>
                        <Col span={7}>
                          <TimePicker
                            disabled={isfalse}
                            style={{ width: 240 }}
                            onChange={(time, timeString) =>
                              this.beginTimeChange(time, timeString, key)
                            }
                            placeholder="开始时间"
                            value={moment(
                              item.beginTime && item.beginTime
                                ? item.beginTime
                                : "00:00:00",
                              "HH:mm:ss"
                            )}
                          />
                        </Col>
                        <Col span={1}>——</Col>
                        <Col span={6}>
                          <TimePicker
                            disabled={isfalse}
                            style={{ width: 240 }}
                            onChange={(time, timeString) =>
                              this.endTimeChange(time, timeString, key)
                            }
                            value={moment(
                              item.endTime && item.endTime
                                ? item.endTime
                                :"23:59:59",
                              "HH:mm:ss"
                            )}
                          />
                        </Col>
                        <Col span={6}>
                          <span
                            style={{
                              display: "inline-block",
                              marginLeft: "16px"
                            }}
                          >
                            限速:
                          </span>
                          <Input
                            style={{ width: 150 }}
                            disabled={isfalse}
                            value={
                                item.networkSpeed
                            }
                            suffix="KB/s"
                            onChange={e => this.rateChange(e, key)}
                            // placeholder="请输入"
                          ></Input>
                        </Col>
                        {isfalse ? null : (
                          <Col span={2}>
                            <div className="count" style={{ display: "flex" }}>
                              {key > 0 ? (
                                <div
                                  className="jian"
                                  onClick={() => this.delClick(key)}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    border: "1px solid #ccc",
                                    textAlign: "center",
                                    lineHeight: "28px",
                                    borderRadius: "50%",
                                    marginLeft: "15px"
                                  }}
                                >
                                  -
                                </div>
                              ) : (
                                <div
                                  className="jia"
                                  onClick={() => this.addClick()}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    border: "1px solid #ccc",
                                    textAlign: "center",
                                    lineHeight: "28px",
                                    marginRight: "5px",
                                    marginLeft: "15px",
                                    borderRadius: "50%"
                                  }}
                                >
                                  +
                                </div>
                              )}
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  ))}
              </Col>
            </Row>
            <div></div>
          </div>
        </Spin>
        <Modal
          title="提示"
          okText="确认"
          cancelText="取消"
          width="300px"
          style={{ marginTop: "20px" }}
          visible={this.state.visible}
          onOk={this.handleOk.bind(this, this.state.type)}
          onCancel={this.actorCancel.bind(this)}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "5px",
              lineHeight: "5px"
            }}
          >
            <b>
              {this.state.type == "0"
                ? "是否启动应用?"
                : this.state.type == "1"
                ? "是否停止应用?"
                : this.state.type == "2"
                ? "是否重启应用?"
                : this.state.type == "3"
                ? "是否重启机器?"
                : this.state.type == "4"
                ? "是否升级应用?"
                : this.state.type == "3002"
                ? "是否开机?"
                : this.state.type == "3003"
                ? "是否关机?"
                : this.state.type == "4000"
                ? "是否停止播放?"
                : "是否保存?"}
            </b>
          </div>
        </Modal>
        <div style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,.3)',position:'fixed',top:0,left:0,display:this.state.display,zIndex:'999',display: this.state.ycevent}}>
        <div style={{width:'550px',height:'500px',position: "fixed",
            top: "20%", background:"#fff",
            left: "30%",display:this.state.ycevent}}>
         <div style={{width:'550px',height:'450px',border:'1px solid #ccc',overflowY:'scroll', background:"#fff",display:this.state.ycevent}}>
         <div style={{ background:"#fff",display:this.state.ycevent}}>
          {this.state.sendList}
         </div>
         </div>
         <Row style={{marginTop:'5px'}}>
           <Col span={10}><Input style={{width:'98%'}} onChange={this.inpVal.bind(this,'command')} value={this.state.command}></Input></Col>
           <Col span={3}><Button onClick={this.sendEvent.bind(this)}>发送</Button></Col>
           <Col span={3}><Button onClick={this.clearEvent.bind(this)}>清空</Button></Col>
           <Col span={3}><Button onClick={this.quEvent.bind(this)}>取消</Button></Col>
         </Row>
        </div>
        </div>
        <div style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,.3)',position:'fixed',top:0,left:0,display:this.state.display,zIndex:'999',display: this.state.isbtn}}>
        <div
          style={{
            width: "40%",
            height: "30%",
            position: "fixed",
            top: "35%",
            left: "30%",
            background: "#fff",
            border: "1px solid #ccc",
            display: this.state.isbtn
          }}
        >
          <Row style={{ margin: "20px 0 20px 10px" }}>
            <Input
              type="radio"
              style={{ marginRight: "20px", width: "12px", height: "12px" }}
              checked={this.state.ischange}
              onClick={this.inpCheck.bind(this, "0")}
            ></Input>
            <span>日志周期：</span>
            <Select
              style={{ width: "200px", marginLeft: "30px"}}
              disabled={this.state.upchange}
              onChange={this.cycle}
            >
              <Option value="0">1天</Option>
              <Option value="1">3天</Option>
              <Option value="2">7天</Option>
              <Option value="3">所有</Option>
            </Select>
          </Row>
          <Row style={{ margin: "0px 0 10px 10px" }}>
            <input
              type="radio"
              style={{ marginRight: "20px" }}
              checked={this.state.ischanges}
              onClick={this.inpCheck.bind(this, "1")}
            ></input>
            <span>当天时间</span>
          </Row>
        {/*<Row style={{ margin: "0px 0 0px 20px" }}>
           <Col span={9} style={{ marginLeft: "25px" }}>
              开始时间
            </Col>
            <Col span={9} style={{ marginLeft: "20px" }}>
              结束时间
            </Col>
          </Row>
          <Row style={{ margin: "0px 0 0px 20px" }}>
            <Col span={9} style={{ marginLeft: "25px" }}>
              <DatePicker
                style={{width:200}}
                format="YYYY-MM-DD HH:mm:ss"
                disabled={this.state.upchanges}
                onChange={this.dateChang.bind(this, "downBegin")}
                defaultValue={this.state.downBegin}
                showTime
              ></DatePicker>
            <br />
            </Col>
            <Col span={9} style={{ marginLeft: "20px" }}>
              <DatePicker
                style={{width:200}}
                format="YYYY-MM-DD HH:mm:ss"
                disabled={this.state.upchanges}
                onChange={this.dateChang.bind(this, "downEnd")}
                defaultValue={this.state.downEnd}
                showTime
              ></DatePicker>
            </Col>
          </Row> */}
          <Row style={{ marginTop: "20px" }}>
            <Button style={{ marginLeft: "50%" }} onClick={this.determine}>
              确定
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              onClick={this.btnClick.bind(this, "none")}
            >
              取消
            </Button>
          </Row>
        </div>
        </div>
        <Modal
          title="提示"
          okText="确认"
          cancelText="取消"
          width="300px"
          style={{ marginTop: "20px" }}
          visible={this.state.downvis}
          onOk={this.downOk.bind(this)}
          onCancel={this.downCancel.bind(this)}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
              height: "5px",
              lineHeight: "5px"
            }}
          >
            <b>
             是否下载?
            </b>
          </div>
        </Modal>

        <Modal title="列表" okText="确认" cancelText="取消" width="600px"  style={{marginTop:"20px"}} visible={this.state.showFilms}  onOk={this.showFilmsOk.bind(this)} onCancel={this.showFilmsOk.bind(this)}>
              <div style={{width:'100%',textAlign:"center"}}>
                  <Table size={"small"} columns={cols} dataSource={datas}  pagination/>
              </div>
        </Modal>
        {/* </div> */}
        
      </div> //最后的div
    );
  }
}
export default BoxManageDetail;

import React from 'react';
import { Tabs } from 'antd';
import Go from './go.js';

const { TabPane } = Tabs;
class TabList extends React.Component{
    constructor(props) {
        super(props);
        this.newTabIndex = 0;
        const panes = [
            {
                menuName: '首页',
                templateUrl: <Go goRoute = {props.goRoute}/>,
                id: '-1',
                closable: false,
              },
        ];
        this.state = {
          activeKey: panes[0].id,
          panes,
        };
      }
      componentWillReceiveProps(nextProps) {//监听左边菜单点击事件
        if(nextProps.routeName.id == undefined){
             return;
        }
        var flag = true;
        var array = this.state.panes
        for(var i = 0 ;i < array.length;i++){
           if(array[i].id == nextProps.routeName.id){//说明已有这个标签
              flag = false;
           }
        }
       if(flag){//没有的话展开此标签
        this.state.panes.push(nextProps.routeName)
       }
        this.state.activeKey = nextProps.routeName.id
        this.setState({panes: this.state.panes})
      }

      onChange = activeKey => {
        this.setState({ activeKey });
      };
    
      onEdit = (targetKey, action) => {
        this[action](targetKey);
      };
    
      add = () => {
        const panes = this.state.panes;
        const activeKey = `newTab${this.newTabIndex++}`;
        panes.push({ title: 'New Tab', content: 'Content of new Tab', key: activeKey });
        this.setState({ panes, activeKey });
      };
    
      remove = targetKey => {
        let activeKey = this.state.activeKey;
        let lastIndex;
        this.state.panes.forEach((pane, i) => {
          if (pane.id === targetKey) {
            lastIndex = i - 1;
          }
        });
        const panes = this.state.panes.filter(pane => pane.id !== targetKey);
        if (panes.length && activeKey === targetKey) {
          if (lastIndex >= 0) {
            activeKey = panes[lastIndex].id;
          } else {
            activeKey = panes[0].id;
          }
        }
        this.setState({ panes, activeKey });
      };


      render() {
        return (
          // <div className="containerBox">
          <div className="container-content">
            <Tabs onChange={this.onChange} activeKey={this.state.activeKey} type="editable-card" 
                onEdit={this.onEdit} hideAdd={true}>
              {this.state.panes.map(pane => (
                <TabPane tab={pane.menuName} key={pane.id} closable={pane.closable}>
                  {pane.templateUrl}
                </TabPane>
              ))}
            </Tabs>
            </div>
            // </div>
        );
      }
}
export default TabList;
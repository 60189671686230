import React from 'react';
import { Upload, Icon, Input,Select,Button,Spin,message,Modal} from 'antd';
import history from '../history';

const { confirm } = Modal;
class CertificateDetails extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:{},
        loading:false,
        previewVisible: false,
        previewImage: '',
        fileList: [],
        uploading: false,
        statusList:[],
      }
     
    }
    componentWillMount() {    
       this.showBtn(this.props.buttonList)//控制按钮显示
    }
    //控制按钮显示
    showBtn(array){
         for(var i= 0; i<array.length;i++){
              if(array[i].buttonCode == "btn_upload"){
                this.state.btn_upload = true
              }
         }
    }


    //关于上传的js
    handleUpload() {
        const { fileList } = this.state;
        for(var i=0;i<fileList.length;i++){
            var name = fileList[i].name;
            name = name.substring(name.lastIndexOf(".") + 1);
            if(name != "pem"){
               message.error("文件格式有误");
               return;
            }
            if(fileList[i].flag != undefined){
                fileList[i].flag = false;
            }
        }
        // fileList.forEach(file => {
          const formData = new FormData();
          formData.append('file', fileList[0]);
          var index = 0;
          this.certificateIsExist(formData,fileList[0],index);//先查询文件是否已存在
        // });
      };
      //查询文件是否已存在
       certificateIsExist(formData,file,index){
           if(index == null){
                  return;
           }
        var flag;
        
        fetch('/api/certificate/certificateIsExist',{
            method: 'post',
            credentials: 'include',
            body: formData
        }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                flag = data.result;
            }else  {
                message.error(data.message);
                    if(data.code == 2003){
                        history.push({pathname:'/'});
                }
            }
        }).then( (e) => {
            if(!flag){//不存在的话直接上传
                this.upload(formData,index)
            }else{
                this.cover(formData,file,index);
            };
            // this.setState({ loading: false });
        });
    }

         //上传文件
         upload(formData,index){
            this.setState({
                uploading: true,
            });
            var result = true;
            fetch('/api/certificate/uploadCertificate',{
                method: 'post',
                credentials: 'include',
                body: formData
            }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                return res.json();
            }).then(function (data) {
                if (data.code == 0) {
                    //    message.success("第"+(index+1)+"文件上传成功")
                }else  {
                    result = false
                    message.error("第"+(index+1)+"文件上传失败");
                    // if(data.code == 2003){
                    //     history.push({pathname:'/'});
                    // }
                }
            }).then( (e) => {
                if(result){
                    this.state.fileList[index].status="上传成功";
                }else{
                    this.state.fileList[index].status="上传失败";
                }
                this.state.fileList[index].flag=true
                this.state.statusList = this.state.fileList
                this.getIndex(this.state.fileList)//获取脚标
                if(index == this.state.fileList.length-1){
                    // this.state.fileList = [];
                    this.setState({
                        uploading: false,
                    });
                }
            });
        }
        //是否覆盖
        cover(formData,file,index){
            confirm({
                title: file.name+'已存在，是否覆盖',
                content: '',
                onOk:()=> {
                    this.upload(formData,index);
                },
                onCancel:()=> {
                    this.state.fileList[index].status="取消覆盖";
                    this.state.statusList = this.state.fileList
                    this.state.fileList[index].flag=true
                    this.getIndex(this.state.fileList)//获取脚标
                    this.setState({
                        uploading: false,
                    });
                },
              });
        }
        getIndex(fileList){
            var index = null;
           for(var i=0;i<fileList.length;i++){
               if(!fileList[i].flag){
                  index = i;
                  break;
               }
           }
           const formData = new FormData();
           formData.append('file', fileList[index]);
           this.certificateIsExist(formData,fileList[index],index);//先查询文件是否已存在
        }
        empty(){
            this.state.statusList = [];
            this.setState({fileList: []})
        }

    render(){
        const { uploading, fileList } = this.state;
    const props = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          state.statusList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };
    return (
        <div>
      <div style={{width:"30%",display:"inline-block",marginLeft:'3%'}}>
        <Upload accept={".pem"} {...props} multiple={true}>
          <Button>
            <Icon type="upload" /> 选择文件
          </Button>
        </Upload>
        <Button type="primary" onClick={this.handleUpload.bind(this)} disabled={fileList.length === 0} loading={uploading} style={{ marginTop: 16,display:this.state.btn_upload == true?"inline-block":"none" }}>
          {uploading ? '上传中...' : '上传'}
        </Button>

        <Button type="primary" onClick={this.empty.bind(this)} disabled={fileList.length === 0} style={{ marginTop: 16,marginLeft:50 }}>
          清空
        </Button>
      </div>
      <div style={{width:"30%",display:"inline-block",position:"absolute",marginTop:30}}>
           {this.state.statusList.map((item)=>{
                                     return(
                                        <div style={{height:20.67,marginTop:9.5,color:item.status=="success"?"blue":"red"}}>{item.status}</div>
                                    )
                                })
            }
      </div>
      </div>
    );
  }
}
export default CertificateDetails;
import React from 'react';
import {Tabs} from 'antd';
import FilmBagRecordList from './FilmBagRecordList.js';

const { TabPane } = Tabs;
class FilmBagRecord extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        isfalse:null,
      }
    }
    render(){
      const {isfalse}=this.state
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey}>
            <TabPane tab="分配记录列表" key="1">
              <FilmBagRecordList  buttonList={this.props.buttonList}/>
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default FilmBagRecord;
import React from 'react';
import { Menu, Icon,message } from 'antd';
import history from '../history.js'


const { SubMenu }  = Menu;
class MenuList extends React.Component{
  
    constructor(props) {
      super(props);  
    }
    
  state = {
    params:[],
    // openKeys: ['sub1'], 默认打开第一个
    openKeys: [],
    rootSubmenuKeys:[]
  };
  componentWillMount() {
    let content = {};
    let array = [];
    fetch('/api/system/searchMenu',{
      method: 'post',
      credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
               array=data.result;    
            }else  {
              message.error(data.message);
                if(data.code == 2003){
                  history.push({pathname:'/'});
              }
            }
        }).then( (e) => {
          for(var i=0;i<array.length;i++){
            this.state.rootSubmenuKeys.push(array[i].id);
          }
          this.setState({params:array})
       });
  }

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };
  //菜单
  // renderMenu=(data)=>{
  //   console.log(data)
  //  return data.map((item)=>{
  //    if(item.childMenu){
  //      return (
  //        <SubMenu title={ <span>
  //         <span>{item.menuName}</span>
  //           </span>} key={item.id}>
  //         {/* {this.renderMenu(item.children)} */}
          
  //        </SubMenu>
  //      )
  //    }
  //   return <Menu.Item onClick={this.props.routeState.bind(this,item)} key={item.id}>{item.menuName}</Menu.Item>
  //  })
  // }
  render() {
    return (
      // <div style = {{marginTop:"100px",height:700,maxHeight:700,display:"inline-block",borderRight:"solid 1px #e6e6e6",overflowX:"hidden",overflowY:"auto"}}>
     <div className="menu_Box" style = {{marginTop:"100px",display:"inline-block",borderRight:"solid 1px #e6e6e6"}}>
     
      {this.state.params.map((item,index)=>{
      return (
        <Menu mode="inline" openKeys={this.state.openKeys} onOpenChange={this.onOpenChange} style={{ border:"none" }}>
          <SubMenu key={item.id}
            title={
              <span>
            <span>{item.menuName}</span>
              </span>
            }>
            {item.childMenu.map((ite,index)=>{ 
              return (
                ite.childMenu[0].menuLevel == "3"?
                <SubMenu  key={ite.id}
                  title={
                    <span>
                  <span>{ite.menuName}</span>
                    </span>
                  }>
                {ite.childMenu.map((items,index)=>{
                  return (
                  <Menu.Item key={items.id} onClick={this.props.routeState.bind(this,items)}>{items.menuName}</Menu.Item>
                  )
                },this)}
                </SubMenu>:<Menu.Item key={ite.id} onClick={this.props.routeState.bind(this,ite)}>{ite.menuName}</Menu.Item>
              )
            },this)}
          </SubMenu>
        </Menu> 
      )
    },this)}
     
    </div>
    );
  }
}
export default MenuList;
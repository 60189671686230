import React from 'react';
import { Tabs, message } from 'antd';
import RoleList from './roleList.js';
import RoleDetails from './roleDetails.js';

const { TabPane } = Tabs;
class RoleManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: '1',
      isfalse: null,
      params: null
    };
  }

  // componentWillMount() {
  //   this.getUserList(); //获取用户列表
  // }
 
  callback(activeKey, type, params) {
    if (type == 'details') {
      this.state.isfalse = true;
      this.state.params = params;
      // this.getUserList();
    }
    if (type == 'add') {
      this.state.isfalse = false;
      this.state.params = null;
    }
    this.setState({ activeKey: activeKey });
  }
  render() {
    // userList = {this.state.userList}
    return (
      <Tabs
        type="card"
        activeKey={this.state.activeKey}
        onChange={this.callback.bind(this)}>
        <TabPane tab="角色列表" key="1">
          <RoleList
            buttonList={this.props.buttonList}
            callback={this.callback.bind(this)}
          />
        </TabPane>
        <TabPane tab="角色详情" key="2">
          <RoleDetails
            userList={this.state.userList}
            isfalse={this.state.isfalse}
            buttonList={this.props.buttonList}
            params={this.state.params}
            callback={this.callback.bind(this)}
          />
        </TabPane>
      </Tabs>
    );
  }
}
export default RoleManage;

import React from "react";
import { Table, Spin, message, Select, Modal, DatePicker, Button } from "antd";
import history from "../history";

const { Option } = Select;
class ToBeAuditedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      cinemaList: [],
      startValue: null,//日期定义
      endValue: null,
      endOpen: false,
      visible: false,
      list: []
    };
  }
  //获取列表数据的接口
  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if (ty == "1") {
      this.state.start = 0
    } else {
      this.state.start = this.state.page
    }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      // createTime: this.statecreateTime,
      approvalStatus: '0',
      payStatus: '0',
      payMethod: '3',
      beginTime: this.state.beginTime,
      endTime: this.state.endTime,
      orderNum: this.state.orderNum,
      cinemaName: this.state.cinemaName
    };
    fetch("/api/keyOrder/queryKeyOrderList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.state.selectedRow = [];
        this.setState({ loading: false });
      });
  }
  componentWillMount() {
    this.onload();
    this.showBtn(this.props.buttonList); //控制按钮显示
  }

  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true;
      }
      if (array[i].buttonCode == "btn_audit") {
        this.state.btn_audit = true;
      }
    }
  }

  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }

  //翻页
  pageChange(page, pageSize) {
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
  //选择日期
  OnChange(type, date, dateString) {
    if (type == "createTime") {
      this.state.createTime = dateString
      console.log(this.state.createTime)
    }

  }
  //input框的值
  valueChange(type, e) {
    if (type == "orderNum") {
      this.state.orderNum = e.target.value
    }
    if (type == "cinemaName") {
      this.state.cinemaName = e.target.value
    }
  }

  //关于日期
  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };
  onStartChange = (value, vs) => {
    this.state.beginTime = vs
    this.onChange('startValue', value);
  };

  onEndChange = (value, vs) => {
    this.state.endTime = vs
    this.onChange('endValue', value);
  };
  //弹框按钮的确定 取消
  handleOk() {
    this.payCel()
    this.setState({ visible: false })
  }
  actorCancel() {
    this.setState({ visible: false })
  }
  //点击按钮
  eventClick() {
    if (this.state.selectedRow.length == 0) {
      message.error("尚未选中数据！");
      return;
    }
    this.setState({ visible: true }) //弹框
    // this.btnActive(type)
  }

  payCel() {
    for (var i = 0; i < this.state.selectedRow.length; i++) {
      this.state.list[i] = { id: this.state.selectedRow[i].id, approvalStatus: "1" }
    }

    fetch("/api/keyOrder/toApproval", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state.list)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data) => {
        if (data.code == 0) {
          message.success('审核成功')
          this.onload()
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
  }


  render() {
    const { startValue, endValue, endOpen } = this.state;
    const columns = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "订单号",
        dataIndex: "orderNum",
        key: "orderNum",
        render: (text, record) => text
      },
      {
        title: "价格(元)",
        dataIndex: "price",
        key: "price",
        render: (text, record) => text

      },
      {
        title: "影院名称",
        dataIndex: "cinemaName",
        key: "cinemaName",
        render: (text, record) => text
      },
      {
        title: "下单时间",
        dataIndex: "createTime",
        key: "createTime",
      },
      {
        title: "影片名称",
        dataIndex: "filmName",
        key: "filmName",
        render: (text, record, index) => <div style={{ display: 'flex', flexDirection: 'column' }}>
          {
            record.filmList.map((item, index) => {
              return <span key={index}>{item.filmName}</span>
            })
          }
        </div>
      }
    ];
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this) //获取选中值
    };
    return (
      <div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div className="page-content-info">
            <div className="toolbar-group">
              <div className="toolbar-left">
                <div className="form-main">
                  <input className="form-control" onChange={this.valueChange.bind(this, "orderNum")} placeholder="请输入订单号" />
                </div>
                <div className="form-main">
                  <input className="form-control" onChange={this.valueChange.bind(this, "cinemaName")} placeholder="请输入影院名称" />
                </div>
                <div className="form-main">
                  下单时间：
                  <DatePicker
                    disabledDate={this.disabledStartDate}
                    format="YYYY-MM-DD "
                    value={startValue}
                    placeholder="请选择开始时间"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}
                  />
                  -
                    <DatePicker
                    disabledDate={this.disabledEndDate}
                    format="YYYY-MM-DD"
                    value={endValue}
                    placeholder="请选择结束时间"
                    onChange={this.onEndChange}
                    onOpenChange={this.handleEndOpenChange}
                  />
                </div>
                  <button
                    style={{
                      display:
                        this.state.btn_search == true ? "inline-block" : "none"
                    }}
                    onClick={this.onload.bind(this, '1')}
                    className="btn btn-default btn-search"
                    type="button"
                  >
                    检索
                </button>
                <Button style={{ display: this.state.btn_audit == true ? "inline-block" : "none", height: '36px', marginLeft: '10px' }} onClick={this.eventClick.bind(this)}>审核通过</Button>
              </div>

              <div className="toolbar-right" style={{ width: "100%" }}>
                <Table
                  size={"small"}
                  rowKey={record => record.id}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
        </Spin>
        <Modal title="提示" width="300px" cancelText="取消" okText="确认" style={{ marginTop: "20px" }} visible={this.state.visible} onOk={this.handleOk.bind(this)} onCancel={this.actorCancel.bind(this)}>
          <div style={{ width: '100%', textAlign: "center", height: "5px", lineHeight: "5px" }}>
            <b>是否审核通过?</b>
          </div>
        </Modal>
      </div>
    );
  }
}
export default ToBeAuditedList;

import React from 'react';
import { Table, Spin,Button,message,Tabs,Modal,Upload,Icon} from 'antd';
import history from '../history';

const { confirm } = Modal;
class CinemaList extends React.Component{
  
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
			loading:false
		}
	}
		
		onload(ty){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			if(ty=="1"){
				this.state.start=0
				}else{
				this.state.start=this.state.page
				}
			let content = {start:this.state.start,limit:this.state.pageSize,
				name:this.state.name,code:this.state.code
			};
			fetch('/api/cinema/list',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
								array=data.result; 
                                total=data.total;
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                    this.state.params=array;
                    this.state.total=total;
					this.state.selectedRowKeys = [];
					this.state.selectedRow = [];
                    this.setState({ loading: false });
                });
		 }
		 componentWillMount() { 
			this.onload()
			this.showBtn(this.props.buttonList)//控制按钮显示
		}
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_search"){
						this.state.btn_search = true
				  }
				  if(array[i].buttonCode == "btn_add"){
					this.state.btn_add = true
				  }
				  if(array[i].buttonCode == "btn_delete"){
					this.state.btn_delete = true
				  }
				 
			 }
		}
		valueChange(type,e) {
			if(type == "name"){
					this.state.name=e.target.value
			}
			if(type == "code"){
				this.state.code=e.target.value
			}
		}
		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
		
		pageChange(page,pageSize){//翻页
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
	 }
	  showConfirm = () => {
		if(this.state.selectedRow.length == 0){
			message.error("尚未选中数据！");
			return;
		}
		// console.log(this.state.selectedRow.length,this.state.selectedRow.length)
		confirm({
		  title: '确定删除?',
		  content: '',
		  okText: '确定',
		  cancelText:"取消",
		  onOk:()=> {
			this.setState({ loading: true });
			let array=this.state.selectedRow.map((item,index)=>{return {id:this.state.selectedRow[index].id}})
			fetch('/api/cinema/deleteCinema',{
				method: 'post',
				credentials: 'include',
                headers: {
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify(array),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                    return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        message.success("删除成功")
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
					this.state.selectedRow.length=0;
                    this.onload();
            });
		  },
		  onCancel() {
		  },
		});
	  }
	  //导入
	uploadChange(info){
    this.setState({ loading: true})
    if (info.file.status === 'done') {
	  message.success('导入成功');
	  this.onload();
    }
    if (info.file.status === 'error') {
      message.success('导入失败');
    }
    this.setState({ loading: false});
	}
    render(){
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title: '影院名称',
					dataIndex: 'name',
					key: 'name',
					render: (text,record) => <a href="javascript:;" onClick = {this.props.callback.bind(this,"2","details",record)}>{text}</a>,
				},
				{
					title: '影院编码',
					dataIndex: 'code',
					key: 'code',
				},
				{
					title: '所属院线',
					dataIndex: 'theaterName',
					key: 'theaterName',        
				},
				{
					title: '创建时间',
					dataIndex: 'createTime',
					key: 'createTime',
				}
				
			];
			const dataSource = this.state.params;
			const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
								<div className="page-content-info">
									<div className="toolbar-group">
										<div className="toolbar-left">
											<div className="form-main">
												<input className="form-control" onChange={this.valueChange.bind(this,"name")} placeholder="请输入影院名称"/>
											</div>
											<div className="form-main">
												<input className="form-control" onChange={this.valueChange.bind(this,"code")} placeholder="请输入影院编码"/>
											</div>
											{/* <div class="form-main">
												<select  onChange={this.valueChange.bind(this,"userSource")} class="bs-select form-control fixed">
													<option value="">请选择用户归属</option>
													<option value="0">运营人员</option>
													<option value="1">影院人员</option>
												</select>
											</div> */}
											<button style={{display:this.state.btn_search == true?"inline-block":"none"}} onClick={this.onload.bind(this,'1')} className="btn btn-default btn-search" type="button">检索</button>
										</div>
										<div>
											 <Button style={{display:this.state.btn_add == true?"inline-block":"none",marginLeft:10}} type="primary" onClick = {this.props.callback.bind(this,"2","add",null)}>新增</Button>
										     <Button style={{display:this.state.btn_delete == true?"inline-block":"none",marginLeft:10}} type="danger" onClick = {this.showConfirm.bind(this,this)}>删除</Button>
											 <Upload name = "file"   action="/api/common/uploadFile" data={{"modKeyOrderFilmId":null,"type":"5","cinemaId":null,"filmId":null}} onChange={e=>this.uploadChange(e)}>
												 <Button style={{marginLeft:10}}><Icon type="upload" /> 导入</Button>
											 </Upload>
										</div> 
										<div className="toolbar-right" style={{width:'100%'}}>
											<Table size={"small"} rowKey={record => record.id} rowSelection={rowSelection} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
										</div>
									</div>
								</div>
              </Spin>
            </div>    
        )
    }
}
export default CinemaList;
import React, { Component } from 'react';
import history from '../history.js';
import Logo from '../../img/logo.png'
import { Menu, Dropdown, Icon,message,Input,Modal } from 'antd';

let md5 = require("md5");
class Header extends Component {
	constructor(props) {
		super(props);  

		this.state={
			search:"",
			visible: false,
			oldPassword:'',
			userName:''
		}
	  }
	routeState (path) {
         history.push({pathname:path});
	};
	searchValue(e){
		this.setState({search:e.target.value})
	}

	onClick = ({ key }) => {
		if(key == "/updatePassword"){
			this.setState({
				visible: true
			})
		   return;
		}
		
		if(key == "/"){
			var content = {};
			fetch('/api/login/doLogout',{
				method: 'post',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(content),
			}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
				return res.json();
			}).then(data=>{
				this.setState({userName:data.result.code})
			})
		}
		history.push({pathname:key})
	  };


	handleOk () {
		if(this.state.oldPassword==undefined || this.state.oldPassword==""){
			message.error("请输入原密码！"); 
			return;
		}
		if(this.state.password==undefined || this.state.password==""){
			message.error("请输入新密码！"); 
			return;
		}
		if(this.state.confirmPassword != this.state.password){
			message.error("两次密码输入不同，请核实！"); 
			return;
		}
		let content={password:md5(this.state.password),oldPassword:md5(this.state.oldPassword)}
		fetch('/api/userCenter/updatePassword',{
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {             
            if(!res.ok){                
                 message.error("网络不可用！");             
            }
            return res.json();
        }).then((data)=> {
            if (data.code == 0) {
				message.success("修改成功");
				this.setState({ visible: false });
			    history.push({pathname:"/"})
            }else  {
				message.error(data.message);
            }
        })
	};
	
	  handleCancel () {	
		this.setState({
			visible: false
		});
	  };
	  passwordChange(e){
		 this.state.password = e.target.value
	  }
	  confirmPassword(e){
		this.state.confirmPassword = e.target.value
	  }
	  passwordWrite(e){
		this.state.oldPassword=e.target.value
	  }
    render() {
		const menu = (
			<Menu onClick={this.onClick.bind(this)}>
			  <Menu.Item key="/updatePassword">修改密码</Menu.Item>
			  <Menu.Item key="/">退出系统</Menu.Item>
			</Menu>
		  )
		
        return (
        <div className="page-header navbar navbar-fixed-top">
			<div className="container">
				<div className="page-logo">
					<a href="javascript:;" className="logo-name">
                    	<img src={Logo} alt="logo" className="logo-default" />
					</a>
				</div>
				<div className="top-menu" style={{marginRight:"50px",float:"right",marginTop:"8px"}}>
					<Dropdown overlay={menu} trigger={['click']}>
						<a className="ant-dropdown-link">
						您好，{this.state.userName} <Icon type="down" />
						</a> 
					</Dropdown>
				</div>
			</div>
			<Modal title="修改密码" visible={this.state.visible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)}>
			    原密码：<Input type = "password" style={{width:"60%",marginLeft:15}} placeholder="请输入原密码" onChange = {this.passwordWrite.bind(this)}/><br/><br/>
				新密码：<Input type = "password" style={{width:"60%",marginLeft:15}} placeholder="请输入新密码" onChange = {this.passwordChange.bind(this)}/><br/><br/>
				确认密码：<Input type = "password" style={{width:"60%"}} placeholder="请再次输入密码" onChange = {this.confirmPassword.bind(this)}/>
            </Modal>
		</div>
        )

    }
}
export default Header;
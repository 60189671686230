import React, { Component } from 'react';
import {HashRouter as Router,Route,Switch,Redirect} from 'react-router-dom';
import history from '../components/history';
import '../css/base.css';
import '../css/bootstrap.min.css';
import '../css/global.css';
import '../css/index.css';
import {Model} from 'antd'; 

import Login from '../components/login.js';
import Manage from '../components/index/manage';
class MRoute extends Component {
  render() {
  //   function getConfirmation(message, callback) { // 至关重要的callback方法，可以异步执行
  //     if (!G.pageChangeConfirm) { // G.pageChangeConfirm为页面内的全局变量，用于数据交互与条件判断
  //         callback(true);
  //         return;
  //     }
  //     Modal.confirm({
  //         title: '离开该页面，表单信息将不被保留？是否确定离开该页面？',
  //         content: '',
  //         okText: '离开',
  //         cancelText: '取消',
  //         onOk() {
  //             callback(true);
  //         },
  //         onCancel() {
  //             callback(false);
  //         },
  //     });
  // }
    return (
  
      <Router history={history}><Switch>  
          {/* <Redirect path="/" to="/login"></Redirect>      */}
          <Route path="/" component={Login} exact/>
          <Route path="/manage" component={Manage}/>        
        </Switch>
     </Router>
    );
  }
}
 
export default MRoute;

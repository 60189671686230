import React from 'react';
import { Upload, Icon, Input,Select,Button,Spin,message,Modal} from 'antd';
import history from '../history';

const { Option } = Select;
class ActorDetails extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:{},
        loading:false,
        previewVisible: false,
        previewImage: '',
        fileList:[],
        isfalse: null,
        esfalse:false
      }
     
    }
    componentWillMount() {
       if(this.props.params != null){
            this.state.isfalse = this.props.isfalse;
            this.state.params = this.props.params;
            this.onload(this.state.params.id);
       }else{
        this.state.fileList=[];
       }
       this.showBtn(this.props.buttonList)//控制按钮显示
    }
    //控制按钮显示
    showBtn(array){
         for(var i= 0; i<array.length;i++){
              if(array[i].buttonCode == "btn_save"){
                this.state.btn_save = true
              }
              if (array[i].buttonCode == "btn_update") {
                this.state.btn_update = true;
              }
              if (array[i].buttonCode == "btn_cancel") {
                this.state.btn_cancel = true;
              }
         }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps != null ){
            if(nextProps.params != null){
                this.state.isfalse = nextProps.isfalse;
                this.state.params = nextProps.params;
                this.onload(this.state.params.id);
                this.state.esfalse=false
            }else{
                this.state.fileList=[];
                this.state.isfalse=null
                this.setState({
                    params: {}
                });
            }
            
        }else{
            this.state.isfalse=null
            this.setState({
                params: {}
            });
        }
    }
    
    //获取详情数据的接口
    onload(id){
        let dataParams = {};
        this.setState({ loading: true });
        let content = {id:id}
            fetch('/api/system/actorDetails',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        dataParams = data.result;
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.state.fileList = [];
                    this.state.params = dataParams;
                    var imgPath = {
                        uid: dataParams.id,
                        url:this.state.params.profilePictureFile
                    }
                    this.state.fileList.push(imgPath);
                    this.state.loading = false;
                    this.setState({ fileList: this.state.fileList });
                });
    }
    //input改变的值
    handleChange(type,e) {
        let params=this.state.params;
        if(type == "name"){
           params.name = e.target.value
        }
        if(type == "description"){
           params.description = e.target.value
        }
        this.setState({params})
    }
    //保存
    save(){
        // if(this.state.url == null && this.state.fileList.length <1){
        //     message.error("请上传演员图片")
        //     return;
        // };
        if(this.state.params.name == undefined || this.state.params.name== ''){
            message.error("演员姓名未填写，请核实！");
            return;
        };
        if( this.state.params.sex == undefined ||this.state.params.sex== ""){
            message.error("性别未填写，请核实！");
            return;
        };
        this.state.params.profilePictureFile = this.state.url;
        this.setState({ loading: true });
            fetch('/api/system/saveActor',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.params),
                }).then(function (res) {           
                      if(!res.ok){   
                        message.error("网络不可用！");             
                    }
                        return res.json();
                }).then( (data)=> {
                    if (data.code == 0) {
                        message.success("保存成功！");
                        if(data.result.id!=null){
                            this.onload(data.result.id)
                          }
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                 let params=this.state.params;
                   params.name = ""
                   params.sex = ""
                   params.description = ""
                   params.fileList = [];
                    this.setState({ loading: false,params,isfalse: true, esfalse: false });
                });
    }
    //select的值
    selectChange(type,value){
        let params=this.state.params;
        if(type == "sex"){
          params.sex = value
        }
        this.setState({params });
    }

    //关于上传的js
    handleCancel = () => this.setState({ previewVisible: false });
    handlePreview = async file => {
        if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
        previewImage: file.url || file.preview,
        previewVisible: true,
        });
    };

    imgChange = ({ fileList }) => this.setState({ fileList });
    getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
      
     cencel(type) {
        //修改还是取消
        if (type == 'write') {
            this.setState({
                isfalse: false,
                esfalse:true
            });
        } else {
            this.setState({
                isfalse: true,
                esfalse: false,
            });
        }
    }
    render(){
        const { previewVisible, previewImage, fileList } = this.state;
        if(fileList.length>0&&fileList[0].status == "done"){
            this.state.url = fileList[0].response.result
        }
        if(fileList.length==0){
            this.state.url = null
        }
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        let { isfalse ,esfalse} = this.state;
        return(
            <div className = "userDetails" key={(this.state.params != null && this.state.params.id == '-1')?this.state.params.id:this.state.params.id}>
                <Spin spinning={this.state.loading} tip="Loading...">
                <div style = {{margin:"0 0 18px 30px"}}>
                        <Button style={{display:this.state.btn_save == true?"inline-block":"none"}} type="primary" disabled={isfalse} onClick = {this.save.bind(this)}>保存</Button> 
                        <Button style={{display:this.state.btn_update == true ? "inline-block" : "none",marginLeft: 20 }} disabled={esfalse}  onClick={this.cencel.bind(this, 'write')}> 修改</Button>
                        <Button style={{display:this.state.btn_cancel == true ? "inline-block" : "none",marginLeft: 20}} disabled={isfalse} onClick={this.cencel.bind(this)}> 取消 </Button>
                        <Button style={{marginLeft:20}} onClick = {this.props.callback.bind(this,"1",null,null)}>返回</Button>
                    </div> 
                    <Input hidden={true} value={this.state.params.id}/>
                    <p>演员姓名:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                      <Input disabled={isfalse} value={this.state.params.name} placeholder = "请输入演员姓名" onChange={this.handleChange.bind(this,"name")}/>
                    </p>
                    <p>性别：<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                        <Select disabled={isfalse} value={this.state.params.sex}  style={{width:"78%",float:"right"}} onChange={this.selectChange.bind(this,"sex")}>
                            <Option value="">--请选择性别--</Option>
                            <Option value="0">男</Option>
                            <Option value="1">女</Option>
                        </Select>
                    </p>
                    <p>描述:
                        <Input disabled={isfalse} value={this.state.params.description}  placeholder = "请输入演员描述" onChange={this.handleChange.bind(this,"description")}/>
                    </p>
                    <div className="clearfix" style = {{marginLeft:30}}>
                        <Upload
                            action="/api/system/actorImage"//上传地址
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            onChange={this.imgChange}
                            >
                            {fileList.length >= 1 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </div>
                </Spin>    
            </div>    
        )
    }
}
export default ActorDetails;
import React from 'react';
import { Table, Spin,Button,message,Tabs,Modal} from 'antd';
import history from '../history';

const { confirm } = Modal;

class MsgTemplateList extends React.Component{
  
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
			loading:false
		}
	}
		//获取列表数据的接口
		onload(ty){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			if(ty=="1"){
				this.state.start=0;
				}else{
				this.state.start=this.state.page;
				}
			
			fetch('/api/message/queryNotificationList',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify({}),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
                                array=data.result;    
                                total=data.total;
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                    this.state.params=array;
                    this.state.total=total;
					this.state.selectedRowKeys = [];
					this.state.selectedRow = [];
                    this.setState({ loading: false });
                });
		 }
		 componentWillMount() {
			this.onload();
			this.showBtn(this.props.buttonList)//控制按钮显示
		}
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_search"){
						this.state.btn_search = true
				  }
				  if(array[i].buttonCode == "btn_add"){
					this.state.btn_add = true
				  }
				if(array[i].buttonCode == "btn_delete"){
					this.state.btn_delete = true
				}
			 }
		}

		
		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow;
			this.setState({ selectedRowKeys });
		};
		
		pageChange(page,pageSize){//翻页
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
		}
		//删除
	  showConfirm = () => {
		if(this.state.selectedRow.length == 0){
			message.error("尚未选中数据！");
			return;
		}
		confirm({
		  title: '确定删除?',
		  content: '',
		  okText: '确定',
		  cancelText:"取消",
		  onOk:()=> {
			this.setState({ loading: true });
            let array=this.state.selectedRow
			fetch('/api/message/deleteMessageNotification',{
				method: 'post',
				credentials: 'include',
                headers: {
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify(array),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                    return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        message.success("删除成功")
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.onload();
            });
		  },
		  onCancel() {
		  },
		});
	  }
	//启用 禁用
	statusClick (type,obj){
		let str='';
		if(this.state.selectedRow.length == 0&&obj==null){
			message.error("尚未选中数据！");
			return;
		}else if(obj!=null){
			obj.status=type;
			this.state.selectedRow[0]=obj;
		}else if(this.state.selectedRow.length>0){
            for(var i=0;i<this.state.selectedRow.length;i++){
				this.state.selectedRow[i].status=type;
			}
		}
		if(type=='0'){
			str='启用成功';
		}else{
			str='禁用成功';
		}
		confirm({
		  title: type=='0'?'确认启用?':type=='1'?'确认禁用?':'',
		  content: '',
		  okText: '确定',
		  cancelText:"取消",
		  onOk:()=> {
			// this.setState({ loading: true });
			let array=this.state.selectedRow;
			fetch('/api/message/updateMessageNotification',{
				method: 'post',
				credentials: 'include',
                headers: {
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify(array),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                    return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        message.success(str)
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.onload();
            });
		  },
		  onCancel() {
		  },
		});
	  }

    render(){
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title: '模板名称',
					dataIndex: 'name',
					key: 'name',
					render: (text,record) => <a href="javascript:;" onClick = {this.props.callback.bind(this,"2","details",record)}>{text}</a>,
				},
				{
					title: '服务节点',
					dataIndex: 'nodeListName',
                    key: 'nodeListName',
				
				},
				{
					title: '状态',
					dataIndex: 'status',
                    key: 'status', 
                    render: (text) =>  text == '0'?"启用": text == '1'?"禁用":'',
                       
				},{
					title: '通知方式',
					dataIndex: 'method',
					key: 'method',
					render:(text)=><div>{text=text.split(',').map(item=>item=='0'?'邮件'+'、':item=='1'?'短信'+'、':item=='2'?'微信'+'、':'')}</div>
                },
				{
					title: '通知目标',
					dataIndex: 'bList',
					key: 'bList',
					render:(text)=><div>{text.map(item=>item.targetName+',')}</div>
                },
                {
					title: '操作',
					dataIndex: '',
                    key: '',
                    render:(record)=><div><Button type="primary" disabled={record.status=='1'?false:true} onClick={this.statusClick.bind(this,'0',record)}>启用</Button><Button style={{marginLeft:'6px'}} type="primary" disabled={record.status=='0'?false:true} onClick={this.statusClick.bind(this,'1',record)}>禁用</Button></div>
				}
				
			];
			const dataSource = this.state.params;
			const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
				<div class="page-content-info">
					<div class="toolbar-group">
						<div style={{marginBottom:'10px'}}>
							<Button type="primary" onClick = {this.props.callback.bind(this,'2','details',null)}>新增</Button>
							<Button style={{marginLeft:10}} type="danger" onClick = {this.showConfirm.bind(this)}>删除</Button>
                            <Button style={{marginLeft:10}} type="primary" onClick={this.statusClick.bind(this,'0',null)}>启用</Button>
							<Button style={{marginLeft:10}} type="primary" onClick={this.statusClick.bind(this,'1',null)}>禁用</Button>
							<Button style={{marginLeft:10}} type="primary" onClick={this.onload.bind(this,'1')}>刷新</Button>
						</div>
					    <div class="toolbar-right" style={{width:'100%'}}>
					    <Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
						</div>
					</div>
				</div>
              </Spin>
            </div>    
        )
    }
}
export default MsgTemplateList;
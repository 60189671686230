import React from 'react';
import { Table, Spin, Button, message, Row, Modal, Col, DatePicker,Input,Select,InputNumber} from 'antd';
import history from '../history';
import moment from 'moment';
const { confirm } = Modal;
const {Option}=Select;
class AvailableList extends React.Component {

    constructor(props) {
        super(props); 
        this.state = {
            params: [], 
            page: 0,
            pageSize: 10,
            selectedRowKey: [],
            selectedRow: [],
            selectedRowKeys: [],
            selectedRows:[],
            total: 0,
            loading: false,
            visible:false,
            type:"",
            display:'none',
            give:'none',
            cinemaList:[],
            userlist:[],
            deList:[],
            giveList:[],
            seltype:'',
            useId:'',
            radioCheck:false,
            radioType:'0',
            effectiveBegin:'',
            effectiveEnd:'',
            priceAttribute:'1'
        }
    }
   //列表的接口 
    onload() {
        let array = []
        let total = 0;
        let content = {
            start: this.state.start,
            limit: this.state.pageSize,
            isLargeClass:'1',
            status:'0'
        };
        this.setState({loading:true})
        fetch('/api/coupon/couponList', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.params = array;
            this.state.total = total;
            this.setState({ loading: false });
            this.state.params.map(item=>{
             return item.checked=false;
           })
        });
    }
    //影院人员接口
    usering(){
      let content = { };
      fetch('/api/coupon/queryUserList', {
          method: 'post',
          credentials: 'include',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(content),
      }).then(function (res) {
          if (!res.ok) {
              message.error("网络不可用！");
          }
          return res.json();
      }).then( (data)=> {
          if (data.code == 0) {
              this.state.userList = data.result;
          } else {
              message.error(data.message);
              if (data.code == 2003) {
                  history.push({ pathname: '/' });
              }
          }
      })
    }
    //生命周期
    componentWillMount() {
        this.onload();
        this.usering();
        this.showBtn(this.props.buttonList);//控制按钮显示
    }
    //控制按钮显示
    showBtn(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_give") {
                this.state.btn_give = true
            }
            if (array[i].buttonCode == "btn_delete") {
                this.state.btn_delete = true
            }
            if (array[i].buttonCode == "btn_add") {
                this.state.btn_add = true
            }
        }
    }
    
    //获取选中值
    onSelectChange(selectedRowKey, selectedRow) {
        this.state.selectedRow = selectedRow
        this.setState({ selectedRowKey });
        console.log(selectedRowKey)
    };
//获取子选中值
    onSelectChanges(selectedRowKeys, selectedRows) {
        this.state.selectedRows = selectedRows
        this.setState({ selectedRowKeys });
    }
     //翻页
    pageChange(page, pageSize) {
        page = (page - 1) * pageSize;
        this.state.page = page;
        if(this.state.selectedRow.length>0){
          this.setState({selectedRow:[],radioType:"0"})
        }
        this.onload();
      }

//删除
showConfirm = (id) => {
  let deList=this.state.deList
    if (this.state.selectedRow.length == 0&&this.state.selectedRows.length==0) {
      message.error("尚未选中数据！");
      return;
    }else if (this.state.selectedRows.length >0&&this.state.selectedRow.length >0) {
      message.error("不可同时选中！");
      return;
    }else if(this.state.selectedRows.length >0&&this.state.selectedRow.length ==0){
      if(id==this.state.selectedRows[0].largeClassId){
        for(var i=0;i<this.state.selectedRows.length;i++){
          deList[i]={
            id:this.state.selectedRows[i].id,
            isLargeClass:'0'
          };
        }
      }else{
        message.error("不可操作！");
        return;
      }
  }else if(this.state.selectedRows.length ==0&&this.state.selectedRow.length >0){
    if(id==this.state.selectedRow[0].id){
      deList=[{ id:this.state.selectedRow[0].id,
        isLargeClass:'1'}]
    }else{
      message.error("不可操作！");
      return;
    }
  }
    confirm({
      title: "确定删除?",
      content: "",
      okText: '确定',
      cancelText:"取消",
      onOk: () => {
        this.setState({ loading: true });
        fetch("/api/coupon/deleteCoupon", {
          method: "post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(deList)
        })
          .then(function(res) {
            if (!res.ok) {
              message.error("网络不可用！");
            }
            return res.json();
          })
          .then((data)=> {
            if (data.code == 0) {
              message.success("删除成功");
              if(this.state.selectedRow.length>0){
                this.state.selectedRow=[];
              }
              if( this.state.selectedRows.length>0){
                  this.state.selectedRows=[];
              }
             
            } else {
              message.error(data.message);
              if (data.code == 2003) {
                history.push({ pathname: "/" });
              }
            }
          })
          .then(e => {
            this.state.selectedRow.length = 0;
            if(this.state.selectedRow.length>0){
              this.setState({selectedRow:[],radioType:"0"})
            }
            this.onload();
          });
      },
      onCancel() {}
    });
  };
  //赠送
  giveClick(type,id){
    let {give,seltype}=this.state;
   
      if (this.state.selectedRow.length == 0&&this.state.selectedRows.length == 0) {
        message.error("尚未选中数据！");
        return;
      }else if (this.state.selectedRow.length>0&&this.state.selectedRows.length> 0) {
        message.error("不可同时选中");
        return;
      }
      if(type=="none"){
       
        if(this.state.selectedRows.length>0&&this.state.selectedRow.length ==0){
          if(id!=this.state.selectedRows[0].largeClassId){
            message.error("不可操作！");
            return;
          }
      }else if(this.state.selectedRows.length ==0&&this.state.selectedRow.length >0){
        if(id!=this.state.selectedRow[0].id){
          message.error("不可操作！");
          return;
        }
       }
       give="block";
       }else if(type=="block"){
        give="none";
       }

     this.setState({give, seltype})
  }
  giveokClick(){
    let {giveList}=this.state
    if(this.state.selectedRow.length>0){
      if(this.state.cinemaId==undefined||this.state.cinemaId==''){
        message.error('影院未填写');
        return;
      }
      if(this.state.id==undefined||this.state.id==''){
        message.error('用户未填写');
        return;
      }
      if(this.state.number==undefined||this.state.number==''){
        message.error('数量未填写');
        return;
      }
      
        giveList=[{
          id:this.state.selectedRow[0].id,
          cinemaId:this.state.cinemaId,
          userId:this.state.id,
          isLargeClass:"1",
          number:this.state.number
      }]
      
  
    }else if(this.state.selectedRows.length>0){
      if(this.state.cinemaId==undefined||this.state.id==undefined){
        message.error('有必填项未填写')
        return;
      }else{
     
        for(var i=0;i<this.state.selectedRows.length;i++){
          giveList[i]={
            id:this.state.selectedRows[i].id,
            cinemaId:this.state.cinemaId,
            userId:this.state.id,
            isLargeClass:"0",
            largeClassId:this.state.selectedRows[i].largeClassId,
          };
      }
    }
  }
    this.giveClick('block')
    fetch("/api/coupon/giveCoupon", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(giveList)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data) =>{
        if (data.code == 0) {
          message.success("赠送成功");
          if(this.state.selectedRow.length>0){
            this.setState({selectedRow:[],radioType:"0"})
          }
          if( this.state.selectedRows.length>0){
            this.setState({selectedRows:[]})
          }
          this.onload();
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
  }
   //新建
   addClick(type){
    let {display}=this.state;
    if(type=="none"){
     display="block";
     this.setState({
       effectiveBegin:'',
       effectiveEnd:''
     })
    }else if(type=="block"){
       display="none";
       this.state.name="";
       this.state.type="";
       this.state.category="";
       this.state.effectiveBegin="";
       this.state.effectiveEnd="";
       this.state.number="";
       this.state.conditions=null;
       this.state.discount=null;
       this.state.describes="";
       this.state.subsidyRatioOfIssuers=null;
       this.state.code='';
       this.state.priceAttribute='1';
    }
    this.setState({display,name:this.state.name,type:this.state.type,category:this.state.category,effectiveBegin:this.state.effectiveBegin,
      effectiveEnd:this.state.effectiveEnd,number:this.state.number,conditions:this.state.conditions,discount:this.state.discount,
      describes:this.state.describes, subsidyRatioOfIssuers:this.state.subsidyRatioOfIssuers,priceAttribute:this.state.priceAttribute,
     code:this.state.code})
   }
   okClick(){
     let {selectedRow}=this.state
     if(selectedRow.length==0){
       if(this.state.name==undefined||this.state.name==null||this.state.name==""){
        message.error('优惠券名称未填写')
        return;
       }
       if(this.state.discount==undefined||this.state.discount==null||
       this.state.conditions==undefined||this.state.conditions==null){
        message.error('满减未填写完整');
        return;
       }
       if(this.state.type==undefined||this.state.type==null||this.state.type==""){
        message.error('优惠券类型未填写');
        return;
       }
       if(this.state.category==undefined||this.state.category==null||this.state.category==""){
        message.error('优惠券品类未填写')
        return;
       }
       if(this.state.subsidyRatioOfIssuers==undefined||this.state.subsidyRatioOfIssuers==null){
        message.error('发行方补贴比例未填写')
        return;
       }
       if(this.state.effectiveEnd==undefined||this.state.effectiveEnd==null||this.state.effectiveEnd==""||this.state.effectiveBegin==undefined||this.state.effectiveBegin==null||this.state.effectiveBegin==""){
        message.error('优惠券有效期未填写')
        return;
       }
       if(this.state.number==undefined||this.state.number==null||this.state.number==""){
        message.error('优惠券数量未填写')
        return;
       }
       
    }else{
      if(this.state.number==undefined||this.state.number==null||this.state.number==""){
          message.error('数量未填写')
          return;
       }
    }
    
    const content=selectedRow.length == 0?{
    name:this.state.name,type:this.state.type,
    category:this.state.category,effectiveBegin:moment(this.state.effectiveBegin).format('YYYY-MM-DD')+' 00:00:00',effectiveEnd:moment(this.state.effectiveEnd).format('YYYY-MM-DD')+' 23:59:59',
    number:this.state.number*1,conditions:this.state.conditions,discount:this.state.discount,describes:this.state.describes,
    subsidyRatioOfIssuers:parseFloat(this.state.subsidyRatioOfIssuers),platformSubsidyRatio:100-this.state.subsidyRatioOfIssuers,
    priceAttribute:this.state.priceAttribute,code:this.state.code
  }:{
    id:selectedRow[0].id,name:selectedRow[0].name,type:selectedRow[0].type,
    category:selectedRow[0].category,effectiveBegin:selectedRow[0].effectiveBegin,effectiveEnd:selectedRow[0].effectiveEnd,
    number:this.state.number*1,conditions:selectedRow[0].conditions,discount:selectedRow[0].discount,describes:this.state.describes,
    subsidyRatioOfIssuers:parseFloat(selectedRow[0].subsidyRatioOfIssuers),platformSubsidyRatio:100-selectedRow[0].subsidyRatioOfIssuers,
    priceAttribute:this.state.priceAttribute,code:this.state.code
  }
  console.log(content);
  this.addClick('block');
  this.setState({ loading: true });
    fetch("/api/coupon/addCoupon", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data) =>{
        if (data.code == 0) {
          message.success("新建成功");
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.name="";
        this.state.type="";
       this.state.category="";
       this.state.effectiveBegin="";
       this.state.effectiveEnd="";
       this.state.number="";
       this.state.conditions=null;
       this.state.discount=null;
       this.state.describes="";
       this.state.subsidyRatioOfIssuers=null;
       this.state.priceAttribute='1';
       this.state.code='';
        this.setState({ loading: false });
        if(this.state.selectedRow.length>0){
          this.setState({selectedRow:[],radioType:"0"})
        }
        this.onload();
      });
}
   //修改值
   handleChange(type,e){
    let state=this.state
   if (type == "name") {
     state.name = e.target.value;
   }
   if (type == "type") {
    state.type = e;
   }
   if (type == "category") {
   state.category = e;
   }
   if (type == "number") {
   state.number = e.target.value;
   }
   if(type=="conditions"){   
     state.conditions=e;
   }
   if(type=="priceAttribute"){
     state.priceAttribute=e;
   }
   if(type=='code'){
     state.code=e.target.value;
   }
   if (type == "discount") {
    state.discount = e;
    if(state.discount*1>state.conditions*1){
      message.error('超过满足的金额！');
     }
   }    
   if(type=="describes"){
     state.describes=e.target.value;
   }
   if(type=='subsidyRatioOfIssuers'){
     state.subsidyRatioOfIssuers=e;
   }
   this.setState({state})
 }

 onStartChange = (value, vs) => {
  this.setState({effectiveBegin:value});
 };

onEndChange = (value, vs) => {
  this.setState({effectiveEnd:value});
};

 //请求影院的接口
 onLoading(useId) {
  let content = {id:useId};
  let array = [];
  fetch('/api/userCenter/userDetails', {
      method: 'post',
      credentials: 'include',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
  }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
  }).then(function (data) {
      if (data.code == 0) {
          array = data.result.userCinemaList;
      }
  }).then((e) => {
      this.setState({
          cinemaList: array
      })
  });
}
//选择的值
SelectChange(type, e) {
  let state=this.state;
  if(type=="id"){
    state.id = e;
    this.onLoading(state.id);
  }
  if(type=="cinemaId"){
    state.cinemaId = e;
  }
  if(type=="number"){
    state.number = e.target.value;
    if(state.number>this.state.selectedRow[0].number){
      message.error('已超过现有数量！');
    }
  }
  this.setState({state});
}
//单选框
radioBtn(record,radioType){
    const {selectedRow,params}=this.state;
    for(var i=0;i<params.length;i++){
      if(params[i].id==record.id&&radioType=="0"&&selectedRow.length==0){
        selectedRow.push(record);
        params[i].checked=true;
        this.setState({radioType:'1'});
      }else if(params[i].id==record.id&&radioType=="1"&&params[i].checked==true){
        selectedRow.splice(0,1);
        params[i].checked=false; 
        this.setState({radioType:'0'});
      }
    }
    this.setState({selectedRow});
  }
    render() {
        const columns = [  
          {
            title: '',
            render:(text,record,index)=><input type="radio" name="radio" checked={record.checked} 
            onClick={this.radioBtn.bind(this,record,this.state.radioType)}></input>
          },
            {
                title: '序号',
                render:(text,record,index)=><><span>{index+1}</span></>
                
            },
            {
                title: '优惠券名称',
                dataIndex: 'name',
                key: 'name'
            },
            {
              title:'优惠券满减',
              dataIndex: 'conditions',
                key: 'conditions',
            render:(text,record,index)=><span>满{record.conditions}减{record.discount}</span>
            },
            {
              title: "优惠券类型",
              dataIndex: "type",
              key: "type",
              render:text=>text=text==0?"满减券":text==1?"折扣券":text==2?"现金券":"免场次券"
            },
            {
              title: "优惠券品类",
              dataIndex: "category",
              key: "category",
              render:text=>text==0?"通用券":""
            },
            {
              title:'优惠券有效期', 
              dataIndex:'effectiveBegin',
              key:'effectiveBegin',
              render:(text,record)=>text=text!=undefined||text!=null?record.effectiveBegin.slice(0,10)+'-'+(record.effectiveEnd).slice(0,10):text
            },
           {
                title: '数量',
                dataIndex: 'number',
                key: 'number',
           },{
            title: '',
            dataIndex: '',
            key: '',
            render:(text,record)=><div><Button type="primary" style={{marginRight:'10px',display: this.state.btn_give== true ? "inline-block" : "none"}} onClick={this.giveClick.bind(this,'none',record.id)}>赠送</Button><Button type="primary" style={{display: this.state.btn_delete == true ? "inline-block" : "none" }} onClick={this.showConfirm.bind(this,record.id)}>删除</Button></div>
           }


        ];
        const dataSource = this.state.params;
        const { selectedRowKeys,selectedRowKey } = this.state;
        const { selectedRow,selectedRows} = this.state;
        // const rowSelection = {
        //     type:"radio", 
        //     selectedRowKey,
        //     selectedRow,
        //     onChange: this.onSelectChange.bind(this),//获取选中值
        // };
        const rowSelections = {
          selectedRowKeys,
          selectedRows,
          onChange: this.onSelectChanges.bind(this),//获取选中值
      };    
        const expandedRowRender = (record) => {  //子表单
            const columns=[
              { title: '劵编码', dataIndex: 'code', key: 'code'},
              { title: '券名称', dataIndex: 'name', key: 'name' },
              { title: '劵说明', dataIndex: 'describes', key: 'describes'},
              { title: '创建人', dataIndex: 'creator', key: 'creator'},
              {title:'发行方补贴比列',dataIndex:'subsidyRatioOfIssuers',key:'subsidyRatioOfIssuers',
              render:(text)=>text+'%'
            }
            ]
            const data=record.bList
            return <Table size={"small"} rowSelection={rowSelections} rowKey={record=>record.id} columns={columns} dataSource={data} pagination={false} />
        }
        return (
            <div>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <div className="page-content-info">
                        <div className="toolbar-group">
                            <div className="toolbar-left">
                                <div style={{width:'100%',marginBottom:'20px'}}>
                                <Row>
                                <Button type="primary" style={{marginLeft:10,display: this.state.btn_add == true ? "inline-block" : "none" }} onClick={this.addClick.bind(this,'none')}>新建</Button>
                                <Button type="primary" style={{marginLeft:10,}} onClick={this.onload.bind(this)}>刷新</Button>
                                {/* <Button type="primary" style={{marginLeft:20}} onClick={this.giveClick.bind(this,'none','1')}>赠送</Button>
                                <Button type="primary" style={{marginLeft:20}} onClick={this.showConfirm.bind(this,"1")}>删除</Button> */}
                                </Row>
                                </div>
                            </div>
                            <div className="toolbar-right" style={{ width: '100%'}}>
                                <Table columns={columns}   expandedRowRender={expandedRowRender}  
                                dataSource={dataSource} rowKey={record=>record.id}
                                pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}
                                // rowSelection={rowSelection}
                                />                          
                            </div>
                          
                        </div>
                    </div>
                </Spin>
      <div style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,.3)',position:'fixed',top:0,left:0,display:this.state.display,zIndex:'999'}}  onClick={this.addClick.bind(this,'block')}>         
        <div class="tanbox" style={{width:'32%',height:'85%',maxHeight:'680px',overflow:'auto',position:'fixed',top:'14%',left:'35%',background:'#fff',display:this.state.display,border:'1px solid #ccc',zIndex:'999'}} onClick={(e)=>e.stopPropagation()}>
        <Row style={{ margin: '30px 5px 5px 20px' }}>      
               <Col span={22}><span style={{width:'105px',display:'inline-block'}}> 优惠券名称：</span>
               {selectedRow.length>0?selectedRow[0].name:
               <Input   
                style={{ width: 240}}
                onChange={this.handleChange.bind(this, "name")}
                value={this.state.name}
                placeholder="请输入优惠券名称"
               /> }
               </Col>
        </Row>
       
        <Row style={{ margin: '30px 5px 5px 20px' }}>
              <Col span={22}><span style={{width:'105px',display:'inline-block'}}> 优惠券类型：</span> 
              {selectedRow.length>0?selectedRow[0].type==0?"满减券":selectedRow[0].type==1?"折扣券":selectedRow[0].type==2?"现金券":"免场次券":
              <Select
                    style={{  width: 240}}
                    onChange={this.handleChange.bind(this, "type")}
                    value={this.state.type}
                    placeholder="请选择优惠券类型"
                  >
                    <Option value="">--优惠券类型--</Option>
                    <Option value="0">满减券</Option>
                    {/* <Option value="1">折扣券</Option>
                    <Option value="2">现金券</Option>
                    <Option value="3">免场次券</Option> */}
                  </Select>
              }
              </Col> 
        </Row>
        {
          selectedRow.length>0&&selectedRow[0].priceAttribute!=null? <Row style={{ margin: '30px 5px 5px 20px' }}>
          <Col span={22}><span style={{width:'105px',display:'inline-block'}}>满减类型：</span> 
          {selectedRow[0].priceAttribute=='1'?"有价券":selectedRow[0].priceAttribute=='2'?"卡包券":"免费券"}
          </Col> 
        </Row>:this.state.type==0&&this.state.type!=""?
          <Row style={{ margin: '30px 5px 5px 20px' }}>
              <Col span={22}><span style={{width:'105px',display:'inline-block'}}>满减类型：</span> 
             
              <Select
                    style={{  width: 240}}
                    onChange={this.handleChange.bind(this, "priceAttribute")}
                    value={this.state.priceAttribute}
                    placeholder="请选择满减类型"
                  >
                    <Option value="1">有价券</Option>
                    <Option value="2">卡包券</Option>
                    <Option value="3">免费券</Option>
                  </Select>
              </Col> 
        </Row>:''
          }
          {
             selectedRow.length>0&&selectedRow[0].code!=null?<Row style={{ margin: '30px 5px 5px 20px' }}>
             <Col span={22}><span style={{width:'105px',display:'inline-block'}}>卡包编码：</span>
             {selectedRow[0].code}
             </Col></Row>
             :this.state.type==0&&this.state.type!=""&&this.state.priceAttribute=='2'&&this.state.priceAttribute!=""?
            <Row style={{ margin: '30px 5px 5px 20px' }}>
            <Col span={22}><span style={{width:'105px',display:'inline-block'}}>卡包编码：</span> 
              <Input 
              style={{ width: 240}}
              onChange={this.handleChange.bind(this, "code")}
              value={this.state.code}
              placeholder="请填写卡包编码"
              ></Input>
           
            </Col> 
           </Row>:''
          }
        {
            selectedRow.length>0&&selectedRow[0].conditions!=null?<Row style={{ margin: '30px 5px 5px 20px' }}>
            <Col span={22}>满{selectedRow[0].conditions}减{selectedRow[0].discount}</Col>
          </Row>:this.state.type==0&&this.state.type!=""?
        <Row style={{ margin: '30px 5px 5px 20px' }}>
         <Col span={22}>
            满 <InputNumber
            style={{ width: 120}}
            onChange={this.handleChange.bind(this, "conditions")}
            value={this.state.conditions}
          />减<InputNumber  
          style={{ width: 120}}
          onChange={this.handleChange.bind(this, "discount")}
          value={this.state.discount}
        />
          </Col>
          </Row>:""
        }
        <Row style={{margin: '30px 5px 5px 20px'}}>
         < Col span={22} ><span style={{width:'105px',display:'inline-block'}}> 优惠券品类：</span> 
         {selectedRow.length>0?selectedRow[0].category=="0"?"通用券":"":
         <Select
                    style={{ width: 240}}
                    onChange={this.handleChange.bind(this, "category")}  
                    value={this.state.category}
                    placeholder="请选择优惠券品类"
                  >
                   <Option value="">--优惠券品类--</Option>
                    <Option value="0">通用券</Option>
                  </Select>
         }
         </Col>
        </Row>
        <Row style={{ margin: '30px 5px 5px 20px' }}>      
               <Col span={22}><span style={{width:'120px',display:'inline-block'}}>发行方补贴比例：</span>
               {selectedRow.length>0?selectedRow[0].subsidyRatioOfIssuers==null?'0':selectedRow[0].subsidyRatioOfIssuers:
               <InputNumber   
                style={{ width: 180}}
                onChange={this.handleChange.bind(this, "subsidyRatioOfIssuers")}
                value={this.state.subsidyRatioOfIssuers}
                placeholder="请输入比例"
                min={0}
                max={100}
               /> }%
               </Col>
        </Row>
        <Row style={{ margin: '30px 5px 5px 20px' }}>
              <Col span={22}> <span style={{width:'105px',display:'inline-block'}}>优惠券有效期：</span>
{selectedRow.length>0?(selectedRow[0].effectiveBegin).slice(0,10)+"-"+(selectedRow[0].effectiveEnd).slice(0,10):
               <><DatePicker
                                            style={{ width: '180px' }}
                                            format="YYYY-MM-DD "
                                            onChange={this.onStartChange}  
                                            placeholder="请选择开始日期"
                                            value={this.state.effectiveBegin}
                                        />
                                        -
                                            <DatePicker
                                            style={{ width: '180px',margin:'10px 0 0 105px'}}
                                            format="YYYY-MM-DD " 
                                            onChange={this.onEndChange}
                                            placeholder="请选择结束日期"
                                            value={this.state.effectiveEnd}
                                        />
                                        </>
}
               </Col>
        </Row>       
      <Row style={{margin:'30px 5px 5px 20px',height:'32px',lineHeight:'32px'}}>
        <Col span={22}>
        <span style={{width:'105px',display:'inline-block'}}>优惠券数量：</span><Input
                style={{ width: 240,marginLeft:'2px'}}
                onChange={this.handleChange.bind(this,"number")}
                value={this.state.number}
                placeholder=" "
              /></Col>
      </Row>
      <Row style={{margin:'30px 5px 5px 20px'}} align="moddle">
        <Col span={6} >
         <span style={{width:'105px',display:'inline-block'}}>说明：</span>
         </Col>
         <Col span={17} style={{lineHeight:'32px'}}>
        <textarea 
                style={{ width: 240,marginLeft:'2px'}}
                onChange={this.handleChange.bind(this,"describes")}
                value={this.state.describes}
              /></Col>
      </Row>
        <Row style={{marginTop:'50px'}}>
            <Button type="primary" style={{marginRight:'10px',marginLeft:'40%',width:'15%'}} onClick={this.addClick.bind(this,'block')}>取消</Button>
            <Button type="primary" style={{width:'15%'}} onClick={this.okClick.bind(this)}>确定</Button>
        </Row>
        </div>
      </div>  
      <div style={{width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,.3)',position:'fixed',top:0,left:0,display:this.state.give,zIndex:'999'}} onClick={this.giveClick.bind(this,'block')}>
        <div class="tanbox1" style={{width:'32%',height:'75%',maxHeight:'600px',overflow:'auto',position:'fixed',top:'20%',left:'35%',background:'#fff',display:this.state.give,border:'1px solid #ccc',zIndex:'999'}}  onClick={(e)=>e.stopPropagation()}>
        {
         selectedRow.length>0?<><Row style={{ margin: '20px 5px 5px 20px' }}>      
         <Col span={22}>优惠券名称：{selectedRow.length>0?selectedRow[0].name:''}
         </Col>
  </Row>
  <Row style={{ margin: '30px 5px 5px 20px' }}>
        <Col span={22}> 优惠券类型：{selectedRow.length>0?selectedRow[0].type==0?"满减券":selectedRow[0].type==1?"折扣券":selectedRow[0].type==2?"现金券":"免场次券":''}
        </Col> 
  </Row>
  {
     selectedRow.length>0?selectedRow[0].type==0?
    <Row style={{ margin: '30px 5px 5px 20px' }}>
    <Col span={22}> 满减类型：{selectedRow[0].priceAttribute==1?"有价券":selectedRow[0].priceAttribute==2?"卡包券":"免费券"}
   </Col> 
   </Row>:"":''
  }
  {
     selectedRow.length>0?selectedRow[0].type==0&&selectedRow[0].priceAttribute=='2'?
     <Row style={{ margin: '30px 5px 5px 20px' }}>
     <Col span={22}> 卡包编码：{selectedRow.length>0?selectedRow[0].code:''}
    </Col> 
    </Row>:"":''
  }
  {
     selectedRow.length>0?selectedRow[0].type==0?
    <Row style={{ margin: '30px 5px 5px 20px' }}>
    <Col span={22}> 满{selectedRow.length>0?selectedRow[0].conditions:''}减{selectedRow.length>0?selectedRow[0].discount:''}
   </Col> 
   </Row>:"":''
  }
  <Row style={{margin: '30px 5px 5px 20px'}}>
   < Col span={22} > 优惠券品类：{selectedRow.length>0?selectedRow[0].category=="0"?"通用券":"":""}
   </Col>
  </Row>
  <Row style={{margin: '30px 5px 5px 20px'}}>
   < Col span={22}>发行方补贴比例：{selectedRow.length>0?selectedRow[0].subsidyRatioOfIssuers:""}
   </Col>
  </Row>
  <Row style={{ margin: '30px 5px 5px 20px' }}>
        <Col span={23}> 优惠券有效期：{selectedRow.length>0?(selectedRow[0].effectiveBegin).slice(0,10)+"-"+(selectedRow[0].effectiveEnd).slice(0,10):""}
         </Col>
  </Row></>:<><Row style={{ margin: '50px 5px 5px 20px' }}>      
         <Col span={22}>优惠券名称：{selectedRows.length>0?selectedRows[0].name:''}
         </Col>
  </Row>
  <Row style={{ margin: '30px 5px 5px 20px' }}>
        <Col span={22}> 优惠券类型：{selectedRows.length>0?selectedRows[0].type==0?"满减券":selectedRows[0].type==1?"折扣券":selectedRows[0].type==2?"现金券":"免场次券":''}
  </Col> 
  </Row>
  {
     selectedRows.length>0?selectedRows[0].type==0?
    <Row style={{ margin: '30px 5px 5px 20px' }}>
    <Col span={22}> 满减类型：{selectedRows[0].priceAttribute==1?"有价券":selectedRows[0].priceAttribute==2?"卡包券":"免费券"}
   </Col> 
   </Row>:"":''
  }
  {
     selectedRows.length>0?selectedRows[0].type==0&&selectedRows[0].priceAttribute=='2'?
     <Row style={{ margin: '30px 5px 5px 20px' }}>
     <Col span={22}> 卡包编码：{selectedRows.length>0?selectedRows[0].code:''}
    </Col> 
    </Row>:"":''
  }
  {
     selectedRows.length>0?selectedRows[0].type==0?
    <Row style={{ margin: '30px 5px 5px 20px' }}>
    <Col span={22}> 满{selectedRows.length>0?selectedRows[0].conditions:''}减{selectedRows.length>0?selectedRows[0].discount:''}
   </Col> 
   </Row>:"":''
  }
  <Row style={{margin: '30px 5px 5px 20px'}}>
   < Col span={22} > 优惠券品类：{selectedRows.length>0?selectedRows[0].category=="0"?"通用券":"":""}
   </Col>
  </Row>
  <Row style={{margin: '30px 5px 5px 20px'}}>
   < Col span={22} > 发行方补贴比例：{selectedRows.length>0?selectedRows[0].subsidyRatioOfIssuers:""}
   </Col>
  </Row>
  <Row style={{ margin: '30px 5px 5px 20px' }}>
        <Col span={22}> 优惠券有效期：{selectedRows.length>0?(selectedRows[0].effectiveBegin).slice(0,10)+"-"+(selectedRows[0].effectiveEnd).slice(0,10):""}
         </Col>
  </Row></>
        }
        <Row style={{margin:'30px 5px 5px 20px',height:'32px',lineHeight:'32px'}}>
        <Col span={22}>
        <Select
             showSearch
             style={{ width: '240px' }}
             placeholder="请输入赠送用户"
             optionFilterProp="children"
             onChange={this.SelectChange.bind(this, 'id')}
             filteroption={(input, option) =>
               option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
             >
              
              {this.state.userList&&this.state.userList.map((item) => {
               return (
                 <option value={item.id}>{item.name}</option>
                     )
                })
              }
       </Select>   
        </Col>
      </Row>
        <Row style={{margin:'30px 5px 5px 20px',height:'32px',lineHeight:'32px'}}>
        <Col span={22}>
        <Select
             showSearch
             style={{ width: '240px' }}
             placeholder="请输入赠送影院"
             optionFilterProp="children"
             onChange={this.SelectChange.bind(this, 'cinemaId')}
             filteroption={(input, option) =>
               option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
             >
              {this.state.cinemaList&&this.state.cinemaList.map((item) => {
               return (
                 <option value={item.cinemaId}>{item.cinemaName}</option>
                    )
                })
              }
       </Select>       
        </Col>
      </Row>
             
      <Row style={{margin:'30px 5px 5px 20px',height:'32px',lineHeight:'32px'}}>
        <Col span={22}>
          {
            selectedRows.length>0?"":<Input
            placeholder="请输入赠送数量"
            style={{ width: 240,marginLeft:'2px'}}
            onChange={this.SelectChange.bind(this,"number")}
            value={this.state.number}
          />
          }
              </Col>
      </Row>
        <Row style={{marginTop:'25px'}}>
            <Button type="primary" style={{marginRight:'10px',marginLeft:'40%',width:'15%'}} onClick={this.giveClick.bind(this,'block')}>取消</Button>
            <Button type="primary" style={{width:'15%'}} onClick={this.giveokClick.bind(this)}>确定</Button>
        </Row>
        </div>
        </div>
        </div>  
        )
    }
}
export default AvailableList;
    
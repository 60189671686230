import React from 'react';
import { Table, Spin,Button,message,Tabs,Modal,Tooltip} from 'antd';
import history from '../history';

const { confirm } = Modal;
class FilmAreaList extends React.Component{
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
			loading:false
		}
	}
		//获取列表数据的接口
		onload(ty){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			if(ty=="1"){
				this.state.start=0
				}else{
				this.state.start=this.state.page
				}
			let content = {start:this.state.start,limit:this.state.pageSize,
				name:this.state.name,filmName:this.state.filmName,isEnable:this.state.isEnable
			};
			fetch('/api/zone/list',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
                                array=data.result;    
                                total=data.total;
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                    this.setState({params:array,total, loading: false,selectedRow:[],selectedRowKeys:[] });
                });
		 }
		 componentWillMount() {
			this.onload()
			this.showBtn(this.props.buttonList)//控制按钮显示
		}
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_search"){
						this.state.btn_search = true
				  }
				  if(array[i].buttonCode == "btn_add"){
					this.state.btn_add = true
				  }
				if(array[i].buttonCode == "btn_delete"){
					this.state.btn_delete = true
				}
			 }
		}

		valueChange(type,e) {
			if(type == "name"){
				this.state.name=e.target.value;
			}
			if(type == "filmName"){
				this.state.filmName=e.target.value;
			}
		}
		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
		
		pageChange(page,pageSize){//翻页
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
		}
		//删除
	   showConfirm = () => {
		if(this.state.selectedRow.length == 0){
			message.error("尚未选中数据！");
			return;
		}else if(this.state.selectedRow.length>0){
			if(this.state.selectedRow.some(item=>{return item.isRecommend=='1'})){
				   message.error("选中的数据有推荐专区启用状态");
				   return;
			    
				}else{
					confirm({
						title: '确定删除?',
						content: '',
						okText: '确定',
						cancelText:"取消",
						onOk:()=> {
						  this.setState({ loading: true });
						  let array=this.state.selectedRow;
						  fetch('/api/zone/deleteZone',{
							  method: 'post',
							  credentials: 'include',
							  headers: {
									  'Content-Type': 'application/json'
							  },
							  body: JSON.stringify(array),
							  }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
								  return res.json();
							  }).then(function (data) {
								  if (data.code == 0) {
									  message.success("删除成功")
								  }else  {
									  message.error(data.message);
										  if(data.code == 2003){
											  history.push({pathname:'/'});
									  }
								  }
							  }).then( (e) => {
								  this.onload();
						  });
						},
					  });

				}
			
			}
		}
		//是否启用
		enable(num){
			let str=''
			let {selectedRow}=this.state;
			if(selectedRow.length == 0){
				message.error("尚未选中数据！");
				return;
			}
			if(num==1){
				str='启用成功';
			}else if(num==0){
				str='禁用成功';
			}
			confirm({
			  title:num=='1'?'确定启用?':num=='0'?'确定禁用?':'' ,
			  content: '',
			  okText: '确定',
			  cancelText:"取消",
			  onOk:()=> {
				let array=[];
				this.setState({ loading: true });
				for(var i=0;i<this.state.selectedRow.length;i++){
					this.state.selectedRow[i].isEnable=num;
					array.push({id:this.state.selectedRow[i].id,isEnable:this.state.selectedRow[i].isEnable})
				}
				
				fetch('/api/zone/saveIsEnable',{
					method: 'post',
					credentials: 'include',
					headers: {
							'Content-Type': 'application/json'
					},
					body: JSON.stringify(array),
					}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
						return res.json();
					}).then(function (data) {
						if (data.code == 0) {
							message.success(str)
						}else  {
							message.error(data.message);
								if(data.code == 2003){
									history.push({pathname:'/'});
							}
						}
					}).then( (e) => {
						this.onload();
				});
			  },
			  onCancel() {
			  },
			});
			

		}
		
		
		
	  
	  

    render(){
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title: '专区名称',
					dataIndex: 'name',
					key: 'name',
					render: (text,record) => <a href="javascript:;" onClick = {this.props.callback.bind(this,"2","details",record)}>{text}</a>,
				},
				{
					title: '专区图片文件地址',
					dataIndex: 'address',
					key: 'address'
				},
				{
					title: '介绍说明',
					dataIndex: 'description',
					key: 'description',
					onCell:()=>{
						return {
							style:{
								maxWidth:200,
								overflow:'hidden',
								whiteSpace:'nowrap',
								textOverflow:'ellipsis',
								cursor:'pointer'
							}
						}
					},
					render:(text)=> <Tooltip placement='topLeft' title={text}>{text}</Tooltip>
				},
				{
					title: '是否启用',
					dataIndex: 'isEnable',
					key: 'isEnable',
					render:(text)=>text==0?'否':text==1?'是':''
				},
				{
					title: '是否推荐专区',
					dataIndex: 'isRecommend',
					key: 'isRecommend',
					render:(text)=>text==0?'否':text==1?'是':''
				},

			];
			const dataSource = this.state.params;
			const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
				<div className="page-content-info">
				  <div className="toolbar-group">
					  <div className="toolbar-left">
						  <div className="form-main">
							  <input className="form-control" onChange={this.valueChange.bind(this,"name")} placeholder="请输入专区名称"/>
						  </div>
						  <div className="form-main">
							  <input className="form-control" onChange={this.valueChange.bind(this,"filmName")} placeholder="请输入影片名称"/>
						  </div>
						  <button style={{display:this.state.btn_search == true?"inline-block":"none"}} onClick={this.onload.bind(this,'1')} class="btn btn-default btn-search" type="button">检索</button>
					  </div>
					  <div>
						 <Button style={{marginLeft:10}} type="primary" onClick = {this.props.callback.bind(this,"2","add",null)}>新增</Button>
						 <Button style={{marginLeft:10}} type="primary" onClick = {this.enable.bind(this,'1')}>启用</Button>
						 <Button style={{marginLeft:10}} type="primary" onClick = {this.enable.bind(this,'0')}>禁用</Button>
						 <Button style={{marginLeft:10}} type="danger" onClick = {this.showConfirm.bind(this,this)}>删除</Button>
					  </div>
					    <div className="toolbar-right" style={{width:'100%'}}>
						  <Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
						</div>
				  </div>
				</div>
              </Spin>
            </div>    
        )
    }
}
export default FilmAreaList;
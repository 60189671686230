import React from 'react';
import { Row, Col, InputNumber, Select, Button, Spin, message, Input } from 'antd';
import history from '../history';
const { Option } = Select;
class SetFilmDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: {},
            loading: false,
            isfalse: null,
            esfalse: false
        }

    }
    componentWillMount() {
        if (this.props.params != null) {
            this.state.isfalse = this.props.isfalse;
            this.state.params = this.props.params;
            this.onload(this.state.params.id);
        }
        this.showBtn(this.props.buttonList)//控制按钮显示
    }
    //控制按钮显示
    showBtn(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_save") {
                this.state.btn_save = true
            }
            if (array[i].buttonCode == "btn_update") {
                this.state.btn_update = true;
            }
            if (array[i].buttonCode == "btn_cancel") {
                this.state.btn_cancel = true;
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps != null) {
            if (nextProps.params != null) {
                this.state.isfalse = nextProps.isfalse;
                this.state.params = nextProps.params
                this.onload(this.state.params.id);
                this.state.esfalse = false
            } else {
                this.state.fileList = [];
                this.state.isfalse = null
                this.setState({
                    params: {}
                });
            }

        } else {
            this.state.isfalse = null
            this.setState({
                params: {}
            });
        }
    }
    //获取详情数据的接口
    onload(id) {
        let dataParams = {};
        this.setState({ loading: true });
        let content = { id: id }
        fetch('/api/cinema/film/details', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                dataParams = data.result;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.setState({ params: dataParams, loading: false })
        });
    }
    //保存
    save() {
        let content = {
            id: this.state.params.id,
            filmLevel: this.state.params.filmLevel,
            makeFilmStatus: '1',
            monthPrice: this.state.params.monthPrice,
            price: this.state.params.price,
            useUpCount:this.state.params.useUpCount,
            innerPrice:this.state.params.innerPrice
        }
        this.setState({ loading: true });
        fetch('/api/cinema/film/setFilmInfo', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then((data) => {
            if (data.code == 0) {
                message.success("保存成功！");
                if (data.result != null) {
                    this.onload(data.result.id)
                }
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.setState({ loading: false, isfalse: true, esfalse: false });
        });
    }
    //提交
    submit() {
        if (this.state.params.filmLevel == undefined || this.state.params.monthPrice == undefined || 
            this.state.params.filmLevel == "" || this.state.params.monthPrice == "" || 
            this.state.params.price == undefined || this.state.params.price == ""||
            this.state.params.useUpCount == undefined || this.state.params.useUpCount == ""||
            this.state.params.innerPrice == undefined || this.state.params.innerPrice == ""
            ) {
            message.error("存在必填项未填写，请核实！")
            return;
        };
        let content = {
            id: this.state.params.id,
            filmLevel: this.state.params.filmLevel,
            makeFilmStatus: '2',
            monthPrice: this.state.params.monthPrice,
            price: this.state.params.price,
            useUpCount:this.state.params.useUpCount,
            innerPrice:this.state.params.innerPrice
        }
        this.setState({ loading: true });
        fetch('/api/cinema/film/setFilmInfo', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then((data) => {
            if (data.code == 0) {
                message.success("提交成功！");
                if (data.result != null) {
                    this.onload(data.result.id)
                }
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.setState({ loading: false, isfalse: true, esfalse: false });
        });
    }
    //select的值
    selectChange(type, e) {
        let params = this.state.params;
        if (type == "filmLevel") {
            params.filmLevel = e;
        }
        if (type == "monthPrice") {
            params.monthPrice = e;
        }
        if (type == "price") {
            params.price = e;
        }
        if (type == "useUpCount") {
            params.useUpCount = e;
        }
        if (type == "innerPrice") {
            params.innerPrice = e;
        }
        this.setState({ params });
    }


    cencel(type) {
        //修改还是取消
        if (type == 'write') {
            this.setState({
                isfalse: false,
                esfalse: true
            });
        } else {
            this.setState({
                isfalse: true,
                esfalse: false,
            });
        }
    }
    render() {
        let { isfalse, esfalse } = this.state
        return (
            <div className="userDetails" key={(this.state.params != null && this.state.params.id == '-1') ? this.state.params.id : this.state.params.id}>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <div style={{ margin: "0 0 18px 30px" }}>
                        <Button style={{}} type="primary" disabled={isfalse} onClick={this.save.bind(this)}>保存</Button>
                        <Button style={{ marginLeft: 20 }} disabled={esfalse} onClick={this.cencel.bind(this, 'write')}> 修改</Button>
                        <Button style={{ marginLeft: 20 }} disabled={isfalse} onClick={this.cencel.bind(this)}> 取消 </Button>
                        <Button style={{ marginLeft: 20 }} onClick={this.props.callback.bind(this, "1", null, null)}>返回</Button>
                        <Button style={{ marginLeft: 20 }} onClick={this.submit.bind(this)}>提交</Button>
                    </div>
                    <Input hidden={true} value={this.state.params.id} />
                    <div style={{ display: 'flex', width: '100%', height: '127px', border: '1px solid #ccc' }}>
                        <div style={{ width: '11%' }} >
                            {
                                this.state.params.imagesList.map(item => item.fileType == "0" ? <img style={{ width: '75%', height: '107px', margin: '7px' }} src={item.relativePath} /> : '')
                            }

                        </div>
                        <div style={{ flex: 1, marginLeft: '5px', marginTop: '7px' }}>
                            <Row>影片名称：{this.state.params.filmName}</Row>
                            <Row>影片类型：{this.state.params.filmType == "2D" ? '2D' : '3D'}</Row>
                            <Row>发行公司：{this.state.params.company}</Row>
                            <Row>版权周期：{this.state.params.intervalBegin}</Row>
                        </div>
                    </div>
                    <div style={{ marginTop: '20px', width: '100', height: '127px', border: '1px solid #ccc' }}>
                        <span style={{ margin: '15px 0 0 5px', display: 'inline-block', borderBottom: '1px solid #ccc' }}>定级：</span>
                        <Row style={{ margin: '5px 0 0 5px' }}>
                            <Col>
                                影片级别：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                <Select style={{ width: '176px' }} disabled={isfalse} onChange={this.selectChange.bind(this, 'filmLevel')} value={this.state.params.filmLevel == "A" ? 'A' : this.state.params.filmLevel == "B" ? 'B' : this.state.params.filmLevel == "C" ? 'C' : ''}>
                                    <Option value="A">A</Option>
                                    <Option value="B">B</Option>
                                    <Option value="C">C</Option>
                                </Select>
                            </Col>
                        </Row>
                    </div>
                    <div style={{ marginTop: '20px', width: '100', height: '217px', border: '1px solid #ccc' }}>
                        <span style={{ margin: '15px 0 0 5px', display: 'inline-block', borderBottom: '1px solid #ccc' }}>定价：</span>
                        <Row style={{ margin: '5px 0 0 5px' }}>
                            <Col>包月价格：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><InputNumber min={0} style={{ width: '176px' }} disabled={isfalse} onChange={this.selectChange.bind(this, 'monthPrice')} value={this.state.params.monthPrice}></InputNumber>元</Col>
                            <Col style={{ marginTop: '10px' }}>包场价格：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><InputNumber min={0} style={{ width: '176px' }} disabled={isfalse} onChange={this.selectChange.bind(this, 'price')} value={this.state.params.price}></InputNumber>元</Col>
                            <Col style={{ marginTop: '10px' }}>所需消耗片包次数：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><InputNumber min={0} style={{ width: '176px' }} disabled={isfalse} onChange={this.selectChange.bind(this, 'useUpCount')} value={this.state.params.useUpCount}></InputNumber>次</Col>
                            <Col style={{ marginTop: '10px' }}>密钥成本价：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><InputNumber min={0} style={{ width: '176px' }} disabled={isfalse} onChange={this.selectChange.bind(this, 'innerPrice')} value={this.state.params.innerPrice}></InputNumber>元</Col>
                        </Row>
                    </div>
                </Spin>
            </div>
        )
    }
}
export default SetFilmDetails;
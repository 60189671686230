import React from 'react';
import {Tabs} from 'antd';
import BeOverdueList from '../BeOverdue/BeOverdueList.js';


const { TabPane } = Tabs;
class IsDeleted extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isgn:'2'
      }
    }
    callback(activeKey,type,params){
        if(type == "details"){
          this.state.params = params
        }
        if(type == "add"){
          this.state.params = null
        }
      this.setState({activeKey:activeKey})
    }
    
    render(){
        return(
          <div>
              <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
                <TabPane tab="列表" key="1">
               <BeOverdueList buttonList={this.props.buttonList} callback = {this.callback.bind(this)} isgn={this.state.isgn}/>
              </TabPane>
            </Tabs>
          </div>
        )
    }
}
export default IsDeleted;
import React from 'react';
import Header from './header.js';
import MenuList from './menu.js';
import Tabs from './tabs.js';
import FlimManage from '../filmManage/filmManage.js';
import UserManage from '../userManage/userManage.js';
import CinemaManage from '../cinemaManage/cinemaManage.js';
import RoleManage from '../roleManage/roleManage.js';
import Privilege from '../privilege/privilege.js';
import ActorManage from '../actorManage/actorManage.js';
import EditManage from '../editManage/editManage.js';
import DirectorManage from '../directorManage/directorManage.js';
import CertificateManage from '../certificateManage/certificateManage.js';
import CertificateDownload from '../logManage/certificateDownload.js';
import OperationLog from '../logManage/operationLog.js';
import DeviceManage from '../DeviceManage/DeviceManage.js';
import DownloadApply from '../DownloadApple/downloadApple.js';
import OrderMange from '../orderMange/orderMange.js';
import BoxActivate from '../boxActivate/boxActivate.js';
import BoxManage from '../boxManage/boxManage.js';
import DownloadTask from '../downloadTask/downloadTask.js';
import BoxVersionManage from '../BoxVersionManage/BoxVersionManage.js';
import StrategyManage from '../StrategyManage/StrategyManage';
import PendingOrders from '../PendingOrders/PendingOrders';
import SecretOrderExported from '../SecretOrderExported/SecretOrderExported';
import SecretOrderConfirm from '../SecretOrderConfirm/SecretOrderConfirm';
import DcpOrderExported from '../DcpOrderExported/DcpOrderExported';
import DcpOrderConfirm from '../DcpOrderConfirm/DcpOrderConfirm';
import ToBeAudited from '../ToBeAudited/ToBeAudited';
import Audited from '../Audited/Audited';
import Available from '../Available/Available';
import AlreadyPresented from '../AlreadyPresented/AlreadyPresented';
import BeOverdue from '../BeOverdue/BeOverdue';
import IsDeleted from '../IsDeleted/IsDeleted';
import IssuerManage from '../IssuerManage/IssuerManage';
import TheatreChainManage from '../TheatreChainManage/TheatreChainManage';
import AdministrativeDivisionManage from '../AdministrativeDivisionManage/AdministrativeDivisionManage';
import SetFilm from '../SetFilm/SetFilm';
import Settled from '../Settled/Settled';
import Unsettled from '../Unsettled/Unsettled';
import ToBeConfirmed from '../ToBeConfirmed/ToBeConfirmed';
import HardDiskManage  from '../HardDiskManage/HardDiskManage';
import InAndOutRecord  from '../InAndOutRecord/InAndOutRecord';
import OperationRecord from '../OperationRecord/OperationRecord';
import ImportTemplate from '../ImportTemplate/ImportTemplate';
import ListAuthority from '../ListAuthority/ListAuthority';
import FilmList from '../FilmList/FilmList';
import MsgTemplateList from '../MsgTemplateList/MsgTemplate';
import NodeList from '../NodeList/NodeManage';
import ToConfirmed from '../ToConfirmed/ToConfirmed';
import Confirmed from '../Confirmed/Confirmed';
import InvoiceList from '../invoice/invoiceList';
import FilmBag from '../FilmBag/FilmBagManage';
import FilmBagRecord from '../FilmBagRecord/FilmBagRecord';
import FilmBagUseRecord from '../FilmBagUseRecord/FilmBagUseRecord';
import IngestTask from '../IngestTask/IngestTask';
import EnumManage from '../EnumManage/enumManage';
import FilmAreaList from '../FilmAreaList/FilmAreaManage';
import RecommendList from '../RecommendFilmArea/RecommendFilmArea';
class Manage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: {}
    }
  }

  routeState(state) {
    switch (state.templateUrl) {
      case "BoxVersionManage":
        state.templateUrl = <BoxVersionManage buttonList={state.childMenu} />
      break;
      case "DownloadApply":
        state.templateUrl = <DownloadApply buttonList={state.childMenu} />
      break;
      case "FlimList":
        state.templateUrl = <FlimManage buttonList={state.childMenu} />
      break;
      case "UserManage":
        state.templateUrl = <UserManage buttonList={state.childMenu} />
      break;
      case "CinemaManage":
        state.templateUrl = <CinemaManage buttonList={state.childMenu} />
      break;

      case "RoleManage":
        state.templateUrl = <RoleManage buttonList={state.childMenu} />
      break;

      case "Privilege":
        state.templateUrl = <Privilege buttonList={state.childMenu} />
      break;

      case "ActorManage":
        state.templateUrl = <ActorManage buttonList={state.childMenu} />
      break;

      case "EditManage":
        state.templateUrl = <EditManage buttonList={state.childMenu} />
      break;

      case "DirectorManage":
        state.templateUrl = <DirectorManage buttonList={state.childMenu} />
      break;

      case "CertificateManage":
        state.templateUrl = <CertificateManage buttonList={state.childMenu} />
      break;

      case "OperationLog":
        state.templateUrl = <OperationLog buttonList={state.childMenu} />
      break;

      case "CertificateDownload":
        state.templateUrl = <CertificateDownload buttonList={state.childMenu} />
      break;
      case "DeviceManage":
        state.templateUrl = <DeviceManage buttonList={state.childMenu} />
      break;
      case "OrderList":
        state.templateUrl = <OrderMange buttonList={state.childMenu} />
      break;
      case "BoxActivate":
        state.templateUrl = <BoxActivate buttonList={state.childMenu} />
      break;
      case "BoxManage":
        state.templateUrl = <BoxManage buttonList={state.childMenu} />
      break;
      case "DownloadTask":
        state.templateUrl = <DownloadTask buttonList={state.childMenu} />
      break;
      case "StrategyManage":
        state.templateUrl = <StrategyManage buttonList={state.childMenu} />
      break;
      case "PendingOrders":
        state.templateUrl = <PendingOrders buttonList={state.childMenu} />
      break;
      case "SecretOrderExported":
        state.templateUrl = <SecretOrderExported buttonList={state.childMenu} />
      break;
      case "SecretOrderConfirm":
        state.templateUrl = <SecretOrderConfirm buttonList={state.childMenu} />
      break;
      case "DcpOrderExported":
        state.templateUrl = <DcpOrderExported buttonList={state.childMenu} />
      break;
      case "DcpOrderConfirm":
        state.templateUrl = <DcpOrderConfirm buttonList={state.childMenu} />
      break;
      case "ToBeAudited":
        state.templateUrl = <ToBeAudited buttonList={state.childMenu} />
      break;
      case "Audited":
        state.templateUrl = <Audited buttonList={state.childMenu} />
      break;
      case "Available":
        state.templateUrl = <Available buttonList={state.childMenu} />
      break;
      case "AlreadyPresented":
        state.templateUrl = <AlreadyPresented buttonList={state.childMenu} />
      break;
      case "BeOverdue":
        state.templateUrl = <BeOverdue buttonList={state.childMenu} />
      break;
      case "IsDeleted":
        state.templateUrl = <IsDeleted buttonList={state.childMenu} />
      break;
      case "IssuerManage":
        state.templateUrl = <IssuerManage buttonList={state.childMenu} />
        break;
      case "TheatreChainManage":
        state.templateUrl = <TheatreChainManage buttonList={state.childMenu} />
      break;
      case "AdministrativeDivisionManage":
        state.templateUrl = <AdministrativeDivisionManage buttonList={state.childMenu} />
      break;
      case "SetFilm":
        state.templateUrl = <SetFilm buttonList={state.childMenu} />
      break;
      case "Settled":
        state.templateUrl = <Settled buttonList={state.childMenu} />
      break;
      case "Unsettled":
        state.templateUrl = <Unsettled buttonList={state.childMenu} />
      break;
      case "ToBeConfirmed":
        state.templateUrl = <ToBeConfirmed buttonList={state.childMenu} />
      break;  
      case "HardDiskManage":
        state.templateUrl = <HardDiskManage buttonList={state.childMenu} />
      break; 
      case "InAndOutRecord":
        state.templateUrl = <InAndOutRecord buttonList={state.childMenu} />
      break; 
      case "OperationRecord":
       state.templateUrl = <OperationRecord buttonList={state.childMenu} />
      break;
      case "ImportTemplate":
        state.templateUrl = <ImportTemplate buttonList={state.childMenu} />
      break; 
      case "ListAuthority":
        state.templateUrl = <ListAuthority buttonList={state.childMenu} />
      break;
      case "FilmList":
        state.templateUrl = <FilmList buttonList={state.childMenu} />
      break;
      case "MsgTemplateList":
        state.templateUrl = <MsgTemplateList buttonList={state.childMenu} />
      break; 
      case "NodeList":
        state.templateUrl = <NodeList buttonList={state.childMenu} />
      break; 
      case "ToConfirmed":
        state.templateUrl = <ToConfirmed buttonList={state.childMenu} />
      break; 
      case "Confirmed":
        state.templateUrl = <Confirmed buttonList={state.childMenu} />
      break;
      case "InvoiceList":
        state.templateUrl = <InvoiceList buttonList={state.childMenu} />
      break;
      case "FilmBag":
        state.templateUrl = <FilmBag buttonList={state.childMenu} />
      break;
      case "FilmBagRecord":
        state.templateUrl = <FilmBagRecord buttonList={state.childMenu} />
      break;
      case "FilmBagUseRecord":
        state.templateUrl = <FilmBagUseRecord buttonList={state.childMenu} />
      break;
      case "IngestTask":
        state.templateUrl=<IngestTask buttonList={state.childMenu}/>
      break;
      case "EnumManage":
        state.templateUrl=<EnumManage buttonList={state.childMenu}/>
      break;
      case "FilmAreaList":
        state.templateUrl=<FilmAreaList buttonList={state.childMenu}/>
      break;
      case "RecommendFilmArea":
        state.templateUrl=<RecommendList buttonList={state.childMenu}/>
      break;
      default:
      break;
    }
    this.setState({ params: state })
  }

  goRoute(data) {//首页跳转
    // this.routeState({templateUrl:"DirectorManage",id:"13"}) 
  }

  render() {
    return (
      <div>
        <Header />
        <MenuList routeState={this.routeState.bind(this)}></MenuList>
        <Tabs goRoute={this.goRoute.bind(this)} routeName={this.state.params} />
      </div>
    )
  }
}
export default Manage;
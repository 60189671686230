import React from "react";
import { Table, Spin, Button, message, Select, Modal, Row,Col,Input, Upload,Icon} from "antd";
import history from "../history";
import {formatFileSize} from "../../utils/formatFile"
const { Option } = Select;
class BoxActivateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      cinemaList: [],
      fileList:[],
      list:[],
      theaterList:[],
      visible:false,
      type:""
    };
  }
//列表数据的接口以及筛选
  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if(ty=="1"){
      this.state.start=0
      }else{
      this.state.start=this.state.page
      }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      serialNumber: this.state.serialNumber,
      status: this.state.status,
      // deviceType: this.state.deviceType,
      // cinemaName: this.state.cinemaName,
      // serialNumber: this.state.serialNumber,
    };
    fetch("/api/device/queryBoxList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        // console.log(array)
        this.state.params = array;
        this.state.total = total;
          // if(array.length>0){     //默认设备的第一个
          //   this.state.cinemaList=array[0]
          //   var imgPath = {
          //     uid: array[0].id,
          //     url:this.state.cinemaList.filePath
          // }
          // if(this.state.fileList.length<1){
          //   this.state.fileList.push(imgPath);
          // }
          // }
          // this.state.rowId = array[0].id
          // this.setRowClassName({id:array[0].id})
        this.setState({ loading: false });
      });
  }

  componentWillMount() {
    this.onload()
    this.showBtn(this.props.buttonList); 
     this.theater()
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true;
      }
      if (array[i].buttonCode == "btn_cancei_active") {
        this.state.btn_cancei_active = true;
      }
      if (array[i].buttonCode == "btn_active") {
        this.state.btn_active = true;
      }
    }
  }
 //影院列表
  list(id){
    let content = {theaterChainId:id};
    let array = [];
    fetch('/api/cinema/list',{
      method: 'post',
      credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {             
          if(!res.ok){                 
            message.error("网络不可用！");             
          }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
               array=data.result;    
            }else {
              message.error(data.message);
              if (data.code == 2003) {
                history.push({ pathname: "/" });
              }
            }
        }).then( (e) => {
          
         this.setState({list:array})
       });
  }
 //院线列表
  theater(){
    let content = {};
    let array = [];
    fetch("/api/cinema/theatreList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;
        }
      })
      .then(e => {
        this.state.theaterList = array;
        this.setState({ loading: false });
      });
  }

  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }

  pageChange(page, pageSize) {
    //翻页
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
 //input框取值（获取2个值）
  handleChange(type, e, obj) {
    let cinemaList=this.state.cinemaList
    if (type == "cinemaId") {
    cinemaList.cinemaName = obj.props.children;
    cinemaList.cinemaId = e;
    }
    this.setState({
      cinemaList
    })
  }
//input框取值（获取2个值）
 valChang(type, e, obj){
   let cinemaList=this.state.cinemaList
  if (type == "theaterChainId") {
    cinemaList.theaterChainName = obj.props.children;
    cinemaList.theaterChainId = e
    this.list(cinemaList.theaterChainId)
  }
  this.setState({
    cinemaList
  })
 }
 //点击行展示详情
  clickevent(val){
      this.setState({
        rowId:val.id
      })
      let dataParams = {};
      this.setState({ loading: true });
      let content = { id: val.id }
      fetch(' /api/device/boxDetails', {
        method: 'post',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(content),
      }).then(function (res) {
        if (!res.ok) { message.error("网络不可用！"); }
        return res.json();
      }).then((data) => {
        if (data.code == 0) {
          dataParams = data.result;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: '/' });
          }
        }
      }).then((e) => {
        var imgPath = {
          uid: dataParams.id,
          url:dataParams.filePath
      }
      if(this.state.fileList.length<1){
        this.state.fileList.push(imgPath);
      }
      this.setState({ cinemaList: dataParams, loading: false });
      });
  }
  //input框取值
  inpChange(type,e){
    let cinemaList=this.state.cinemaList
    if(type=="contractCode"){
    cinemaList.contractCode=e.target.value
    }
    this.setState({
      cinemaList:cinemaList
    })
  }
 //select框取值
  handChange(type,value){
    if(type=="status"){
      this.state.status=value
      }
  }
  //弹框按钮的确定 取消
  handleOk(type){
    this.btnActive(type)
    this.setState({visible:false})
  }
  actorCancel(){
    this.setState({visible:false})
}
 //点击激活还是未激活
  eventClick(type){
   this.setState({visible:true,type}) //弹框
    // this.btnActive(type)
  }
  //请求的接口  激活的时候有没有需要判断
 btnActive(type){
   if(this.state.cinemaList.contractCode==undefined||this.state.cinemaList.theaterChainName==undefined||this.state.cinemaList.cinemaName==undefined){
     message.error('存在必填项未填写，请核实')
     return 
   } 
   this.setState({ loading: true });
   let content={id:this.state.cinemaList.id,cinemaId:this.state.cinemaList.cinemaId,
    contractCode:this.state.cinemaList.contractCode,status:type,
    theaterChainId:this.state.cinemaList.theaterChainId,cinemaName:this.state.cinemaList.cinemaName,
    theaterChainName:this.state.cinemaList.theaterChainName
  }
  // console.log(content)
  fetch("/api/device/isActive", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(content)
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    }) 
    .then((data)=> {
      if (data.code == 0) {
        let cinemaList= this.state.cinemaList;
        let params=this.state.params;
        if(type=="1"){
          message.success("激活成功！");
         
          if(cinemaList.status=="1")  {
            cinemaList.status="0";
            params.status="1";
          }else{
            cinemaList.status="1";
            params.status="0";
          }
          this.setState({cinemaList,params}); 
                
        }else if(type=="0"){
          message.success("取消激活成功！");
         if(cinemaList.status=="1")  {
          cinemaList.status="0";
          params.status="1";
        }else{
          cinemaList.status="1";
          params.status="0";
        }
        cinemaList.cinemaName="";
        cinemaList.theaterChainName="";
        cinemaList.contractCode="";
        this.setState({cinemaList,params});  
        
        }
       
      }else{
        message.error(data.message)
        return;
      }
    })
    .then(e => {
      this.setState({loading: false});
      this.onload()
    });
  }

  //关于上传图片
  handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
        previewImage: file.url || file.preview,
        previewVisible: true,
        });
    };
   //上传图片
   handleCancel = () => this.setState({ previewVisible: false });
    imgChange = ({ fileList }) => this.setState({ fileList });
    getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      }
      //input框的值
      valueChange(type,e){
        if(type=="serialNumber"){
          this.state.serialNumber=e.target.value
        }
      }
      //点击添加类事件
      setRowClassName=(record)=>{
        return record.id===this.state.rowId?'clickRowStyl':''
      }

  render() {
    const columns = [
    //   {
    //     title: "序号",
    //     render: (text, record, index) => `${index + 1}`
    //   },
      {
        title: "序列号",
        dataIndex: "serialNumber",
        key: "serialNumber",
      },
      {
        title: "激活状态",
        dataIndex: "status",
        key: "status",
        render: (text, record) =>text=="0"?"未激活":text=="1"?"已激活":''
      },
    ];
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this), //获取选中值
    };
  
  

    const { previewVisible, previewImage, fileList } = this.state;
    if(fileList.length>0&&fileList[0].status == "done"){
        this.state.url = fileList[0].response.result
    }
    if(fileList.length==0){
        this.state.url = null
    }

    const uploadButton = (
      <div>
          <Icon type="plus" />
          <div className="ant-upload-text">上传图片</div>
      </div>
  );

    return (
      <div className="boxActivateList">
        <Spin spinning={this.state.loading} tip="Loading...">
          <div className="page-content-info">
            <div className="toolbar-group">
              <div className="toolbar-left" style={{display:"flex"}}>
                <div className="leftbox" style={{width:'300px'}}>
                <div className="form-main">
                  <input className="form-control" style={{width:'80px'}} onChange={this.valueChange.bind(this, "serialNumber")} placeholder="序列号" />
                </div>
                <div className="form-main">
                  <Select
                    style={{ width: '80px'}}
                    onChange={this.handChange.bind(this, "status")}
                    placeholder="全部"
                  >
                    <Option value="">--全部--</Option>
                    <Option value="0">未激活</Option>
                    <Option value="1">已激活</Option>
                  </Select>
                </div>
                <div className="form-main">
                <button
                  style={{
                    display:
                      this.state.btn_search == true ? "inline-block" : "none",
                  }}
                  onClick={this.onload.bind(this,'1')}
                  className="btn btn-default btn-search"
                  type="button"
                >
                  检索
                </button>
                </div>
              <div className="toolbar-right" style={{ width: "100%" }}>
                <Table
                  size={"small"}
                  columns={columns}
                  dataSource={dataSource}
                  rowKey={record=>record.id}
                  onRow={record=>{return {onClick:this.clickevent.bind(this,record)}}}
                  rowClassName={this.setRowClassName}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
                </div>
                <div className="rightbox" style={{width:'600px',marginLeft:"30px"}}>
                <Row style={{margin:'10px 5px 5px 0', borderBottom:'1px solid #ccc' }}>
                <span
              style={{
                width: "2px",
                height: "15px",
                background: "red",
                display: "inline-block",
                margin: "10px 10px 0 0"
              }}
            ></span>
              <b>激活信息</b>
            </Row>
            <Row  style={{ margin:'10px 5px 5px 0' }}>
              <Col span={14}> 序列号：<span>{this.state.cinemaList.serialNumber}</span></Col>
              <Col span={10}> 名称：<span>{this.state.cinemaList.deviceName}</span> </Col>
            </Row>
            <Row style={{ margin: '10px 5px 5px 0' }}>
              <Col span={12}>所属院线： <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Select
                    showSearch
                    disabled={this.state.cinemaList.length==0||this.state.cinemaList.status=="1"?true:false}
                    style={{ width: 200 }}   
                    optionFilterProp="children" 
                    placeholder="院线"
                    value={this.state.cinemaList.theaterChainName}
                    onChange={this.valChang.bind(this,"theaterChainId")}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.theaterList.map((item, ind) => {
                      return <Option value={item?item.id:''} key={ind}>{item?item.name:''}</Option>
                    })}
                  </Select> </Col>
            </Row>
            <Row style={{ margin: '10px 5px 0 0' }}>
              <Col span={12}>所属影院： <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Select
                    showSearch
                    disabled={this.state.cinemaList.length==0||this.state.cinemaList.status=="1"?true:false}
                    style={{ width: 200 }}
                    placeholder="影院"     
                    optionFilterProp="children"
                    value={this.state.cinemaList.cinemaName}
                    onChange={this.handleChange.bind(this, "cinemaId")}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.list.map((item, ind) => {
                      return <Option  value={item?item.id:''} key={ind}>{item?item.name:''}</Option>
                    })}
                  </Select></Col>
            </Row>
            <Row style={{ margin: '10px 5px 5px 0' }}>
              <Col span={12}>合同编号： <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Input  placeholder="合同编码" disabled={this.state.cinemaList.length==0||this.state.cinemaList.status=="1"?true:false} value={this.state.cinemaList.contractCode} style={{ width: '200px' }}
              onChange={this.inpChange.bind(this,'contractCode')}>
              </Input></Col>
            </Row>
            <Row style={{ margin: '10px 5px 5px 0' }}>
              <Col span={24}>
              <Button style={{marginLeft:'60%',marginRight:'20px',display: this.state.btn_active== true ? "inline-block" : "none",width:'100px'}} type="primary" disabled={this.state.cinemaList.length==0||this.state.cinemaList.status=="0"?true:false} onClick={this.eventClick.bind(this,'0')}>取消激活</Button>
              <Button style={{display: this.state.btn_cancei_active== true ? "inline-block" : "none",width:'100px'}} type="primary" disabled={this.state.cinemaList.length==0||this.state.cinemaList.status=="1"?true:false} onClick={this.eventClick.bind(this,'1')}>激活</Button></Col>
            </Row>
            <Row style={{ margin: '10px 5px 5px 0',borderBottom:'1px solid #ccc'}}>
            <span
              style={{
                width: "2px",
                height: "15px",
                background: "red",
                display: "inline-block",
                margin: "10px 10px 0 0"
              }}
            ></span>
              <b>基本信息</b>
            </Row>
             <div style={{display:'flex'}}>
               <div style={{width:'60%'}}>
               <Row style={{ margin: '10px 5px 5px 0' }}>
               <Col span={12}>型号：<span>{this.state.cinemaList.modelNum}</span></Col>
               </Row>
               <Row style={{ margin: '10px 5px 5px 0' }}>
               <Col span={12}>CPU：<span>{this.state.cinemaList.cpuMsg}</span></Col>
               </Row>
               <Row style={{ margin: '10px 5px 5px 0' }}>
               <Col span={12}>存储：<span>{this.state.cinemaList.totalSize==null?null:formatFileSize(this.state.cinemaList.totalSize)}</span></Col>
               </Row>
               <Row style={{ margin: '10px 5px 5px 0' }}>
               <Col span={12}>系统：<span>{this.state.cinemaList.systemEdition}</span></Col>
               </Row>
               </div>
               <div  style={{width:'40%'}}>
               <Upload
                      action=" /api/common/uploadFile"//上传地址
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={this.handlePreview}
                      onChange={this.imgChange}
                      data={{type:3,cinemaId:null,filmId:null,modKeyOrderFilmId:null}}
                      disabled={this.state.cinemaList.length==0||this.state.cinemaList.status=="1"?true:false}
                      >
                      {fileList.length >= 1 ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                        <img alt="example" style={{ width: '100%' }} src={previewImage}/>
                </Modal>
               </div>
             </div>
                </div>
              
              </div>
            </div>
          </div>
        </Spin>
        <Modal title="提示" width="300px" cancelText="取消"  okText="确认" style={{marginTop:"20px"}} visible={this.state.visible}  onOk={this.handleOk.bind(this,this.state.type)} onCancel={this.actorCancel.bind(this)}>
                  <div style={{width:'100%',textAlign:"center",height:"5px",lineHeight:"5px"}}>
                  <b>{this.state.type=="0"?"是否取消激活?":"是否激活?"}</b>
                  </div>
        </Modal>
      </div>
    );
  }
}
export default BoxActivateList;

import React from 'react';
import { Upload, Icon, Input,Select,Button,Spin,message,Modal} from 'antd';
import history from '../history';



const { Option } = Select;
class DirectorDetails extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:{},
        loading:false,
        previewVisible: false,
        previewImage: '',
        fileList:[],
        isfalse: null,
        esfalse:false
      }
     
    }
    componentWillMount() {
       if(this.props.params != null){
        this.state.isfalse = this.props.isfalse;
            this.state.params = this.props.params;
            this.onload(this.state.params.id);
       }
      
       this.showBtn(this.props.buttonList)//控制按钮显示
    }
    //控制按钮显示
    showBtn(array){
         for(var i= 0; i<array.length;i++){
              if(array[i].buttonCode == "btn_save"){
                this.state.btn_save = true
              }
              if (array[i].buttonCode == "btn_update") {
                this.state.btn_update = true;
              }
              if (array[i].buttonCode == "btn_cancel") {
                this.state.btn_cancel = true;
              }
         }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps != null ){
            if(nextProps.params != null){
                this.state.isfalse = nextProps.isfalse;
                this.state.params = nextProps.params
                this.onload(this.state.params.id);
                this.state.esfalse=false
            }else{
                this.state.isfalse=null
                this.setState({
                    params: {}
                });
            }
            
        }else{
            this.state.isfalse=null
            this.setState({
                params: {}
            });
        }
    }
    //获列表数据详情的 接口
    onload(id){
        let dataParams = {};
        this.setState({ loading: true });
        let content = {id:id}
            fetch('/api/system/directorDetails',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
                }).then(function (res) {             
                    if(!res.ok){                 
                        message.error("网络不可用！");             
                    }
                        return res.json();
                }).then( (data)=> {
                    if (data.code == 0) {
                        this.setState({
                            params:data.result,
                            loading: false
                        })
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                })
    }
   //改变input的值
    handleChange(type,e) {
        let params= this.state.params
        if(type == "name"){
            params.name = e.target.value
        }
        if(type == "description"){
            params.description = e.target.value
        }
        this.setState({params})
    }
    //保存
    save(){
        if(this.state.params.name == "" ||this.state.params.name == undefined || this.state.params.sex== undefined|| this.state.params.sex== ""){
            message.error("存在必填项未填写，请核实！")
            return;
        };
        this.setState({ loading: true });
            fetch('/api/system/saveDirector',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.params),
                }).then(function (res) {            
                     if(!res.ok){                
                          message.error("网络不可用！");             
                        }
                        return res.json();
                }).then( (data)=> {
                    if (data.code == 0) {
                        message.success("保存成功！");
                        if(data.result.id!=null){
                            this.onload(data.result.id)
                          }
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.setState({ loading: false,isfalse: true, esfalse: false });
                });
    }
    //改变select框的值
    selectChange(type,value){
        let params=  this.state.params;
        if(type == "sex"){
          params.sex = value
        }
        this.setState({params})
    }
    cencel(type) {
        //修改还是取消
        if (type == 'write') {
            this.setState({
                isfalse: false,
                esfalse:true
            });
        } else {
            this.setState({
                isfalse: true,
                esfalse: false,
            });
        }
    }
    render(){
        let { isfalse ,esfalse} = this.state
        return(
            <div className = "userDetails" key={(this.state.params != null && this.state.params.id == '-1')?this.state.params.id:this.state.params.id}>
                <Spin spinning={this.state.loading} tip="Loading...">
                <div style = {{margin:"0 0 18px 30px"}}>
                        <Button style={{display:this.state.btn_save == true?"inline-block":"none"}} type="primary" onClick = {this.save.bind(this)} disabled={isfalse}>保存</Button> 
                        <Button style={{display:this.state.btn_update == true ? "inline-block" : "none",marginLeft: 20 }}onClick={this.cencel.bind(this, 'write')} disabled={esfalse}> 修改</Button>
                        <Button style={{display:this.state.btn_cancel == true ? "inline-block" : "none",marginLeft: 20}}onClick={this.cencel.bind(this)} disabled={isfalse}> 取消 </Button>
                        <Button style={{marginLeft:20}} onClick = {this.props.callback.bind(this,"1",null,null)}>返回</Button>
                    </div>
                    <Input hidden={true} defaultValue={this.state.params.id}/>
                    <p>导演姓名:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                      <Input disabled={isfalse} value={this.state.params.name} placeholder = "请输入导演姓名" onChange={this.handleChange.bind(this,"name")}/>
                    </p>
                    <p>性别：<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                        <Select disabled={isfalse} value={this.state.params.sex}  style={{width:"78%",float:"right"}} onChange={this.selectChange.bind(this,"sex")}>
                            <Option value="">--请选择性别--</Option>
                            <Option value="0">男</Option>
                            <Option value="1">女</Option>
                        </Select>
                    </p>
                    <p>描述:
                        <Input disabled={isfalse} value={this.state.params.description}  placeholder = "请输入导演描述" onChange={this.handleChange.bind(this,"description")}/>
                    </p>
                     
                </Spin>    
            </div>    
        )
    }
}
export default DirectorDetails;
import React, { Component } from 'react';
import history from './history.js'
import "../css/login.css";
import { message ,Spin} from 'antd';
import LoginUserPic from '../img/login-user.png';
import LoginPasswordPic from '../img/login-password.png';
import LoginClosePic from '../img/close.png';
import LoginOpenPic from '../img/open.png';
let md5 = require("md5");

class Login extends Component {
    constructor(props) {
        super(props);  
        this.codeChange = this.codeChange.bind(this);
        this.passwordChange = this.passwordChange.bind(this);
        this.state={
            code:localStorage.getItem("code"),
            remember:false,
            showPassword:LoginClosePic,
            inputType:"password",
            loading:false
        }
    }
    codeChange(e){
        this.state.code=e.target.value;
    }
    passwordChange(e){
        this.state.password=e.target.value;
    }
    rememberChange(e){
        this.state.remember=e.target.checked;
    }

    onKeyup(e) {
        if(e.keyCode === 13) {
            this.handleSubmit()
        }
    }
    
    handleSubmit = (e) => {
        localStorage.setItem("code", this.state.code);
        if(this.state.remember==true){
            localStorage.setItem("code", this.state.code);
            localStorage.setItem("remember", true);
        }else{
            localStorage.removeItem("code", this.state.code);
            localStorage.removeItem("remember");
        }
        if(this.state.code == "" || this.state.code ==null){
            message.error("请输入用户名");
            return;
        }
        if(this.state.password == "" || this.state.password ==null){
            message.error("请输入密码");
            return;
        }
        let content = {code: this.state.code, password:md5(this.state.password),userSource:"0"};
        this.setState({ loading: true });
        fetch('/api/login/doLogin',{
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {             
            if(!res.ok){                
                 message.error("网络不可用！");             
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                localStorage.setItem("userName", data.result.name);
                history.push({pathname:'/manage'});
            }else  {
                message.error(data.message);
            }
        }).then( (e) => {
            this.setState({ loading: false });
        });
    };
    showPassword(){ 
        var arr = this.state.showPassword.split("/");
        if(arr.length == 30){
            this.state.inputType="password"
            this.setState({showPassword:LoginClosePic}) 
        }else{
            this.state.inputType="text"
            this.setState({showPassword:LoginOpenPic})
        }
    }

  render() {
      var remember = localStorage.getItem("remember");
      var code = localStorage.getItem("code");
    //   var password = localStorage.getItem("password");
      if(code == undefined){
        code="";
      }
    //   if(password == undefined){
    //     password="";
    //   }
    return (
        <Spin spinning={this.state.loading} tip="Loading...">
            <div className="login">
                <div id="outer">
                    <div id="middle">
                        <div id="inner">
                            <div id="content">
                                <div className="content">
                                    <form className="login-form" method="post">
                                        <div className="form-group form-user">
                                            <div className="input-icon">
                                                <span className="login-user"><img src={LoginUserPic} /></span>
                                                <input style={{height:"62px"}} defaultValue={code} onChange={this.codeChange}  className="form-control placeholder-no-fix " type="text" autoComplete="off" placeholder="请输入用户名" name="code"/>
                                            </div>
                                        </div>
                                        <div className="form-group form-password">
                                            <div className="input-icon">
                                                <span className="login-password"><img src={LoginPasswordPic} /></span>
                                                <span className="login-close"><img onClick={this.showPassword.bind(this)} style={{marginLeft:"-30px"}} src={this.state.showPassword}/></span>
                                                <input style={{height:"62px"}} defaultValue="" onKeyUp={this.onKeyup.bind(this)} onChange={this.passwordChange} className="form-control placeholder-no-fix" type={this.state.inputType} autoComplete="new-password" placeholder="**********" name="password"/>
                                            </div>
                                        </div>
                                        <div className="form-actions sky-form">
                                            <label class="checkbox"><input defaultChecked={remember} onChange={this.rememberChange.bind(this)} type="checkbox" name="remember"/><i></i>记住用户名</label>
                                        </div>
                                        <div className="login-button">
                                            <button type="button" className="btn" onClick={this.handleSubmit}>
                                                登录
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                {/* <div className="copyright">
                                    Copyright©2014-2019 版权所有：中影光峰激光影院技术(北京)有限公司 京ICP备15058245号-2
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spin>
    );
 } 

}

export default Login;

import React from "react";
import { Table, Spin, message, Select, Button, DatePicker } from "antd";
import history from "../history";

const { Option } = Select;
class equipmentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      cinemaList: [],
      startValue: null,//日期定义
      endValue: null,
      endOpen: false,
    };
  }
  //获取列表数据的接口
  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if(ty=="1"){
      this.state.start=0
      }else{
      this.state.start=this.state.page
      }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      // createTime: this.statecreateTime,
      status: this.state.status,
      downloadMethod: this.state.downloadMethod,
      orderNum: this.state.orderNum,
      beginTime: this.state.beginTime,
      endTime: this.state.endTime,
    };
    //  console.log(content)
    fetch("/api/cinema/list/searchFilmDownloadApply", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.setState({ loading: false });
      });
  }
  componentWillMount() {
    this.onload();
    this.showBtn(this.props.buttonList); //控制按钮显示
  }

  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true;
      }
    }
  }


  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }

  //翻页
  pageChange(page, pageSize) {
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
  //选择日期
  OnChange(type, date, dateString) {
    if (type == "createTime") {
      this.state.createTime = dateString
    }

  }
  //input框的值
  valueChange(type, e) {
    if (type == "orderNum") {
      this.state.orderNum = e.target.value
    }
  }
  //select框的值
  handleChange(type, value) {
    if (type == "status") {
      this.state.status = value
    }
    if (type == "downloadMethod") {
      this.state.downloadMethod = value
    }
  }
  //关于日期
  disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };
  disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };
  onChange = (field, value) => {
    this.setState({
      [field]: value,
    });
  };
  handleStartOpenChange = open => {
    if (!open) {
      this.setState({ endOpen: true });
    }
  };

  handleEndOpenChange = open => {
    this.setState({ endOpen: open });
  };
  onStartChange = (value, vs) => {
    this.state.beginTime = vs
    this.onChange('startValue', value);
  };

  onEndChange = (value, vs) => {
    this.state.endTime = vs
    this.onChange('endValue', value);
  };
  
  render() {
    const { startValue, endValue, endOpen } = this.state;
    const columns = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "下载申请单号",
        dataIndex: "orderNum",
        key: "orderNum",
        render: (text, record) => (
          <a
            href="javascript:;"
            onClick={this.props.callback.bind(this, "2", "details", record)}
          >
            {text}
          </a>
        )
      },
      {
        title: "下载方式",
        dataIndex: "downloadMethod",
        key: "downloadMethod",
        render: (text,record) => text == '0'?"快递":"网络",

      },
      {
        title: "下载影片",
        dataIndex: "filmName",
        key: "filmName",
        render:(text,record)=><span>{text=text.slice(0,text.length-1)}</span>
      },
      {
        title: "开始时间",
        dataIndex: "beginTime",
        key: "beginTime",
      },
      {
        title: "结束时间",
        dataIndex: "endTime",
        key: "endTime",
      },
      {
        title: "传输状态",
        dataIndex: "status",
        key: "status",
        render: (text,record) => {
          if(text=="0"){
            return <span>待处理</span>
          }
          if(text=="1"){
            return <span>传输中</span>
          }
          if(text=="2"){
            return  <span>传输完成</span>
          }
          if(text=="3"){
            return  <span>传输失败</span>
          }
          if(text=="4"){
            return <span>传输取消</span>
          }
          if(text=="5"){
            return <span>传输暂停</span>
          }
      }
      },
      {
        title: "快递公司",
        dataIndex: "expressCompany",
        key: "expressCompany",
      },
      {
        title: "快递单号",
        dataIndex: "expressNum",
        key: "expressNum",
      },
      {
        title: "备注",
        dataIndex: "note",
        key: "note",
      }
    ];
    const dataSource = this.state.params;
    // console.log(dataSource)
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this) //获取选中值
    };
    return (
      <div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div className="page-content-info">
            <div className="toolbar-group">
              <div className="toolbar-left">
                <div className="form-main">
                  <input className="form-control" onChange={this.valueChange.bind(this, "orderNum")} placeholder="请输入申请单号" />
                </div>
                <div className="form-main">
                  <DatePicker
                    disabledDate={this.disabledStartDate}
                    showTime
                    format="YYYY-MM-DD"
                    value={startValue}
                    placeholder="请选择开始时间"
                    onChange={this.onStartChange}
                    onOpenChange={this.handleStartOpenChange}
                  />
                  -
                    <DatePicker
                    disabledDate={this.disabledEndDate}
                    showTime
                    format="YYYY-MM-DD"
                    value={endValue}
                    placeholder="请选择结束时间"
                    onChange={this.onEndChange}
                    open={endOpen}
                    onOpenChange={this.handleEndOpenChange}
                  />
                </div>
                <div className="form-main">
                  <Select
                    // labelInValue 
                    style={{ width: 167, height: 50 }}
                    onChange={this.handleChange.bind(this, "status")}
                   placeholder="请选择传输状态"
                  >
                    <Option value="">--传输状态--</Option>
                    <Option value="0">待处理</Option>
                    <Option value="1">传输中</Option>
                    <Option value="2">传输完成</Option>
                    <Option value="3">传输失败</Option>
                    <Option value="4">传输取消</Option>
                    <Option value="5">传输暂停</Option>
                  </Select>
                </div>
                <div className="form-main">
                  <Select
                    // labelInValue
                    style={{ width: 167, height: 50 }}
                    onChange={this.handleChange.bind(this, "downloadMethod")}
                   placeholder="请选择下载方式"
                  >
                    <Option value="">--下载方式--</Option>
                    <Option value="0">快递</Option>
                    <Option value="1">网络</Option>
                  </Select>
                </div>
              
              </div>
              <button
                 style={{display:this.state.btn_search == true?"inline-block":"none"}}
                  onClick={this.onload.bind(this,'1')}
                  className="btn btn-default btn-search"
                  type="button"
                >
                  检索
                </button>
              <div className="toolbar-right" style={{ width: "100%" }}>
                <Table
                  size={"small"}
                  rowKey={record=>record.id}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
export default equipmentList;

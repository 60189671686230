import React from 'react';
import {Tabs} from 'antd';
import DownloadList from './downloadList.js';
import DownloadDetail from './downloadDetail.js';

const { TabPane } = Tabs;
class DownloadApple extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isfalse:null
      }
    }
    callback(activeKey,type,params){
        if(type == "details"){
          this.state.isfalse=true
          this.state.params = params
        }
        if(type == "add"){
          this.state.isfalse=false
          this.state.params = null
        }
      this.setState({activeKey:activeKey})
    }
    
    render(){
        return(
          <div>
              <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
              <TabPane tab="影片订单列表" key="1">
                <DownloadList buttonList={this.props.buttonList}  callback = {this.callback.bind(this)}/>
              </TabPane>
              <TabPane tab="影片订单详情" key="2">
                <DownloadDetail buttonList={this.props.buttonList} isfalse={this.state.isfalse} params = {this.state.params} callback = {this.callback.bind(this)}/>
              </TabPane>
            </Tabs>
          </div>
        )
    }
}
export default DownloadApple;
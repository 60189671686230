import React from 'react';
import { Table, Spin, Button, message, Row, Modal, Select } from 'antd';
import history from '../history';
import { formatFileSize } from "../../utils/formatFile";
const { Option } = Select
class IngestTaskList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            params: [],
            page: 0,
            pageSize: 10,
            total: 0,
            loading: false,
        }
    }
    componentWillMount(){
        this.onload();
        this.timer=setInterval(() => {
            this.onload();
        }, 6000);
    }
    componentWillUnmount(){
        clearInterval(this.timer)
    }
    onload(ty){
        let array = []
        let total = 0;
        if(ty=='1'){
            this.state.start=0;
        }else{
            this.state.start=this.state.page;
        }
        let content={
            start: this.state.start,
            limit: this.state.pageSize,
            status: this.state.status,
            type: this.state.type,
            serialNumber:this.state.serialNumber
        };
        fetch('/api/downloadTask/queryIngestTask',{
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.setState({ loading: false,params:array,total:total});
        });
    }
    //翻页
    pageChange(page, pageSize) {
        page = (page - 1) * pageSize;
        this.state.page = page;
        this.onload();
    }
    valueChange(type, e) {
        if (type == "status") {
            this.state.status = e;
        }
        if (type == "type") {
            this.state.type = e;
        }
        if (type == "serialNumber") {
            this.state.serialNumber = e.target.value;
        }
    }
    //点击导入DCP按钮
    btnClick(record){
        let content={id:record.id};
        fetch('/api/downloadTask/ingestTaskReImport',{
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                message.success('导入成功');
                this.onload();
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        })
    }
    render(){
        const columns=[
            {
                title: '序号',
                render:(text,record,index)=>`${index+1}`
                
            },
            {
                title: '影片名称',
                dataIndex: 'filmName',
                key: 'filmName',
                render:(text,record,index)=>text
            },
            {
                title: '盒子序列号',
                dataIndex: 'serialNumber',
                key: 'serialNumber',
            },
            {
                title: '类型',
                dataIndex: 'type',
                key: 'type',
                render: (text) => text == '0' ? "DCP" : "密钥"
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text, record) => {
                    if (text == "1") {
                        return <span>导入中</span>
                    }
                    if (text == "2") {
                        return <span>导入成功</span>
                    }
                    if (text == "3") {
                        return <span>导入失败</span>
                    }                   
                }
            }, 
            {
                title: '失败次数',
                dataIndex: 'errorCount',
                key: 'errorCount',
            },
            {
                title: '导入错误原因',
                dataIndex: 'errorReason',
                key: 'errorReason',
            },
            {
                title: '已导入偏移量',
                dataIndex: 'currentSize',
                key: 'currentSize',
                render:(text)=><span>{formatFileSize(text)}</span>
            },
            {
                title: '需要导入的总量',
                dataIndex: 'totalSize',
                key: 'totalSize',
                render:(text)=><span>{formatFileSize(text)}</span>
            },
            {
                title:'操作',
                dataIndex: '',
                key: '',
                render:(text,record,index)=><Button type="primary" disabled={record.type==0?false:true} onClick={this.btnClick.bind(this,record)}>导入DCP</Button>
            }
        ];
        const dataSource = this.state.params;
        return(
        <div>
            <Spin spinning={this.state.loading} tip="Loading...">
                <div className="page-content-info">
                    <div className="toolbar-group">
                        <div className="toolbar-left">
                            <div className="form-main">
                                    <input style={{width:167}} className="form-control" onChange={this.valueChange.bind(this, "serialNumber")} placeholder="请输入盒子序列号"/>
                                </div>
                                <div className="form-main">
                                    类型:
                                    <Select
                                        style={{ width: 167, height: 50, margin: '0 0px 0 10px' }}
                                        onChange={this.valueChange.bind(this, "type")}
                                        placeholder='请选择类型'
                                    >
                                        <Option value="">--类型--</Option>
                                        <Option value="0">DCP</Option>
                                        <Option value="1">密钥</Option>
                                    </Select>
                                </div><div className="form-main">
                                    状态:
                                    <Select
                                        style={{ width: 167, height: 50, margin: '0 5px 0 5px' }}
                                        onChange={this.valueChange.bind(this, "status")}
                                        placeholder='请选择状态'
                                    >
                                        <Option value="">--状态--</Option>
                                        <Option value="1">导入中 </Option>
                                        <Option value="2">导入成功</Option>
                                        <Option value="3">导入失败</Option>
                                    </Select>
                                </div>
                                <button onClick={this.onload.bind(this,'1')} className="btn btn-default btn-search" type="button">检索</button>
                        </div>
                        <div className="toolbar-right" style={{ width: '100%' }}>
                                <Table columns={columns} dataSource={dataSource} rowKey={record=>record.id}
                                pagination={{onChange: this.pageChange.bind(this),total:this.state.total}} />                          
                        </div>
                    </div>
                </div>    
            </Spin>
        </div>
        )
    }
}
export default IngestTaskList;
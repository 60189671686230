import React from 'react';
import {Tabs} from 'antd';
import FilmBagList from './FilmBagList.js';
import FilmBagDetails from './FilmBagDetails.js';
import history from '../history';
const { TabPane,message } = Tabs;
class FilmBagManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        params:null,
        activeKey:"1",
        isfalse:null,
      }
    }
    componentWillMount(){
      this.searchEnum();
      this.searchEnum2();
      this.searchEnum3();
      this.searchEnum4();
    }
    searchEnum(){
     let content={type:'4'};
     let array=[];
     fetch('/api/common/searchEnum',{
      method:'post',
      credentials:'include',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify(content),
    }).then(function(res){
      if(!res.ok){
        message.error('网络不可用！');
      }
      return res.json();
    }).then(function(data){
      if(data.code==0){
        array=data.result;
      }else{
        message.error(data.message);
        if(data.code==2003){
          history.push({pathname:'/'})
        }
      }
    }).then(e=>{
      this.setState({validityList:array});
    })
  }
    searchEnum2(){
      let array=[];
      let content={type:'5'};
      fetch('/api/common/searchEnum',{
      method:'post',
      credentials:'include',
      headers:{
        'Content-Type':'application/json'
      },
      body:JSON.stringify(content),
    }).then(function(res){
      if(!res.ok){
        message.error('网络不可用！');
      }
      return res.json();
    }).then(function(data){
      if(data.code==0){
        array=data.result;
      }else{
        message.error(data.message);
        if(data.code==2003){
          history.push({pathname:'/'})
        }
      }
    }).then(e=>{
      this.setState({innerPlayList:array});
    })
   }
    searchEnum3(){
        let array=[];
        let content={type:'6'};
        fetch('/api/common/searchEnum',{
          method:'post',
          credentials:'include',
          headers:{
            'Content-Type':'application/json'
          },
          body:JSON.stringify(content),
        }).then(function(res){
          if(!res.ok){
            message.error('网络不可用！');
          }
          return res.json();
        }).then(function(data){
          if(data.code==0){
            array=data.result;
          }else{
            message.error(data.message);
            if(data.code==2003){
              history.push({pathname:'/'})
            }
          }
        }).then(e=>{
          this.setState({numberOfSeatsList:array});
        })
      }
      searchEnum4(){
        let array=[];
        let content={type:'7'};
        fetch('/api/common/searchEnum',{
        method:'post',
        credentials:'include',
        headers:{
          'Content-Type':'application/json'
        },
        body:JSON.stringify(content),
      }).then(function(res){
        if(!res.ok){
          message.error('网络不可用！');
        }
        return res.json();
      }).then(function(data){
        if(data.code==0){
          array=data.result;
        }else{
          message.error(data.message);
          if(data.code==2003){
            history.push({pathname:'/'})
          }
        }
      }).then(e=>{
        this.setState({buyTypeList:array});
      })
    }
    callback(activeKey,type,params){
      if(type == "details"){
            this.state.isfalse=true;
            this.state.params = params;
      }
      if(type == "add"){
            this.state.params = null;
            this.state.isfalse=false;
      }
        this.setState({activeKey:activeKey,params:this.state.params});
    }
    render(){
      const {isfalse}=this.state;
      let basics = {};
      basics.validityList = this.state.validityList;
      basics.innerPlayList = this.state.innerPlayList;
      basics.numberOfSeatsList = this.state.numberOfSeatsList;
      basics.buyTypeList = this.state.buyTypeList;
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
            <TabPane tab="片包维护列表" key="1">
              <FilmBagList  buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
            </TabPane>
            <TabPane tab="片包维护详情" key="2">
              <FilmBagDetails basics={basics}  buttonList={this.props.buttonList} isfalse={isfalse} params = {this.state.params} callback = {this.callback.bind(this)}/>
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default FilmBagManage;
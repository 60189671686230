import React from "react";
import { Tabs, message } from "antd";
import CinemaList from "./cinemaList.js";
import CinemaDetails from "./cinemaDetails.js";

const { TabPane } = Tabs;
class CinemaManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: "1",
      params: null,
      flag: false,
      dataSource:null,
      isfalse:null,
      shadowList:[],
      administrationList:[],
      queryAgentList:[],
      queryDistributorList:[],
      userList:[],
      userListTwo:[]
    };
  }
  callback(activeKey, type, params) {
    
    if (type == "details") {
      this.state.flag = true;
      this.state.isfalse=true;
      let content = { id: params.id };
      let array = [];
      fetch("/api/cinema/cinemaDetails", {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(content)
      })
        .then(function(res) {
          if (!res.ok) {
            message.error("网络不可用！");
          }
          return res.json();
        })
        .then((data)=> {
          if (data.code == 0) {
            this.setState({
              params : data.result,
              dataSource:data.result.hallList
            })
          }
        })
    }
    if (type == "add") {
      this.state.params = null;
      this.state.flag = false;
      this.state.isfalse=false;
      this.state.dataSource=null;
    }
    this.setState({ activeKey: activeKey });
  }
  componentDidMount() {
    this.getTheatreList(); //获取院线列表
    this.shadow();
    this.administration();
    this.getAgent();
    this.getDistributor();
    this.userList();
    this.userListTwo();
  }
  //
  getTheatreList() {
    //获取院线列表
    let content = {};
    let array = [];
    fetch("/api/cinema/theatreList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function(data) {
        if (data.code == 0) {
          array = data.result;
        }
      })
      .then(e => {
        this.state.theatreList = array;
      });
  }

  //查询影投列表
 shadow(){
  let content = {};
  let array = [];
  fetch("/api/cinema/cinemaInvestmentList", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(content)
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then(function(data) {
      if (data.code == 0) {
        array = data.result;
      }
    })
    .then(e => {
      this.state. shadowList = array;
    });
 }
 //查询6大行政区列表
 administration(){
  let content = {};
  let array = [];
  fetch("/api/cinema/administrativeDivisionList", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(content)
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then(function(data) {
      if (data.code == 0) {
        array = data.result;
      }
    })
    .then(e => {
      this.state. administrationList = array;
    });
 }
 //查询代理商列表
 getAgent(){
  let content = {};
  let array = [];
  fetch("/api/system/queryAgentList", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(content)
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then(function(data) {
      if (data.code == 0) {
        array = data.result;
      }
    })
    .then(e => {
      this.state.queryAgentList = array;
    });
 }
 //查询经销商列表
 getDistributor(){
  let content = {};
  let array = [];
  fetch("/api/system/queryDistributorList", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(content)
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then(function(data) {
      if (data.code == 0) {
        array = data.result;
      }
    })
    .then(e => {
      this.state.queryDistributorList = array;
    });
 }
 //查询销售列表
 userList(){
  let content = {departmentCode:"001"};
  let array = [];
  fetch("/api/userCenter/userList", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(content)
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then(function(data) {
      if (data.code == 0) {
        array = data.result;
      }
    })
    .then(e => {
      this.state.userList = array;
    });
 }
 //查询商务部列表
 userListTwo(){
  let content = {departmentCode:"002"};
  let array = [];
  fetch("/api/userCenter/userList", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(content)
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then(function(data) {
      if (data.code == 0) {
        array = data.result;
      }
    })
    .then(e => {
      this.state.userListTwo = array;
    });
 }
  render() {
    let basics = {};
    basics.theatreList = this.state.theatreList;
    basics.shadowList = this.state.shadowList;
    basics.administrationList = this.state.administrationList;
    basics.queryAgentList = this.state.queryAgentList;
    basics.queryDistributorList = this.state.queryDistributorList;
    basics.userList = this.state.userList;
    basics.userListTwo = this.state.userListTwo;
    const {isfalse}=this.state
    return (
      <div>
        <Tabs
          type="card"
          activeKey={this.state.activeKey}
          onChange={this.callback.bind(this)}
        >
          <TabPane tab="影院影厅列表" key="1">
            <CinemaList
              buttonList={this.props.buttonList}
              callback={this.callback.bind(this)}
            />
          </TabPane>
          <TabPane tab="影院影厅详情" key="2">
            <CinemaDetails
              buttonList={this.props.buttonList}
              basics={basics}
              params={this.state.params}
              dataSource={this.state.dataSource}
              flag={this.state.flag}
              isfalse={isfalse}
              callback={this.callback.bind(this)}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
export default CinemaManage;

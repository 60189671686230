import React from 'react';
import {Tabs,message} from 'antd';
import InAndOutRecordList from './InAndOutRecordList.js';
const { TabPane } = Tabs;
class InAndOutRecord extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
      }
    }  
 
    render(){
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey}>
            <TabPane tab="出入库记录" key="1">
              <InAndOutRecordList  buttonList={this.props.buttonList} />
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default InAndOutRecord;
import React from 'react';
import { Tabs } from 'antd';
import SetFilmList from './SetFilmList.js';
import SetFilmDetails from './SetFilmDetails.js';

const { TabPane } = Tabs;
class SetFilm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey: "1",
            isfalse: null,
        }
    }
    callback(activeKey, type, params) {
        if (type == "details") {
            this.state.isfalse = true;
            this.state.params = params
        }
        if (type == "add") {
            this.state.params = null
            this.state.isfalse = false;
        }
        this.setState({ activeKey: activeKey })
    }
    render() {
        const { isfalse } = this.state
        return (
            <div>
                <Tabs type="card" activeKey={this.state.activeKey} onChange={this.callback.bind(this)}>
                    <TabPane tab="定级定价列表" key="1">
                        <SetFilmList buttonList={this.props.buttonList} callback={this.callback.bind(this)} />
                    </TabPane>
                    <TabPane tab="定级定价详情" key="2">
                        <SetFilmDetails buttonList={this.props.buttonList} isfalse={isfalse} params={this.state.params} callback={this.callback.bind(this)} />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
export default SetFilm;
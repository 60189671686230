import React from "react";
import {Tabs} from 'antd';
import RecommendList from "./RecommendList.js";
const { TabPane } = Tabs;
class RecommendFilmArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey:"1",
        }
    }
    callback(activeKey,type,params){
        if(type == "details"){
          this.state.isfalse=true;
          this.state.params = params
        }
        if(type == "add"){
          this.state.params = null
          this.state.isfalse=false;
        }
      this.setState({activeKey:activeKey})
    }
    render(){
        return(
            <div>
                <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
                    <TabPane tab="推荐专区" key="1">
                        <RecommendList buttonList={this.props.buttonList}  callback = {this.callback.bind(this)}/>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
export default RecommendFilmArea;
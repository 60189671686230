import React from 'react';
import { Input, Select, Button, Spin, message, Row, Col, DatePicker, Icon, Modal, Table, Upload, Checkbox, InputNumber } from 'antd';
import history from '../history';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const dateFormat = "YYYY-MM-DD";
class EditDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: {

            },
            actorArray: [],//全部演员数组
            loading: false,
            page: 0,
            pageSize: 10,
            isfalse: null,
            selectedRowKeys: [],
            selectedRow: [],
            total: 0,
            visible: false,
            actorList: [],//演员信息列表
            previewVisible: false,
            previewImage: '',
            fileLista: [],//海报数组
            filmClassList: [],
            directorList: [],
            fileLists: [],
            companylist: [],
            pianlist: [],
            esfalse: false,
            fastdirector:false,
            fastActor:false,
            fileList:[],//演员图片
            intervalBegin:'',
            intervalEnd:'',
            releaseDate:'',
            appointSaleDate:'',
            countryList:[],//发行国家
            timeYearList:[],//所属年份
            saveCount:0,
            saveVisible:false
        }

    }
    componentWillMount() {
        if (this.props.params != null) {
            this.state.isfalse = this.props.isfalse;
            this.getFilmDetails(this.props.params.id);
            this.getDirectorList()
           
        }
        this.searchEnum(); //发行国家
        this.searchEnumTime();//所属年份
        this.searchEnumE();
        this.searchEnumN();
        this.getActorList();
        this.queryList();
        this.showBtn(this.props.buttonList)//控制按钮显示

    }
    //控制按钮显示
    showBtn(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_save" && this.props.params != null) {
                this.state.btn_save = true
            }
            if (array[i].buttonCode == "btn_submit" && this.props.params != null) {
                this.state.btn_submit = true
            }
            if (array[i].buttonCode == "btn_update" && this.props.params != null) {
                this.state.btn_update = true
            }
            if (array[i].buttonCode == "btn_cancel" && this.props.params != null) {
                this.state.btn_cancel = true
            }

        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps != null) {
            if (nextProps.params != null) {
                this.state.isfalse = this.props.isfalse;
                this.showBtn(this.props.buttonList)
                this.state.btn_save = true;
                this.getDirectorList()
                this.getFilmDetails(nextProps.params.id);
                this.searchEnum(); //发行国家
                this.searchEnumTime();//所属年份
                this.state.esfalse = false
            } else {
                this.state.isfalse = null
                this.setState({
                    params: {}
                });
            }

        } else {
            this.state.isfalse = null
            this.setState({
                params: {}
            });
        }
    }
    //筛选影片条件：发行国家
  searchEnum(){
    let array=[];
    let content = {type:"2"}
      fetch('/api/common/searchEnum',{
        method: 'post',
        credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(content),
        }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
          return res.json();
        }).then(function (data) {
          if (data.code == 0) {
            array=data.result;    
          }
        }).then( (e) => {
          this.setState({countryList:array})
      });
}
searchEnumTime(){
    let array=[];
    let content = {type:"3"}
      fetch('/api/common/searchEnum',{
        method: 'post',
        credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(content),
        }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
          return res.json();
        }).then(function (data) {
          if (data.code == 0) {
            array=data.result;    
          }
        }).then( (e) => {
          this.setState({timeYearList:array})
      });
}
searchEnumE(){
    let array=[];
    let content = {type:"8"}
      fetch('/api/common/searchEnum',{
        method: 'post',
        credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(content),
        }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
          return res.json();
        }).then(function (data) {
          if (data.code == 0) {
            array=data.result;    
          }
        }).then( (e) => {
          this.setState({haveDcpSaleDayNum:array})
      });
}
searchEnumN(){
    let array=[];
    let content = {type:"9"}
      fetch('/api/common/searchEnum',{
        method: 'post',
        credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(content),
        }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
          return res.json();
        }).then(function (data) {
          if (data.code == 0) {
            array=data.result;    
          }
        }).then( (e) => {
          this.setState({notDcpSaleDayNum:array})
      });
}
    //导演的数据接口
    getDirectorList() { 
        let array = [];
        fetch('/api/system/directorList', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({}),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
            }
        }).then((e) => {
            this.state.directorList = array;
            
            this.setState({directorList:this.state.directorList});
        });
    }
    //查询影片详情
    getFilmDetails(id) {
        this.setState({ loading: true });
        let content = { id: id }
        let params = {};
        fetch('/api/cinema/film/details', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                params = data.result;
              
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.fileLista = [];
            this.state.fileLists = [];
            this.state.params = params;
            this.state.intervalBegin= params.intervalBegin==null?null:moment(params.intervalBegin).format('YYYY-MM-DD');
            this.state.intervalEnd= params.intervalEnd==null?null:moment(params.intervalEnd).format('YYYY-MM-DD');
            this.state.releaseDate= params.releaseDate==null?null:moment(params.releaseDate).format('YYYY-MM-DD');
            this.state.appointSaleDate= params.appointSaleDate==null?null:moment(params.appointSaleDate).format('YYYY-MM-DD');
            this.state.params.actorList = params.actorList;
            this.state.settlementMethod = params.settlementMethod
            this.state.settlementPrice = params.settlementPrice;
            this.state.settlementCycle = params.settlementCycle;
            this.state.proportion = params.proportion;
            //海报字段转义
            for (var i = 0; i < params.imagesList.length; i++) {
                if (params.imagesList[i].fileType == 2) {
                    params.imagesList[i].uid = params.imagesList[i].id;
                    params.imagesList[i].url = params.imagesList[i].relativePath;
                    this.state.fileLists.push(params.imagesList[i]);
                } else {
                    params.imagesList[i].uid = params.imagesList[i].id;
                    params.imagesList[i].url = params.imagesList[i].relativePath;
                    this.state.fileLista.push(params.imagesList[i]);
                }

            }
            //所属分类数据整理
            this.state.filmClassList = [];
            if (this.state.params.filmClass != null) {
                var filmClassArr = this.state.params.filmClass.split(",");
                for (var i = 0; i < filmClassArr.length; i++) {
                    if (filmClassArr[i] != "") {
                        this.state.filmClassList.push({ id: filmClassArr[i] })
                    }
                }
            }
            this.state.loading = false;
            this.setState({ fileLista: this.state.fileLista, fileLists: this.state.fileLists,releaseDate:this.state.releaseDate,intervalBegin:this.state.intervalBegin,intervalEnd:this.state.intervalEnd,appointSaleDate:this.state.appointSaleDate });
        });
    }

    cencel(type) {
        //修改还是取消
        if (type == 'write') {
            this.setState({
                isfalse: false,
                esfalse: true
            });
        } else {
            this.setState({
                isfalse: true,
                esfalse: false,
            });
        }
    }

    //获取选中值
    onSelectChange(selectedRowKeys, selectedRow) {
        this.state.selectedRow = selectedRow
        this.setState({ selectedRowKeys });
    };
    //翻页
    pageChange(page, pageSize) {
        page = (page - 1) * pageSize
        this.state.page = page;
        this.getActorList();
    }
    //获取全部演员
    getActorList() {
        let array = [] 
        let total = 0;
        let content = {
            start: this.state.page, limit: this.state.pageSize,
            name: this.state.actorName
        };
        this.setState({ loading: true });
        fetch('/api/system/actorList', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.actorArray = array;
            this.state.total = total;
            this.state.selectedRowKeys = [];
            this.setState({ loading: false });
        });
    }

    toData() {
        var arr = this.state.filmClassList
        var idStr = ","
        var noteStr = ""
        for (var i = 0; i < arr.length; i++) {
            idStr += arr[i].id + ","
            noteStr += arr[i].note + "/"
        }
        noteStr = noteStr.substring(0, noteStr.length - 1);
        this.state.params.filmClass = idStr;
        this.state.params.filmClassName = noteStr;

        //重新组合海报数组
        var list = [];
        for (var i = 0; i < this.state.fileLista.length; i++) {//海报
            if (this.state.fileLista[i].relativePath != undefined) {//接口返回的图片地址
                list.push({ relativePath: this.state.fileLista[i].relativePath, fileType: this.state.fileLista[i].fileType })
            } else {
                list.push({ relativePath: this.state.fileLista[i].response.result, fileType: this.state.fileLista[i].fileType })
            }
        }

        for (var i = 0; i < this.state.fileLists.length; i++) {//片花
            if (this.state.fileLists[i].relativePath != undefined) {//接口返回的图片地址
                list.push({ relativePath: this.state.fileLists[i].relativePath, fileType: this.state.fileLists[i].fileType })
            } else {
                list.push({ relativePath: this.state.fileLists[i].response.result, fileType: this.state.fileLists[i].fileType })
            }
        }
        this.state.params.imagesList = list
        // this.setState({imagesList : list})
        //组合影片分类
        var str = ""
        var strName = ""
        for (var i = 0; i < this.state.filmClassList.length; i++) {
            str += this.state.filmClassList[i].id + ","
            for (var x = 0; x < this.props.enumArray.length; x++) {
                if (this.state.filmClassList[i].id == this.props.enumArray[x].enumValue) {
                    strName += this.props.enumArray[x].enumName + "/"
                }
            }
        }
        strName = strName.substring(0, strName.length - 1);
        this.state.params.filmClass = str;
        this.state.params.filmClassName = strName;
        //日期处理
        this.state.params.releaseDate=this.state.releaseDate==null||this.state.releaseDate==''?null:moment(this.state.releaseDate).format('YYYY-MM-DD')+' 00:00:00';
        this.state.params.appointSaleDate=this.state.appointSaleDate==null||this.state.appointSaleDate==''?null:moment(this.state.appointSaleDate).format('YYYY-MM-DD')+' 00:00:00';
        this.state.params.intervalEnd=this.state.intervalEnd==null||this.state.intervalEnd==''?null:moment(this.state.intervalEnd).format('YYYY-MM-DD')+' 23:59:59';
        this.state.params.intervalBegin=this.state.intervalBegin==null||this.state.intervalBegin==''?null:moment(this.state.intervalBegin).format('YYYY-MM-DD')+' 00:00:00';
    }
    save(){
       const {saveCount,params} = this.state;
       if(params.editStatus=='1'){
        if(saveCount==1){
            this.setState({saveVisible:true});
           }else{
            this.setState({saveVisible:false});
            this.saveOk();
           }
       }else{
        this.saveOk();
       }
     
    }
    //保存的取消
    saveCancel(){
        this.setState({saveVisible:false})
    }
    //保存   
    saveOk() {
        this.toData();
        this.setState({ loading: true });
        fetch('/api/cinema/film/saveFilm', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.params),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then((data) => {
            if (data.code == 0) {
                message.success("保存成功！");
                this.setState({ loading: false, isfalse: true, esfalse: false,saveVisible:false,saveCount:0});
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.setState({ loading: false});
        });
    }

    //提交
    submit() {
        this.toData();
        if (
            this.state.params.filmName == null || this.state.params.filmName == "" ||
            this.state.params.filmLength == null || this.state.params.filmLength == "" ||
            this.state.params.heatRate == null || this.state.params.heatRate == "" ||
            this.state.params.rank == null || this.state.params.rank == "" ||
            // this.state.params.price == null || this.state.params.price == "" ||
            this.state.params.filmType == null || this.state.params.filmType == "" ||
            this.state.params.filmSubtitles == null || this.state.params.filmSubtitles == "" ||
            this.state.params.company == null || this.state.params.company == "" ||
            this.state.params.country == null || this.state.params.country == "" ||
            this.state.params.filmYear == null || this.state.params.filmYear == "" ||
            // this.state.params.isSchedule == null ||this.state.params.isSchedule == "" ||
            this.state.intervalBegin == null || this.state.intervalBegin == "" ||
            this.state.intervalEnd == null || this.state.intervalEnd == "" ||
            this.state.params.directorId == null || this.state.params.directorId == "" ||
            this.state.params.filmClassName == null || this.state.params.filmClassName == "" ||
            this.state.params.description == null || this.state.params.description == "" ||
            this.state.releaseDate == null || this.state.releaseDate == "" ||
            this.state.appointSaleDate == null || this.state.appointSaleDate == ""||
            this.state.haveDcpSaleDayNum == null || this.state.haveDcpSaleDayNum == ""||
            this.state.notDcpSaleDayNum == null || this.state.notDcpSaleDayNum == ""
            //|| this.state.params.monthPrice == null || this.state.params.monthPrice == ""
        ) {
            message.error("存在必填项未填写，请核实！");
            return;
        }
        this.setState({ loading: true });
        fetch('/api/cinema/film/submit', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.params),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then( (data)=> {
            if (data.code == 0) {
                message.success("提交成功！");
                this.setState({saveCount:1});
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.setState({ loading: false, isfalse: true, esfalse: false });
        });
    }
    //选择日期
    dateChang(type, date, dateString) {
        let {intervalBegin,intervalEnd,releaseDate,appointSaleDate} = this.state;
        if (type == "intervalBegin") {
            intervalBegin = date ;
        }
        if (type == "intervalEnd") {
            intervalEnd = date ;
        }
        if (type == "releaseDate") {
            releaseDate = date;
        }
        if (type=="appointSaleDate"){
            appointSaleDate=date;
        }
        this.setState({ intervalBegin,intervalEnd ,releaseDate,appointSaleDate})
    }
    // selectChange(type, e) {
    //     if (type == "directorId") {
    //         this.state.params.directorId = e;
    //     }
    // }
    //修改input框的值
    valueChange(type, e) {
        let params = this.state.params
        if (type == "filmName") {
            params.filmName = e.target.value;
        }
        if (type == "filmDownloadName") {
            params.filmDownloadName = e.target.value;
        }
        if (type == "filmLength") {
            params.filmLength = e.target.value;
        }
        if (type == "heatRate") {
            params.heatRate = e.target.value;
        }
        if (type == "rank") {
            params.rank = e.target.value;
        }
        // if (type == "price") {
        //     params.price = e.target.value;
        // }
        if (type == "filmType") {
            params.filmType = e;
        }
        if (type == "filmSubtitles") {
            params.filmSubtitles = e;
        }
        if (type == "country") {
            params.country = e;
        }
        if (type == "filmYear") {
            params.filmYear = e;
        }
        if (type == "isSchedule") {
            params.isSchedule = e;
        }
        if (type == "directorId") {
            params.directorId = e;
        }
        if (type == "description") {
            params.description = e.target.value;
        }
        // if (type == "monthPrice") {
        //     params.monthPrice = e.target.value;
        // }
        if (type == "settlementMethod") {
            params.settlementMethod = e;
            if (this.state.settlementMethod != params.settlementMethod) {
                params.settlementPrice = '';
                params.settlementCycle = '';
                params.proportion = '';
            } else {
                params.settlementPrice = this.state.settlementPrice;
                params.settlementCycle = this.state.settlementCycle;
                params.proportion = this.state.proportion;
            }
        }
        if (type == "settlementPrice") {
            params.settlementPrice = e;
        }
        if (type == "settlementCycle") {
            params.settlementCycle = e;
        }
        if (type == "proportion") {
            params.proportion = e;
        }
        if (type=="haveDcpSaleDayNum"){
            params.haveDcpSaleDayNum=e;
        }
        if (type=="notDcpSaleDayNum"){
            params.notDcpSaleDayNum=e;
        }
        this.setState({ params })
    }
    //关于选择演员的js
    actorNameChange(e) {
        this.state.actorName = e.target.value
        this.getActorList();
    }
    addActor() {
        this.state.selectedRowKeys = [];
        this.setState({ visible: true })
    }
    deleteActor(index) {
        this.state.params.actorList.splice(index, 1);
        this.setState({ actorList: this.state.params.actorList });
    }
    //确定选择演员
    handleOk() {
        var selectArray = this.state.selectedRow
        for (var i = 0; i < selectArray.length; i++) {
            selectArray[i].postFile = selectArray[i].profilePictureFile
            selectArray[i].actorName = selectArray[i].name
            selectArray[i].actorId = selectArray[i].id
            this.state.params.actorList.push(selectArray[i]);
        }
        this.setState({ visible: false })
    }
    actorCancel() {
        this.setState({ visible: false })
    }
    actorValueChange(item, e) {
        item.roleName = e.target.value
        this.setState({ item })
    }
    //关于上传的js
    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };
    //上传图片时的操作
    imgChange = ({ fileList, index, file }) => {
        this.setState({ fileLista: fileList });
    }
    imgChanges = ({ fileList, index, file }) => {
        for (var i = 0; i < fileList.length; i++) {
            if (fileList[i].status == "done") {
                fileList[i].fileType = 2
            }
        }
        this.setState({ fileLists: fileList });
    }
    imgChangeActor=({ fileList })=>{
       if(fileList[0].status == "done"){
        this.state.profilePictureFile=fileList[0].response.result;
       }
        this.setState({fileList, profilePictureFile:this.state.profilePictureFile});
    }
    getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    //所属分类
    checkBoxChange(type, event) {
        if (event.target.checked) {
            this.state.filmClassList.push({ id: type })
        } else {
            for (var i = 0; i < this.state.filmClassList.length; i++) {
                if (type == this.state.filmClassList[i].id) {
                    this.state.filmClassList.splice(i, 1)
                }
            }
        }

    }
    //发布公司
    selectChange(type, e, obj) {
        let params = this.state.params
        if (type == "company") {
            params.issuerId = e
            params.company = obj.props.children
        }
        this.setState({
            params
        })
    }
    //导演筛选
    handleSearch = value => {
        var directorList = [];
        console.log(this.state.directorList)
        for (var i = 0; i < this.state.directorList.length; i++) {
            if (this.state.directorList[i].name.indexOf(value) != -1) {
                directorList.push(this.state.directorList[i])
            }
        }
        this.setState({ directorList: directorList })
    }
    //发布公司
    queryList() {
        let array = []
        this.setState({ loading: true });
        fetch('/api/issue/queryIssueList', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({}),
        }).then(function (res) {
            if (!res.ok) {
                message.error("网络不可用！");
            }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.companylist = array;
            this.setState({ loading: false });
        });
    }
    //增加导演
    fastAdd(){
        this.setState({fastdirector:true});
    }
    //导演信息的填写
    handleChange(type,e) {
        let {params}=this.state;
        if(type == "name"){
            params.name = e.target.value;
        }
        if(type == "dirdescription"){
            this.state.dirdescription = e.target.value;
        }
        if(type == "sex"){
            params.sex = e;
          }
        this.setState({params,dirdescription:this.state.dirdescription});
    }
    //导演的确定
    dirSave(){
        let {params}=this.state;
        if(params.name == "" ||params.name == undefined || params.sex== undefined|| params.sex== ""){
            message.error("存在必填项未填写，请核实！")
            return;
        };
        let content={name:params.name,sex:params.sex,description:this.state.dirdescription}
        this.setState({ loading: true });
            fetch('/api/system/saveDirector',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
                }).then(function (res) {            
                     if(!res.ok){                
                          message.error("网络不可用！");             
                        }
                        return res.json();
                }).then( (data)=> {
                    if (data.code == 0) {
                        message.success("保存成功！");
                        this.getDirectorList();
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    let {params}=this.state;
                    params.name='';
                    params.sex='';
                    this.state.dirdescription='';
                    this.setState({ loading: false,params,dirdescription:this.state.dirdescription,fastdirector:false});
                });
    }
    //导演的取消
    fastDirCencal(){
        let {params,fastdirector}=this.state;
        params.name='';
        params.sex='';
        params.description='';
        this.setState({ loading: false,params,fastdirector:false});  
    }
    //增加演员
    fastAddActor(){
        this.setState({fastActor:true});
    }
    //演员信息填写
    handlesChange(type,e) {
        let {actorName,actorSex,actordescription}=this.state;
        if(type == "actorName"){
            actorName = e.target.value;
        }
        if(type == "actordescription"){
           actordescription = e.target.value;
        }
        if(type == "actorSex"){
            actorSex = e;
          }
        this.setState({actorName,actorSex,actordescription});
    }
    //演员的确定
    actorSave(){
        let {actorName,actorSex,actordescription,profilePictureFile}=this.state;
        if(actorName == "" ||actorName == undefined || actorSex== undefined|| actorSex== ""){
            message.error("存在必填项未填写，请核实！")
            return;
        };
        let content={name:actorName,sex:actorSex,description:actordescription,profilePictureFile}
        this.setState({ loading: true });
            fetch('/api/system/saveActor',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(content),
                }).then(function (res) {            
                     if(!res.ok){                
                          message.error("网络不可用！");             
                        }
                        return res.json();
                }).then( (data)=> {
                    if (data.code == 0) {
                        message.success("保存成功！");
                        this.getActorList();
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.setState({loading: false,actordescription:'',fastActor:false,actorSex:'',actorName:'',fileList:[]});
                });
    }
    //演员的取消
    fastActorCencal(){
       this.setState({fastActor:false,loading: false,actordescription:'',actorSex:'',actorName:'',fileList:[]});
    }
    //抓取影片信息
    getFilmInfo(){
        let content={filmName:this.props.params.filmName,id:this.props.params.id}
        this.setState({ loading: true });
        fetch('/api/cinema/film/getFilmInfo',{
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
            }).then(function (res) {            
                 if(!res.ok){                
                      message.error("网络不可用！");             
                    }
                    return res.json();
            }).then( (data)=> {
                if (data.code == 0) {
                this.state.params=data.result;
                this.state.intervalBegin=data.result.intervalBegin==null||data.result.intervalBegin==''?null:moment(data.result.intervalBegin).format('YYYY-MM-DD');
                this.state.intervalEnd=data.result.intervalEnd==null||data.result.intervalEnd==''?null:moment(data.result.intervalEnd).format('YYYY-MM-DD');
                this.state.releaseDate=data.result.releaseDate==null||data.result.releaseDate==''?null:moment(data.result.releaseDate).format('YYYY-MM-DD');
                this.state.appointSaleDate=data.result.appointSaleDate==null||data.result.appointSaleDate==''?null:moment(data.result.appointSaleDate).format('YYYY-MM-DD');
                this.state.params.actorList = data.result.actorList;
                this.state.settlementMethod = data.result.settlementMethod;
                this.state.settlementPrice = data.result.settlementPrice;
                this.state.settlementCycle = data.result.settlementCycle;
                this.state.proportion = data.result.proportion;
                //海报字段转义
                for (var i = 0; i < this.state.params.imagesList.length; i++) {
                    if (this.state.params.imagesList[i].fileType == 2) {
                        this.state.params.imagesList[i].uid = this.state.params.imagesList[i].id;
                        this.state.params.imagesList[i].url = this.state.params.imagesList[i].relativePath;
                        this.state.fileLists.push(this.state.params.imagesList[i]);
                    } else {
                        this.state.params.imagesList[i].uid = this.state.params.imagesList[i].id;
                        this.state.params.imagesList[i].url = this.state.params.imagesList[i].relativePath;
                        this.state.fileLista.push(this.state.params.imagesList[i]);
                    }
    
                }
                //所属分类数据整理
                this.state.filmClassList = [];
                if (this.state.params.filmClass != null) {
                    var filmClassArr = this.state.params.filmClass.split(",");
                    for (var i = 0; i < filmClassArr.length; i++) {
                        if (filmClassArr[i] != "") {
                            this.state.filmClassList.push({ id: filmClassArr[i] })
                        }
                    }
                }
    
                this.setState({params:this.state.params});
                }else  {
                    message.error(data.message);
                        if(data.code == 2003){
                            history.push({pathname:'/'});
                    }
                }
            }).then( (e) => {
              this.setState({loading:false})
            });
    }
    render() {
        // const { getFieldDecorator } = this.props.form;
        const columns = [
            {
                title: '序号',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '演员姓名',
                dataIndex: 'name',
                key: 'name'
            },
            {
                title: '性别',
                dataIndex: 'sex',
                key: 'sex',
                render: (text, record) => text == '0' ? "男" : "女",

            }

        ];

        const dataSource = this.state.actorArray;
        const { selectedRowKeys } = this.state;
        const { selectedRow } = this.state;
        const rowSelection = {
            selectedRowKeys,
            selectedRow,
            onChange: this.onSelectChange.bind(this),//获取选中值
        };

        const { previewVisible, previewImage, fileLista, fileLists } = this.state;


        const uploadButton = (
            <div style={{ height: "87.99px", width: "103.99" }}>
                <Icon type="plus" />
                <div className="ant-upload-text">上传图片</div>
            </div>
        );

        const uploadButtons = (
            <div style={{ height: "87.99px", width: "103.99" }}>
                <Icon type="plus" />
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        const uploadButtonActor= (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">上传图片</div>
            </div>
        );
        let { isfalse, esfalse, params,fileList,saveVisible } = this.state;
        return (
            // <Form onSubmit={this.submit.bind(this)} className="login-form">
            <div className="editDetails" key={(this.state.params != null && this.state.params.id == '-1') ? this.state.params.id : this.state.params.id}>
                <Spin spinning={this.state.loading} tip="Loading...">
                    <Input hidden={true} defaultValue={this.state.params.id} />
                    <div className="gutter-example">
                        <Row gutter={16}>
                            <div style={{ position: 'fixed', top: '200px', zIndex: '999', left: ' 64.5%' }}>
                                <Button  onClick={this.getFilmInfo.bind(this)}>抓取影片信息</Button>
                                <Button type="primary" style={{ display: this.state.btn_save == true ? "inline-block" : "none", marginLeft: 25 }} disabled={isfalse} onClick={this.save.bind(this)}>保存</Button>
                                <Button style={{ display: this.state.btn_update == true ? "inline-block" : "none", marginLeft: 20 }} onClick={this.cencel.bind(this, 'write')} disabled={esfalse}>修改</Button>
                                <Button style={{ display: this.state.btn_cancel == true ? "inline-block" : "none", marginLeft: 20 }} onClick={this.cencel.bind(this)} disabled={isfalse}>取消</Button>
                                <Button style={{ marginLeft: 20 }} onClick={this.props.callback.bind(this, "1", null, null)}>返回</Button>
                                <Button type="primary" onClick={this.submit.bind(this)} style={{ display: this.state.btn_submit == true ? "inline-block" : "none", marginLeft: 20 }} disabled={isfalse}>提交</Button>
                            </div>
                        </Row>
                        <Row gutter={16} style={{ marginTop: '5px' }}>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">影片名称:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Input required={true} style={{marginLeft: 5 }} disabled={isfalse} placeholder="请输入影片名称" className="editInput" value={this.state.params.filmName} onChange={this.valueChange.bind(this, "filmName")} />
                                </div>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">DCP名称: 
                                    <Input disabled={isfalse} style={{marginLeft: 13 }} required={true} placeholder="请输入DCP名称" className="editInput" value={this.state.params.filmDownloadName} onChange={this.valueChange.bind(this, "filmDownloadName")} />
                                </div>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">影片时长:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Input disabled={isfalse} style={{marginLeft: 5 }}  required={true} placeholder="请输入影片时长" className="editInput" value={this.state.params.filmLength} onChange={this.valueChange.bind(this, "filmLength")} />
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">影片热度:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Input disabled={isfalse} required={true} placeholder="1342" className="nmbInput" value={this.state.params.heatRate} onChange={this.valueChange.bind(this, "heatRate")} />
                                </div>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">影片评分:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Input disabled={isfalse}  required={true} placeholder="例：9.2" className="nmbInput" value={this.state.params.rank} onChange={this.valueChange.bind(this, "rank")} />
                                </div>
                            </Col>
                            {/* <Col className="gutter-row" span={7}>
                                <div className="gutter-box">影片价格:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Input disabled={isfalse} style={{ width: 237.48 }} required={true} placeholder="例：9.2" className="nmbInput" value={this.state.params.price} onChange={this.valueChange.bind(this, "price")} />
                                </div>
                            </Col> */}

                        </Row>
                        <Row>
                            {/* <Col className="gutter-row" span={7}>
                                <div className="gutter-box">包月影片价格:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Input disabled={isfalse} style={{ width: 237.48 }} required={true} placeholder="例：9" className="nmbInput" value={this.state.params.monthPrice} onChange={this.valueChange.bind(this, "monthPrice")} />
                                </div>
                            </Col> */}
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">影片类型:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Select disabled={isfalse} required style={{ width: "58%", marginLeft: 5 }} value={this.state.params.filmType} onChange={this.valueChange.bind(this, "filmType")}>
                                        <Option value="2D">2D</Option>
                                        <Option value="3D">3D</Option>
                                    </Select>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">字幕类型:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Select disabled={isfalse} style={{ width: "58%", marginLeft: 5 }} value={this.state.params.filmSubtitles} onChange={this.valueChange.bind(this, "filmSubtitles")}>
                                        <Option value="CN">CN</Option>
                                        <Option value="EN">EN</Option>
                                    </Select>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">发行公司:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Select disabled={isfalse} style={{ width: "58%", marginLeft: 5 }} value={this.state.params.company} onChange={this.selectChange.bind(this, "company")}>
                                        {
                                            this.state.companylist.length==0?null:this.state.companylist.map((item, index) => {
                                                return <Option value={item ? item.id : ""}>{item ? item.name : ""}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">发行国家:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Select disabled={isfalse} style={{ width: "58%", marginLeft: 5 }} value={this.state.params.country} onChange={this.valueChange.bind(this, "country")}>
                                    {
                                           this.state.countryList.lenght==0?null:this.state.countryList.map((item,index)=>{
                                               return <Option value={item.enumName}>{item?item.enumName:""}</Option>
                                           })
                                       }
                                    </Select>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">所属年份:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Select disabled={isfalse} style={{ width: "58%", marginLeft: 5 }} value={this.state.params.filmYear} onChange={this.valueChange.bind(this, "filmYear")}>
                                    {
                                            this.state.timeYearList.length==0?null:this.state.timeYearList.map((item,index)=>{
                                                return <Option value={item.enumName}>{item?item.enumName:''}</Option>
                                            })
                                        }
                                    </Select>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">所属档期:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Select disabled={isfalse} style={{ width: "58%", marginLeft: 5 }} value={this.state.params.isSchedule} onChange={this.valueChange.bind(this, "isSchedule")}>
                                        <Option value={0}>二轮片</Option>
                                        <Option value={1}>首轮片</Option>
                                    </Select>
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col className="gutter-row" span={11}>
                                <div className="gutter-box">版权周期:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <DatePicker disabled={isfalse} style={{ width: "208px",marginLeft:5 }} value={this.state.intervalBegin==null||this.state.intervalBegin==''?null:moment(this.state.intervalBegin)} format={dateFormat} onChange={this.dateChang.bind(this, "intervalBegin")} /> —
                                    <DatePicker disabled={isfalse} style={{ width: "208px",marginLeft:5 }} required={true} value={this.state.intervalEnd==null||this.state.intervalEnd==''?null:moment(this.state.intervalEnd)} format={dateFormat} onChange={this.dateChang.bind(this, "intervalEnd")} />
                                </div>
                            </Col>   
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                                <div className="gutter-box">售卖日期:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                <DatePicker disabled={isfalse} style={{ width: "208px",marginLeft:5}} required={true} value={this.state.appointSaleDate==null||this.state.appointSaleDate==''?null:moment(this.state.appointSaleDate)} format={dateFormat} onChange={this.dateChang.bind(this, "appointSaleDate")} />
                                </div>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <div className="gutter-box">有DCP可售卖间隔天数:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Select showSearch 
                                        disabled={isfalse}
                                        style={{ width: "58%", marginLeft: 5 }}
                                        value={this.state.params.haveDcpSaleDayNum}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={this.valueChange.bind(this, "haveDcpSaleDayNum")}>
                                        {this.state.haveDcpSaleDayNum&&this.state.haveDcpSaleDayNum.map((item, index) => {
                                            return (
                                                <Option value={item.enumValue}>{item.enumName}</Option>
                                            )
                                        })
                                        }
                                    </Select>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <div className="gutter-box">无DCP可售卖间隔天数:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Select showSearch 
                                        disabled={isfalse}
                                        style={{ width: "58%", marginLeft: 5 }}
                                        value={this.state.params.notDcpSaleDayNum}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={this.valueChange.bind(this, "notDcpSaleDayNum")}>
                                        {this.state.notDcpSaleDayNum&&this.state.notDcpSaleDayNum.map((item, index) => {
                                            return (
                                                <Option value={item.enumValue}>{item.enumName}</Option>
                                            )
                                        })
                                        }
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">上映日期:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <DatePicker disabled={isfalse} style={{ width: "208px",marginLeft:5}} required={true} value={this.state.releaseDate==null||this.state.releaseDate==''?null:moment(this.state.releaseDate)} format={dateFormat} onChange={this.dateChang.bind(this, "releaseDate")} /></div>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={7}>
                                <div className="gutter-box">影片导演:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <Select showSearch 
                                        disabled={isfalse}
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        style={{ width: "58%", marginLeft: 5 }}
                                        value={this.state.params.directorId}
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        onChange={this.valueChange.bind(this, "directorId")}>
                                        {this.state.directorList.map((item, index) => {
                                            return (
                                                <Option value={item.id}>{item.name}</Option>
                                            )
                                        })
                                        }
                                    </Select>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={2} style={{marginTop:'20px'}}>
                                <Button disabled={isfalse} onClick={this.fastAdd.bind(this)}>新增导演</Button>
                            </Col>
                            <Col className="gutter-row" span={14}>
                                <div className="gutter-box"> 所属分类:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    {this.props.enumArray == undefined ? [] : this.props.enumArray.map((item, index) => {
                                        return (
                                            <Checkbox disabled={isfalse} required={true} defaultChecked={this.state.params.filmClassName != undefined && this.state.params.filmClassName.indexOf(item.enumName) != -1}
                                                onChange={this.checkBoxChange.bind(this, item.enumValue)}>{item.enumName}</Checkbox>
                                        )
                                    })
                                    }
                                </div>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col className="gutter-row" span={10}>
                                <div className="gutter-box">影片简介:<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                                    <TextArea disabled={isfalse} required={true} rows={4} placeholder="请输入影片简介" value={this.state.params.description} onChange={this.valueChange.bind(this, "description")} />
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={10}>
                                <div className="gutter-box">方式结算:
                                    <Select
                                        disabled={isfalse}
                                        style={{ width: "208px", marginLeft: 5 }}
                                        value={params.settlementMethod == "1" ? "买断" : params.settlementMethod == "2" ? "分账" : params.settlementMethod == "3" ? "保定+分账" : ''}
                                        onChange={this.valueChange.bind(this, "settlementMethod")}
                                    >
                                        <Option value={1}>买断</Option>
                                        <Option value={2}>分账</Option>
                                        <Option value={3}>保底+分账</Option>
                                    </Select>
                                </div>
                            </Col>
                        </Row>
                        <div style={{ width: '100%', height: '130px', border: '1px solid #ccc', marginTop: '10px' }}>
                            {
                                params.settlementMethod == "1" ? <Row style={{ margin: '10px' }}> <Col>买断价格：<InputNumber disabled={isfalse} style={{ width: '155px' }} onChange={this.valueChange.bind(this, 'settlementPrice')} value={params.settlementPrice}></InputNumber>元</Col></Row>

                                    : params.settlementMethod == "2" ? <> <Row style={{ margin: '45px 20px' }}>
                                        <Col span={6}><span style={{ display: 'inline-block', width: '130px' }}> 发行方分账比例：</span><InputNumber disabled={isfalse} min={1} max={99} style={{ width: '100px' }} onChange={this.valueChange.bind(this, 'proportion')} value={params.proportion}></InputNumber>%</Col>
                                        <Col span={6}><span style={{ display: 'inline-block', marginTop: '5px' }}>其他方分账比例:</span><InputNumber disabled={true} value={100 - params.proportion} style={{ width: '100px' }}></InputNumber>%</Col></Row>
                                    </>

                                        : params.settlementMethod == "3" ? <><Row style={{ margin: '10px' }}> <Col>保底价格：<InputNumber disabled={isfalse} style={{ width: '155px' }} onChange={this.valueChange.bind(this, 'settlementPrice')} value={params.settlementPrice}></InputNumber>元</Col></Row>
                                            <Row style={{ margin: '10px' }}> <Col span={6}><span style={{ display: 'inline-block', width: '130px' }}> 发行方分账比例：</span><InputNumber disabled={isfalse} min={1} max={99} style={{ width: '100px' }} onChange={this.valueChange.bind(this, 'proportion')} value={params.proportion}></InputNumber>%</Col>
                                                <Col span={6}><span style={{ display: 'inline-block', marginTop: '5px' }}>其他方分账比例：</span><InputNumber disabled={true} value={100 - params.proportion} style={{ width: '100px' }}></InputNumber>%</Col></Row>
                                            <Row style={{ marginLeft: '10px' }}><Col><span style={{ display: 'inline-block', width: '130px' }}>结算周期：</span><InputNumber disabled={isfalse} style={{ width: '100px' }} onChange={this.valueChange.bind(this, 'settlementCycle')} value={params.settlementCycle}></InputNumber>月</Col></Row></> : ''
                            }

                        </div>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={30}>
                                <div className="gutter-box">主演信息：<Icon onClick={this.addActor.bind(this)} style={{ cursor: "pointer" }} type="plus-circle" theme="twoTone" /></div>
                            </Col>
                            <div>
                                {this.state.params.actorList == undefined ? null : this.state.params.actorList.map((item, index) => {
                                    return (
                                        <div className="txClass">
                                            <img src={item.postFile} />
                                            <div>
                                                {item.actorName}
                                            </div>
                                            <div>
                                                饰
                                            </div>
                                            <div>
                                                <Input disabled={isfalse} placeholder="输入角色" value={item.roleName} onChange={this.actorValueChange.bind(this, item)} />
                                            </div>
                                            <div>
                                                <Icon onClick={this.deleteActor.bind(this, index)} style={{ cursor: "pointer" }} type="close-circle" theme="twoTone" />
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </Row>

                        <Row gutter={16}>
                            <Col className="gutter-row" span={30}>
                                <div className="gutter-box">海报信息<span style={{ color: "red" }}>(注：第一张为封面，后三张为海报)</span>：</div>
                            </Col>
                            <div style={{ marginLeft: 30, marginTop: 10 }}>
                                <Upload
                                    disabled={isfalse}
                                    action="/api/cinema/film/posterImage"//上传地址
                                    listType="picture-card"
                                    fileList={fileLista}
                                    onPreview={this.handlePreview}
                                    onChange={this.imgChange}
                                >
                                    {fileLista.length >= 4 ? null : uploadButton}
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </div>
                        </Row>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={30}>
                                <div className="gutter-box">片花信息<span style={{ color: "red" }}></span>：</div>
                            </Col>
                            <div style={{ marginLeft: 30, marginTop: 10 }}>
                                <Upload
                                    disabled={isfalse}
                                    action="/api/cinema/film/posterImage"//上传地址
                                    listType="picture-card"
                                    fileList={fileLists}
                                    onPreview={this.handlePreview}
                                    onChange={this.imgChanges}
                                >
                                    {fileLists.length >= 3 ? null : uploadButtons}
                                </Upload>
                                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </div>
                        </Row>

                    </div>
                </Spin>

                <Modal title="选择演员" okText="确认" cancelText="取消" visible={this.state.visible} onOk={this.handleOk.bind(this)} onCancel={this.actorCancel.bind(this)}>
                    <div class="toolbar-left">
                        <div class="form-main" style={{display:'flex'}}>
                            <input class="form-control" style={{ width: "50%", marginBottom: "20px",marginRight:'20px' }} onChange={this.actorNameChange.bind(this)} placeholder="请输入演员姓名" />
                            <Button onClick={this.fastAddActor.bind(this)}>新增演员</Button>
                        </div>
                        
                    </div>
                    <Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource} pagination={{ onChange: this.pageChange.bind(this), total: this.state.total }} />
                </Modal>
                 <Modal okText="确认" cancelText="取消" visible={this.state.fastdirector} onOk={this.dirSave.bind(this)} onCancel={this.fastDirCencal.bind(this)}>
                  <p>导演姓名:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                      <Input style={{width:"80%"}} disabled={isfalse} value={this.state.params.name} placeholder = "请输入导演姓名" onChange={this.handleChange.bind(this,"name")}/>
                    </p>
                    <p>性别：<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                        <Select disabled={isfalse} value={this.state.params.sex}  style={{width:"80%",marginLeft:'19px'}} onChange={this.handleChange.bind(this,"sex")}>
                            <Option value="">--请选择性别--</Option>
                            <Option value="0">男</Option>
                            <Option value="1">女</Option>
                        </Select>
                    </p>
                    <p>描述:
                        <Input style={{width:"80%",marginLeft:'38px'}} disabled={isfalse} value={this.state.dirdescription}  placeholder = "请输入导演描述" onChange={this.handleChange.bind(this,"dirdescription")}/>
                    </p>
                </Modal>
                <Modal okText="确认" cancelText="取消" visible={this.state.fastActor} onOk={this.actorSave.bind(this)} onCancel={this.fastActorCencal.bind(this)}>
                   <p>演员姓名:<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                      <Input style={{width:"80%"}} disabled={isfalse} value={this.state.actorName} placeholder = "请输入演员姓名" onChange={this.handlesChange.bind(this,"actorName")}/>
                    </p>
                    <p>性别：<span style={{color:"#b94a48",fontWeight:900}}>*</span>
                        <Select disabled={isfalse} value={this.state.actorSex}  style={{width:"80%",marginLeft:'19px'}} onChange={this.handlesChange.bind(this,"actorSex")}>
                            <Option value="">--请选择性别--</Option>
                            <Option value="0">男</Option>
                            <Option value="1">女</Option>
                        </Select>
                    </p>
                    <p>描述:
                        <Input style={{width:"80%",marginLeft:'38px'}} disabled={isfalse} value={this.state.actordescription}  placeholder = "请输入演员描述" onChange={this.handlesChange.bind(this,"actordescription")}/>
                    </p>
                    <div className="clearfix" style = {{marginLeft:30}}>
                        <Upload
                            action="/api/system/actorImage"//上传地址
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={this.handlePreview}
                            onChange={this.imgChangeActor}
                            >
                            {fileList.length >= 1 ? null : uploadButtonActor}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </div>
                </Modal>
                <Modal  title="是否再次保存" visible={saveVisible} okText="确认" cancelText="取消" onOk={this.saveOk.bind(this)} onCancel={this.saveCancel.bind(this)}>
                   <p>编目内容已提交,再次点击保存会取消编目完成状态,是否继续保存</p>         
                </Modal>
            </div>
            // </Form>  
        )
    }
}
// EditDetails =Form.create({ name: 'edit_form' })(EditDetails);
export default EditDetails;
import React from "react";
import { Table, Spin, Button, message, Select, Modal, DatePicker,Icon,Row,Col,Input } from "antd";
import history from "../history";

const { confirm } = Modal;
const { Option } = Select;
class OperationRecordList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      orderBy:'desc',
      startValue: null,//日期定义
      endValue: null,
      endOpen: false,

    };
  }

  onload(ty) {
      if(this.state.endOperateTime==" 23:59:59"){
        this.state.endOperateTime=''
      }
      if(this.state.startOperateTime== " 00:00:00"){
        this.state.startOperateTime=''
      }
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if (ty == "1") {
      this.state.start = 0
    } else {
      this.state.start = this.state.page
    }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      operate:this.state.operate,
      harddiskNumber: this.state.harddiskNumber, 
      sn: this.state.sn,
      orderBy:this.state.orderBy,
      endOperateTime: this.state.endOperateTime, 
      startOperateTime: this.state.startOperateTime,
    };
    // console.log(content)
    fetch("/api/harddiskOperate/queryHarddiskOperateList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.setState({ loading: false});
      });
  }
  componentWillMount() {
    this.onload();
    // this.showBtn(this.props.buttonList); //控制按钮显示
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true;
      }
      if (array[i].buttonCode == "btn_add") {
        this.state.btn_add = true;
      }
      if (array[i].buttonCode == "btn_delete") {
        this.state.btn_delete = true;
      }
    }
  }
  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }

  pageChange(page, pageSize) {
    //翻页
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
 //点击排序
  clickEvent(type){
  if(type=='0'){
    this.state.orderBy='desc'
  }else if(type=='1'){
    this.state.orderBy='asc'
  }
  this.setState({orderBy:this.state.orderBy})
  this.onload();
  }

  //查询的值
  valueChanges(type, e){
    if (type == "harddiskNumber") {
      this.state.harddiskNumber = e.target.value;
    }
    if (type == "sn") {
      this.state.sn = e.target.value;
    }
    this.setState({harddiskNumber: this.state.harddiskNumber,sn:this.state.sn})
  }
  //查询select值
  handleChange(type, value) {
    if (type == "operate") {
      this.state.operate = value;
    }
   this.setState({ 
    operate:this.state.operate,
    })
  }
  
   //日期代码
   disabledStartDate = startValue => {
    const { endValue } = this.state;
    if (!startValue || !endValue) {
        return false;
    }
    return startValue.valueOf() > endValue.valueOf();
};
disabledEndDate = endValue => {
    const { startValue } = this.state;
    if (!endValue || !startValue) {
        return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
};
onChange = (field, value) => {
    this.setState({
        [field]: value,
    });
};
handleStartOpenChange = open => {
    if (!open) {
        this.setState({ endOpen: true });
    }
};

handleEndOpenChange = open => {
    this.setState({ endOpen: open });
};
onStartChange = (value, vs) => {
    this.state.startOperateTime = vs +' 00:00:00'
    this.onChange('startValue', value);
};

onEndChange = (value, vs) => {
    this.state.endOperateTime = vs +' 23:59:59'
    this.onChange('endValue', value);
};
  render() {
    const columns = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "硬盘编号",
        dataIndex: "harddiskNumber",
        key: "harddiskNumber",
        
      },
      {
        title: "SN",
        dataIndex: "sn",
        key: "sn",
      },
      {
        title:'存储容量(TB)',
        dataIndex: "storageCapacity",
        key: "storageCapacity",
      },
      {
        title: "操作",
        dataIndex: "operate",
        key: "operate",
        render:(text,record)=>text=="0"?"新增":text=="1"?"修改":text=="2"?'入库':text=="3"?'出库':'删除'
      },
      {
        title:'操作人',
        dataIndex: "creator",
        key: "creator",
      },
      {
        title: <div style={{display:'flex',alignItems:'center'}}><span>操作时间</span><p style={{display:'flex',flexDirection:'column'}}><Icon type="caret-up" style={{marginTop:'12px'}} onClick={this.clickEvent.bind(this,'1')}></Icon><Icon type="caret-down" onClick={this.clickEvent.bind(this,'0')}></Icon></p></div>,
        dataIndex: "operateTime",
        key: "operateTime",
        render:(text)=>text?text.slice(0,10):''
      },
     
    ];
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this), //获取选中值
    };
    const { startValue, endValue, endOpen } = this.state;
    return (
      <div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div className="page-content-info">
            <div className="toolbar-group">
              <div className="toolbar-left">
                <div style={{ width: "100%", marginBottom: 5 }}>
                </div>
                <div className="form-main">
                  <input className="form-control" onChange={this.valueChanges.bind(this, "harddiskNumber")} placeholder="请输入硬盘编号" />
                </div>
                <div className="form-main">
                  <input className="form-control" onChange={this.valueChanges.bind(this, "sn")} placeholder="请输入SN号" />
                </div>
                <div className="form-main">
                <Select
                    // labelInValue 
                    style={{ width: 167, height: 50 }}
                    onChange={this.handleChange.bind(this, "operate")}
                    value={this.state.operate}
                    placeholder="请选择操作"
                  >
                    <Option value="">--最近操作--</Option>
                    <Option value="0">新增</Option>
                    <Option value="1">修改</Option>
                    <Option value="2">入库</Option>
                    <Option value="3">出库</Option>
                    <Option value="4">删除</Option>
                   
                  </Select>
                </div>
                <div className="form-main">
                     <DatePicker
                        style={{ width: '167px' }}
                        disabledDate={this.disabledStartDate}
                        format="YYYY-MM-DD"
                        value={startValue}
                        placeholder="请选择开始时间"
                        onChange={this.onStartChange}
                        onOpenChange={this.handleStartOpenChange}
                      />
                        -
                      <DatePicker
                        style={{ width: '167px' }}
                        disabledDate={this.disabledEndDate} 
                        format="YYYY-MM-DD"
                        value={endValue}
                        placeholder="请选择结束时间"
                        onChange={this.onEndChange}
                        open={endOpen}
                        onOpenChange={this.handleEndOpenChange}
                      />
                </div>
                <button
                  onClick={this.onload.bind(this,'1')}
                  className="btn btn-default btn-search"
                  type="button"
                >
                  检索
                </button>
              </div>

              <div className="toolbar-right" style={{ width: "100%" }}>
                <Table
                  rowKey={record=>record.id}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
         
        </Spin>
      </div>
    );
  }
}
export default OperationRecordList;

import React from 'react';
import { Spin, message, Table, Button, Input, Modal } from 'antd';
import history from '../../components/history';
const Search = Input.Search;

class FlimList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: [],
      enumArray: [],
      selectedRowKeys: [],
      selectedRow: [],
      page: 0,
      pageSize: 10,
      total: 0,
      display: "none",
      sort: "heat_rate",
      visible: false,
      type: "",
      start: '',
      countryList:[],//发行国家
      timeYearList:[],//所属年份
      visibles: false,
    }
  }
  componentWillMount() {
    this.searchEnum();//查询影片类型枚举值
    this.onload();//查询影片列表
    this.showBtn(this.props.buttonList)//控制按钮显示
    this.searchEnums(); //发行国家
    this.searchEnumTime();//所属年份
  }
  //控制按钮显示
  showBtn(array) {
    console.log(array);
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true
      }
      if (array[i].buttonCode == "btn_release") {
        this.state.btn_release = true
      }
      if (array[i].buttonCode == "btn_cancel_release") {
        this.state.btn_cancel_release = true
      }
      if (array[i].buttonCode == "btn_add") {
        this.state.btn_add = true
      }
      if (array[i].buttonCode == "btn_edit") {
        this.state.btn_edit = true
      }
      if(array[i].buttonCode=='btn_synchronized'){
        this.state.btn_synchronized=true
      }
    }
  }
  active(params) {//排序
    this.state.sort = params;
    this.onload();
  }
  screen(condition, obj, e) {//筛选
    this.state.page = 0;
    var filmClassArray = e.target.parentNode.children
    for (var i = 1; i < filmClassArray.length; i++) {
      filmClassArray[i].className = "screening-world"
    }
    e.target.className += " active"
    if (condition == "filmClass") {//全部类型
      if (obj == "") {
        this.state.filmClass = obj
      } else {
        this.state.filmClass = "," + obj.enumValue + ","
      }
    }
    if (condition == "country") {//全部地区
      this.state.country = obj
    }
    if (condition == "isSchedule") {//是否首轮片
      this.state.isSchedule = obj
    }
    if (condition == "year") {//影片年份
      this.state.filmYear = obj
    }
    if (condition == "release") {//是否发布
      this.state.release = obj;
      this.state.isFake='0';
    }
    if (condition == "editStatus") {//是否编目
      this.state.editStatus = obj
    }
    if(condition == "isFake"){
      this.state.isFake=obj;
      this.state.release=''
    }
    if(this.state.release=='1'){
      this.onload('',2);
    }else{
      this.onload('');
    }
    
  }
  onload(ty,num) {
    console.log(ty,num)
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if (ty == "1") {
      this.state.start = 0;
    } else {
      this.state.start = this.state.page
    }
    
    let content = {
      start: this.state.start, limit: this.state.pageSize, sort: this.state.sort, filmName: this.state.filmName,
      filmClass: this.state.filmClass, country: this.state.country, isSchedule: this.state.isSchedule, filmYear: this.state.filmYear,
      releases: this.state.release, editStatus: this.state.editStatus,isFake:this.state.isFake
    };
    fetch('/api/cinema/film/searchByCinema', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        array = data.result;
        total = data.total;
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.state.params = array;
      if(num==2){
        this.state.params=this.state.params.filter((item,x)=>{
        if(item.isFake=='1'&&item.releases=='1'){
        
        }else{
          return item
        }
      })
      }
      
      this.state.total = total;
      this.state.selectedRowKeys = [];
      this.setState({ loading: false });
    });
  }
  searchEnum() {
    let enumArray = [];
    let content = { type:' 1' }
    fetch('/api/common/searchEnum', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        enumArray = data.result;
      } else {

      }
    }).then((e) => {
      this.setState({ enumArray: enumArray });
    });
  }
   //筛选影片条件：发行国家
   searchEnums(){
    let array=[];
    let content = {type:"2"}
      fetch('/api/common/searchEnum',{
        method: 'post',
        credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(content),
        }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
          return res.json();
        }).then(function (data) {
          if (data.code == 0) {
            array=data.result;    
          }
        }).then( (e) => {
          this.setState({countryList:array})
      });
}
//所属年份
searchEnumTime(){
    let array=[];
    let content = {type:"3"}
      fetch('/api/common/searchEnum',{
        method: 'post',
        credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(content),
        }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
          return res.json();
        }).then(function (data) {
          if (data.code == 0) {
            array=data.result;    
          }
        }).then( (e) => {
          this.setState({timeYearList:array})
      });
}
  pageChange(page, pageSize) {//翻页
    page = (page - 1) * pageSize
    this.state.page = page;
    this.onload();
  }
  //展开筛选
  displayChange() {
    if (this.state.display == "none") {
      this.setState({ display: "block" });
    } else {
      this.setState({ display: "none" });
    }
  }
  onSearch(filmName) {//搜索
    this.state.filmName = filmName;
    this.onload('1');
  }
  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  };
  //确定取消
  handleOk(type) {
    this.btnActive(type);
    this.setState({ visible: false });
  }
  actorCancel() {
    this.setState({ visible: false });
  }
  toRelease(type) {
    if (this.state.selectedRow.length == 0) {
      message.error("请选择数据，再进行操作");
      return;
    }
    this.setState({ visible: true, type });
  }
  //发布
  btnActive(state) {
    var str = ''
    if (state == 1) {
      str = "发布成功";
    } else if (state == 0) {
      str = "取消发布成功";
    }else if(state == 2){
      str = "预发布成功";
    }
    this.setState({ loading: true });
    let content = { state: state, films: this.state.selectedRow };
    fetch('/api/cinema/film/isRelease', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        message.success(str)
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.state.selectedRow.length=0;
      this.state.loading = false;
      this.onload();
    });
  }
  //编目认领
  toEdit() {
    if (this.state.selectedRow.length == 0) {
      message.error("请选择数据，再进行操作");
      return;
    }
    this.setState({ loading: true })
    let content = { films: this.state.selectedRow }
    fetch('/api/cinema/film/toEdit', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        message.success("认领成功");
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.state.selectedRow.length = 0;
      this.state.loading = false
      this.onload();
    });
  }
  //重置
  newClick = () => {
    //去掉className:active screening-main
    let nodelist = [...document.getElementsByClassName('screening-world')];
    for (let i = 0; i < nodelist.length; i++) {
      if (nodelist[i].getAttribute('class') == 'screening-world active') {
        nodelist[i].setAttribute('class', 'screening-world')
      }
    }
    this.state.filmName='';
    this.state.filmClass='';
    this.state.country='';
    this.state.isSchedule='';
    this.state.filmYear='';
    this.state.release='';
    this.state.editStatus='';
    this.state.isFake='';
     this.setState({filmName:  this.state.filmName,
    filmClass:  this.state.filmClass, country:this.state.country, isSchedule:  this.state.isSchedule, filmYear:  this.state.filmYear,
    release:  this.state.release, editStatus:this.state.editStatus,isFake:this.state.isFake});
    this.onload();
    // //调用接口
    // this.setState({ loading: true,filmName: '',
    // filmClass: '', country: '', isSchedule: '', filmYear: '',
    // releases: '', editStatus: '' });
    // let array = [];
    // let total = 0;
    // let content = {
    //   start: this.state.page, limit: this.state.pageSize, sort: this.state.sort, filmName: '',
    //   filmClass: '', country: '', isSchedule: '', filmYear: '',
    //   releases: '', editStatus: ''
    // };
    // fetch('/api/cinema/film/searchByCinema', {
    //   method: 'post',
    //   credentials: 'include',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify(content),
    // }).then(function (res) {
    //   if (!res.ok) { message.error("网络不可用！"); }
    //   return res.json();
    // }).then(function (data) {
    //   if (data.code == 0) {
    //     array = data.result;
    //     total = data.total;
    //   } else {
    //     message.error(data.message);
    //     if (data.code == 2003) {
    //       history.push({ pathname: '/' });
    //     }
    //   }
    // }).then((e) => {
    //   this.state.params = array;
    //   this.state.total = total;
    //   this.state.selectedRowKeys = [];
    //   this.setState({ loading: false });
    // });
  }
  //设置黑白名单
  setEvent(){
    if(this.state.selectedRow.length!=1){
      return message.error('请选择一条数据');
    }
    let content = {filmId:this.state.selectedRow[0].id,filmName:this.state.selectedRow[0].filmName};
    fetch('/api/filmListAuthority/setFilmListAuthority', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then( (data)=> {
      if (data.code == 0) {
        this.state.loading = false;
        this.props.callback('3','add',content,'1');
      } else if(data.code=='-2'){
        this.state.loading = false;   
        this.props.callback('2','details',data.result,'2');  
      } else {
        message.error(data.message);
        this.state.loading = false;
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
      this.setState({selectedRow:[]});
      this.onload();
    })
  }
  //影片同步
  synchronization(){
    let content=[];
    let str='';
    if(this.state.selectedRow.length==0){
      return message.error('请选择数据，再进行操作');
    }
    for(var i=0;i<this.state.selectedRow.length;i++){
      if(this.state.selectedRow[i].releases=='1'){
        return message.error('选择的影片中有已发布的影片,无法进行同步')
      }else{
        content.push({id:this.state.selectedRow[i].id})
      }
    }
    fetch('/api/cinema/film/synchronization', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then( (data)=> {
      if (data.code == 0) {
        if(data.result.length==0){
          message.success('同步成功');
        }
        if(data.result.length>0){
          for(var i=0;i<data.result.length-1;i++){
             str+=data.result[i].flimName+'、'
          }
          message.success(str+'在对方系统已发布，无法同步')
        }
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      
      }
      this.setState({selectedRow:[]});
      this.onload();
    })
  }
  //设为预制
  okClick(record){
    this.setState({visibles:true,record})
  }
  handleshe(){
    let {record}=this.state;
    let content={id:record.id,filmDownloadName:record.filmName};
    fetch('/api/cinema/film/setPrecut', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then( (data)=> {
      if (data.code == 0) {
          message.success('预制成功');
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      
      }
      this.setState({visibles:false});
      this.onload();
    })
  }
  cancelshe(){
    this.setState({visibles:false});
  }
  render() {
    const columns = [
      {
        title: '序号',
        render: (text, record, index) => `${index + 1}`,
      },
      {
        title: '影片名称',
        dataIndex: 'filmName',
        key: 'filmName',

      },
      // {
      //   title: '影片分类',
      //   dataIndex: 'filmClassName',
      //   key: 'filmClassName',
      //   render: (text, record, index) => {
      //     if (text != undefined && text.length > 10) {
      //       return text.substring(0, 10) + "..."
      //     } else {
      //       return text
      //     }
      //   }
      // },
      {
        title:'是否预制影片状态',
        dataIndex:'setPrecut',
        key:'setPrecut',
        render:(text)=><span>{text==1?'是':'否'}</span>
      },
      {
        title: '编目状态',
        dataIndex: 'editStatus',
        key: 'editStatus',
        render: (text, record) => {
          if (record.editStatus == "0") {
            return "未编目"
          }
          if (record.editStatus == "1") {
            return "编目中"
          }
          if (record.editStatus == "2") {
            return "编目完成"
          }
        }
      },
      {
        title: '定级定价状态',
        dataIndex: 'makeFilmStatus',
        key: 'makeFilmStatus',
        render: (text) => text == '0' ? "未定级定价" : text == '1' ? '定级定价中' : text == '2' ? '定级定价完成' : ''
      },
      {
        title: '发布状态',
        dataIndex: 'releases',
        key: 'releases',
        render: (text, record) =>record.isFake=='1'&&text == "1" ?'预发布':text == "0" ? "未发布":text == "1" ? "已发布":''
      },
      
      {
        title: '影片时长',
        dataIndex: 'filmLength',
        key: 'filmLength',
      },
      {
        title: '上映日期',
        dataIndex: 'releaseDate',
        key: 'releaseDate',
        render: (text, record, index) => <span>{text != null ? text = text.substring(0, 10) : ""}</span>
      },
      {
        title:'影片是否同步',
        dataIndex:'synchronizationStatus',
        render:(text)=><span>{text=='1'?'已同步':'未同步'}</span>
      },
      {
        title:'操作',
        dataIndex:'',
        render:(text,record,index)=><Button disabled={record.setPrecut=="1"?true:false} type="primary" onClick={this.okClick.bind(this,record)}>设为预制</Button>
      }

    ];
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this),//获取选中值
    };

    return (
      <div style={{ marginLeft: 15,background:'#fff'}}>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div class="screening-classification">
            <div class="screening-text">筛选分类</div>

            <div class="screening-open">
              <button type="button" onClick={this.displayChange.bind(this)} class="btn btn-default screening-button">{this.state.display == "none" ? "展开筛选" : "收起筛选"}</button>
            </div>
            <div class="clearfix"></div>
            <div class="screening-open-content" style={{ display: this.state.display }}>
              <div className="screening-main">
                <a href="javascript:;" onClick={this.screen.bind(this, "filmClass", "")} class="screening-style">全部类型</a>
                {(this.state.enumArray.length == 0) ? null : this.state.enumArray.map((item, index) => {
                  return (
                    <a href="javascript:;" onClick={this.screen.bind(this, "filmClass", item)} class="screening-world">{item.enumName}</a>
                  )
                }, this)}
              </div>
              <div className="screening-main">
              <a href="javascript:;" onClick={this.screen.bind(this, "country", "")} class="screening-style">全部地区</a>
                {
                  this.state.countryList.length==0?null:this.state.countryList.map((item,index)=>{
                    return <a href="javascript:;" onClick={this.screen.bind(this, "country", item.enumName)} class="screening-world">{item.enumName}</a>
                  })
                }
              </div>
              <div className="screening-main">
                <a href="javascript:;" onClick={this.screen.bind(this, "year", "")} class="screening-style">全部年份</a>
                {
                  this.state.timeYearList.length==0?null:this.state.timeYearList.map((item,index)=>{
                    return <a href="javascript:;" onClick={this.screen.bind(this, "year", item.enumName)} class="screening-world">{item.enumName}</a>
                  })
                }
              </div>
              <div className="screening-main">
                <a href="javascript:;" onClick={this.screen.bind(this, "isSchedule", "")} class="screening-style">全部档期</a>
                <a href="javascript:;" onClick={this.screen.bind(this, "isSchedule", "1")} class="screening-world">首轮片</a>
                <a href="javascript:;" onClick={this.screen.bind(this, "isSchedule", "0")} class="screening-world">二轮片</a>
              </div>
              <div className="screening-main">
                <a href="javascript:;" onClick={this.screen.bind(this, "editStatus", "")} class="screening-style">编目类型</a>
                <a href="javascript:;" onClick={this.screen.bind(this, "editStatus", "0")} class="screening-world">未编目</a>
                <a href="javascript:;" onClick={this.screen.bind(this, "editStatus", "2")} class="screening-world">已编目</a>
              </div>
              <div className="screening-main">
                <a href="javascript:;" onClick={this.screen.bind(this, "release", "")} class="screening-style">发布类型</a>
                <a href="javascript:;" onClick={this.screen.bind(this, "release", "0")} class="screening-world">未发布</a>
                <a href="javascript:;" onClick={this.screen.bind(this, "release", "1")} class="screening-world">已发布</a>
                <a href="javascript:;" onClick={this.screen.bind(this, "isFake", "1")} class="screening-world">预发布</a>
              </div>
              <div  style={{ marginTop: '30px', marginLeft: '45%' }}><Button type="primary" onClick={this.newClick}>重置</Button></div>
            </div>
          </div>
          <div class="sort-content">
            <div class="sort-style">
              <a href="javascript:;" onClick={this.active.bind(this, "heat_rate")} className={this.state.sort === "heat_rate" ? 'active' : ''}>按热门排序</a>
              <a href="javascript:;" onClick={this.active.bind(this, "release_date")} className={this.state.sort === "release_date" ? 'active' : ''}>按时间排序</a>
              <a href="javascript:;" onClick={this.active.bind(this, "rank")}  className={this.state.sort === "rank" ? 'active' : ''} class="last">按评分排序</a>
              {/* 新增的按钮判断style={{display:this.state.btn_add == true?"inline-block":"none"}}     */}
              {this.props.type=='1'?<a href="javascript:;" onClick={this.setEvent.bind(this)}><Button type="primary">设置黑白名单</Button></a>:''}
              {this.props.type=='1'?'':<Button type="primary" onClick={this.props.callback.bind(this,"2","add",null)} className = "btn_release">新增</Button>}
              {this.props.type=='1'?'':<Button onClick={this.toRelease.bind(this,"2")}  className = "btn_release">预发布</Button>}
              {this.props.type=='1'?'':<Button onClick={this.toRelease.bind(this, "1")}  style={{ display: this.state.btn_release == true ? "inline-block" : "none" }} className="btn_release">发布</Button>}
              {this.props.type=='1'?'':<Button onClick={this.toRelease.bind(this, "0")} style={{ display: this.state.btn_cancel_release == true ? "inline-block" : "none" }} className="btn_cancel_release">取消发布</Button>}
              {this.props.type=='1'?'':<Button type="primary" onClick={this.toEdit.bind(this)} style={{ display: this.state.btn_edit == true ? "inline-block" : "none" }} className="btn_cancel_release">编目认领</Button>}
              {this.props.type=='1'?'':<Button type="primary" onClick={this.synchronization.bind(this)} style={{display:this.state.btn_synchronized==true?'inline-block':'none'}} className="btn_cancel_release">影片同步</Button>}
              <span style={{ display: this.state.btn_search == true ? "inline-block" : "none" }} className="search-stream"><Search placeholder="请输入影片名称" enterButton="搜索" onSearch={value => this.onSearch(value)} /></span>
            </div>
            <div class="clearfix"></div> 
          </div>
          <div>
            <div style={{ width: "100%" }}>
              <Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource} pagination={{ onChange: this.pageChange.bind(this), total: this.state.total }} />
            </div>
          </div>
          <div class="clearfix"></div>
        </Spin>
        <Modal title="提示" okText="确认" cancelText="取消" width="300px" style={{ marginTop: "20px" }} visible={this.state.visible} onOk={this.handleOk.bind(this, this.state.type)} onCancel={this.actorCancel.bind(this)}>
          <div style={{ width: '100%', textAlign: "center", height: "5px", lineHeight: "5px" }}>
            <b>{this.state.type == "0" ? "确定取消发布?" :this.state.type == "1" ? "确定发布?":this.state.type == "2" ?'确定预发布':''}</b> 
          </div>
        </Modal>
        <Modal title="提示" okText="确认" cancelText="取消" width="300px" style={{ marginTop: "20px" }} visible={this.state.visibles} onOk={this.handleshe.bind(this)} onCancel={this.cancelshe.bind(this)}>
          <div style={{ width: '100%', textAlign: "center", height: "5px", lineHeight: "5px" }}>
            确定设为预制
          </div>
        </Modal>
      </div>
    )
  }
}
export default FlimList;
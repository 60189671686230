import React from "react";
import {
  Row,
  Col,
  Input,
  Select,
  Button,
  Spin,
  message,
  DatePicker
} from "antd";
import history from "../history";
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
class DeviceManageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      loading: false,
      previewVisible: false,
      previewImage: "",
      fileList: [],
      cinemaCodeList: [],
      cinemaList: [],
      isfalse:null,
      dateFormat:'YYYY-MM-DD',
      esfalse:false,
      installTime:''
    };
  }
  componentWillMount() {
    if (this.props.params != null) {
       this.state.isfalse=this.props.isfalse;
       this.state.params = this.props.params;
       this.state.flag=this.props.flag
       this.onload(this.state.params.id);
    } else {
      this.state.fileList = [];
    }
    this.state.cinemaList = this.props.basics.cinemaList;
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps != null) {
      if (nextProps.params != null) {
        this.state.params=nextProps.params
        this.state.isfalse=nextProps.isfalse
        this.state.flag=this.props.flag
        this.onload(this.state.params.id);
        this.state.esfalse=false
      } else {
        this.state.isfalse=null
        this.setState({
          params: {}
        });
      }
    } else {
      this.state.isfalse=null
      this.setState({
        params: {}
      });
    }
  }
  onload(id){
    let dataParams = {};
    this.setState({ loading: true });
    let content = {id:id}
        fetch('/api/cinema/searchDeviceDetails',{
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
            }).then(function (res) {             
              if(!res.ok){                 
                message.error("网络不可用！");             
              }
              return res.json();
            }).then( (data)=> {
                if (data.code == 0) {
                    dataParams = data.result;
                }else  {
                    message.error(data.message);
                        if(data.code == 2003){
                            history.push({pathname:'/'});
                    }
                }
            }).then( (e) => {
              this.state.installTime=dataParams.installTime;
                this.setState({ params:dataParams,loading: false,installTime:this.state.installTime});
            });
}
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_save") {
        this.state.btn_save = true;
      }
      if (array[i].buttonCode == "btn_update") {
        this.state.btn_update = true;
      }
      if (array[i].buttonCode == "btn_cancel") {
        this.state.btn_cancel = true;
      }
    }
  }

  handleChange(type, e) {
    let {params}=this.state
    if (type == "deviceName") {
     params.deviceName = e.target.value;
    }
    if (type == "description") {
    params.description = e.target.value;
    }
   
    if(type=="deviceCode"){
      // console.log(e.target.value,"deciceCode")
       params.deviceCode = e.target.value;
     }
    this.setState({
      params:{...params},
    
    })
  }
  selectChange(type, e,obj) {
    if (type == "cinemaName") {
      this.state.params.cinemaId= e.id; 
      this.state.params.cinemaName =e.name;
      this.onloading(e.code)
    }
   
    
  }
  selectChange1(type,e,obj){
    let params= this.state.params
    if (type == "hallId") {
     params.hallId=e
     params.posHallName = obj.props.children;
    }
    this.setState({
      params:{...params}
    })
  }

  valChange(type,value){
    let params= this.state.params
    if (type == "deviceType") {
     params.deviceType = value;
     if(params.deviceType=="Box"){
       message.info('请到盒子管理')
     }
    }
    if (type == "status") {
     params.status = value;
    }
    if (type == "devicePlayType") {
      params.devicePlayType = value;
     }
    this.setState({
      params:{...params}
    })
  }
  save() {
    //保存
    // if (
    //   this.state.params.deviceName == undefined ||  
    //   this.state.params.deviceType == undefined ||
    //   this.state.params.posHallName == undefined ||
    //   this.state.params.installTime == undefined ||
    //   this.state.params.cinemaName == undefined ||
    //   this.state.params.status == undefined||
    //   this.state.params.deviceCode == undefined
    //
    // ) {
    //   message.error("存在必填项未填写，请核实！");
    //   return;
    // }
    if(this.state.params.deviceName == undefined){ 
      message.error("设备名称未填写，请核实！");
      return;
    }
    if(this.state.params.deviceCode == undefined){
      message.error("设备编码未填写，请核实！");
      return;
    }
    if(this.state.params.deviceType == undefined){
      message.error("设备类型未填写，请核实！");
      return;
    }
   
    if(this.state.params.cinemaName == undefined){
      message.error("安装影院未填写，请核实！");
      return;
    }
    if(this.state.params.posHallName == undefined){
      message.error("安装影厅未填写，请核实！");
      return;
    }
    if(this.state.installTime == undefined||this.state.installTime==null||this.state.installTime==''){
      message.error("安装时间未填写，请核实！");
      return;
    }
    if(this.state.params.status == undefined){
      message.error("状态未填写，请核实！");
      return;
    }
   
    this.state.params.installTime=moment(this.state.installTime).format('YYYY-MM-DD');
    this.setState({ loading: true });

    fetch("/api/device/saveDevice", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state.params)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data)=> {
        if (data.code == 0) {
          message.success("保存成功！");
          if(data.result.id!=null){
            this.onload(data.result.id)
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e=>{
        this.setState({loading:false,isfalse: true, esfalse: false})
      })
  }

  onloading(code) {  //影厅的接口
    let array = [];
    let content = { cinemaCode: code };
    fetch("/api/cinema/queryHallListByCinema", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function(data) {
        if (data.code == 0) {
          array = data.result;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.cinemaCodeList = array; //接收数据
        this.setState({ loading: false });
      });
  }
  
  //修改还是取消
  cencel(type) {
    if (type == 'write') {
        this.setState({
            isfalse: false,
            esfalse:true,
            flag:false
        });
    } else {
        this.setState({
            isfalse: true,
            esfalse: false,
            flag:true
        });
    }
}
  OnChange(type, date, dateString) {
    if (type == "installTime") {
      this.state.installTime = date 
    }
    this.setState({installTime:this.state.installTime});
  }

  render() {
    const { fileList,dateFormat } = this.state;
    if (fileList.length > 0 && fileList[0].status == "done") {
      this.state.url = fileList[0].response.result;
    }
    if (fileList.length == 0) {
      this.state.url = null;
    }
    let { esfalse,isfalse } = this.state;
    return (
      <div
        className="DeviceManageDetails"
        key={
          this.state.params != null && this.state.params.id == "-1"
            ? this.state.params.id
            : this.state.params.id
        }
      >
        <Spin spinning={this.state.loading} tip="Loading...">
          {this.state.params.deviceType=="Box"?null:
          <div>
            <Button
              style={{
                display:this.state.btn_save == true ? "inline-block" : "none",
                marginLeft: 10
              }}
              onClick={this.save.bind(this)}
              type="primary"
              disabled={isfalse}
            >
              保存
            </Button>
            <Button
              style={{
                display: this.state.btn_update == true ? "inline-block" : "none",
                marginLeft: 20
              }}
              onClick={this.cencel.bind(this, 'write')}
              disabled={esfalse}
            >
              修改
            </Button>
            <Button
              style={{
                display: this.state.btn_cancel == true ? "btn_cancel" : "none",
                marginLeft: 20
              }}
              onClick={this.cencel.bind(this)}
              disabled={isfalse}
            >
              取消
            </Button>
            <Button style={{marginLeft:20}} onClick = {this.props.callback.bind(this,"1",null,null)}>返回</Button>
          </div>
           }
          <div className="ewqbox" style={{ marginLeft: 10}}>
              <p>
                设备名称：
                <Input
                 style={{ width: "15%", }}
                  disabled={isfalse}
                  value={this.state.params.deviceName}
                  placeholder="请输入设备名称"
                  onChange={this.handleChange.bind(this, "deviceName")}
                />
              </p>
              <p>
                设备编码：
                <Input  
                 style={{ width: "15%" , }}
                  disabled={this.state.isfalse}
                  value={this.state.params.deviceCode}
                  placeholder="请输入设备编码"
                  onChange={this.handleChange.bind(this, "deviceCode")}
                />
              </p>
           
            <p>
              设备类型：
              <Select
                disabled={isfalse}
                style={{ width: "15%", }}
                onChange={this.valChange.bind(this, "deviceType")}
                value={this.state.params.deviceType}
              >
                <Option value="">--请选择--</Option>
                <Option value="Projector">Projector</Option>
                <Option value="CentralContrl">CentralContrl</Option>
                <Option value="Box">Box</Option>
              </Select>
            </p>
           
            {
              this.state.params.deviceType=="Projector"?
              <p>
             密钥类型：
             <Select
                disabled={isfalse}
                style={{ width: "15%", }}
                onChange={this.valChange.bind(this, "devicePlayType")}
                value={this.state.params.devicePlayType}
              >
                <Option value="">--请选择--</Option>
                <Option value="0">KDM</Option>
                <Option value="1">LDM</Option>
              </Select></p>:null
             
            }
           
            <p>
              安装影院：
              <Select
                disabled={isfalse}
                value={this.state.params.cinemaName}
                key={this.state.params.cinemaName}
                style={{ width: "15%", }}
                onChange={this.selectChange.bind(this, "cinemaName")}
              >
                {this.state.cinemaList.map(item => {
                return <Option value={item}>{item?item.name:""}</Option>;
                })}
              </Select>
            </p>
            <p>
              安装影厅：
              <Select
                disabled={isfalse}
                key={this.state.params.posHallName}
                value={this.state.params.posHallName}
                style={{ width: "15%", }}
                onChange={this.selectChange1.bind(this,"hallId")}
              >
                <Option value="">---</Option>
                {this.state.cinemaCodeList.map((item,index)=>{
                  return  <Option value={item.id}>{item.name}</Option>
                })}
                
              
              </Select>
            </p>
            <p>
              安装时间：
             <DatePicker
                    style={{ width: "15%", }}
                    onChange={this.OnChange.bind(this, 'installTime')}
                    value={this.state.installTime==null||this.state.installTime==''?null:moment(this.state.installTime)}
                    format={dateFormat}
                    disabled={isfalse}
                    placeholder="请选择时间"
                  />
            </p>
         
          <p>
            运行状态：
            <Select
              disabled={isfalse}
              style={{ width: "15%", }}
              onChange={this.valChange.bind(this, "status")}
              value={this.state.params.status=="0"?"运行中":this.state.params.status=="1"?"未运行":''}
            >
              <Option value="0">运行中</Option>
              <Option value="1">未运行</Option>
            </Select>
          </p>
          <Row style={{ margin: '10px 5px 5px 0' }}>
          
              <Col span={12} style={{lineHeight:'73px'}}>
              备注:
              <TextArea
              style={{ width: "30%", marginLeft: 40 }}
              disabled={isfalse}
              required={true}
              rows={3}
              placeholder="备注"
              value={this.state.params.description}
              onChange={this.handleChange.bind(this, "description")}
            />
              </Col>
            </Row>
          </div>
        </Spin>
      </div>
    );
  }
}
export default DeviceManageDetails;

import React from 'react';
import {Radio,Spin,Button,Icon,message,Select} from 'antd';
import history from '../history';

const { Option } = Select;
class ListAuthorityDetail extends React.Component{ 
    constructor(props){
        super(props);
        this.state={
           params:{},
           loading:false,
           theatreList:[],
           cinemaList:[],
           pushObj:null,
           pushObjs:null,
           bListLeft:[],//存放院线数据
           bListRight:[],//存放影院数据
           name:'',
           cineName:'',
           letterList:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
        }
    }
    componentWillMount(){
        if (this.props.params != null) {
            this.state.params = this.props.params;
            this.state.isfalse = this.props.isfalse;
            this.onload(this.state.params.id);
            this.getCinemaList();
            this.getTheatreList();
          } 
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps != null) {
            if (nextProps.params != null) {
              this.state.params = nextProps.params;
              this.state.isfalse = this.props.isfalse;
              this.state.esfalse = false;
              this.onload(this.state.params.id);
              this.getCinemaList();
              this.getTheatreList();
            } else {
              this.setState({
                params: {}
              });
            }
          } else {
            this.setState({
              params: {}
            });
          }
    }
      onload(id) {
        let dataParams = {};
        let bListLeft=[];
        let bListRight=[];
        let LetterLeft=[];
        let LetterRight=[];
        this.setState({ loading: true });
        let content = { id: id }
        fetch('/api/filmListAuthority/authorityListDetails', {
          method: 'post',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(content),
        }).then(function (res) {
          if (!res.ok) { message.error("网络不可用！"); }
          return res.json();
        }).then((data) => {
          if (data.code == 0) {
            dataParams = data.result;
          } else {
            message.error(data.message);
            if (data.code == 2003) {
              history.push({ pathname: '/' });
            }
          }
        }).then((e) => {
          if(dataParams){
          for(var i=0;i<dataParams.bList.length;i++){
            if(dataParams.bList[i].type=='0'){ 
             LetterLeft.push(dataParams.bList[i]);
             LetterLeft.sort((a,b)=>a.theaterChainName.localeCompare(b.theaterChainName,'zh-Hans-CN',{sensitivity:'accent'}));
            }else if(dataParams.bList[i].type=='1'){
             LetterRight.push(dataParams.bList[i]);
             LetterRight.sort((a,b)=>a.cinemaName.localeCompare(b.cinemaName,'zh-Hans-CN',{sensitivity:'accent'}));
            }
          }
          
        }
          this.setState({ params: dataParams,bListLeft:LetterLeft,bListRight:LetterRight,loading: false });
        });
    }
    //院线接口
    getTheatreList(){
      let content = {};
      let array = [];
      fetch('/api/cinema/theatreList',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(content),
					}).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								 array=data.result;    
							}
					}).then( (e) => {
            this.setState({ theatreList:array});
				 });
    }
    //影院接口
    getCinemaList(){
      let content = {};
      let array=[];
      fetch('/api/cinema/list',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(content),
					}).then(function (res) {             
            if(!res.ok){                 
              message.error("网络不可用！");             
            }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								 array=data.result;    
							}
					}).then( (e) => {
            this.setState({
              cinemaList:array
            })
				 });
    }
    //选择黑白名单
    radioChange(e){
      let {params}=this.state;
      params.type=e.target.value;
      this.setState({params});
    }
    //选择启用和禁用
    radiosChange(e){
      let {params}=this.state;
      params.status=e.target.value;
      this.setState({params});
    }
    //修改取消
    cencel(type){
      if (type == 'write') {
        this.setState({
          isfalse: false,
          esfalse: true
        });
      } else {
        this.setState({
          isfalse: true,
          esfalse: false,
        });
      }
    }
    //院线选择
    SelectChange(type,e,obj){
      let Obj={};
      Obj.theaterChainId=e;
      Obj.theaterChainName=obj.props.children;
      Obj.type='0';
      this.setState({pushObj:Obj,name:obj.props.children});
      
    }
    //影院选择
    SelectsChange(type,e,obj){
      let Obj={};
      Obj.cinemaId=e;
      Obj.cinemaName=obj.props.children;
      Obj.type='1';
      this.setState({pushObjs:Obj,cineName:obj.props.children});
     
    }
    //点击给院线 影院 添加数据
    addEvent(type){
      if(this.state.isfalse!=true){
      let {bListLeft,bListRight,pushObj,pushObjs}=this.state;
      if(type=="theater"){
        if(pushObj==null){
          return message.error('请选择一个院线');
        }
        for(var i=0;i<bListLeft.length;i++){
          if(bListLeft[i].theaterChainName==pushObj.theaterChainName){
            return message.error('院线已添加');
          }
        }
        bListLeft.push(pushObj);
        bListLeft.sort((a,b)=>a.theaterChainName.localeCompare(b.theaterChainName,'zh-Hans-CN',{sensitivity:'accent'}));
        this.state.name='';
      } else if(type=="cinema"){
        if(pushObjs==null){
          return message.error('请选择一个影院');
        }
        for(var i=0;i<bListRight.length;i++){
          if(bListRight[i].cinemaName==pushObjs.cinemaName){
            return message.error('影院已添加');
          }
        }
        bListRight.push(pushObjs);
        bListRight.sort((a,b)=>a.cinemaName.localeCompare(b.cinemaName,'zh-Hans-CN',{sensitivity:'accent'}));
        this.state.cineName='';
      }
      this.setState({bListLeft,bListRight,cineName:this.state.cineName,name:this.state.name});
    }
    }

    //院线列表删除
    //影院列表删除
    delete(type,index){
      if(this.state.isfalse!=true){
      let {bListLeft,bListRight}=this.state;
      if(type=='theaterChainName'){
       for(var i=0;i<bListLeft.length;i++){
         if(index==i){
           bListLeft.splice(index,1);
         }
       }
      }
      if(type=='cinemaName'){
        for(var i=0;i<bListRight.length;i++){
          if(index==i){
            bListRight.splice(index,1);
          }
        }
      }
      this.setState({bListLeft,bListRight});
    }
  }
    //保存
    save(){
    this.state.params.bList=this.state.bListLeft.concat(this.state.bListRight);
    if(this.state.params.type!='0'&&this.state.params.type!='1'){
     return  message.error('请选择黑名单或白名单');
    }
    this.setState({ loading: true }); 
    fetch("/api/filmListAuthority/saveAuthorityList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state.params)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data) => {
        if (data.code == 0) {
          message.success("保存成功！");
          if (data.result != null) {
            this.onload(data.result.id)
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({ loading: false, isfalse: true, esfalse: false });
      });
    }
    render(){
        let {isfalse,esfalse,params,bListLeft,bListRight}=this.state;
        return(
            <div 
            className="ListAuthorityDetails"
            // key={
            //     this.state.params != null && this.state.params.id == "-1"
            //       ? this.state.params.id
            //       : this.state.params.id
            //   }
            >
            <Spin spinning={this.state.loading} tip="Loading...">
              <div style={{ marginLeft: '25px' }}>
                <Button
                style={{
                    marginLeft: 10
                }}
                onClick={this.save.bind(this)}
                type="primary"
                disabled={isfalse}
                >
                保存
                </Button>
                <Button style={{marginLeft: 20 }} disabled={esfalse} onClick={this.cencel.bind(this, 'write')}>
                修改
                </Button>
                <Button
                style={{marginLeft: 20 }} disabled={isfalse} onClick={this.cencel.bind(this, true)}>
                取消
                </Button>
                <Button style={{ marginLeft: 20 }} onClick={this.props.callback.bind(this, "1", null, null)}>返回</Button>
              </div>
              <div style={{marginLeft:'25px',marginTop:'25px'}}>
              <div style={{width:'100%',background:'#ccc',height:'40px',lineHeight:'40px',marginLeft:'5px'}}>影片名称:{this.state.params!=null?this.state.params.filmName:''}</div>
                  <div style={{marginTop:'25px',display:'flex'}}>
                      <span style={{width:'20px',height:'20px',background:'#1990FF',color:'#fff',display:'inline-block',borderRadius:'50%',textAlign:'center'}}>1</span>
                      <Radio.Group style={{marginLeft:'20px'}} onChange={this.radioChange.bind(this) }>
                        <Radio disabled={isfalse} checked={this.state.params.type=='0'?true:false} value={0}>白名单</Radio>
                        <Radio disabled={isfalse} checked={this.state.params.type=='1'?true:false} value={1}>黑名单</Radio>
                      </Radio.Group>
                  </div>
                  <div style={{margin:'10px',height:'80px',borderLeft:'1px solid #ccc'}}></div>
                  <div style={{marginTop:'25px',display:'flex'}}>
                    <span style={{width:'20px',height:'20px',background:'#1990FF',color:'#fff',display:'inline-block',borderRadius:'50%',textAlign:'center'}}>2</span>
                    <div style={{width:'200px',height:'50px',marginRight:'10px',marginLeft:'20px'}}>
                      <Select  style={{width:'200px'}}
                      disabled={isfalse}
                      showSearch 
                      onChange={this.SelectChange.bind(this,'theaterChainId')}
                      optionFilterProp="children" 
                      value={this.state.name==''?'此处查找院线':this.state.name}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      
                      >
                        {
                          this.state.theatreList&&this.state.theatreList.map((item,index)=>{
                            return <Option value={item.id} key={index}>{item.name}</Option>
                          })
                        }
                      </Select>
                     </div>
                    <Icon type="caret-right"  style={{fontSize:'33px'}} onClick={this.addEvent.bind(this,'theater')}></Icon>
                    <div style={{width:'340px',height:'360px',overflow:'auto',position:'relative',border:'1px solid #ccc'}}>
                        <ul  style={{listStyle:'none'}}>
                            <li style={{borderBottom:'1px solid #ccc',textAlign:'center',lineHeight:'37px',marginRight:'30px'}}>院线</li>
                            {
                              bListLeft&&bListLeft.map((item,index)=>{
                              return  <li style={{borderBottom:'1px solid #ccc',display:'flex',width:'90%',lineHeight:'36px',marginLeft:'5px'}}><span style={{width:'88%',display:'inline-block'}}>{item.theaterChainName}</span><Icon style={{marginTop:'15px',zIndex:'999'}} disabled={isfalse} onClick={this.delete.bind(this,'theaterChainName',index)} type="close-circle"></Icon></li>
                              })
                            }
                        </ul> 
                    </div>
                    <div style={{width:'200px',height:'50px',marginRight:'10px',marginLeft:'10px'}}>
                      <Select  style={{width:'200px'}}
                      disabled={isfalse}
                      showSearch 
                      value={this.state.cineName==''?'此处查找影院':this.state.cineName}
                      onChange={this.SelectsChange.bind(this,'cinemaId')}
                      optionFilterProp="children" 
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      
                      >
                        {
                          this.state.cinemaList&&this.state.cinemaList.map((item,index)=>{
                            return <Option value={item.id} key={index}>{item.name}</Option>
                          })
                        }
                      </Select>
                     </div>
                    <Icon type="caret-right" style={{fontSize:'33px'}} onClick={this.addEvent.bind(this,'cinema')}></Icon>
                    <div style={{width:'340px',height:'360px',overflow:'auto',position:'relative',border:'1px solid #ccc'}}>
                        <ul style={{listStyle:'none'}}> 
                            <li style={{borderBottom:'1px solid #ccc',textAlign:'center',lineHeight:'37px',marginRight:'30px'}}>影院</li>
                            {
                              bListRight&&bListRight.map((item,index)=>{
                              return  <li style={{borderBottom:'1px solid #ccc',display:'flex',width:'90%',lineHeight:'36px',marginLeft:'5px'}}><span style={{width:'88%',display:'inline-block'}} disabled={isfalse}>{item.cinemaName}</span><Icon style={{marginTop:'15px',zIndex:'999'}} onClick={this.delete.bind(this,'cinemaName',index)} type="close-circle"></Icon></li>
                              })
                            } 
                        </ul>
                       
                    </div>
                  </div>
                  <div style={{margin:'10px',height:'80px',borderLeft:'1px solid #ccc'}}></div> 
                  <div style={{marginTop:'25px',display:'flex'}}>
                    <span style={{width:'20px',height:'20px',background:'#1990FF',color:'#fff',display:'inline-block',borderRadius:'50%',textAlign:'center'}}>3</span>
                    <Radio.Group style={{marginLeft:'20px'}} onChange={this.radiosChange.bind(this)}>
                        <Radio disabled={isfalse} checked={this.state.params.status=='0'?true:false} value={0}>启用</Radio>
                        <Radio disabled={isfalse} checked={this.state.params.status=='1'?true:false} value={1}>禁用</Radio>
                    </Radio.Group>
                  </div>
              </div>
            </Spin>    
            </div>
            )
    }
   
}
export default ListAuthorityDetail;
import React from 'react';
import {Tabs} from 'antd';
import SecretOrderExportedList from './SecretOrderExportedList.js';
import SecretOrderExportedDetail from './SecretOrderExportedDetail.js'
const { TabPane } = Tabs;
class SecretOrderExported extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1"
      }
    }
      callback(activeKey,type,params){
          if(type == "details"){
            this.state.params = params;
          }
          if(type == "add"){
            this.state.params = null;
          }
        this.setState({activeKey:activeKey})
      }
    render(){
      const {isfalse}=this.state;
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
            <TabPane tab="待处理密钥列表" key="1">
              <SecretOrderExportedList  buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
            </TabPane>
            <TabPane tab="待处理密钥详情" key="2">
            <SecretOrderExportedDetail buttonList={this.props.buttonList} params = {this.state.params} callback = {this.callback.bind(this)}/>
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default SecretOrderExported;
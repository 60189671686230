import React from 'react';
import {Tabs} from 'antd';
import DirectorList from './directorList';
import DirectorDetails from './directorDetails.js';

const { TabPane } = Tabs;
class DirectorManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        isfalse:null,
        params:null
      }
    }
      callback(activeKey,type,params){
          if(type == "details"){
            this.state.isfalse=true;
            this.state.params = params
          }
          if(type == "add"){
            this.state.isfalse=false;
            this.state.params = null
          }
        this.setState({activeKey:activeKey})
      }
    render(){
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
            <TabPane tab="导演列表" key="1">
              <DirectorList  buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
            </TabPane>
            <TabPane tab="导演详情" key="2">
              <DirectorDetails  buttonList={this.props.buttonList} isfalse={this.state.isfalse} params = {this.state.params} callback = {this.callback.bind(this)}/>
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default DirectorManage;
import React from "react";
import {
  Input,
  Select,
  Button,
  Spin,
  message,
  Modal,
  Row,
  Col,
  Table
} from "antd";
import history from "../history";
import { formatFileSize } from "../../utils/formatFile";
const { Option } = Select;
const { TextArea } = Input;
class DownloadDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      loading: false,
      isfalse: true,
      dataSource:[],
      esfalse:false,
      status:'',
      type:0
    };
  }
  componentWillMount() {
    if (this.props.params != null) {
      this.state.params = this.props.params;
      this.state.isfalse = this.props.isfalse;
      this.onload(this.state.params.id);
      this.timer=setInterval(() => {
        this.onloading(this.state.params.id);
        }, 5000); 
    }
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps != null) {
      if (nextProps.params != null) {
        this.state.params = nextProps.params;
        this.state.dataSource=nextProps.params;
        this.onload(this.state.params.id);
        this.state.status=''
        this.state.isfalse = this.props.isfalse;
        // this.onload(this.state.params);
        this.state.esfalse=false
      } else {
        this.setState({
          params: {}
        });
      }
    } else {
      this.setState({
        params: {}
      });
    }
  }

  componentWillUnmount(){
    clearInterval(this.timer)
}

cencel(type) {
  //修改还是取消
  if (type == 'write') {
      this.setState({
          isfalse: false,
          esfalse:true
      });
  } else {
      this.setState({
          isfalse: true,
          esfalse: false,
      });
  }
}
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_update") {
        this.state.btn_update = true;
      }
      if (array[i].buttonCode == "btn_save") {
        this.state.btn_save = true;
      }
      if (array[i].buttonCode == "btn_cancel") {
        this.state.btn_cancel = true;
      }
    }
  }
  onload(id) {
    let dataParams = {};
    this.setState({ loading: true });
    let content = { id: id}
    fetch('/api/downApply/orderDetails', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then((data) => {
      if (data.code == 0) {
        dataParams = data.result;
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.setState({ params: dataParams, loading: false,status:dataParams.status,expressCompany:dataParams.expressCompany,expressNum:dataParams.expressNum });
    });
}
  //请求数据的接口
  onloading(id){
    let dataParams = {};
    let content = { id: id}
    
    fetch('/api/downApply/orderDetails', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then((data) => {
      if (data.code == 0) {
        dataParams = data.result;
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.setState({ params: dataParams });
    });
  }
   
//获取数据的状态值
valChange(type,value){
 if(type=="status"){
  this.setState({status:value})
 }
}
 //保存
 btnClick(){
  this.setState({ loading: true });
  let content={id:this.state.params.id,status:this.state.status,expressCompany:this.state.expressCompany,
    expressNum:this.state.expressNum,keyOrderId:this.state.params.keyOrderId}
  fetch("/api/downApply/updateDownloadApplyOrder", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json" 
    },
    body: JSON.stringify(content)
  })
    .then(function(res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    }) 
    .then((data)=> {
      if (data.code == 0) {
        message.success("保存成功！");
        if(data.result!=null){
          this.onloading(data.result.id)
        }
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: "/" });
        }
      }
    })
    .then(e => {
      this.setState({ loading: false,isfalse: true, esfalse: false });
    });
 }
 
 inpChange(type,e){
   if(type=="expressCompany"){
    this.setState({expressCompany:e.target.value})
   }
   if(type=="expressNum"){
    this.setState({expressNum:e.target.value})
  }

 }

  render() {
    let { esfalse,isfalse } = this.state
    let columns = [
      {
        title: '影片',
        dataIndex: 'filmNameReally',
        key: 'filmNameReally',
        render:(text,record)=><span>{text}</span>},
      {
        title: '文件大小',
        dataIndex: 'totalSize',
        key: 'totalSize',
        render:(text,record)=><span>{formatFileSize(text)}</span>
      },
      {
        title: '传输状态',
        dataIndex: 'status',
        key: 'status',
        render: (text,record) => {
          if(text=="0"){
            return <span>未开始</span>
          }
          if(text=="1"){
            return <span>下载中</span>
          }
          if(text=="2"){
            return  <span>下载完成待校验</span>
          }
          if(text=="3"){
            return  <span>校验中</span>
          }
          if(text=="4"){
            return <span>下载成功(校检完成)</span>
          }
          if(text=="5"){
            return <span>下载失败</span>
          }
          if(text=="6"){
            return <span>取消</span>
          }
          if(text=="7"){
            return <span>暂停</span>
          }
      }
      },
      {
        title: '平均速率',
        dataIndex: 'realAverageSpeed',
        key: 'realAverageSpeed',
        render:(text)=><span>{text==null?'0'+"MB/s":text+'MB/s'}</span>
      },
      {
        title: '开始时间',
        dataIndex: 'beginTime',
        key: 'beginTime',
      },
      {
        title: '结束时间',
        dataIndex: 'endTime',
        key: 'endTime',
      },{
        title: '当前进度',
        dataIndex: 'percent',
        key: 'percent',//=text.toFixed(2)+"%"
        render:(text)=><span>{text=(text*1).toFixed(2)+"%"}</span>
      },{
        title: '备注',
        dataIndex: 'note',
        key: 'note',
      }
    ]
    return (
      <div
        className="downloadDetails"
        key={
          this.state.params != null && this.state.params.id == "-1"
            ? this.state.params.id
            : this.state.params.id
        }
        
      >
        <Spin spinning={this.state.loading} tip="Loading...">
          <div class="boxButton" style={{marginLeft:'20px'}}>
            <Button
              style={{
                display: this.state.btn_save== true ? "inline-block" : "none",
                marginLeft: 10
              }}
              onClick={this.btnClick.bind(this, this.props.flag)}
              type="primary"
              disabled={isfalse}
            >
              保存
            </Button>
            <Button
              style={{
                display: this.state.btn_update == true ? "inline-block" : "none",
                marginLeft: 20
              }}
             
              onClick={this.cencel.bind(this, 'write')}
              disabled={esfalse}
            >
              修改
            </Button>
            <Button
              style={{
                display: this.state.btn_cancel == true ? "inline-block" : "none",
                marginLeft: 20
              }}
              onClick={this.cencel.bind(this)}
              disabled={isfalse}
             
            >
              取消
            </Button>
            <Button style={{marginLeft:20}} onClick = {this.props.callback.bind(this,"1",null,null)}>返回</Button>
          </div>
          <div style={{ marginLeft: '30px'}}>
            <Row style={{ margin: '10px 5px 15px 0', borderBottom: '1px solid #ccc' }}>
            <span
              style={{
                width: "2px",
                height: "15px",
                background: "red",
                display: "inline-block",
                margin: "10px 10px 0 0"
              }}
            ></span>
              <b>基本信息</b>
        </Row>
            <Row style={{ margin: '10px 5px 15px 0' }}>
              <Col>下载单号：<span>{this.state.params.orderNum}</span> </Col>
            </Row>
            <Row style={{ margin: '10px 5px 15px 0' }}>
              <Col>下载方式：<span>{this.state.params.downloadMethod=="0"?"快递":"网络"}</span></Col>
            </Row>
            {this.state.params.downloadMethod=="0"?
            <Row style={{ margin: '10px 5px 15px 0' }}>
              <Col span={12}>快递公司：<Input disabled={isfalse} value={this.state.expressCompany} style={{ width: '200px' }}
              onChange={this.inpChange.bind(this,'expressCompany')}>
              </Input>
              </Col>
            <Col span={12}>快递单号：<Input disabled={isfalse} value={this.state.expressNum} style={{ width: '200px' }}
              onChange={this.inpChange.bind(this,'expressNum')}>
              </Input>
            {/* <span>{this.state.params.expressNum}</span> */}
              </Col>
            </Row>
            : null
            }
            <Row style={{ margin: '10px 5px 15px 0' }}>
              <Col span={10}>传输状态：<Select disabled={isfalse} value={this.state.status} style={{ width: '200px' }}
              onChange={this.valChange.bind(this,'status')}
              >
                <Option value="0">待处理</Option>
                <Option value="1">传输中</Option>
                <Option value="2">传输成功</Option>
                <Option value="3">传输失败</Option>
                <Option value="4">传输取消</Option>
                <Option value="5">传输暂停</Option> 
              </Select>
              </Col>
            </Row>
            <Row style={{ margin: '10px 5px 15px 0' }}>
              <Col span={10}>
                影院名称：<span>{this.state.params.cinemaName}</span>
              </Col>
            </Row>
            <Row style={{ margin: '10px 5px 15px 0' }}>
              <Col span={16}>
                影院地址：<span>{this.state.params. cinemaAddress}</span>
              </Col>
            </Row>
            <Row style={{ margin: '10px 5px 15px 26px' }}>
              <Col span={10}>
                时    间：<span>{this.state.params.createTime}</span>
              </Col>
            </Row> 
          </div>
          {/* downloadMethod有来判断 */}
          {this.state.params.downloadMethod=="0"?
          <div style={{ marginLeft: '30px' }}>
            {/* <Row style={{ margin: '10px 5px  15px 0', borderBottom: '1px solid #ccc' }}>
              收货信息
           </Row> */}
           <Row style={{ margin: '10px 5px 15px 0', borderBottom: '1px solid #ccc' }}>
            <span
              style={{
                width: "2px",
                height: "15px",
                background: "red",
                display: "inline-block",
                margin: "10px 10px 0 0"
              }}
            ></span>
              <b>收货信息</b>
        </Row>
            <Row style={{ margin: '10px 5px 15px 28px' }}>
              <Col> 收件人：<span>{this.state.params.addresseeName}</span> </Col>
            </Row>
            <Row style={{ margin: '10px 5px 15px 14px' }}>
              <Col> 联系方式：<span>{this.state.params.addresseePhone}</span> </Col>
            </Row>
            <Row style={{ margin: '10px 5px 15px 0' }}>
              <Col> 收件人地址：<span>{this.state.params.address}</span> </Col>
            </Row>
          </div>:
          <Table columns={columns}  dataSource={this.state.params.filmDownloadInfo}  pagination={false}></Table>
         }
        </Spin>
      </div>
    )
  }
}
export default DownloadDetail;

//<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">

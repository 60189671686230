import React from 'react';
import {Tabs,message} from 'antd';
import DeviceManageDetails from './DeviceManageDetails.js';
import DeviceManageList from './DeviceManageList.js';
const { TabPane } = Tabs;
class EquipmentManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        cinemaList:[],
        isfalse:null,
        flag:null
      }
    }
    componentWillMount() {
    this.getCinemaList()
    }
    //获取影院列表
    getCinemaList(){
      let content = {};
      let array=[];
      fetch('/api/cinema/list',{
				method: 'post',
				credentials: 'include',
							headers: {
									'Content-Type': 'application/json'
							},
							body: JSON.stringify(content),
					}).then(function (res) {             
            if(!res.ok){                 
              message.error("网络不可用！");             
            }
							return res.json();
					}).then(function (data) {
							if (data.code == 0) {
								 array=data.result;    
							}
					}).then( (e) => {
            this.setState({
              cinemaList:array
            })
				 });
    }
      
    callback(activeKey,type,params){
          if(type == "details"){
            this.state.params = params
            this.state.isfalse=true
            this.state.flag=true
          }
          if(type == "add"){
            this.state.params = null
            this.state.isfalse=false
            this.state.flag=true
          }
        this.setState({activeKey:activeKey})
      }
    

    
    render(){
      let basics = {}
      basics.cinemaList = this.state.cinemaList;
      const {isfalse,flag}=this.state
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
            <TabPane tab="设备列表" key="1">
              <DeviceManageList  buttonList={this.props.buttonList}  callback = {this.callback.bind(this)}/>
            </TabPane>
            <TabPane tab="设备详情" key="2">
              <DeviceManageDetails  buttonList={this.props.buttonList}  flag={flag} basics={basics} isfalse={isfalse}  params = {this.state.params} callback = {this.callback.bind(this)}/> 
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default EquipmentManage;
import React from "react";
import { Input, Select, Button, Spin, message, Table, Checkbox } from "antd";
import history from "../history";
import { thisExpression, isTSEnumMember } from "@babel/types";
let md5 = require("md5");

const { Option } = Select;
const { Search } = Input;
class UserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      loading: false,
      page: 0,
      pageSize: 10,
      rightList: [],
      leftList: [],
      listnum: 0,
      slist: [],
      count: 2,
      dataSource: [],
      theaterChainName: null,
      userCinemaList: [],
      isfalse: null,
      esfalse: false,
      roleLists: null,
      updatePassword: false,
      payList: [],
      payArray: [
        { type: '0', id: '0', payName: '微信' },
        { type: '1', id: '1', payName: '月结' },
        { type: '2', id: '2', payName: '资金池' },
        { type: '3', id: '3', payName: '对公付款' }
      ],
      payClass: '',
      companylist: [],
      addType:false,
      queryDepartmentList:[]
    };
  }


  componentWillMount() {
    if (this.props.params != null) {
      this.state.params = this.props.params;
      this.state.isfalse = this.props.isfalse;
      this.state.dataSource = this.state.params.userCinemaList;
      this.onload(this.state.params.id);
      this.state.flag = this.props.flag;
     
    }
    this.state.cinemaList = this.props.basics.cinemaList;
    this.state.theaterList = this.props.basics.theatreList;
    this.state.queryDepartmentList = this.props.basics.queryDepartmentList;
    // this.state.dataBasics = this.props.basics.roleList;
    this.queryList();;//获取发行方
    this.showBtn(this.props.buttonList); //控制按钮显示
    this.getRoleList();//获取角色列表
    this.setState({
      params: {}
    });

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps != null) {
      if (nextProps.params != null) {
        this.state.params = nextProps.params;
        this.state.isfalse = nextProps.isfalse;
        this.state.dataSource = this.state.params.userCinemaList || [];
        // this.state.dataBasics = nextProps.basics.roleList;
        // this.getRoleList();//获取角色列表
        this.state.rightList = [];
        this.state.esfalse = false;
        this.state.flag = nextProps.flag;
        this.onload(this.state.params.id);
        this.setState({
          payList:[]
        });
      } else {
        // this.state.dataBasics = nextProps.basics.roleList;
        this.state.rightList = [];
        this.state.roleLists = [];
        this.state.isfalse = null;
        this.state.flag = null;
        this.state.dataSource = [];
        this.setState({
          params: {},
          payList:[]
        });
        this.getRoleList();//获取角色列表
        this.queryList();//获取发行方
      }
    } else {
      this.state.isfalse = null;
      this.state.dataSource = [];
      this.state.flag = null;
      this.setState({
        params: {},
        payList:[]
      });
    }
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_save") {
        this.state.btn_save = true;
      }
      if (array[i].buttonCode == "btn_update") {
        this.state.btn_update = true;
      }
      if (array[i].buttonCode == "btn_cancel") {
        this.state.btn_cancel = true;
      }
    }
  }
  //获取角色列表
  getRoleList() {
    let content = { status: '0' };
    let array = [];
    fetch('/api/userCenter/roleList', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        array = data.result;
      }
    }).then((e) => {

      this.setState({ dataBasics: array });
      this.roleData(this.state.dataBasics);
    });
  }
  //获取数据列表的接口
  onload(id) {
    let content = { id: id };
    fetch("/api/userCenter/userDetails", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(data => {
        if (data.code == 0) {
          this.setState({
            params: data.result,
            dataSource: data.result.userCinemaList,
            roleLists: data.result.roleList
          });
          this.getRoleList();
          this.state.payList = [];
          if (this.state.params.payMethod != null) {
            this.state.payClassArr = this.state.params.payMethod.split(",");
            for (var i = 0; i < this.state.payClassArr.length; i++) {
              if (this.state.payClassArr[i] != "" && this.state.payClassArr[i] != "undefined") {
                this.state.payList.push(this.state.payClassArr[i])
              }
            }
          }
        }
      });
  }
  //翻页
  pageChange(page, pageSize) {
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }

  //input框修改
  handleChange(type, e) {
    let params = this.state.params
    if (type == "code") {
      params.code = e.target.value;
    }
    if (type == "name") {
      params.name = e.target.value;
    }
    if (type == "telephone") {
      params.telephone = e.target.value;
    }
    if (type == "email") {
      params.email = e.target.value;
    }
    if (type == "jobnumber") {
      params.jobnumber = e.target.value;
    }
    if (type == "password") {
      params.password = e.target.value;
      this.setState({ updatePassword: true });
    }

    this.setState({
      params: { ...params }
    })
  }
  roleData = (dataBasics) => {
    var rightList = []; //已分配角色数组  sysRole实体
    var allList = Array.from(dataBasics); //所有角色数组
    var chosedRoleList = this.state.roleLists; //用户拥有的角色数组 SysRoleUser实体
    if (chosedRoleList == null || chosedRoleList.length == 0) {
      //用户还没有角色时
      this.setState({ leftList: allList });
    }
    // console.log(this.state.leftList,allList,chosedRoleList)
    if (
      allList != null &&
      allList.length > 0
      && chosedRoleList != undefined &&
      chosedRoleList.length > 0
    ) {
      for (var i = 0; i < chosedRoleList.length; i++) {
        for (var x = 0; x < allList.length; x++) {
          if (chosedRoleList[i].roleId == allList[x].id) {
            rightList.push(allList[x]);
            allList.splice(x, 1);
          }
        }
      }
      this.setState({
        rightList, leftList: allList//未分配角色数组
      })

    }
  }
  //组合支付方式
  toData() {
    var str = ""
    for (var i = 0; i < this.state.payList.length; i++) {
      if (this.state.payList[i] != "undefined") {
        str += this.state.payList[i] + ","
      }
    }
    str = str.substring(0, str.length - 1);
    this.state.payClass = str;
  }
  //保存
  save() {
    this.toData();
      if(  this.state.params.code == undefined||this.state.params.code ==''){
        message.error("未填写用户名！");
        return;
      }
      if(  this.state.params.userSource == undefined){
        message.error("未填写用户归属！");
        return;
      }
      if(  this.state.params.telephone == undefined){
        message.error("未填写手机号！");
        return;
      }
      if(  this.state.params.name == undefined||this.state.params.name ==''){
        message.error("未填写姓名！");
        return;
      }
      
    if (this.state.params.code.length < 2 || this.state.params.code.length > 35) {
      message.error('用户名不正确,2-35个字')
      return;
    }
    if (!(/^[a-zA-Z]\w+$/).test(this.state.params.password)) {
      message.error('密码格式不正确 以字母开头，只能包含字符、数字和下划线')
      return;
    }
    if (this.state.params.telephone.length != 11) {
      message.error("手机号格式不正确，请重新输入！");
      return;
    }
      if(this.state.dataSource.length>0){
      for(var i=0;i<this.state.dataSource.length;i++){
        if(this.state.dataSource[i].cinemaName==''){
          return message.error('未填写影院名称');
        }
      } 
    }
   
    // if (
    //   this.state.rightList.length > 0 &&
    //   this.state.params.userSource == "1"
    // ) {
    //   message.error("影院人员暂不需要配置角色！");
    //   return;  
    // }
    // if (
    //   this.state.params.userCinemaList == undefined ||
    //   this.state.params.userCinemaList.length == 0
    // ) {
    //   message.error("影院影厅没有填写！");
    //   return;
    // }
    if(this.state.password==this.state.params.password){
      this.setState({updatePassword:false})
    }else{
      this.setState({updatePassword:true})
    }

    this.setState({ loading: true });
    let content = {
      id: this.state.params.id,
      code: this.state.params.code,
      password: this.state.params.password == null ? null : md5(this.state.params.password),
      userSource: this.state.params.userSource,
      name: this.state.params.name,
      sex: this.state.params.sex,
      telephone: this.state.params.telephone,
      email: this.state.params.email,
      jobnumber: this.state.params.jobnumber,
      departmentId: this.state.params.departmentId,
      departmentCode: this.state.params.departmentCode,
      //  cinemaId:this.state.params.cinemaId,
      //  theaterChinaId: this.state.params.theaterChinaid,
      roleList: this.state.rightList,
      userCinemaList: this.state.params.userCinemaList,
      updatePassword: this.state.updatePassword,
      payMethod: this.state.payClass,
      issuerId: this.state.params.issuerId
    };

    fetch("/api/userCenter/saveUser", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data) => {
        if (data.code == 0) {
          message.success("保存成功！");
          if (data.result != null) {
            this.onload(data.result.id);
          }
          this.state.params.password = null;
          this.setState({ loading: false, isfalse: true, esfalse: false, flag: true,updatePassword: false });
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({ loading: false, payClass: '',updatePassword: false});
      });
  }
  //select框的值
  selectChange(type, e,obj) {
    let params = this.state.params
    if (type == "userSource") {
      params.userSource = e;
    }
    if (type == "sex") {
      params.sex = e;
    }
    if (type == "departmentId") {
      params.departmentId = e;
      params.departmentCode=obj.props.value2;
    }
    // if (type == "payMethod") {
    //  params.payMethod = value;
    // }
    this.setState({
      params: params 
    })
  }
  //联动
  selectData(index, type, e, obj) {
    if (type == "cinemaId") {
      this.state.params.cinemaName = obj.props.children;
      this.state.params.cinemaId = e;
      this.setState({ loading: true });
      let array = [];
      let content = { id: this.state.params.cinemaId };
      fetch("/api/cinema/queryTheaterChainByCinema", {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(content)
      })
        .then(function (res) {
          if (!res.ok) {
            message.error("网络不可用！");
          }
          return res.json();
        })
        .then(function (data) {
          if (data.code == 0) {
            array = data.result;
          } else {
            message.error(data.message);
            if (data.code == 2003) {
              history.push({ pathname: "/" });
            }
          }
        })
        .then(e => {
          this.state.theaterChainName = array.name;
          this.state.theaterChainId = array.id
          this.state.dataSource[index] = {
            theaterChainName: this.state.theaterChainName,
            cinemaName: this.state.params.cinemaName,
            cinemaId: this.state.params.cinemaId,
            theaterChainId: this.state.theaterChainId
          };
          this.state.params.userCinemaList = this.state.dataSource;
          this.setState({ loading: false });
        });
    }
  }
  leftRoleList(id, e) {
    //左边角色列表的点击事件
    console.log(e);
    e.preventDefault();
    e.stopPropagation();
    var leftList = this.state.leftList;
    leftList.map((item, index) => {
      if (item.id == id) {
        this.state.rightList.push(item); //右加
        leftList.splice(index, 1); //左删
      }
    });
    this.setState({ leftList: leftList, rightList: this.state.rightList });

  }
  rightRoleList(id) {
    //右边角色列表的点击事件
    var rightList = this.state.rightList;
    rightList.map((item, index) => {
      if (item.id == id) {
        this.state.leftList.push(item); //左加
        rightList.splice(index, 1); //右删
      }
    });
    this.setState({ rightList: rightList });
  }
  handleAdd = () => {
    //点击增加行
    const { count, listnum, dataSource } = this.state;
    const newData = {
      id: '',
      cinemaName: '',
      theaterChainName: ''
    };
    this.state.dataSource.push(newData);
    this.setState({
      count: count + 1, 
      listnum: listnum + 1,
      dataSource: [...this.state.dataSource],
      addType:true
    });
  };

  //删除一行
  deleteData(item, index) {
    let arrs = this.state.dataSource;
    arrs.splice(index, 1);
    this.setState({
      dataSource: [...arrs]
    });
  }

  cencel(type) {
    //修改还是取消
    if (type == 'write') {
      this.setState({
        isfalse: false,
        esfalse: true
      });
    } else {
      this.setState({
        isfalse: true,
        esfalse: false,
      });
    }
  }
  //支付方式多选
  checkBoxChange(type, event) {
    if (event.target.checked) {
      this.state.payList.push(type)
    } else {
      for (var i = 0; i < this.state.payList.length; i++) {
        if (type == this.state.payList[i]) {
          this.state.payList.splice(i, 1)
        }
      }
    }
    this.setState({ payList: this.state.payList })
  }
  //发布公司接口
  queryList() {
    let array = []
    this.setState({ loading: true });
    fetch('/api/issue/queryIssueList', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({}),
    }).then(function (res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        array = data.result;
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.state.companylist = array;
      this.setState({ loading: false });
    });
  }
  //发布公司修改
  selectChanges(type, e, obj) {
    let params = this.state.params
    if (type == "issuerId") {
      params.issuerId = e
      params.company = obj.props.children
    }
    this.setState({
      params
    })
  }

  //刷新
  refresh(){
    let array=[];//影院的
    let arrayTheater=[];//院线的
    //调用影院的接口
    fetch('/api/cinema/list', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({}),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        array = data.result;
      }
    }).then((e) => {
      this.setState({ cinemaList: array });
    });
     //调用院线的接口
    fetch('/api/cinema/theatreList', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({}),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then(function (data) {
      if (data.code == 0) {
        array = data.result;
      }
    }).then((e) => {
      this.setState({ theatreList: arrayTheater });
    });
    //调用角色的接口
    this.getRoleList();
  }

  render() {
    var opList = this.state.cinemaList;

    const columns = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "影院",
        dataIndex: "cinemaName",
        key: "cinemaName",
        render: (text, record, index) => (
          <Select
            value={this.state.dataSource[index].cinemaName}
            disabled={isfalse}
            showSearch
            filterOption={true}
            optionFilterProp="children"
            onChange={this.selectData.bind(this, index, "cinemaId")}
          >
            {opList.map(item => {
              return (
                <Option value={item ? item.id : ""}>
                  {item ? item.name : ""}
                </Option>
              );
            })}
          </Select>
        )
      },

      {
        title: "院线",
        dataIndex: "theaterChainName",
        key: "theaterChainName",
        render: (text, record, index) => (
          <Input
            className="inputed"
            disabled
            value={this.state.dataSource[index].theaterChainName}
          />
        )
      },
      {
        title: "操作",
        dataIndex: "",
        key: "1",

        render: (text, record, index) => (
          <Button disabled={isfalse} onClick={this.deleteData.bind(this, record.key, index)}>
            删除
          </Button>
        )
      }
    ];
    let { isfalse, esfalse } = this.state;
    return (
      <div
        className="userDetails"
        key={
          this.state.params != null && this.state.params.id == "-1"
            ? this.state.params.id
            : this.state.params.id
        }
      >
        <Spin spinning={this.state.loading} tip="Loading...">
          <div style={{ marginLeft: 30, marginBottom: 20 }}>
            <Button
              style={{
                display: this.state.btn_save == true ? "inline-block" : "none"
              }}
              type="primary"
              onClick={this.save.bind(this)}
              disabled={isfalse}
            >
              保存
            </Button>
            <Button style={{ display: this.state.btn_update == true ? "inline-block" : "none", marginLeft: 20 }} onClick={this.cencel.bind(this, 'write')} disabled={esfalse}> 修改</Button>
            <Button style={{ display: this.state.btn_cancel == true ? "inline-block" : "none", marginLeft: 20 }} onClick={this.cencel.bind(this)} disabled={isfalse}> 取消 </Button>
            <Button style={{ marginLeft: 20 }} onClick={this.props.callback.bind(this, "1", null, null)}  disabled={isfalse}>
              返回
            </Button>
          </div>
          <Input hidden={true} defaultValue={this.state.params.id} />
          <p>
            用户名: <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
            <Input
              
              disabled={
                this.state.isfalse||this.state.flag? true : false
              }
              value={this.state.params.code}
              placeholder="请输入用户名(2-35个字)"
              onChange={this.handleChange.bind(this, "code")}
            />
          </p>

          <p className="second-element">
            用户归属:
            <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
            <Select
              disabled={isfalse}
              value={this.state.params.userSource}
              style={{ width: "78%", float: "right" }}
              onChange={this.selectChange.bind(this, "userSource")}
            >
              <Option value="">--请选择用户归属--</Option>
              <Option value="0">运营人员</Option>
              <Option value="1">影院人员</Option>
            </Select>
          </p>
          <p>

            密码: <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
            <Input
              type='text'
              disabled={
                this.state.isfalse ? true : false
              }
              value={this.state.flag ? this.state.params.password == null ? '******' : this.state.params.password : this.state.params.password}
              placeholder="(以字母开头，只能包含字符、数字和下划线)"
              onChange={this.handleChange.bind(this, 'password')}
            />
          </p>
          <p className="second-element">
            发行方：
            <Select disabled={isfalse} style={{ width: "78%", float: "right" }} value={this.state.params.issuerId} onChange={this.selectChanges.bind(this, "issuerId")}>

              {
                this.state.companylist && this.state.companylist.map((item, index) => {
                  return <Option value={item ? item.id : ""}>{item ? item.name : ""}</Option>
                })
              }
            </Select>
          </p>
          <p>
            姓名: <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
            <Input
              disabled={isfalse}
              value={this.state.params.name}
              placeholder="请输入用户姓名"
              onChange={this.handleChange.bind(this, "name")}
            />
          </p>
          <p className="second-element">
            性别：
            <Select
              disabled={isfalse}
              value={this.state.params.sex}
              style={{ width: "78%", float: "right" }}
              onChange={this.selectChange.bind(this, "sex")}
            >
              <Option value="">--请选择性别--</Option>
              <Option value="0">男</Option>
              <Option value="1">女</Option>
            </Select>
          </p>
          <p>
            联系方式:
            <span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
            <Input
              disabled={isfalse}
              value={this.state.params.telephone}
              placeholder="请输入手机号"
              onChange={this.handleChange.bind(this, "telephone")}
            />
          </p>
          <p className="second-element">
            邮箱:
            <Input
              disabled={isfalse}
              value={this.state.params.email}
              placeholder="请输入邮箱"
              onChange={this.handleChange.bind(this, "email")}
            />
          </p>

          <p>
            工号:
            <Input
              disabled={isfalse}
              value={this.state.params.jobnumber}
              placeholder="请输入工号"
              onChange={this.handleChange.bind(this, "jobnumber")}
            />
          </p>
          <p className="second-element">
            所属部门：
            <Select
              disabled={isfalse}
              value={this.state.params.departmentId}
              style={{ width: "77%", float: "right" }}
              onChange={this.selectChange.bind(this, "departmentId")}
            >
              <Option value="" value2="">---请选择所属部门---</Option>
             {this.state.queryDepartmentList &&
                  this.state.queryDepartmentList.map((item, index) => {
                    return <Option value={item?item.id:''} value2={item.code} key={index}>{item.name}</Option>;
                  })}
            </Select>
          </p>
          <p style={{ width: '50%' }} >
            支付方式:
          {this.state.payArray == undefined ? [] : this.state.payArray.map((item, index) => {

              return (
                <Checkbox disabled={isfalse} required={true} checked={this.state.payList != undefined && this.state.payList.indexOf(item.id) != -1}
                  onChange={this.checkBoxChange.bind(this, item.id)}>{item.payName}</Checkbox>
              )
            })
            }
          </p>

          {this.state.params.userSource == "0" ? null : (
            <div>
              <Button
                onClick={this.handleAdd}
                type="primary"
                style={{ marginBottom: 16, marginLeft: 30 }}
                disabled={isfalse}
              >
                增行
              </Button>
              <Button
                onClick={this.refresh.bind(this)}
                type="primary"
                style={{ marginBottom: 16, marginLeft: 30 }}
                disabled={isfalse}
              >
               刷新
              </Button>
              <div className="sbox">
                <Table
                  size={"small"}
                  columns={columns}
                  dataSource={this.state.dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                  }}
                />
              </div>
            </div>
          )}
          <div className="role-content">
            <div>用户归属角色</div>
            <div>
              <div className="role-list">
                <span className="title">未分配角色</span>
                <ul style={{ marginLeft: '10px' }}>
                  {this.state.leftList == undefined
                    ? null
                    : this.state.leftList.map(item => {
                      return (
                        <li style={{ height: '35px' }} onClick={(e) => this.leftRoleList(item.id, e)}>
                          {item.name}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <div className="role-lists">
                <div> &gt; </div>
                <div> &lt; </div>
              </div>
              <div className="role-list">
                <span className="title">已分配角色</span>
                <ul>
                  {this.state.rightList.map(item => {
                    return (
                      <li style={{ height: '35px' }} onClick={this.rightRoleList.bind(this, item.id)}>
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </Spin>
      </div >
    );
  }
}
export default UserDetails;

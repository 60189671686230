import React from 'react';
import { Table, Spin, Button, message, Tabs, Modal, Select, Checkbox,Tooltip  } from 'antd';
import history from '../history';
const { Option } = Select
class BoxManageList extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			params: [],
			page: 0,
			pageSize: 10,
			selectedRowKeys: [],
			selectedRow: [],
			selectedRowKey: [],
			selectedRows: [],
			total: 0,
			loading: false,
			nums: [],
			list: [],
			visible:false,
			type:"",
			datas:[],
			visibles:false,
		}
	}

	onload(type,ty) {
		if(type=="1"){
		 this.setState({ loading: false });
		}else{
		 this.setState({ loading: true });
		}
		let array = []
		let total = 0;
		if(ty=="1"){
			this.state.start=0
			}else{
			this.state.start=this.state.page
			}
		let content = {
			start: this.state.start, limit: this.state.pageSize,
			status: this.state.status, isRunning: this.state.isRunning,
			cinemaName: this.state.cinemaName,
			serialNumber: this.state.serialNumber,
			runningStatus: this.state.runningStatus
		};
		fetch('/api/device/queryBoxList', {
			method: 'post',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(content),
		}).then(function (res) {
			if (!res.ok) {
				message.error("网络不可用！");
			}
			return res.json();
		}).then(function (data) {
			if (data.code == 0) {
				array = data.result;
				total = data.total;
			} else {
				message.error(data.message);
				if (data.code == 2003) {
					history.push({ pathname: '/' });
				}
			}
		}).then((e) => {
			this.state.params = array;
			this.state.total = total;
			this.setState({ loading: false });
		});
	}
	componentWillMount() {
		this.onload()
		this.timer=setInterval(() => { 
			if(this.state.start==0){ 
				this.onload('1','1')
			}else{
				this.onload('1')
			}
			
			}, 5000); 
		this.showBtn(this.props.buttonList)//控制按钮显示
	}
	componentWillUnmount(){
		clearInterval(this.timer)
	}
	//数量接口
	device(type) {
		if(type=="1"){
			this.setState({ loading: false });
		   }else{
			this.setState({ loading: true });
		   }
		let array = []
		fetch('/api/device/statistics', {
			method: 'post',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({}),
		}).then(function (res) {
			if (!res.ok) {
				message.error("网络不可用！");
			}
			return res.json();
		}).then(function (data) {
			if (data.code == 0) {
				array = data.result;
			} else {
				message.error(data.message);
				if (data.code == 2003) {
					history.push({ pathname: '/' });
				}
			}
		}).then((e) => {
			this.state.nums = array;
			this.setState({ loading: false });
		});
	}

	//控制按钮显示
	showBtn(array) {
		for (var i = 0; i < array.length; i++) {
			if (array[i].buttonCode == "btn_search") {
				this.state.btn_search = true
			}
			if (array[i].buttonCode == "btn_system_upgrade") {
				this.state.btn_system_upgrade = true
			}
		}
	}
	//input的值
	valueChange(type, e) {
		if (type == "serialNumber") {
			this.state.serialNumber = e.target.value
		}
		if (type == "cinemaName") {
			this.state.cinemaName = e.target.value
		}
	}
	//select获取选中值
	onSelectChange(selectedRowKey, selectedRow) {
		this.state.selectedRow = selectedRow
		this.setState({ selectedRowKey });
	};
    //单选框获取选中值
	onSelectChanges(selectedRowKeys, selectedRow) {
		this.state.selectedRows = selectedRow
		this.setState({ selectedRowKeys });
	};
	//翻页
	pageChange(page, pageSize) {
		page=(page-1)*pageSize
		this.state.page=page;
		this.onload();
	}
	//翻页
	pageChanges(page, pageSize) {
		page=(page-1)*pageSize
		this.state.pages=page;
		this.devClick();
	}
	handleChange(type, value) {
		if (type == "status") {
			this.state.status = value
		}
		if (type == "isRunning") {
			this.state.isRunning = value
		}
		if (type == "runningStatus") {
			this.state.runningStatus = value
		}
	}
    //刷新
	onloading = () => {
		this.onload('1')
		this.device('1')
	}
	//确定 取消
	handleOk(type){
		if (this.state.selectedRows.length == 0) {
			message.error("尚未选中数据！");
			return;
		}
		if (this.state.selectedRows.length >1) {
			message.error("只能选择一条数据！");
			return;
		}
		for(var i=0;i<this.state.selectedRow.length;i++){
			this.state.list[i]={serialNumber:this.state.selectedRow[i].serialNumber,
				systemEdition:this.state.selectedRows[0].versionNum,
				boxVersionId:this.state.selectedRows[0].id,
				filePath:this.state.selectedRows[0].filePath  
			}
		}
		this.btnActive(type,this.state.list)
		this.setState({visible:false})
	  }
	actorCancel(){
		this.setState({visible:false,selectedRowKeys:[],selectedRows:[]});
	}
	//请求按钮的接口
	btnActive(type,list){
		// for (var i = 0; i < this.state.selectedRow.length; i++) {
		// 	this.state.list[i] = { serialNumber: this.state.selectedRow[i].serialNumber}
		// }

		this.setState({ loading: true });
		let content = { handle: type, list,isUpgradeDb:this.state.isUpgradeDb}
		fetch("/api/device/boxControl", {
			method: "post",
			credentials: "include",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(content)
		})
			.then(function (res) {
				if (!res.ok) {
					message.error("网络不可用！");
				}
				return res.json();
			})
			.then(function (data) {
				if (data.code == 0) {
					message.success("系统升级成功！");
				} else {
					message.error(data.message);
					if (data.code == 2003) {
						history.push({ pathname: "/" });
					}
				}
			})
			.then(e => {
				this.setState({ loading: false});
				this.state.selectedRowKey = [];
				this.state.selectedRowKeys = [];
				this.onload()
			});
	}
	//系统升级
	devClick(type) {
		if (this.state.selectedRow.length == 0) {
			message.error("尚未选中数据！");
			return;
		}
		let arrdata=[]
        fetch('/api/boxVersion/queryBoxVersionList', {
          method: 'post',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({}),
        }).then(function (res) {
          if (!res.ok) { message.error("网络不可用！"); }
          return res.json();
        }).then((data) => {
          if (data.code == 0) {
			arrdata = data.result;
			this.state.datas=arrdata
          } else {
            message.error(data.message);
            if (data.code == 2003) {
              history.push({ pathname: '/' });
            }
          }
        })
	 this.setState({visible:true,type});
	}
	//是否升级数据库
	 onChange(e) {
		 this.state.isUpgradeDb = e.target.checked;
	  }
	//触发预制
	okClick(record){
		this.setState({visibles:true,record})
	  }
	handleshe(){
	let {record}=this.state;
    let content={serialNumber:record.serialNumber};
    fetch('/api/device/toSetPrecut', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then( (data)=> {
      if (data.code == 0) {
          message.success('触发预制成功');
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      
      }
	  this.setState({visibles:false});
      this.onload();
    })
  }
  cancelshe(){
    this.setState({visibles:false});
  }
	render() {
		const cols=[{
			title: '版本号',
			dataIndex: 'versionNum',
			key: 'versionNum'
		},
		{
			title: '版本名称',
			dataIndex: 'versionName',
			key: 'versionName'
		},
		{
			title: '是否可用',
			dataIndex: 'status',
			key: 'status',
			render:(text)=>text=="0"?"是":text=="1"?"否":""
		}]
		const columns = [
			{
				title: '序号',
				render: (text, record, index) => `${index + 1}`,
			}, {
				title: '序列号',
				dataIndex: 'serialNumber',
				key: 'serialNumber',
				render: (text, record) => (
					<a
						href="javascript:;"
						onClick={this.props.callback.bind(this, "2", "details", record)}
					>
						{text}
					</a>
				)
			},
			{
				title: '盒子名称',
				dataIndex: 'deviceName',
				key: 'deviceName',
				render: (text, record) => (
					<a
						href="javascript:;"
						onClick={this.props.callback.bind(this, "3", "journal", record)}
					>
						{text}
					</a>
				)
			},
			{
				title: '盒子ip',
				dataIndex: 'ipMsg',
				key: 'ipMsg',
				onCell:()=>{
					return {
						style:{
							maxWidth:100,
							overflow:'hidden',
							whiteSpace:'nowrap',
							textOverflow:'ellipsis',
							cursor:'pointer'
						}
					}
				},
			   render:(text)=><Tooltip placement='topLeft' title={text}>{text}</Tooltip>
			},
			{
				title: 'agent心跳',
				dataIndex: 'agentHeart',
				key: 'agentHeart',
				render:(text)=>text=='0'?"离线": text == '1'?"在线":""
			},
			{
				title: '激活状态',
				dataIndex: 'status',
				key: 'status',
				render: (text, record) => text == '0' ? "未激活" :  text == '1'?"已激活":'',
			},
			{
				title: '在线状态',
				dataIndex: 'isRunning',
				key: 'isRunning',
				render: (text, record) => text == '0' ? "离线" :text=='1'?"在线":""
			},
			// {
			// 	title: '运行状态',
			// 	dataIndex: 'runningStatus',
			// 	key: 'runningStatus',
			// 	render: (text, record) => {
			// 		if (text == "0") {
			// 			return <span>正常</span>
			// 		}
			// 		if (text == "1") {
			// 			return <span>预警</span>
			// 		}
			// 		if (text == "2") {
			// 			return <span>错误</span>
			// 		}
			// 	}
			// }, 
			{
				title: '院线',
				dataIndex: 'theaterChainName',
				key: 'theaterChainName',
			}, {
				title: '影院',
				dataIndex: 'cinemaName',
				key: 'cinemaName',
			}, {
				title: '激活时间',
				dataIndex: 'activeTime',
				key: 'activeTime ',
			}, {
				title: '最近上线时间',
				dataIndex: 'newOnlineTime',
				key: 'newOnlineTime',
			},
			{
				title: '是否最新版本',
				dataIndex: 'isNewest',
				key: 'isNewest',
				render:(text)=>text==0?"否":"是"
			},
			{
				title:'操作',
				dataIndex:'',
				render:(text,record,index)=><Button type="primary" onClick={this.okClick.bind(this,record)}>触发预制</Button>
			  }

		];
		const dataSource = this.state.params;
		const { selectedRowKeys,selectedRowKey } = this.state; 
		const { selectedRow,datas,selectedRows } = this.state;
		const rowSelections= {
			selectedRowKey,
			selectedRow,
			onChange: this.onSelectChange.bind(this),//获取选中值  //,marginLeft:'58px'
		};
		const rowSelects={
			selectedRowKeys,
			selectedRows,
			// type:'radio',
			onChange: this.onSelectChanges.bind(this),
			// getCheckboxProps: record => ({
			// 	disabled: record.status == '1',
			// 	name: record.deviceType,
			//   })
		}
		return (
			<div>
				<Spin spinning={this.state.loading} tip="Loading...">
					<div className="page-content-info">
						<div className="toolbar-group">
							<div className="toolbar-left" style={{ width: '100%' }}> 
								<div className="shebox">
									<div className="she"><span>设备总数</span><span style={{ display: 'inline-block', marginTop: 10 }}>{this.state.nums.allCount}</span></div>
									<div className="she"><span><i style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: 50, background: 'blue' }}>
									</i>激活设备</span><span style={{ display: 'inline-block', marginTop: 10 }}>{this.state.nums.activeCount}</span></div>
									<div className="shes"><div style={{width:'80%',display:"flex",flexDirection:"column",textAlign:"center"}}><span style={{ display: 'inline-block', width: '100%' }}>
										<i style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: 50, background: 'green' }}></i>在线设备</span>
										<span style={{ display: 'inline-block', marginTop: 10 }}>{this.state.nums.onlineCount}</span></div>
										<div style={{width:'20%'}}><Button style={{ width: '80px' }} type="primary" onClick={this.onloading}>刷新</Button>
										</div>
									</div>
								</div>
								<div style={{ height: '30px', width: '100%', background: '#ccc' }}><h5 style={{ margin: '20px', lineHeight: '30px' }}>设备列表</h5></div>
								<div style={{ width: 167, margin:'12px'}}>
									<Button  type="primary" style={{display: this.state.btn_system_upgrade == true ? "inline-block" : "none" }} onClick={this.devClick.bind(this, '4')}>系统升级</Button></div>
								<div className="form-main">
									<input className="form-control" onChange={this.valueChange.bind(this, "serialNumber")} placeholder="请输入序列号" />
								</div>
								<div className="form-main">
									激活状态：
                                    <Select
										style={{ width: 167 }}
										onChange={this.handleChange.bind(this, "status")}
										placeholder='全部'
									>
										<Option value="">--全部--</Option>
										<Option value="0">未激活</Option>
										<Option value="1">已激活</Option>
									</Select>
								</div>
								<div className="form-main">
									在线状态：
                                    <Select
										style={{ width: 167 }}
										onChange={this.handleChange.bind(this, "isRunning")}
										placeholder='全部'
									>
										<Option value="">--全部--</Option>
										<Option value="0">离线</Option>
										<Option value="1">在线</Option>
									</Select>
								</div>
								<div className="form-main">
									运行状态：
                                    <Select
										style={{ width: 167}}
										onChange={this.handleChange.bind(this, "runningStatus")}
										placeholder='全部'
									>
										<Option value="">--全部--</Option>
										<Option value="0">正常</Option>
										<Option value="1">预警</Option>
										<Option value="2">错误</Option>
									</Select>
								</div>
								<div className="form-main">
									<input className="form-control" onChange={this.valueChange.bind(this, "cinemaName")} placeholder="请输入影院" />
								</div>
								<button style={{ display: this.state.btn_search == true ? "inline-block" : "none" }} onClick={this.onload.bind(this,'1','1')} className="btn btn-default btn-search" type="button">检索</button>
							</div>
							<div className="toolbar-right" style={{ width: '100%' }}>
								<Table size={"small"} rowKey={record => record.id} rowSelection={rowSelections} columns={columns} dataSource={dataSource} pagination={{ onChange: this.pageChange.bind(this), total: this.state.total }} />
							</div>
						</div>
					</div>
				</Spin>
				<Modal title="列表" okText="确认"  cancelText="取消" width="500px"  style={{marginTop:"20px"}} visible={this.state.visible}  onOk={this.handleOk.bind(this,this.state.type)} onCancel={this.actorCancel.bind(this)}>
                  <div style={{width:'100%',textAlign:"center"}}>
				  <Checkbox onChange={this.onChange.bind(this)}>是否升级数据库</Checkbox>
				  <Table size={"small"} columns={cols} dataSource={datas}  pagination={{onChange: this.pageChanges.bind(this)}} rowSelection={rowSelects}/>
                  </div>
                </Modal>
			    <Modal title="提示" okText="确认" cancelText="取消" width="300px" style={{ marginTop: "20px" }} visible={this.state.visibles} onOk={this.handleshe.bind(this)} onCancel={this.cancelshe.bind(this)}>
				<div style={{ width: '100%', textAlign: "center", height: "5px", lineHeight: "5px" }}>
					确定触发预制
				</div>
        </Modal>
			</div>
		)
	}
}
export default BoxManageList;

import React from 'react';
import { Table, Spin, DatePicker, message, Modal, Select, Button,Input } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import history from '../history';
const { Option } = Select;
class OrderList extends React.Component {  //订单
    constructor(props) {
        super(props);
        this.state = {
            params: [],
            enumArray: [],
            selectedRowKeys: [],
            detalisParams: {},
            page: 0,      //页数
            pageSize: 10, //数量条数
            total: 0,
            loading: false,
            details: false,
            startValue: null,//日期定义
            endValue: null,
            endOpen: false,
            cinemaList: [],
            filmList:[],
            visible:false
        }
    }
    //分页
    pageChange(page, pageSize) {
        page = (page - 1) * pageSize
        this.state.page = page;
        this.onload();
    }

    valueChange(type, e) {
        // this.state.page = 0;
        if (type == "status") {
            this.state.status = e
        }
        if (type == "payStatus") {
            this.state.payStatus =e
        }
        if (type == "keyProcessStatus") {
            this.state.keyProcessStatus = e
        }
        if (type == "transmissionStatus") {
            this.state.transmissionStatus = e
        }
    }
    //请求影院的接口
    onLoading() {
        let content = {};
        let array = [];
        fetch('/api/cinema/list', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
            }
        }).then((e) => {
            this.setState({
                cinemaList: array
            })
        });
    }
    onload(ty) {  //请求列表接口
        this.setState({ loading: true });
        let array = []
        let total = 0;
        if(ty=="1"){
            this.state.start=0
          }else{
            this.state.start=this.state.page
          }
        let content = {
            start: this.state.start, limit: this.state.pageSize,
            payStatus: this.state.payStatus, status: this.state.status,
            keyProcessStatus: this.state.keyProcessStatus,
            transmissionStatus: this.state.transmissionStatus,
            endTime: this.state.endTime, beginTime: this.state.beginTime,
            cinemaId: this.state.cinemaId,orderNum:this.state.orderNum
            // createTime: this.state.createTime
        };
        // console.log(content)
        fetch('/api/keyOrder/queryKeyOrderList', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'  
            },
            body: JSON.stringify(content),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                array = data.result;
                total = data.total;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.params = array;
            this.state.total = total;
            this.state.selectedRowKeys = [];
            this.setState({ loading: false});
        });
    }
    componentWillMount() {
        this.onload()
        this.onLoading()
        this.showBtn(this.props.buttonList)//控制按钮显示
    }
    //控制按钮显示
    showBtn(array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].buttonCode == "btn_search") {
                this.state.btn_search = true
            }
            if (array[i].buttonCode == "btn_export") {
                this.state.btn_export = true
            }
        }
    }
    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
    };

    orderDetails(params) {//查看订单详情
        var obj = {};
        fetch('/api/downApply/orderDetail', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: params.id }),
        }).then(function (res) {
            if (!res.ok) { message.error("网络不可用！"); }
            return res.json();
        }).then(function (data) {
            if (data.code == 0) {
                obj = data.result;
            } else {
                message.error(data.message);
                if (data.code == 2003) {
                    history.push({ pathname: '/' });
                }
            }
        }).then((e) => {
            this.state.detalisParams = obj;
            this.setState({ details: true })
        });
    }

    // detailsOk = e => {
    //     this.setState({ details: false });
    // }

    //日期代码
    disabledStartDate = startValue => {
        const { endValue } = this.state;
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };
    disabledEndDate = endValue => {
        const { startValue } = this.state;
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };
    onChange = (field, value) => {
        this.setState({
            [field]: value,
        });
    };
    handleStartOpenChange = open => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange = open => {
        this.setState({ endOpen: open });
    };
    onStartChange = (value, vs) => {
        this.state.beginTime = vs
        this.onChange('startValue', value);
    };

    onEndChange = (value, vs) => {
        this.state.endTime = vs
        this.onChange('endValue', value);
    };
    SelectChange(type, value) {
      if(type=="cinemaId"){
        this.state.cinemaId = value
      }
    }
    //弹框按钮的确定 取消
  handleOk(){
    this.exportEvent()
    this.setState({visible:false})
  }
  actorCancel(){
    this.setState({visible:false})
}
 //点击按钮
  eventClick(){
   this.setState({visible:true}) //弹框
    // this.btnActive(type)
  }
    //导出
    exportEvent(){
        let content={ payStatus: this.state.payStatus, status: this.state.status,
            keyProcessStatus: this.state.keyProcessStatus,
            transmissionStatus: this.state.transmissionStatus,
            endTime: this.state.endTime, beginTime: this.state.beginTime,
            orderNum:this.state.orderNum,
            cinemaId: this.state.cinemaId}
            fetch('/api/keyOrder/billExport', {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'  
            },
            body: JSON.stringify(content),
        }).then(response => {
            response.blob().then(blob => {
                console.log(blob)
              let blobUrl = window.URL.createObjectURL(blob);
              let a = document.createElement('a'); //获取a标签元素
              let filename='orderList.xls'
              a.href = blobUrl; //设置a标签路径
              a.download =filename;
              a.click();
              window.URL.revokeObjectURL(blobUrl);
              message.success("导出成功");
            });
          })
          .catch(error => {
            message.error("导出失败", error);
          });
    }
    valChange(type,e){
        let state=this.state
     if(type=="orderNum"){
        state.orderNum= e.target.value
     }
   this.setState({
       state
   })
    }  

    render() {
        const { startValue, endValue, endOpen } = this.state;
        const columns = [
            {
                title: '序号',
                render: (text, record, index) => `${index + 1}`,
            },
            {
                title: '订单号',
                dataIndex: 'orderNum',
                key: 'orderNum',
                render:(text,record)=><a href="javascript:;" onClick = {this.props.callback.bind(this,"2","details",record)}>{text}</a>
            },
            {
                title: '价格(元)',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: '影片名称',
                dataIndex: 'filmName',
                ellipsis: true,
                key: 'filmName',
            render:(text,record,index)=> <span>
            {
               record.filmList.map((item, index) => {
               return <span key={index}>{item.filmName}</span>
                })
            }
        </span>
            },
            {
                title: '下单时间',
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: '支付状态',
                dataIndex: 'payStatus',
                key: 'payStatus',
                render: (text,record) => {
                    if(text=="0"){
                      return <span>未支付</span>
                    }
                    if(text=="1"){
                      return <span>已支付</span>
                    }
                    if(text=="2"){
                      return  <span>支付失败</span>
                    }
                }
            },
            {
                title: '订单状态',
                dataIndex: 'status',
                key: 'status',
                render: (text,record) => {
                    if(text=="0"){
                      return <span>未处理</span>
                    }
                    if(text=="1"){
                      return <span>处理中</span>
                    }
                    if(text=="2"){
                      return  <span>处理完成</span>
                    }
                    if(text=="3"){
                      return  <span>处理失败</span>
                    }
                    if(text=="4"){
                      return <span>处理取消</span>
                    }
                }
            },
            {
                title: '密钥处理状态',
                dataIndex: 'keyProcessStatus',
                key: 'keyProcessStatus',
                render: (text,record) => {
                    if(text=="0"){
                      return <span>待处理</span>
                    }
                    if(text=="1"){
                      return <span>申请中</span>
                    }
                    if(text=="2"){
                      return  <span>申请成功</span>
                    }
                    if(text=="3"){
                      return  <span>申请失败</span>
                    }
                    if(text=="4"){
                      return <span>已上传</span>
                    }
                    if(text=="5"){
                      return <span>传输完成</span>
                    }
                    if(text=="6"){
                        return <span>传输失败</span>
                      }
                }
            },
            {
                title: 'DCP传输状态',
                dataIndex: 'transmissionStatus',
                key: 'transmissionStatus',
                render: (text,record) => {
                    if(text=="0"){
                      return <span>待处理</span>
                    }
                    if(text=="1"){
                      return <span>传输中</span>
                    }
                    if(text=="2"){
                      return  <span>传输完成</span>
                    }
                    if(text=="3"){
                      return  <span>传输失败</span>
                    }
                    if(text=="4"){
                      return <span>传输取消</span>
                    }
                    if(text=="5"){
                      return <span>传输暂停</span>
                    }
                }
            },
            {
                title: '导出状态',  
                dataIndex: 'exportStatus',
                key: 'exportStatus',
                render:text=>text=="0"?"未导出":text=="1"?"已导出":"已确认"
            },
            {
                title: '影院名称',
                dataIndex: 'cinemaName',
                key: 'cinemaName',
                render:(text,record) => {
                    if(text!=null&&text.length>15){
                        return text.substring(0,15)+"..."
                      }else{
                        return text
                      }
                },  
            },
            // {
            //     title: '处理人',
            //     dataIndex: 'dealWithPeople',
            //     key: 'dealWithPeople',
            // },

            // {
            //     title: '处理时间',
            //     dataIndex: 'dealWithTime',
            //     key: 'dealWithTime'
            // }, {
            //     title: '备注',
            //     dataIndex: 'description',
            //     dataIndex: 'description',
            //     key: 'description',
            //     ellipsis: true
            // },
        ]
        const dataSource = this.state.params;
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };
       
        // const detalisSource = this.state.detalisParams.filmList;
        return (
            <div className="orderList">
                <Spin spinning={this.state.loading} tip="Loading...">
                    <div className="page-content-info">
                        <div className="toolbar-group">
                            <div className="toolbar-left">
                                {/* <div class="form-main">
                                    <input class="form-control" onChange={this.valueChange.bind(this, "orderNum")} placeholder="请输入订单号" />
                                </div>
                                <div class="form-main">
                                    <div class="input-group date form-datetime" data-date="2019-05-10T05:25:07Z" data-date-format="dd MM yyyy - HH:ii p" data-link-field="dtp_input1">
                                        <DatePicker locale={locale} onChange={this.onChange.bind(this)} placeholder="请选择申请时间" />
                                    </div>
                                </div> */}
                                {/* <div className="frombox"> */}
                                    <div className="form-main">
                                        {/* className="bs-select form-control fixed" */}
                                        <Select onChange={this.valueChange.bind(this, "status")} placeholder="请选择订单状态" style={{width:'167px'}}>
                                            <Option value="">--订单状态--</Option>
                                            <Option value="0">未处理</Option>
                                            <Option value="1">处理中</Option>
                                            <Option value="2">处理完成</Option>
                                            <Option value="3">处理失败</Option>
                                            <Option value="4">处理取消</Option>
                                        </Select>
                                    </div>
                                    <div className="form-main">
                                        <Select onChange={this.valueChange.bind(this, "payStatus")} placeholder="请选择支付状态" style={{width:'167px'}}>
                                            <Option value="">--支付状态--</Option>
                                            <Option value="0">未支付</Option>
                                            <Option value="1">已支付</Option>
                                            <Option value="2">支付失败</Option>
                                        </Select>
                                    </div>
                                    <div className="form-main">
                                        <Select onChange={this.valueChange.bind(this, "keyProcessStatus")} placeholder="秘钥处理状态" style={{width:'167px'}}>
                                            <Option value="">--秘钥处理状态--</Option>
                                            <Option value="0">待处理</Option>
                                            <Option value="1">申请中</Option>
                                            <Option value="2">申请成功</Option>
                                            <Option value="3">申请失败</Option>
                                            <Option value="4">已上传</Option>
                                            <Option value="5">传输成功</Option>
                                            <Option value="6">传输失败</Option>
                                        </Select>
                                    </div>
                                    <div className="form-main">
                                        <Select onChange={this.valueChange.bind(this, "transmissionStatus")} placeholder="DCP传输状态" style={{width:'167px'}}>
                                            <Option value="">--DCP传输状态--</Option>
                                            <Option value="0">待处理</Option>
                                            <Option value="1">传输中</Option>
                                            <Option value="2">传输成功</Option>
                                            <Option value="3">传输失败</Option>
                                            <Option value="4">传输取消</Option>
                                            <Option value="5">传输暂停</Option>
                                        </Select>
                                    </div>

                                    <div className="form-main">
                                        <DatePicker
                                            style={{ width: '167px' }}
                                            disabledDate={this.disabledStartDate}
                                            // showTime
                                            format="YYYY-MM-DD"
                                            value={startValue}
                                            placeholder="请选择开始时间"
                                            onChange={this.onStartChange}
                                            onOpenChange={this.handleStartOpenChange}
                                        />
                                        -
                                            <DatePicker
                                            style={{ width: '167px' }}
                                            disabledDate={this.disabledEndDate} 
                                            format="YYYY-MM-DD"
                                            value={endValue}
                                            placeholder="请选择结束时间"
                                            onChange={this.onEndChange}
                                            open={endOpen}
                                            onOpenChange={this.handleEndOpenChange}
                                        />
                                    </div>
                                    <div class="form-main">
                                        <Input class="form-control" placeholder="请输入订单号" onChange={this.valChange.bind(this,'orderNum')}/> 
                                     </div>
                                    <div style={{marginLeft:901}}>
                                        <Select
                                            showSearch
                                            style={{ width: '167px' }}
                                            placeholder="请输入影院"
                                            optionFilterProp="children"
                                            onChange={this.SelectChange.bind(this, 'cinemaId')}
                                            filteroption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            <option value="">影院</option>
                                            {this.state.cinemaList.map((item) => {
                                                return (
                                                    <option value={item.id}>{item.name}</option>
                                                )
                                            })
                                            }
                                        </Select>
                                        <button style={{ display: this.state.btn_search == true ? "inline-block" : "none" }} onClick={this.onload.bind(this,'1')} className="btn btn-default btn-search" type="button">检索</button>
                                        <Button style={{ display: this.state.btn_export == true ? "inline-block" : "none" ,marginLeft:'10px',marginRight:'10px',height:'33px'}} onClick={this.eventClick.bind(this)}>导出</Button>
                                  
                                    </div>
                                   
                                   
                                   
                            </div>
                            <div className="toolbar-right" style={{ width: '100%' }}>
                                <Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource} pagination={{ onChange: this.pageChange.bind(this), total: this.state.total }}/>
                            </div>
                        </div>
                    </div>
                </Spin>
                {/* <Modal width={800} title="详情" visible={this.state.details} onOk={this.detailsOk} onCancel={this.detailsOk}>
                    <Table columns={detalisColumns} dataSource={detalisSource} pagination={false} />
                </Modal> */} 
                 <Modal title="提示" width="300px" cancelText="取消"  okText="确认" style={{marginTop:"20px"}} visible={this.state.visible}  onOk={this.handleOk.bind(this)} onCancel={this.actorCancel.bind(this)}>
                  <div style={{width:'100%',textAlign:"center",height:"5px",lineHeight:"5px"}}>
                  <b>是否导出?</b>
                  </div>
                </Modal>
            </div>
        )
    }
}
export default OrderList;
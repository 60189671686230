import React from "react";
import { Table, Spin, Button, message, Select, Modal, DatePicker,Icon,Row,Col,Input } from "antd";
import history from "../history";
import InAndOutRecordList from '../InAndOutRecord/InAndOutRecordList';
const { confirm } = Modal;
const { Option } = Select;
class HardDiskList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      pages: 0,
      pageSizes: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      totals: 0,
      loading: false,
      display: 'none',
      visible:'none',
      adata:{},
      record:{},
      sort:'',
      orderBy:'desc',
      orderBys:'desc',
      arecord:{},
      visibles:false,
      recordList:[],
      oneRecord:{}
    };
  }

  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if (ty == "1") {
      this.state.start = 0
    } else {
      this.state.start = this.state.page
    }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      lastOperate:this.state.lastOperate,
      harddiskNumber: this.state.harddiskNumber, 
      sn: this.state.sn,
      storageCapacity: this.state.storageCapacity,
      currentStatus:this.state.currentStatus,
      orderBy:this.state.orderBy,
      sort:this.state.sort
    };
    // console.log(content)
    fetch("/api/harddiskManage/queryHarddiskManageList", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;

          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.setState({ loading: false });
      });
  }
  componentWillMount() {
    this.onload();
    // this.showBtn(this.props.buttonList); //控制按钮显示
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true;
      }
      if (array[i].buttonCode == "btn_add") {
        this.state.btn_add = true;
      }
      if (array[i].buttonCode == "btn_delete") {
        this.state.btn_delete = true;
      }
    }
  }
 //新建 或 修改
 addClick(type,ty,record) {
  let display = this.state.display;
  if (type == "none") {
    display = "block"
  } else if (type == "block") {
    display = "none";
    this.state.params.harddiskNumber='';  
    this.state.params.sn='';
    this.state.params.storageCapacity='';
    this.setState({
      params:this.state.params
    })
  }
  this.state.ty=ty;
  if(ty=='0'){
    this.state.record=record;
  }
  
  this.setState({ display,ty:this.state.ty,record:this.state.record})
}
//新建的确定 或 修改的确定
okClick() {
  let content={}
  let str=''
  if(this.state.ty=='1'){
      if(this.state.params.harddiskNumber==''||this.state.params.harddiskNumber==undefined||this.state.params.harddiskNumber==null){
        return message.error('未填写硬盘编号');
      }
      if(this.state.params.sn==''||this.state.params.sn==undefined||this.state.params.sn==null){
        return message.error('未填写sn');
      }
      if(this.state.params.storageCapacity==''||this.state.params.storageCapacity==undefined||this.state.params.storageCapacity==null){
        return message.error('未填写存储容量');
      }
      if(this.state.params.sn.length!=12){
          return message.error('sn请输入12位');
      }
     content = {
      harddiskNumber: this.state.params.harddiskNumber,  
      sn: this.state.params.sn,
      storageCapacity: this.state.params.storageCapacity
    }
    str='新建成功'
  }else{
    if(this.state.params.storageCapacity==''||this.state.params.storageCapacity==undefined||this.state.params.storageCapacity==null){
      return message.error('未填写存储容量');
    }
     content={
      storageCapacity:this.state.params.storageCapacity, 
      harddiskNumber: this.state.record.harddiskNumber, 
      sn: this.state.record.sn, 
      id:this.state.record.id
     }
     str='修改成功'
  }
  console.log(content)
  this.addClick('block',this.state.ty,this.state.record)
  this.setState({ loading: true });
  fetch("/api/harddiskManage/addHarddiskManage", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(content)
  })
    .then(function (res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then((data) => {
      if (data.code == 0) {
        message.success(str);
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: "/" });
        }
      }
    })
    .then(e => {
      this.setState({ loading: false});
      this.onload();
    });
}
  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }

  pageChange(page, pageSize) {
    //翻页
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
 //点击排序
  clickEvent(type,name){
  if(type=='0'){
    this.state.orderBy='desc'
  }else if(type=='1'){
    this.state.orderBy='asc'
  }
  if(name=='storageCapacity'){
    this.state.sort='storage_capacity'
  }
  if(name=='createDate'){
    this.state.sort='create_date'
  }
  if(name=='lastOperateTime'){
    this.state.sort='last_operate_time'
  }
  //可简化  this.state.orderBy=type=='0'?'desc':type=='1'?'asc':'';
  //this.state.sort=name;
  this.setState({orderBy:this.state.orderBy,sort:this.state.sort})
  this.onload();
  }
  //多个删除
  showConfirm () {
      if (this.state.selectedRow.length == 0) {
        message.error("尚未选中数据！");
        return;
      }
      for(var i=0;i<this.state.selectedRow.length;i++){
        if(this.state.selectedRow[i].currentStatus=='1'){
          message.error("选中数据中有出库的硬盘！");
          return;
        }
      }
    confirm({
    title: <div>该硬盘一旦删除,不可找回。<br/>确定删除{this.state.selectedRow.map(item=><span style={{display:'block'}}>{item.sn}硬盘?</span>)}</div>,
      content: "",
      okText: '确定',
      cancelText:"取消",
      onOk: () => {
        this.setState({ loading: true });
        let array = this.state.selectedRow.map((item, index) => { return { id: this.state.selectedRow[index].id,harddiskNumber: this.state.selectedRow[index].harddiskNumber,
        sn: this.state.selectedRow[index].sn,storageCapacity: this.state.selectedRow[index].storageCapacity
         } }); 
       this.delectEvent(array);
      },
      onCancel() { }
    });
  };
  //每行删除
  hangDelect(record){
    confirm({
      title: <div>该硬盘一旦删除,不可找回。<br/>确定删除<span>{record.sn}硬盘?</span></div>,
        content: "",
        okText: '确定',
        cancelText:"取消",
        onOk: () => {
          this.setState({ loading: true });
          let array = [{id:record.id,harddiskNumber:record.harddiskNumber,
            sn:record.sn,storageCapacity:record.storageCapacity}]
         this.delectEvent(array);
        },
        onCancel() { }
      });
  };
  //删除接口
  delectEvent(array){
    fetch("/api/harddiskManage/deleteHarddiskManage", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(array)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          message.success("删除成功");
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.selectedRow.length=0;
        this.onload();
      });
  }
  //新增的值
  valueChange(type, e) {
    let {params}=this.state;
    if (type == "harddiskNumber") {
      params.harddiskNumber = e.target.value;
    }
    if (type == "sn") {
      if(!(/^[a-zA-Z0-9]*$/g).test(e.target.value)){
        return message.error('sn填写数字和字母');
      }
      params.sn = e.target.value.toUpperCase();
    }
    if (type == "storageCapacity") {
      if((/^[0-9]*$/).test(e.target.value)){
        params.storageCapacity = e.target.value;
      }else{
        return message.error('存储容量只可填写数字');
      }
      
    }
    this.setState({params})
  }
  //查询的值
  valueChanges(type, e){
    if (type == "harddiskNumber") {
      this.state.harddiskNumber = e.target.value
    }
    if (type == "sn") {
      this.state.sn = e.target.value
    }
    this.setState({harddiskNumber: this.state.harddiskNumber,sn:this.state.sn})
  }
  //select值
  handleChange(type, value) {
    if (type == "currentStatus") {
     this.state.currentStatus = value
    }

    if (type == "lastOperate") {
      this.state.lastOperate = value
    }
    if(type=='storageCapacity'){
      this.state.storageCapacity=value
    }
   this.setState({currentStatus:this.state.currentStatus,
    lastOperate:this.state.lastOperate,
    storageCapacity:this.state.storageCapacity})
  }
  //入库
  inClick(){
    if (this.state.selectedRow.length == 0) {
      message.error("尚未选中数据！");
      return;
    }
    confirm({
      title:<div>确定将SN:<br/>{this.state.selectedRow.map(item=><span style={{display:'block'}}>{item.sn}硬盘</span>)}入库?</div>,
      content: "",
      okText: '确定',
      cancelText:"取消",
      onOk: () => {
        this.setState({ loading: true });
        let array = this.state.selectedRow.map((item, index) => { return { id: this.state.selectedRow[index].id,harddiskNumber: this.state.selectedRow[index].harddiskNumber,
        sn: this.state.selectedRow[index].sn,storageCapacity: this.state.selectedRow[index].storageCapacity
         } }); 
        fetch("/api/harddiskManage/putStorageHarddisk", {
          method: "post",
          credentials: "include",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(array)
        })
          .then(function (res) {
            if (!res.ok) {
              message.error("网络不可用！");
            }
            return res.json();
          })
          .then(function (data) {
            if (data.code == 0) {
              message.success("入库成功");
            } else {
              message.error(data.message);
              if (data.code == 2003) {
                history.push({ pathname: "/" });
              }
            }
          })
          .then(e => {
            this.setState({ loading: false });
            this.state.selectedRow.length=0;
            this.onload();
          });
      },
      onCancel() { }
    });
    
  }
  //出库 输入的值
  handleChanges(type,e){
    let {deliverNumber,destAddress,addressee,phoneNumber,purpose}=this.state;
    if (type == "deliverNumber") {
     deliverNumber = e.target.value
    }
    if (type == "destAddress") {
     destAddress = e.target.value
    }
    if (type == "addressee") {
     addressee = e.target.value
    }
    if (type == "phoneNumber") {
     phoneNumber = e.target.value
    }
    if (type == "purpose") {
      purpose = e
     }
    this.setState({deliverNumber,destAddress,addressee,phoneNumber,purpose})
  }
  //确认出库
  outClick(){
    if(this.state.deliverNumber==''||this.state.deliverNumber==undefined||this.state.deliverNumber==null){
      return message.error('未填写快递单号');
    }
    if(this.state.destAddress==''||this.state.destAddress==undefined||this.state.destAddress==null){
      return message.error('未填写目标地址');
    }
    if(this.state.addressee==''||this.state.addressee==undefined||this.state.addressee==null){
      return message.error('未填写收件人');
    }
    if(this.state.phoneNumber==''||this.state.phoneNumber==undefined||this.state.phoneNumber==null){
      return message.error('未填写联系方式');
    }
  
    if(this.state.purpose==''||this.state.purpose==undefined||this.state.purpose==null){
      return message.error('未选择用途');
    }
    if((/^1[3-9]\d{9}$/).test(this.state.phoneNumber)==false){
      return message.error('手机号填写不正确');
    }
    this.cencalClick('block',this.state.adata);
    this.setState({ loading: true });
   let obj={
    id:this.state.adata.id,
    harddiskNumber: this.state.adata.harddiskNumber, 
    sn: this.state.adata.sn,
    storageCapacity: this.state.adata.storageCapacity,
    deliverNumber:this.state.deliverNumber,
    addressee:this.state.addressee,
    phoneNumber:this.state.phoneNumber,
    destAddress:this.state.destAddress,
    purpose:this.state.purpose
   }
   fetch("/api/harddiskManage/outStorageHarddisk", {
    method: "post",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(obj)
  })
    .then(function (res) {
      if (!res.ok) {
        message.error("网络不可用！");
      }
      return res.json();
    })
    .then(function (data) {
      if (data.code == 0) {
        message.success("出库成功");
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: "/" });
        }
      }
    })
    .then(e => {
      this.setState({ loading: false });
      this.onload();
    });
  }
  //出库弹框
  cencalClick(type,record){
    let visible = this.state.visible;
  if (type == "none") {
    visible = "block"
  } else if (type == "block") {
    visible = "none";
    this.setState({  
      deliverNumber:'',
      addressee:'',
      phoneNumber:'',
      destAddress:'',
      purpose:''
    })
  }
  this.setState({ visible,adata:record})
  }
  //查看入出库记录
  lookRecord(record,ty){
    this.setState({oneRecord:record})
    if (ty == "1") {
      this.state.starts = 0
    } else {
      this.state.starts = this.state.pages
    }
    this.setState({visibles:true,loading:true,recordList:[]})
    let array = [];
    let totals = 0;
    let content={
      harddiskNumber:record.harddiskNumber, 
      start: this.state.starts,
      limit: this.state.pageSizes,
      orderBy:this.state.orderBys,
    }
    fetch("/api/harddiskOperate/queryOutAndPutListByHardNum", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(function (data) {
        if (data.code == 0) {
          array = data.result;
          totals = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.state.totals = totals;
        this.setState({ loading: false,recordList:array });
      });
  }
  
  //关闭出入库查看
  recordCancel(){
   this.setState({visibles:false})
  }
  //出入库翻页
  pageChanges(pages, pageSizes) {
    //翻页
    pages = (pages - 1) * pageSizes;
    this.state.pages = pages;
    this.lookRecord();
  }
  //出入库的排序
  clickEvents(type){
    if(type=='0'){
      this.state.orderBys='desc'
    }else if(type=='1'){
      this.state.orderBys='asc'
    }
    this.lookRecord(this.state.oneRecord);
  }
  render() {
    const columns = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "硬盘编号",
        dataIndex: "harddiskNumber",
        key: "harddiskNumber",
        
      },
      {
        title: "SN",
        dataIndex: "sn",
        key: "sn",
      },
      {
        title: <div style={{display:'flex',alignItems:'center'}}><span>存储容量(TB)</span><p style={{display:'flex',flexDirection:'column'}}><Icon type="caret-up" style={{marginTop:'12px'}} onClick={this.clickEvent.bind(this,'1','storageCapacity')}></Icon><Icon type="caret-down" onClick={this.clickEvent.bind(this,'0','storageCapacity')}></Icon></p></div>,
        dataIndex: "storageCapacity",
        key: "storageCapacity",
      },
      {
        title: "当前状态",
        dataIndex: "currentStatus",
        key: "currentStatus",
        render:(text)=>text=='2'?'在库':text=='1'?'出库':'未处理'
      },
      {
        title: <div style={{display:'flex',alignItems:'center'}}><span>创建日期</span><p style={{display:'flex',flexDirection:'column'}}><Icon type="caret-up" style={{marginTop:'12px'}} onClick={this.clickEvent.bind(this,'1','createDate')}></Icon><Icon type="caret-down" onClick={this.clickEvent.bind(this,'0','createDate')}></Icon></p></div>,
        dataIndex: "createDate",
        key: "createDate",
        render:(text)=>text=text.slice(0,10)
      },
      {
        title: "创建人",
        dataIndex: "creator",
        key: "creator"
            },
      {
        title: "最近操作",
        dataIndex: "lastOperate",
        key: "lastOperate",
        render:(text,record)=>text=="0"?"新增":text=="1"?"修改":text=="2"?'入库':text=="3"?'出库':text=="4"?'删除':''
      },
      {
        title:'最近操作人',
        dataIndex: "lastOperater",
        key: "lastOperater",
      },
      {
        title: <div style={{display:'flex',alignItems:'center'}}><span>最近操作时间</span><p style={{display:'flex',flexDirection:'column'}}><Icon type="caret-up" style={{marginTop:'12px'}} onClick={this.clickEvent.bind(this,'1','lastOperateTime')}></Icon><Icon type="caret-down" onClick={this.clickEvent.bind(this,'0','lastOperateTime')}></Icon></p></div>,
        dataIndex: "lastOperateTime",
        key: "lastOperateTime",
        render:(text)=>text=text.slice(0,10)
      },{
        title:'操作',
        dataIndex:'',
        key:'',
        render:(record)=><>{record.currentStatus=='0'?'':record.currentStatus=='1'?<Button onClick={this.lookRecord.bind(this,record,'1')}>查看入出库记录</Button>:<div><div><Button style={{marginLeft:'-68px'}}  onClick={this.addClick.bind(this, 'none','0',record)}>修改</Button><Button style={{marginLeft:'10px'}} onClick={this.hangDelect.bind(this,record)}>删除</Button></div><div style={{marginTop:'5px'}}><Button style={{marginRight:'10px'}} onClick={this.cencalClick.bind(this, 'none',record)}>出库</Button><Button onClick={this.lookRecord.bind(this,record)}>查看入出库记录</Button></div></div>}</>
      }
    ];
    const dataSource = this.state.params; 
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this), //获取选中值
    };
    const column = [
      {
        title: "序号",
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: "硬盘编号",
        dataIndex: "harddiskNumber",
        key: "harddiskNumber",
        
      },
      {
        title: "SN",
        dataIndex: "sn",
        key: "sn",
      },
      {
        title:'存储容量(TB)',
        dataIndex: "storageCapacity",
        key: "storageCapacity",
      },
      {
        title: "操作",
        dataIndex: "operate",
        key: "operate",
        render:(text,record)=>text=="2"?'入库':text=="3"?'出库':''
      },
      {
        title:'操作人',
        dataIndex: "creator",
        key: "creator",
      },
      {
        title: <div style={{display:'flex',alignItems:'center'}}><span>操作时间</span><p style={{display:'flex',flexDirection:'column'}}><Icon type="caret-up" style={{marginTop:'12px'}} onClick={this.clickEvents.bind(this,'1')}></Icon><Icon type="caret-down" onClick={this.clickEvents.bind(this,'0')}></Icon></p></div>,
        dataIndex: "operateTime",
        key: "operateTime",
        render:(text)=>text?text.slice(0,10):''
      },
      {
        title: "快递单号",
        dataIndex: "deliverNumber",
        key: "deliverNumber",
      },
      {
        title: '收件人',
        dataIndex: "addressee",
        key: "addressee",
      },
      {
        title: "联系方式",
        dataIndex: "phoneNumber",
        key: "phoneNumber"
      },
      {
        title: "目标地址",
        dataIndex: "destAddress",
        key: "destAddress"
      },
      {
        title: "用途",
        dataIndex: "purpose",
        key: "purpose",
        render:(text)=>text=='0'?'DCP传输':text=='1'?'节目中心制作DCP':text=='2'?'其他':''
      },
    ];
    return (
      <div className="hardBox">
        <Spin spinning={this.state.loading} tip="Loading...">
          <div className="page-content-info">
            <div className="toolbar-group">
              <div className="toolbar-left">
                <div style={{ width: "100%", marginBottom: 5 }}>
                 
                </div>
                <div className="form-main">
                  <input className="form-control" onChange={this.valueChanges.bind(this, "harddiskNumber")} placeholder="请输入硬盘编号" />
                </div>
                <div className="form-main">
                  <input className="form-control" onChange={this.valueChanges.bind(this, "sn")} placeholder="请输入SN号" />
                </div>
                <div className="form-main">
                <Select
                    style={{ width: 167, height: 50 }}
                    onChange={this.handleChange.bind(this, "storageCapacity")}
                    value={this.state.storageCapacity}
                    placeholder="请选择存储容量"
                  >
                    <Option value="">--存储容量--</Option>
                    <Option value="1">1TB</Option>
                    <Option value="2">2TB</Option>
                   
                  </Select>
                </div>
                <div className="form-main">
                  <Select
                    // labelInValue 
                    style={{ width: 167, height: 50 }}
                    onChange={this.handleChange.bind(this, "currentStatus")}
                    value={this.state.currentStatus}
                    placeholder="请选择当前状态"
                  >
                    <Option value="">--当前状态--</Option>
                    <Option value="0">在库</Option>
                    <Option value="1">出库</Option>
                   
                  </Select>
                </div>
                <div className="form-main">
                  <Select
                    style={{ width: 167, height: 50 }}
                    onChange={this.handleChange.bind(this, "lastOperate")}
                    value={this.state.lastOperate}
                    placeholder="请选择最近操作"
                  >
                    <Option value="">--最近操作--</Option>
                    <Option value="3">出库</Option>
                    <Option value="2">入库</Option>
                    <Option value="0">新增</Option>
                    <Option value="1">修改</Option>
                  </Select>
                </div>
                <button
                  onClick={this.onload.bind(this,'1')}
                  className="btn btn-default btn-search"
                  type="button"
                >
                  检索
                </button>
                <Button
                    style={{ marginLeft: 10 }}
                    type="primary"
                    onClick={this.addClick.bind(this, 'none','1','')}
                  >
                    新增
                  </Button>
                  <Button
                    style={{ marginLeft: 10}}
                    type="danger"
                    onClick={this.showConfirm.bind(this)} //传id
                  >
                    删除
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    type="primary"
                    onClick={this.inClick.bind(this)}
                  >
                   入库
                  </Button>
              </div>

              <div className="toolbar-right" style={{ width: "100%" }}>
                <Table
                  rowKey={record=>record.id}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.3)', position: 'fixed', top: 0, left: 0, display: this.state.visible, zIndex: '999' }} >
          <div class="tanbox" style={{  width: '40%', position: 'fixed', top: '30%', left: '30%', background: '#fff', display: this.state.visible, border: '1px solid #ccc' }}>
            <Row style={{ margin: '10px 5px 5px 100px' }}>
              <Col span={20}><span style={{ width: '125px', display: 'inline-block' }}> 硬盘编号：</span>
              {this.state.adata.harddiskNumber}
              </Col>
            </Row>
           <Row  style={{ margin: '10px 5px 5px 100px' }}>
           <Col span={20}><span style={{ width: '125px', display: 'inline-block' }}> SN：</span>
              {this.state.adata.sn}
            </Col>
           </Row>
           <Row style={{ margin: '10px 5px 5px 100px' }}>
              <Col span={21}><span style={{ width: '125px', display: 'inline-block' }}>存储容量：</span>
              {this.state.adata.storageCapacity}TB
              </Col> 
            </Row>
            <Row style={{ margin: '10px 5px 5px 100px' }}>
              <Col span={22}><span style={{ width: '125px', display: 'inline-block' }}> 快递单号：</span><span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Input
                style={{ width: 240 }}
                value={this.state.deliverNumber}
                placeholder="请输入快递单号"
                onChange={this.handleChanges.bind(this, "deliverNumber")}
              /> <Button style={{marginLeft:'15px'}}>自动填充</Button>
              </Col>
              
            </Row>
            <Row style={{ margin: '10px 5px 5px 100px' }}>
              <Col span={20}><span style={{ width: '125px', display: 'inline-block' }}> 目标地址：</span><span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Input
                style={{ width: 240 }}
                value={this.state.destAddress}
                placeholder="请输入目标地址"
                onChange={this.handleChanges.bind(this, "destAddress")}
              /> </Col>
              
            </Row>
            <Row style={{ margin: '10px 5px 5px 100px' }}>
              <Col span={20}><span style={{ width: '125px', display: 'inline-block' }}> 收件人：</span><span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Input
                style={{ width: 240 }}
                value={this.state.addressee}
                placeholder="请输入收件人"
                onChange={this.handleChanges.bind(this, "addressee")}
              /> </Col>
              
            </Row>
            <Row style={{ margin: '10px 5px 5px 100px' }}>
              <Col span={20}><span style={{ width: '125px', display: 'inline-block' }}> 联系方式：</span><span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Input
                style={{ width: 240 }}
                value={this.state.phoneNumber}
                placeholder="请输入联系方式"
                onChange={this.handleChanges.bind(this, "phoneNumber")}
              /> </Col>
              
            </Row>
            <Row style={{ margin: '10px 5px 5px 100px' }}>
              <Col span={20}><span style={{ width: '125px', display: 'inline-block' }}> 用途：</span><span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Select
                    style={{ width: 240, height: 50 }}
                    onChange={this.handleChanges.bind(this, "purpose")}
                    value={this.state.purpose}
                    placeholder="用途"
                  >
                    <Option value="0">DCP传输</Option>
                    <Option value="1">节目中心制作DCP</Option>
                    <Option value="2">其他</Option>
                  </Select></Col>
              
            </Row>
            <Row style={{ margin: '10px 0' }}>
            <Button type="primary" style={{marginRight: '5%', marginLeft: '33%', width: '15%' }} onClick={this.cencalClick.bind(this, 'block','')}>取消</Button>
              <Button type="primary" style={{ width: '15%' }} onClick={this.outClick.bind(this)}>确定</Button>
             
            </Row>
          </div>
        </div>

        
        <div style={{ width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.3)', position: 'fixed', top: 0, left: 0, display: this.state.display, zIndex: '999' }} >
          <div class="tanbox" style={{ width: '30%', position: 'fixed', top: '40%', left: '40%', background: '#fff', display: this.state.display, border: '1px solid #ccc' }}>
           {
              this.state.ty=='1'?<>
                <Row style={{ margin: '50px 5px 5px 30px' }}>
              <Col span={20}><span style={{ width: '105px', display: 'inline-block' }}> 硬盘编号：</span><span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Input
                style={{ width: 240 }}
                value={this.state.params.harddiskNumber}
                placeholder="请输入硬盘编号"
                onChange={this.valueChange.bind(this, "harddiskNumber")}
              /> </Col>
              
            </Row>
           <Row  style={{ margin: '30px 5px 5px 30px' }}>
           <Col span={20}><span style={{ width: '105px', display: 'inline-block' }}> SN：</span><span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Input
                style={{ width: 240, marginLeft: '2px' }}
                value={this.state.params.sn}
                placeholder="请输入SN"
                onChange={this.valueChange.bind(this, "sn")}
              /></Col>
           </Row>
           <Row style={{ margin: '30px 5px 5px 30px' }}>
              <Col span={21}><span style={{ width: '105px', display: 'inline-block' }}>存储容量：</span><span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Input
                style={{ width: 240 }}
                value={this.state.params.storageCapacity}
                placeholder="请输入存储容量"
                onChange={this.valueChange.bind(this, "storageCapacity")}
              /> TB</Col>
              
            </Row>
              </>:<Row style={{ margin: '30px 5px 5px 30px' }}>
              <Col span={21}><span style={{ width: '105px', display: 'inline-block' }}>存储容量：</span><span style={{ color: "#b94a48", fontWeight: 900 }}>*</span><Input
                style={{ width: 240 }}
                value={this.state.params.storageCapacity}
                placeholder="请输入存储容量"
                onChange={this.valueChange.bind(this, "storageCapacity")}
              /> TB</Col>
              
            </Row>
           }
            
            <Row style={{ margin: '10px 0' }}>
            <Button type="primary" style={{ width: '15%',marginRight: '5%', marginLeft: '62%', }} onClick={this.addClick.bind(this, 'block','','')}>取消</Button>
            <Button type="primary" style={{ width: '15%' }} onClick={this.okClick.bind(this)}>确定</Button>
             
            </Row>
          </div>
        </div>
        <Modal className="outBox" title="出入库记录" footer={[]} visible={this.state.visibles} onCancel={this.recordCancel.bind(this)}>
             <Table size={"small"} columns={column} dataSource={this.state.recordList}  pagination={{ onChange: this.pageChanges.bind(this), total: this.state.totals}}/>
        </Modal>
        </Spin>
        
      </div>
    );
  }
}
export default HardDiskList;

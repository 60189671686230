import React, { Component } from 'react';
import {
  Button,
  message,
  Row,
  Col,
  Modal,
  Select,
  Input,
  Upload,
  Icon
} from 'antd';
import history from '../history';
let { Option } = Select;
class boxJournal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datalist: null,
      params: null,
      aa: ''
    };
  }
  componentWillMount() {
    if (this.props.params != null) {
      this.state.params = this.props.params;
      this.onload(this.props.params);
      this.timer = setInterval(() => {
        this.onload(this.props.params);
      }, 20000);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps != null) {
      if (nextProps.params != null) {
        this.state.params = nextProps.params;
        this.state.datalist = null;
        this.clearTimesr();
        this.onload(nextProps.params);
      } else {
        this.setState({
          params: {},
          datalist: null
        });
      }
    } else {
      this.setState({
        params: {},
        datalist: null
      });
    }
  }
  //销毁
  componentWillUnmount() {
    clearInterval(this.timer);
    this.clearTimesr();
  }
  //定时器
  startTimesr = () => {
    this.timesr = setInterval(() => {
      this.timeLog();
      // this.setState({datalist:null})
    }, 12000);
  };
  clearTimesr = () => {
    clearInterval(this.timesr);
  };
  
  onload = params => {
    let content = {
      boxId: params.id,
      serialNumber: params.serialNumber,
      type: '1',
      isContinue: '1'
    };
    fetch('/api/device/boxRealTimeLog', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error('网络不可用！');
        }
        return res.json();
      })
      .then(data => {
        if (data.code == 0) {
          this.startTimesr();
          //  this.timeLog(data.serialNumber)
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: '/' });
          }
        }
      });
  };
  timeLog() {
    fetch('/api/device/queryRealTimeLog', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ serialNumber: this.state.params.serialNumber })
    })
      .then(function (res) {
        if (!res.ok) {
          message.error('网络不可用！');
        }
        return res.json();
      })
      .then(data => {
        if (data.code == 0) {
          if (data.result == null) {
            return;
          } else if (data.result != null) {
            data.result.content = data.result.content.replace(
              new RegExp("\\\\n", 'gm'),
              '\n'
            );
    
            let aa = data.result.content;

            this.setState({ aa: aa });
            this.clearTimesr();
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: '/' });
          }
        }
      });
  }
  //this.state.datalist

  // TransferString(content){
  //   if (typeof content !== 'string') {
  //     return '';
  //   }
  //   let string = content;
  //   try {
  //     string = string.replace(/\r\n/g, str);
  //     string = string.replace(/\n/g, str);
  //   } catch (e) {
  //     alert(e.message);
  //   }
  //   return string;
  // }
  render() {
    return (
      <div>
        <Row>
          <Col span={3}>
            <Input
              style={{ width: '90%' }}
              defaultValue="实时监控"
              disabled={true}></Input>
            {/* <Select style={{width:'90%'}} value={0} onChange={this.valChange}>
                           <Option value="">实时监控</Option>
                           <Option value="0">实时监控</Option>
                       </Select> */}
          </Col>
          <Col span={2}>
            <Button style={{ width: '100%' }}>ALL</Button>
          </Col>
          <Col span={2}>
            <Button style={{ width: '100%' }}>DEBUG</Button>
          </Col>
          <Col span={2}>
            <Button style={{ width: '100%' }}>INFO</Button>
          </Col>
          <Col span={2}>
            <Button style={{ width: '100%' }}>LOG</Button>
          </Col>
          <Col span={2}>
            <Button style={{ width: '100%' }}>WARN</Button>
          </Col>
          <Col span={2}>
            <Button style={{ width: '100%' }}>ERROR</Button>
          </Col>
          <Col span={2}>
            <Button style={{ width: '100%' }}>FATAL</Button>
          </Col>
        </Row>
        <div
          className="journal"
          style={{ background: '#000', color: '#fff', marginTop: '10px' }}>
          {this.state.aa}
        </div>
      </div>
    );
  }
}
//lastIndexOf  replace(/\n/g,"<br/>")
export default boxJournal;

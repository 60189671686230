import React from 'react';
import {Tabs} from 'antd';
import SecretOrderConfirmList from './SecretOrderConfirmList.js';
import SecretOrderConfirmDetail from './SecretOrderConfirmDetail.js'
const { TabPane } = Tabs;
class SecretOrderConfirm extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        sign:'1'
      }
    }
      callback(activeKey,type,params){
          if(type == "details"){
            this.state.params = params
            console.log(params)
          }
          if(type == "add"){
            this.state.params = null
          }
        this.setState({activeKey:activeKey})
      }
    render(){
        return(
          <div>
            <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
            <TabPane tab="已确定密钥列表" key="1">
              <SecretOrderConfirmList  buttonList={this.props.buttonList} callback = {this.callback.bind(this)} sign={this.state.sign}/>
            </TabPane>
            <TabPane tab="已确定密钥详情" key="2">
            < SecretOrderConfirmDetail buttonList={this.props.buttonList} params = {this.state.params} callback = {this.callback.bind(this)}/>
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default SecretOrderConfirm;
import React from "react";
import { Row,Col,  Spin,Button,message,Modal} from 'antd';
const {confirm}=Modal;
class Project extends React.Component {
    constructor(props) {
      super(props);
      this.state={
        loading:false,
        projectInfor:{
            contentInfo:[]
          },//展示放映机的信息
           serialNumber:'',
           proType:'',//放映机控制指令
        InfoArr:[],//3004获取通道列表
        name:'',
      }
    }
    componentWillMount(){
        this.setState({serialNumber:sessionStorage.getItem('serialNumber')?sessionStorage.getItem('serialNumber'):''}) 
    }
    componentWillReceiveProps(){
       this.setState({serialNumber:sessionStorage.getItem('serialNumber')?sessionStorage.getItem('serialNumber'):''}) 
    }
  //放映机查询定时器
  projectorTimes=(id,type)=>{
    this.timeprojector=setInterval(() => {
      this.timingQuery(id,type); 
      }, 15000);
  }
  //清楚定时器函数
  ClearProject=()=>{
    clearInterval(this.timeprojector);
  }
   //销毁定时刷新
   componentWillUnmount(){
    this.ClearProject();
 }
    //盒子的开机关机等按钮事件
btnsClick(type){
    let str='';
    if(type=='3000'){
      str='开机'
    }else if(type=='3001'){
      str='关机'
    }else if(type=='3002'){
      str='开灯'
    }else if(type=='3003'){
      str='关灯'
    }else if(type=='3004'){
      str='获取通道列表和当前通道名称'
    }
    // else if(type=='3005'){
    //   str='获取当前通道名称'
    // }
    else if(type=='3006'){
      str='设置通道'
    }else if(type=='3007'){
      str='获取激光设备信息'
    }
    confirm({
    title:<span>确定{str}?</span>,
        content: "",
        okText: '确定',
        cancelText:"取消",
        onOk: () => {
          this.setState({ loading: true });
          let content={serialNumber:this.state.serialNumber,handle:type,channelName:type=='3006'?this.state.name:''};
          fetch("/api/device/boxControl", {
            method: "post",
            credentials: "include",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(content)
          })
            .then(function (res) {
              if (!res.ok) {
                message.error("网络不可用！");
              }
              return res.json();
            })
            .then( (data)=> {
              if (data.code == 0) {  
                this.state.proType=type;
                this.setState({loading:false,proType:this.state.proType});
                this.timingQuery(data.result.id,type);
              } else {
                this.setState({loading:false});
                message.error(data.message);
              }
            })
        },
        onCancel() {}
      });
    }
    //放映机控制定时查询
    timingQuery(id,type){
      fetch("/api/device/queryControlProject", {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({id:id})
      })
        .then(function (res) {
          if (!res.ok) {
            message.error("网络不可用！");
          }
          return res.json();
        })
        .then( (data)=> {
          if (data.code == 0) {
            if(data.result.status==null){
              this.projectorTimes(id,type);
             }else if(data.result.status!=null){
              this.ClearProject();
              if(type=='3001'||type=='3002'||type=='3000'||type=='3003'||type=='3006'){
                if(data.result.status=='0'){ 
                  message.success('成功');
                  this.setState({loading:false}) 
                }else if(data.result.status=='1'){
                  message.error('失败');
                  this.setState({loading:false})
                }
              }else{
                if(type=='3004'){
                    var obj = {};
                    obj = JSON.parse(data.result.content);
                    this.state.InfoArr=obj.info.split(',');
                    if(this.state.InfoArr.length>0){
                      this.Clickbtn('3005');
                    }
                }else if(type=='3005'){
                  var obj = {};
                  obj = JSON.parse(data.result.content);
                  this.setState({name:obj.info,loading:false});
                }
                else if(type=='3007'){
                  this.state.projectInfor=JSON.parse(data.result.content);
                  this.state.InfoArr='';
                  this.setState({InfoArr:this.state.InfoArr,loading:false});
                }
                this.setState({projectInfor:this.state.projectInfor,projectorVisible:true});
              }
             }
          } else {
            message.error(data.message);
          }
        })
    }
    //点击进行设置 
    clickEvent(item){
      this.setState({name:item});
    }
    //点击通道名称
    Clickbtn(type){
      this.setState({loading:true});
      let content={serialNumber:this.state.serialNumber,handle:type};
          fetch("/api/device/boxControl", {
            method: "post",
            credentials: "include",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(content)
          })
            .then(function (res) {
              if (!res.ok) {
                message.error("网络不可用！");
              }
              return res.json();
            })
            .then( (data)=> {
              if (data.code == 0) {  
                this.state.proType=type;
                this.setState({proType:this.state.proType});
                this.timingQuery(data.result.id,type);
              } else {
                this.setState({loading:false});
                message.error(data.message);
              }
            })
    }
    render(){
      let {projectInfor}=this.state;
        return(
           <div className="projectBox">
            <Spin spinning={this.state.loading} tip="Loading...">
                {
                    this.state.serialNumber? <div> <div className="rowbox">
                    <Row style={{ margin: "10px 5px 5px 0px" ,width:'100%'}}>
                    <Col span={2}>
                      <Button
                        type="danger"
                        style={{
                        width: "100px",
                        }}
                        onClick={this.btnsClick.bind(this, "3000")}
                      >
                        开机
                      </Button>
                    </Col>
                    <Col span={2}>
                      <Button
                        type="danger"
                        style={{
                          width: "100px",
                          
                        }}
                        onClick={this.btnsClick.bind(this, "3001")}
                      >
                       关机
                      </Button>
                    </Col>
                    <Col span={2}>
                      <Button
                        type="danger"
                        style={{
                          width: "100px",
                          
                        }}
                        onClick={this.btnsClick.bind(this, "3002")}
                      >
                        开灯
                      </Button>
                    </Col>
                    <Col span={2}>
                      <Button
                        type="danger"
                        style={{ width: "100px"}}
                        onClick={this.btnsClick.bind(this, "3003")}
                      >
                        关灯
                      </Button>
                    </Col>
                    <Col span={4}>
                      <Button
                        type="danger"
                        style={{ width: "210px",
                       
                      }}
                      onClick={this.btnsClick.bind(this, "3004")}
                      >
                       获取通道列表和当前通道名称
                      </Button>
                    </Col>
                    {/* <Col span={3}> 
                      <Button
                        type="danger"
                        style={{
                          width: "150px",
                        //   display:
                        //     this.state.btn_upgrade_application == true
                        //       ? "inline-block"
                        //       : "none"
                        }}
                        onClick={this.btnsClick.bind(this, "3005")}
                      >
                       获取当前通道名称
                      </Button>
                    </Col> */}
                    <Col span={2}>
                      <Button
                        type="danger"
                        style={{
                        width: "100px",
                        }}
                        onClick={this.btnsClick.bind(this, "3006")}
                      >
                        设置通道
                      </Button>
                    </Col>
                    <Col span={3}>
                      <Button
                        type="danger"
                        style={{
                          width: "150px",
                          // display:
                          //   this.state.btn_stop_application == true
                          //     ? "inline-block"
                          //     : "none"
                        }}
                        onClick={this.btnsClick.bind(this, "3007")}
                      >
                       获取激光设备信息
                      </Button>
                    </Col>
                  </Row>
                  </div>
                  {
                    // this.state.proType=='3004'?<div className="content">
                    //   {this.state.projectInfor!=null?this.state.InfoArr.map((item,index)=>{
                    //     return <p onClick={this.clickEvent.bind(this,item)}  style={{width:'100px',textAlign:'center',border:item==this.state.name?'1px solid red':'',height:'30px',lineHeight:'30px'}}>{item}</p>
                    //   }):''}
                    // </div>:
                    this.state.proType=='3005'?<div style={{marginTop:'5px'}}>
                      {this.state.InfoArr.length==0?this.state.name:this.state.InfoArr.map((item,index)=>{
                        return <p onClick={this.clickEvent.bind(this,item)} style={{width:'100px',textAlign:'center',border:item==this.state.name?'1px solid red':'',height:'30px',lineHeight:'30px'}}>{item}</p>
                      })}
                    </div>:
                   this.state.proType=='3007'?<div className="content">
                    {
                      projectInfor!=null&&projectInfor.info?<div>
                        <p>projectNo:{projectInfor.projectNo}</p>
                        <p>mode:{projectInfor.info.mode!=undefined?projectInfor.info.mode:''}</p>
                        <p>validity_period:{projectInfor.info.validity_period!=undefined?projectInfor.info.validity_period:''}</p>
                        <p>run_time:{projectInfor.info.run_time!=undefined?projectInfor.info.run_time:''}</p>
                        <p>remain_time:{projectInfor.info.remain_time!=undefined?projectInfor.info.remain_time:''}</p>
                        <p>authorization_time:{projectInfor.info.authorization_time!=undefined?projectInfor.info.authorization_time:''}</p>
                  </div>:''
                    }
                 
                  </div>:''
                  }
                  </div>:""
                }
            
             
              </Spin>
           </div> 
        )
    }
}

export default Project;
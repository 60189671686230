import React from 'react';
import { Table, Spin,Button,message,Select,Modal} from 'antd';
import history from '../history';

const { confirm } = Modal;
const {Option}=Select;
class CertificateList extends React.Component{
  
    constructor(props) {
      super(props);  
		this.state={
			params:[],
			page:0,
			pageSize:10,
			selectedRowKeys: [],
			selectedRow:[],
			total:0,
			loading:false
		}
	}
		
		onload(ty){
			this.setState({ loading: true });
			let array=[]
			let total=0;
			if(ty=="1"){
				this.state.start=0
				}else{
				this.state.start=this.state.page
				}
			let content = {start:this.state.start,limit:this.state.pageSize,
				certificateCode:this.state.certificateCode,releases:this.state.releases
			};
			fetch('/api/certificate/certificateList',{
				method: 'post',
				credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'      
                },
                body: JSON.stringify(content),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                        return res.json();
                }).then(function (data) {
                        if (data.code == 0) {
                                array=data.result;    
                                total=data.total;
                        }else  {
                            message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                            }
                        }
                }).then( (e) => {
                    this.state.params=array;
                    this.state.total=total;
					this.state.selectedRowKeys = [];
					this.state.selectedRow = [];
                    this.setState({ loading: false });
                });
		 }
		 componentWillMount() {
			this.onload()
			this.showBtn(this.props.buttonList)//控制按钮显示
		}
		//控制按钮显示
		showBtn(array){
			 for(var i= 0; i<array.length;i++){
				  if(array[i].buttonCode == "btn_search"){
						this.state.btn_search = true
				  }
				  if(array[i].buttonCode == "btn_add"){
					this.state.btn_add = true
				  }
				if(array[i].buttonCode == "btn_delete"){
					this.state.btn_delete = true
				}
				if(array[i].buttonCode == "btn_release"){
					this.state.btn_release = true
				  }
				  if(array[i].buttonCode == "btn_cancel_release"){
					this.state.btn_cancel_release = true
				  }
			 }
		}

		valueChange(type,e) {
			if(type == "certificateCode"){
					this.state.certificateCode=e.target.value;
			}
			if(type == "releases"){
				this.state.releases=e;
		}
		}
		//获取选中值
		onSelectChange (selectedRowKeys,selectedRow) {
			this.state.selectedRow=selectedRow
			this.setState({ selectedRowKeys });
		};
		//翻页
		pageChange(page,pageSize){
			page=(page-1)*pageSize
			this.state.page=page;
			this.onload();
		}
		//删除
	  showConfirm = () => {
		if(this.state.selectedRow.length == 0){
			message.error("尚未选中数据！");
			return;
		}
		confirm({
		  title: '确定删除?',
		  content: '',
		  okText: '确定',
		  cancelText:"取消",
		  onOk:()=> {
			this.setState({ loading: true });
            let array=this.state.selectedRow
			fetch('/api/certificate/deleteCertificate',{
				method: 'post',
				credentials: 'include',
                headers: {
                        'Content-Type': 'application/json'
                },
                body: JSON.stringify(array),
                }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
                    return res.json();
                }).then(function (data) {
                    if (data.code == 0) {
                        message.success("删除成功")
                    }else  {
                        message.error(data.message);
                            if(data.code == 2003){
                                history.push({pathname:'/'});
                        }
                    }
                }).then( (e) => {
                    this.onload();
            });
		  },
		  onCancel() {
		  },
		});
	  }
	  down(params){
		fetch('/api/certificate/downCertificate?id='+params.id,{
			method: 'get',
			credentials: 'include',
			headers: {
					'Content-Type': 'application/json'
			},
			}).then((response) => {
				response.blob().then(blob => {
				  let blobUrl = window.URL.createObjectURL(blob);
				  let aElement = document.getElementById('downloadDiv'); //获取a标签元素
				  let filename = params.certificateName;//设置文件名称
				  aElement.href = blobUrl;//设置a标签路径
				  aElement.download = filename;
				  aElement.click();
				  window.URL.revokeObjectURL(blobUrl);
				});
			  }).catch((error) => {
				console.log('文件下载失败', error);
			  });
	  }

   //发布
   toRelease(state){
    if(this.state.selectedRow.length == 0){
      message.error("请选择数据，再进行操作");
      return;
	}
	this.state.selectedRow[0].releases = state
    var str = ''
    if(state == 1){
      str="发布成功"
    }else{
      str="取消发布成功"
    }
    this.setState({loading:true})
    fetch('/api/certificate/isRelease',{
			method: 'post',
			credentials: 'include',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.selectedRow),
      }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
          return res.json();
      }).then(function (data) {
          if (data.code == 0) {
            message.success(str)
          }else  {
            message.error(data.message);
            if(data.code == 2003){
              history.push({pathname:'/'});
            }
          }
      }).then( (e) => {
        this.state.loading=false
        this.onload();
      });
  }
    render(){
			const columns = [
				{
					title: '序号',
					render:(text,record,index)=>`${index+1}`,
				},
				{
					title: '证书编码',
					dataIndex: 'certificateCode',
					key: 'certificateCode'
				},
				{
					title: '证书状态',
					dataIndex: 'releases',
					key: 'releases',
					render: (text) => text == "1"?"已发布":"未发布", 
					
				},
				{
					title: '上传时间',
					dataIndex: 'uploadTime',
                    key: 'uploadTime'
				},{
					title: '操作',
					dataIndex: '',
					key: '',
					render: (text, record) => (
						<a href="javascript:;" onClick={this.down.bind(this,record)}>下载</a>
					),
				  }
				
			];
			const dataSource = this.state.params;
			const {selectedRowKeys } = this.state;
            const {selectedRow } = this.state;
			const rowSelection = {
				selectedRowKeys,
				selectedRow,
				onChange: this.onSelectChange.bind(this),//获取选中值
			};
			const Anchor = props => {
				return (
				  <a {...props}>{props.children}</a>
				);
			  };
        return(
            <div>
              <Spin spinning={this.state.loading} tip="Loading...">
			  <Anchor id="downloadDiv" style={{display: 'none'}}></Anchor>
								<div class="page-content-info">
									<div class="toolbar-group">
										<div class="toolbar-left">
											<div class="form-main">
												<input class="form-control" onChange={this.valueChange.bind(this,"certificateCode")} placeholder="请输入证书编码"/>
											</div>
											<div class="form-main">
												<Select style={{width:167}} placeholder="请选择证书状态"   onChange={this.valueChange.bind(this,"releases")} >
													<Option value="">--请选择--</Option>
													<Option value="1">已发布</Option>
													<Option value="0">未发布</Option>
												</Select>
											</div>
											<button style={{display:this.state.btn_search == true?"inline-block":"none"}} onClick={this.onload.bind(this,'1')} class="btn btn-default btn-search" type="button">检索</button>
										</div>
										<div><Button style={{display:this.state.btn_add == true?"inline-block":"none",marginLeft:10}} type="primary" onClick = {this.props.callback.bind(this,"2","add",null)}>新增</Button>
										     <Button onClick={this.toRelease.bind(this,"1")} style={{display:this.state.btn_release == true?"inline-block":"none"}} className = "btn_release">发布</Button>
                                             <Button onClick={this.toRelease.bind(this,"0")} style={{display:this.state.btn_cancel_release == true?"inline-block":"none"}} className = "btn_cancel_release">取消发布</Button>
										     <Button style={{display:this.state.btn_delete == true?"inline-block":"none",marginLeft:10}} type="danger" onClick = {this.showConfirm.bind(this,this)}>删除</Button>
										</div>
										<div class="toolbar-right" style={{width:'100%'}}>
											<Table size={"small"} rowSelection={rowSelection} columns={columns} dataSource={dataSource}  pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}/>
										</div>
									</div>
								</div>
              </Spin>
            </div>    
        )
    }
}
export default CertificateList;
import React from 'react';
import {Tabs} from 'antd';

import IngestTaskList from './IngestTaskList';


const { TabPane } = Tabs;
class IngestTask extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isfalse:null
      }
    }
    callback(activeKey,type,params){
      console.log(type);
      if(type == "details"){
        this.state.isfalse=true;
        this.state.params = params
      }
      if(type == "add"){
        this.state.isfalse=false;
        this.state.params = null
      }
    this.setState({activeKey:activeKey})
  }
    render(){
        return(
          <div>
              <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
                <TabPane tab="导入任务列表" key="1">
               <IngestTaskList buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
              </TabPane>
            </Tabs>
          </div>
        )
    }
}
export default IngestTask;
import React from 'react';
import { Table, Spin, message,Input } from 'antd';
import history from '../history';
class SecretOrderConfirmList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      sign:''
    };
  }
  componentWillMount() {
    this.state.sign=this.props.sign
    this.onload();
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == 'btn_search') {
        this.state.btn_search = true;
      }
    }
  }
  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if(ty=="1"){
      this.state.start=0
      }else{
      this.state.start=this.state.page
      }
    let content = {
      start: this.state.page,
      limit: this.state.pageSize,
      status:'1',
      exportNum:this.state.exportNum,
      exportType:this.state.sign=="1"?"0":this.state.sign=="2"?"1":"",
      exportPeople:this.state.exportPeople,
      confirmPeople:this.state.confirmPeople
    };
    // console.log(content)
    fetch('/api/exportOrder/queryExportOrderList', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content)
    }).then(function(res) {
        if (!res.ok) {
          message.error('网络不可用！');
        }
        return res.json();
      })
      .then(function(data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: '/' });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.setState({ loading: false });
      });
  }
  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.setState({ selectedRowKeys });
  }
  //翻页
  pageChange(page, pageSize) {
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
  //val值
  valChange(type,e){
    if(type=="exportPeople"){
      this.state.exportPeople=e.target.value
    }
    if(type=="exportNum"){
      this.state.exportNum=e.target.value
    }
    if(type=="confirmPeople"){
      this.state.confirmPeople=e.target.value
    }
  }

  
  render() {
    const columns = [
      {
        title: '序号',
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: '导出单号',
        dataIndex: 'exportNum',
        key: 'exportNum',
        render: (text, record) => (
          <a
            href="javascript:;"
            onClick={this.props.callback.bind(this, '2', 'details', record)}>
            {text}
          </a>
        )
      },
      {
        title: '导出时间',
        dataIndex: 'exportTime',
        key: 'exportTime',
      },
      {
        title: '导出操作人员',
        dataIndex: 'exportPeople',
        key: 'exportPeople'
      },
      {
        title: '导出确认时间',
        dataIndex: 'confirmTime',
        key: 'confirmTime'
      },
      {
        title: '导出确认操作人员',
        dataIndex: 'confirmPeople',
        key: 'confirmPeople'
      }
    ];
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this) //获取选中值
    };
    return (
      <div>
        <Spin spinning={this.state.loading} tip="Loading...">
          <div class="page-content-info">
            <div class="toolbar-group">
              <div class="toolbar-left">
                <div class="form-main">
                  <Input class="form-control" placeholder="请输入导出单号" onChange={this.valChange.bind(this,'exportNum')}/>
                </div>
                <div class="form-main">
                  <Input class="form-control" placeholder="请输入导出操作人员" onChange={this.valChange.bind(this,'exportPeople')}/>
                </div>
                <div class="form-main">
                  <Input class="form-control" placeholder="请输入导出确认操作人员" onChange={this.valChange.bind(this,'confirmPeople')}/>
                </div>
                <button
                  onClick={this.onload.bind(this,'1')}
                  class="btn btn-default btn-search"
                  type="button">    
                  检索
                </button>
              </div>
              <div class="toolbar-right" style={{ width: '100%' }}>
                <Table
                  size={'small'}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default SecretOrderConfirmList;

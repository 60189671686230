import React from 'react';
import {Tabs,message} from 'antd';
import EditList from './editList.js';
import EditDetails from './editDetails.js';

const { TabPane } = Tabs;
class EditManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isfalse:null
      }
    }
    // getDirectorList(){
    //   let array = [];
    //   fetch('/api/system/directorList',{
		// 		method: 'post',
		// 		credentials: 'include',
    //             headers: {
    //                 'Content-Type': 'application/json'      
    //             },
    //             body: JSON.stringify({}),
    //             }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
    //                     return res.json();
    //             }).then(function (data) {
    //                     if (data.code == 0) {
    //                             array=data.result;    
    //                     }
    //             }).then( (e) => {
    //                 this.state.directorList=array;
    //             });
    // }
    getEnum(){
      let enumArray = [];
      let content = {type:"1"}
      fetch('/api/common/searchEnum',{
        method: 'post',
        credentials: 'include',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(content),
        }).then(function (res) {             if(!res.ok){                 message.error("网络不可用！");             }
          return res.json();
        }).then(function (data) {
          if (data.code == 0) {
            enumArray=data.result;    
          }
        }).then( (e) => {
          this.state.enumArray=enumArray;
      });
  }
    componentWillMount() {
      this.getEnum();//获取影片类型
			// this.getDirectorList()//获取导演列表
		}
      callback(activeKey,type,params){
          if(type == "details"){
            this.state.params = params
            this.state.isfalse=true;
          }
          if(type == "add"){
            this.state.params = null
            this.state.isfalse=false;
          }
        this.setState({activeKey:activeKey})
      }
    render(){
      const {isfalse}=this.state
        return( 
          <div>
            <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
            <TabPane tab="编目列表" key="1">
              <EditList  buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
            </TabPane>
            <TabPane tab="编目详情" key="2">
              <EditDetails enumArray = {this.state.enumArray}  buttonList={this.props.buttonList} isfalse={isfalse} params = {this.state.params} callback = {this.callback.bind(this)}/>
            </TabPane>
          </Tabs>
          </div>
        )
    }
}
export default EditManage;
import React from "react";
import {
  Input,
  Select,
  Button,
  Spin,
  Table,
  Row,
  Col,
  message,
  Icon,
  Upload
} from "antd";
import history from "../history";
const { Option } = Select;
class OrderDetial extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {},
      loading: false,
      isfalse: true,
      cinemaCodeList: [],
      cinemaList: [],
      str: '',
      filePaths: "",
      falg: false,
      listarr: "",
      fileUploadCount: 0,
      fileType: '',
      esfalse: false
    };
  }
  componentWillMount() {
    if (this.props.params != null) {
      this.state.params = this.props.params;
      this.state.isfalse = this.props.isfalse;
      this.onload(this.state.params.id);
    } else {
      this.state.fileList = [];
    }
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps != null) {
      if (nextProps.params != null) {
        this.state.params = nextProps.params
        this.state.isfalse = this.props.isfalse
        this.onload(this.state.params.id);
        this.state.esfalse = false
      } else {
        this.setState({
          params: {}
        });
      }
    } else {
      this.setState({
        params: {}
      });
    }
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_save") {
        this.state.btn_save = true;
      }
      if (array[i].buttonCode == "btn_update") {
        this.state.btn_update = true;
      }
      if (array[i].buttonCode == "btn_cancel") {
        this.state.btn_cancel = true;
      }
    }
  }
  //请求详情的列表
  onload(id) {
    let dataParams = {};
    this.setState({ loading: true });
    let content = { id: id }
    fetch('/api/keyOrder/queryKeyOrderDetails', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then((data) => {
      if (data.code == 0) {
        dataParams = data.result;
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.setState({ params: dataParams, loading: false });
    });
  }
  ///api/keyOrder/ queryKeyOrderDetails
  cencel(type) {
    //修改还是取消
    if (type == 'write') {
      this.setState({
        isfalse: false,
        esfalse: true
      });
    } else {
      this.setState({
        isfalse: true,
        esfalse: false,
      });
    }
  }
  valChange(type, value) {
    let params = this.state.params
    if (type == "keyProcessStatus") {
      params.keyProcessStatus = value
    }
    this.setState({ params })
  }
  //保存
  save() {
    // let content={keyProcessStatus:this.state.params.keyProcessStatus}
    this.setState({ loading: true });
    fetch("/api/keyOrder/updateKeyOrder", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(this.state.params)
    })
      .then(function (res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then((data) => {
        if (data.code == 0) {
          message.success("保存成功！");
          if (data.result != null) {
            this.onload(data.result.id)
          }
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: "/" });
          }
        }
      })
      .then(e => {
        this.setState({ loading: false, isfalse: true, esfalse: false });
      });
  }
  //上传密钥
  miChange(info, item, ind) {

    if (info.file.status !== 'uploading') {
     
    }
    if (info.file.status === 'done') {
      if (item.playInfoList.length == this.state.fileUploadCount) {
        this.state.fileUploadCount = 0
      }
      item.keyPath += info.file.response.result + ','
      if (item.keyPath.indexOf("null") != -1) {
        item.keyPath = item.keyPath.substring(4, item.keyPath.length)
      }
      item.playInfoList[this.state.fileUploadCount].keyPath = info.file.response.result
      this.state.fileUploadCount++;
      item.isUploadKey = "1";
      this.setState({ item });
      //  item.filePath[index]={filePath:info.file.response.result}
    } else if (info.file.status === 'error') {
      // message.error(`${info.file.name} file upload failed.`);
    }
  }
  //图片上传时传参
  handleUploadData(item) {
    let d = { type: '2', cinemaId: item.cinemaId, filmId: item.filmId, modKeyOrderFilmId: item.id }
    return d;
  }
  //input值
  inpVal(type, e) {
    let params = this.state.params
    if (type == "expressCompany") {
      params.expressCompany = e.target.value
    }
    if (type == "expressNum") {
      params.expressNum = e.target.value
    }
    this.setState({ params })
  }

  render() {
    const props = {
      headers: {
        authorization: 'authorization-text',
      }
    }
    let { esfalse, isfalse, arrlist, fileType, params } = this.state
    let columns = [
      {
        title: '影厅',
        dataIndex: 'hallName',
        key: 'hallName',
      },
      {
        title: '秘钥类型',
        dataIndex: 'devicePlayType',
        key: 'devicePlayType',
        render: (text) => text == "0" ? "KDM" : "LDM"
      },
      {
        title: '秘钥时间/次数',
        dataIndex: 'playTimeBegin',
        key: 'playTimeBegin',
        render: (text, record, index) => text ? text : record.playCount
      },
      {
        title:'影厅设备编码',
        dataIndex:'',
        key:'',
        render:(text,record)=><span>{record.hallInfo!=null?record.hallInfo.serverSerialNumber:''}</span>
      },
      {
        title:'座位数',
        dataIndex:'',
        key:'',
       render:(text,record)=><span>{record.hallInfo!=null?record.hallInfo.seatNumber:''}</span>  
      },
      {
        title: '密钥订单类型',
        dataIndex:'monthlyLength',
        key: 'monthlyLength',
        render: (text, record, index) => text ? "包月" : "包场"
      },
      {
        title: '放映时间',
        dataIndex: 'projectTimeBegin',
        key: 'projectTimeBegin',
        render: (text, record, index) => <span>{record.monthlyLength ? text = '' : text = text + "-" + record.projectTimeEnd}</span>
      },
      // {
      //   title: '包月时长',
      //   dataIndex: 'playTimeBegin',
      //   key: 'playTimeBegin',
      //   render: (text, record, index) => <span>{record.monthlyLength ? text = text + "-" + record.playTimeEnd : text = ''}</span>
      // },
      //modifier
      {
        title: '密钥',
        dataIndex: 'keyPath',
        key: 'keyPath',
        render: (text, record) => {
          if (text != null && text.length > 20) {
            return text.substring(0, 20) + "..."
          } else {
            return text
          }
        },
      }
    ]
    return (
      <div
        className="orderDetails"
        key={
          this.state.params != null && this.state.params.id == "-1"
            ? this.state.params.id
            : this.state.params.id
        }
      >
        <Spin spinning={this.state.loading} tip="Loading...">
          <div style={{ marginLeft: '25px' }}>
            <Button
              style={{
                display: this.state.btn_save == true ? "inline-block" : "none",
                marginLeft: 10
              }}
              onClick={this.save.bind(this)}
              type="primary"
              disabled={isfalse}
            >
              保存
            </Button>
            <Button style={{ display: this.state.btn_update == true ? "inline-block" : "none", marginLeft: 20 }} disabled={esfalse} onClick={this.cencel.bind(this, 'write')}>
              修改
            </Button>
            <Button
              style={{ display: this.state.btn_cancel == true ? "inline-block" : "none", marginLeft: 20 }} disabled={isfalse} onClick={this.cencel.bind(this, true)}>
              取消
            </Button>
            <Button style={{ marginLeft: 20 }} onClick={this.props.callback.bind(this, "1", null, null)}>返回</Button>
          </div>
          <div style={{ marginLeft: '40px'}}>
            <Row style={{ margin: '15px 5px 15px 0', borderBottom: '1px solid #ccc' }}>
              <span
                style={{
                  width: "2px",
                  height: "15px",
                  background: "red",
                  display: "inline-block",
                  margin: "10px 10px 0 0",
                  
                }}
              ></span>
              <b>基本信息</b>
            </Row>
            <Row style={{ margin: '10px 5px 12px 14px' }}>
              <Col> 订单号：<span>{this.state.params.orderNum}</span> </Col>
            </Row>
            <Row style={{ margin: '10px 5px 5px 0' }}>
              <Col span={8}>订单状态：<Select disabled={true} defaultValue={this.state.params.status} style={{ width: '200px' }}>
                <Option value="">选择订单状态</Option>
                <Option value="0">未处理</Option>
                <Option value="1">处理中</Option>
                <Option value="2">完成</Option>
                <Option value="3">失败</Option>
                <Option value="4">取消</Option>
              </Select>
              </Col>
              <Col span={7} style={{marginLeft:'28px'}}>导出状态：<Select disabled={true} defaultValue={this.state.params.exportStatus} style={{ width: '200px' }}>
                <Option value="0">未导出</Option>
                <Option value="1">已导出</Option>
                <Option value="2">已确认</Option>
              </Select>
              </Col>
              <Col span={8}>秘钥处理状态：<Select disabled={isfalse} value={this.state.params.keyProcessStatus} style={{ width: '200px' }} onChange={this.valChange.bind(this, 'keyProcessStatus')}>
                <Option value="0">待处理</Option>
                <Option value="1">申请中</Option>
                <Option value="2">申请成功</Option>
                <Option value="3">申请失败</Option>
                <Option value="4">已上传</Option>
                <Option value="5">传输成功</Option>
                <Option value="6">传输失败</Option>
              </Select>
              </Col>
            </Row>
            <Row style={{ margin: '10px 5px 5px 0px' }}>
              <Col span={8}>支付状态：<Select disabled={true} defaultValue={this.state.params.payStatus} style={{ width: '200px' }}>
                <Option value="0">未支付</Option>
                <Option value="1">已支付</Option>
                <Option value="2">支付失败</Option>
              </Select>
              </Col>
             
              <Col span={8}>DCP传输状态：<Select disabled={true} defaultValue={this.state.params.transmissionStatus} style={{ width: '200px' }}>
                <Option value="">DCP传输状态</Option>
                <Option value="0">待处理</Option>
                <Option value="1">传输中</Option>
                <Option value="2">传输成功</Option>
                <Option value="3">传输失败</Option>
                <Option value="4">传输取消</Option>
                <Option value="5">传输暂停</Option>
              </Select>
              </Col>
            </Row>
            <Row style={{ margin: '12px 5px 12px 0' }}>
              <Col span={16}>
                影院名称：<span>{this.state.params.cinemaName}</span>
              </Col>
             
            </Row>
            <Row style={{ margin: '12px 5px 12px 0' }}>
            <Col span={16}>
               下载单号：<span href="javascript:;">{this.state.params.orderNum}</span>
            </Col>
            </Row>
            <Row style={{ margin: '12px 5px 12px 0' }}>
              <Col span={12}>
                影院地址：<span>{this.state.params.cinemaAddress}</span>
              </Col>
            </Row>
            <Row style={{ margin: '12px 5px 12px 29px'}}>
              <Col span={12}>
                <span>时间：{this.state.params.createTime}</span>
              </Col>
            </Row>
            <Row style={{ margin: '12px 5px 12px 13px' }}>
              <Col span={12}>
              总价格：<span style={{ color: 'red' }}>￥{this.state.params.price}</span>
              </Col>
            </Row>

          </div>
        {/*,background:'#f4f4f4' */}
          <div style={{ margin: '15px 0 0 30px'}}>
            <Row style={{ margin: '10px 5px 5px 0', borderBottom: '1px solid #ccc' }}>
              <span
                style={{
                  width: "2px",
                  height: "15px",
                  background: "red",
                  display: "inline-block",
                  margin: "10px 10px 0 0",
                  
                }}
              ></span>
              <b>影片信息</b>
            </Row>
            {
              this.state.params.filmList && this.state.params.filmList.map((item, ind) => {
                return (
                  <div>
                    <Row style={{ margin: '12px 5px 0 0 ' }}>
                      <Col span={12}> 影片名称：<span>{item.filmName}</span><span>({item.filmInfo!=null?item.filmInfo.filmType:''}/{item.filmInfo!=null?item.filmInfo.filmSubtitles:''})</span></Col>
                      <Col span={12}> 价格：<span style={{ color: 'red' }}>￥{item.playInfoList[0].price}</span> </Col>
                    </Row>
                    <Row style={{ margin: '12px 5px 0 0' }}>
                      <Col span={12}> 是否下载DCP：<span>{item.isToDownload == "0" ? '否' : '是'}</span> </Col>
                      <Col span={12} style={{height:'25px'}}> 是否上传密钥：<span>{item.isUploadKey == "0" ? '否' : '是'}</span>
                        <Upload  name="file" action="/api/common/uploadFile" data={() => this.handleUploadData(item)}
                          onChange={(e) => this.miChange(e, item, ind)}
                          multiple={true}
                        ><Button disabled={isfalse} style={{marginLeft:'6px'}} type="primary"><Icon type="upload" />提交秘钥</Button></Upload>
                      </Col>
                    </Row>
                    <Row style={{ margin: '10px 5px 0 0 ' }}>
                      <Col span={12}>预约状态：<span style={{display:"inline-block",fontWeight:'bold',color:'red'}}>{item.isFake== "0" ? '非预约' : '预约'}</span></Col>
                    </Row>
                    <Table columns={columns} dataSource={item.playInfoList} pagination={false}></Table>
                  </div>
                )
              })
            }
          </div>
          {
            this.state.params.downloadMethod == "0" ? <div style={{ marginLeft: '30px' }}>
            
              <Row style={{ margin: '10px 5px 5px 0', borderBottom: '1px solid #ccc' }}>
                <span
                  style={{
                    width: "2px",
                    height: "15px",
                    background: "red",
                    display: "inline-block",
                    margin: "15px 10px 0 0"
                  }}
                ></span>
                <b>快递信息</b>
              </Row>
              <Row style={{ margin: '10px 5px 5px 30px' }}>
                <Col>  收件人：<span>{params.bosAddressee ? params.bosAddressee.contactName : ''}</span></Col>
              </Row> 
              <Row style={{ margin: '10px 5px 5px 15px' }}>
                <Col>联系方式：<span>{params.bosAddressee ? params.bosAddressee.contactPhone : ''}</span></Col>
              </Row>
              <Row style={{ margin: '10px 5px 5px 0' }}>
                <Col>收件人地址：<span>{params.bosAddressee ? params.bosAddressee.address : ''}</span></Col>
              </Row>
              <Row style={{ margin: '10px 5px 5px 14px' }}>
                <Col span={15}>快递公司：<Input style={{ width: '200px' }} disabled={isfalse} value={this.state.params.expressCompany} onChange={this.inpVal.bind(this, 'expressCompany')}></Input>
                </Col>
              </Row>
              <Row style={{ margin: '10px 5px 5px 14px' }}>
                <Col span={15}>快递单号：<Input style={{ width: '200px' }} disabled={isfalse} value={this.state.params.expressNum} onChange={this.inpVal.bind(this, 'expressNum')} ></Input>
                </Col>
              </Row>
            </div> : null
          }
        </Spin>
      </div>//最后的div
    );
  }
}
export default OrderDetial;

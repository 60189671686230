import React from 'react';
import {Tabs} from 'antd';
import BoxVersionList from './BoxVersionList.js';

const { TabPane } = Tabs;
class BoxVersionManage extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        activeKey:"1",
        params:null,
        isfalse:null
      }
    }
    callback(activeKey,type,params){
        if(type == "details"){
          this.state.params = params
          this.state.isfalse=true;
        }
        if(type == "add"){
            this.state.params = null
            this.state.isfalse=false;
          }
      this.setState({activeKey:activeKey})
    }
    
    render(){
        return(
          <div>
              <Tabs type="card" activeKey={this.state.activeKey} onChange= {this.callback.bind(this)}>
                <TabPane tab="版本列表" key="1">
               <BoxVersionList buttonList={this.props.buttonList} callback = {this.callback.bind(this)}/>
              </TabPane>
            </Tabs>
          </div>
        )
    }
}
export default BoxVersionManage;
import React from "react";
import {
  Input,
  Select,
  Button,
  Spin,
  Table,
  Row,
  Col,
  message,
  Icon,
  Modal
} from "antd";
import history from "../history";
class SecretOrderExportedDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          params: {},
          param: {},
          loading: false,
          disbtn:'none',
          ites:{}
         };
    }
    componentWillMount() {
      if (this.props.params != null) {
        this.state.params = this.props.params;
        this.onload(this.state.params);
      } 
      this.showBtn(this.props.buttonList); //控制按钮显示
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps != null) {
        if (nextProps.params != null) {
          this.state.params = nextProps.params
          this.onload(this.state.params);
        } else {
          this.setState({
            params: {}
          });
        }
      } else {
        this.setState({
          params: {}
        });
      }
    }
    //控制按钮显示
    showBtn(array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].buttonCode == "btn_secret_confirm") {
          this.state.btn_secret_confirm = true;
        }
      }
    }
    //请求详情的列表
  onload(params) {
    let dataParams = {};
    this.setState({ loading: true });
    let content = { id: params.id }
    fetch('/api/exportOrder/queryExportOrderDetails', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content),
    }).then(function (res) {
      if (!res.ok) { message.error("网络不可用！"); }
      return res.json();
    }).then((data) => {
      if (data.code == 0) {
        dataParams = data.result;
        dataParams.bList.map(item=>{
         item.disbtn="none"
        })
      } else {
        message.error(data.message);
        if (data.code == 2003) {
          history.push({ pathname: '/' });
        }
      }
    }).then((e) => {
      this.setState({ param: dataParams, loading: false });
    });
  }
  handleOk(type){
    this.exportEvent()
    this.setState({visible:false})
  }
actorCancel(){
    this.setState({visible:false})
}
btnClick(){
    this.setState({visible:true})

}
  exportEvent(){
    let list=[{id:this.state.param.id}]
          fetch('/api/exportOrder/operation', {
              method: 'post',
              credentials: 'include',
              headers: {
                  'Content-Type': 'application/json'  
              },
              body: JSON.stringify(list),
          }).then(function(res) {
            if (!res.ok) {
              message.error('网络不可用！');
            }
            return res.json();
          }).then((data)=> {
            console.log(data)
            if (data.code == 0) {
             message.success('确认成功')
             this.onload(this.state.param)
             } 
            //else if(data.code==-1){
            //   message.error('存在已导出的订单,请核实')
            // }
            else {
              message.error(data.message);
              if (data.code == 2003) {
                history.push({ pathname: '/' });
              }
            }
          })
   }
   clickbtn(type){
    let {param}=this.state
    if(param.bList[type].disbtn=="none"){
      param.bList[type].disbtn="block"
      // ites.disbtn="block"
    }else if( param.bList[type].disbtn="block"){
      param.bList[type].disbtn="none"
      // ites.disbtn="block"
    }
    this.setState({param})
     }
    render() {
      const columns=[
        // {
        //   title: '序号',
        //   render: (text, record, index) => `${index + 1}`
        // },
        {
          title: '省份',
          dataIndex: 'province',
          key: 'province'
        },
        {
          title: '城市',
          dataIndex: 'city',
          key: 'city'
        },
        {
          title: '院线',
          dataIndex: 'bosTheaterChainName',
          key: 'bosTheaterChainName'
        },
        {
          title: '影城名称',
          dataIndex: 'cinemaName',
          key: 'cinemaName'
        },
        {
          title: '影城编码',
          dataIndex: 'cinemaCode',
          key: 'cinemaCode'
        },
        {
          title: '厅号',
          dataIndex: 'hallName',
          key: 'hallName'
        },
        {
          title: '服务器序列号',
          dataIndex: 'serverSerialNumber',
          key: 'serverSerialNumber'
        },
        {
          title: '开始日期',
          dataIndex: 'beginDate',
          key: 'beginDate'
        },
        {
          title: '开始时间',
          dataIndex: 'beginTime',
          key: 'beginTime'
        },
        {
          title: '结束日期',
          dataIndex: 'endDate',
          key: 'endDate'
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
          key: 'endTime'
        },
        {
          title: '影城联系人',
          dataIndex: 'cinemaContactsName',
          key: 'cinemaContactsName'
        },
        {
          title: '联系电话',
          dataIndex: 'cinemaContactsPhone',
          key: 'cinemaContactsPhone'
        },
        {
          title: '是否中数设备',
          dataIndex: 'isOwner',
          key: 'isOwner',
          render:(text)=>text=="0"?"否":text=="1"?"是":''
        }
      ]
        return (
      <div
        className="SecretOrderExportedDetail"
        key={
          this.state.params != null && this.state.params.id == "-1"
            ? this.state.params.id
            : this.state.params.id
        }
      >
        <Spin spinning={this.state.loading} tip="Loading...">
            <Row>
              <Col span={7}>密钥导出单号：{this.state.params.exportNum}</Col>
              <Col span={7}>导出时间：{this.state.params.exportTime}</Col>
              <Col span={5}>导出操作人员：{this.state.params.exportPeople}</Col>
              <Col span={3}><Button style={{display: this.state.btn_secret_confirm == true ? "inline-block" : "none",margin:'-15px 0 13px 0' }} onClick={this.btnClick.bind(this)}>导出单确认</Button></Col>
            </Row>
            {
             this.state.param.bList&&this.state.param.bList.map((item,index)=>{
               return  <div style={{width:'100%'}}>
               <div style={{width:'100%',height:'40px',border:'1px solid #ddd',lineHeight:'40px',background:'#ccc'}} onClick={this.clickbtn.bind(this,index)}><Row><Col style={{marginLeft:'10px'}} span={22}>{item.filmName}</Col><Col span={1}><Icon type="down"></Icon></Col></Row></div>
               <div style={{width:'100%',display:item.disbtn}}>
               <Table 
                  size={'small'}
                  columns={columns}
                  dataSource={item.exportList}
                  pagination={false}
                  // pagination={{onChange: this.pageChange.bind(this),total:this.state.total}}
                />
               </div>
             </div>
             })
            }
            <Modal title="提示" okText="确认"  cancelText="取消" width="300px"  style={{marginTop:"20px"}} visible={this.state.visible}  onOk={this.handleOk.bind(this)} onCancel={this.actorCancel.bind(this)}>
                  <div style={{width:'100%',textAlign:"center",height:"5px",lineHeight:"5px"}}>
                  <b>是否确认?</b>
                  </div>
            </Modal>
        </Spin>
      </div>
        );
    }
}

export default SecretOrderExportedDetail;
import React from 'react';
import {Button ,message,} from 'antd';
class ImportTemplate extends React.Component{
  
    constructor(props) {
      super(props);  
      this.state={
        visible:false
      }
    }  
//导出
eventClick(){
    let content={ type: "1"}
        fetch('/api/templateManage/exportExcel', {
        method: 'post',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'  
        },
        body: JSON.stringify(content),
    }).then(response => {
        console.log(response)
        response.blob().then(blob => {
            console.log(blob)
          let blobUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a'); //获取a标签元素
          let filename='cinema.xls'
          a.href = blobUrl; //设置a标签路径
          a.download =filename;
          a.click();
          window.URL.revokeObjectURL(blobUrl);
          message.success("导出成功");
        });
      })
      .catch(error => {
        message.error("导出失败", error);
      });
}

    render(){
        return(
          <div>
            <Button type="primary"  onClick={this.eventClick.bind(this)}>影院信息模板</Button>
          </div>
        )
    }
}
export default ImportTemplate;
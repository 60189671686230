import React from "react";
import {
  Input,
  Select,
  Button,
  Spin,
  message,
  Table,
  Upload,
  Row,
  Col
} from "antd";
import history from "../history";
import Model from "../../utils/data";
const { Option } = Select;
class CinemaDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: {
      },
      cinemaparams: [],
      loading: false,
      total: 0,
      isfalse: null,
      fileList: [],
      // previewVisible: false,
      // previewImage: "",
      theatreList: [],
      capitals: [], // 省份
      city: [], // 市
      areaList: [], // 区
      dataList: [],
      dataSource: [],
      count: 0,
      hallPicturePath: "",
      imgPath: {},
      esfalse:false,
      administrationList:[],
      shadowList:[],
      queryAgentList:[],
      queryDistributorList:[],
      userList:[],
      userListTwo:[]
    };

  } 
  onload(id){
    let content = { id: id };
    fetch("/api/cinema/cinemaDetails", {
      method: "post",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error("网络不可用！");
        }
        return res.json();
      })
      .then(data => {
        if (data.code == 0) {
          this.setState({
            params: data.result
          });
        }else {
          message.error(data.message);
          if(data.code == 2003){
          history.push({pathname:'/'});
          }
        }
      });
  }
  componentDidMount() {
    if (this.props.params != null) {
      this.state.params = this.props.params;
      this.state.dataSource = this.props.dataSource
      this.state.isfalse = this.props.isfalse;
       this.onload(this.state.params.id); //请求详情
      // this.state.fileList=this.props.dataSource
     
      //  this.state.dataSource((item,index)=>{
      //     let imgPath = {
      //     uid: this.state.params.id,
      //     url: this.state.hallPicturePath  //有数据让它默认
      // }
      //  this.state.fileList.push(imgPath);
      //  this.setState({ fileList: this.state.fileList});
      //  })
      this.state.loading = false;
      
    } else {
      this.state.fileList = [];
    }
    this.state.theatreList = this.props.basics.theatreList;
    this.state.shadowList = this.props. basics.shadowList;
    this.state.administrationList = this.props.basics.administrationList;
    this.state.queryAgentList = this.props.basics.queryAgentList;
    this.state.queryDistributorList = this.props.basics.queryDistributorList;
    this.state.userList = this.props.basics.userList;
    this.state.userListTwo = this.props.basics.userListTwo;
    this.showBtn(this.props.buttonList); //控制按钮显示
    this.handleAddressData(); //请求城市的接口
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps != null) {
      if (nextProps.params != null) {
        this.state.params = nextProps.params;
        this.state.isfalse = this.props.isfalse;
        this.state.dataSource = nextProps.params.hallList
        this.state.esfalse=false
        for (var i = 0; i < this.state.dataSource.length; i++) {
          this.state.dataSource[i].fileList = [{ uid: this.state.dataSource[i].id, url: this.state.dataSource[i].hallPicturePath }]
        }
        // let imgPath = {
        //     uid: this.state.params.id,
        //     url: this.state.hallPicturePath  //有数据让它默认
        // }
        //  this.state.fileList.push(imgPath);
        //  this.setState({ fileList: this.state.fileList});
      } else {
        this.state.fileList = [];
        this.state.dataSource = [];
        this.state.isfalse = null
        this.setState({
          params: {}
        });
      }
    } else {
      this.state.dataSource = [];
      this.state.isfalse = null
      this.setState({
        params: {}
      });
    }
  }
 

  //改变input的值
  onChange(index, type, e) {
    const dataSource = this.state.dataSource;
    if (type == "description") {
      dataSource[index].description = e.target.value;
    }
    if (type == "code") {
      dataSource[index].code = e.target.value;
    }
    if (type == "serverSerialNumber") {
      dataSource[index].serverSerialNumber = e.target.value;
    }
    
    if (type == "name") {
      dataSource[index].name = e.target.value;
    }
    if (type == "seatNumber") {
      if(!(/^[0-9]*$/).test(e.target.value)){
        return message.error('座位数只能输入数字');
      }
      dataSource[index].seatNumber = e.target.value;
    }
    if (type == "areas") {
      if(!(/^[0-9]*$/).test(e.target.value)){
        return message.error('影厅面积只能输入数字');
      }
      dataSource[index].areas = e.target.value;
    }
    this.setState({
      dataSource: dataSource
    })
  }

 

  valChange(index, type, value) {
    const dataSource = this.state.dataSource
    if (type == "type") {
      // this.state.params.type = e.target.value;
      dataSource[index].type = value;
    }
    this.setState({
      dataSource: dataSource
    })
  }

  //请求接口拿到城市数据 三级联动
  handleAddressData = () => {
    const da = Model.get();
    if (da && da.length > 0) {
      this.setState({
        capitals: da
      });
    }
  };
  //城市
  getCity = value => {
    let params=this.state.params;
    params.province = value;
    const { capitals } = this.state;
    let da = [];
    capitals.map(item => {
      if (item.name === value) {
        da = item.cityList;
      }
    });
    this.state.city = da;
    this.setState({
      city: da,
      params:params
    });
    this.getSreaList(params.city);
    // this.getData();
  };
  //直辖市 县
  getSreaList = value => {
    let params=this.state.params;
    params.city = value;
    const { city } = this.state;
    let ci = [];
    city.map(item => {
      if (item.name === value) {
        ci = item.areaList;
      }
    });
    this.setState({
      areaList: ci,
      params:params
    });
  };
  //城区
  getData = value => {
   let params=this.state.params
   params.area = value;
    // console.log(this.state.params.capacity )
   this.setState({params:params})
  };

  //点击删除
  deleteData(index) {
    let arrs = this.state.dataSource
    arrs.splice(index, 1);
    this.setState({
      dataSource: [...arrs]
    });
  }

  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_update") {
        this.state.btn_update = true;
      }
      if (array[i].buttonCode == "btn_add") {
        this.state.btn_add = true;
      }
      if (array[i].buttonCode == "btn_delete") {
        this.state.btn_delete = true;
      }
    }
  }

  //获取到input改变的值
  handleChange(type, e) {
    let params = this.state.params
    if (type == "code") {
      params.code = e.target.value;
    }
    if (type == "name") {
      params.name = e.target.value;
    }
    if (type == "businessMobile") {
      params.businessMobile = e.target.value;
    }
    if (type == "mail") {
      params.mail = e.target.value;
    }
    if (type == "saleMobile") {
      params.saleMobile = e.target.value;
    }
    if (type == "contactsName") {
      params.contactsName = e.target.value;
    }
    if(type=="contactsPhone"){
     params.contactsPhone=e.target.value
    }
    // if (type == "sale") {
    //   params.sale = e.target.value;
    // }
    if (type == "place") {
      params.place = e.target.value;

    }
    // if (type == "business") {
    //   params.business = e.target.value;
    // }
    this.setState({
      params: { ...params }
    })
  }

  //获取select改变的值   影院的id
  selectChange(type,e,obj) {
    let {params}=this.state;
    if(type=='theaterid'){
     params.theaterChainId = e;
    }
    this.setState({params})
  }
  selectChange1(type,e,obj){
    let {params}=this.state;
    if(type=='cinemaInvestmentId'){
     params.cinemaInvestmentId=e;
     params.cinemaInvestmentName=obj.props.children;
    }
    this.setState({params})
  }
  selectChange2(type,e,obj){
    let {params}=this.state;
    if(type=='areaId'){
     params.areaId = e;
     params.areaName=obj.props.children; 
    }
    if(type=="agentId"){
     params.agentId = e;
     params.agentName=obj.props.children;
    }
    if(type=="distributorId"){
     params.distributorId = e;
     params.distributorName=obj.props.children;
    }
    if(type=="saleId"){
      params.saleId = e;
      params.sale=obj.props.children;
     }
     if(type=="businessId"){
      params.businessId = e;
      params.business=obj.props.children;
     }
    this.setState({params})
  }
  //保存
  btnClick() {
    if(this.state.params.name == undefined || this.state.params.name == ""){
      message.error("未填写影院名称！");
      return;
    }
    if(this.state.params.province == undefined ||this.state.params.province == "" ){
      message.error("未填写所属省份！");
      return;
    }
    if(this.state.params.city == undefined ||  this.state.params.city ==""){
      message.error("未填写所属城市！");
      return;
    }
    if(this.state.params.area == undefined ||this.state.params.area == ""){
      message.error("未填写所属城区！");
      return;
    }
    if(this.state.params.place == undefined || this.state.params.place == ""){
      message.error("未填写详细地址！");
      return;
    }
    if(this.state.params.contactsName == undefined || this.state.params.contactsName == ""){
      message.error("未填写联系人姓名！");
      return;
    }
    if(this.state.params.contactsPhone == undefined || this.state.params.contactsPhone == ""){
      message.error("未填写联系人手机号！");
      return;
    }

    if(this.state.params.theaterChainId == undefined || this.state.params.theaterChainId == ""){
      message.error("未填写所属院线！");
      return;
    }
    if(this.state.params.code == undefined || this.state.params.code == ""){
      message.error("未填写影院编码！");
      return;
    }
    if(this.state.params.cinemaInvestmentId == undefined || this.state.params.cinemaInvestmentId == ""){
      message.error("未填写影投列表！");
      return;
    }
    if(this.state.params.areaId == undefined || this.state.params.areaId == ""){
      message.error("未填写政区列表！");
      return;
    }
    for(i=0;i<this.state.dataSource.length;i++){
      if(this.state.dataSource[i].name==null||this.state.dataSource[i].name==''||this.state.dataSource[i].name==undefined){
        message.error('未填写影厅名称');
        return;
      }
      if(this.state.dataSource[i].code==null||this.state.dataSource[i].code==''||this.state.dataSource[i].code==undefined){
        message.error('未填写影厅编码'); 
        return;
      }
      if(this.state.dataSource[i].serverSerialNumber==null||this.state.dataSource[i].serverSerialNumber==''||this.state.dataSource[i].serverSerialNumber==undefined){
        message.error('未填写服务器序列号');
        return;
      }
      if(this.state.dataSource[i].seatNumber==null||this.state.dataSource[i].seatNumber==''||this.state.dataSource[i].seatNumber==undefined){
        message.error('未填写座位数');
        return;
      }
      if(this.state.dataSource[i].areas==null||this.state.dataSource[i].areas==''||this.state.dataSource[i].areas==undefined){
        message.error('未填写影厅面积');
        return;
      }
     
    }
      for (var i = 0; i < this.state.dataSource.length; i++) {
        if (this.state.dataSource[i].fileList) {
          // this.state.dataSource[i]={...this.state.dataSource[i].fileList}
          this.state.dataSource[i].hallPicturePath = this.state.dataSource[i].fileList[0].url
          delete this.state.dataSource[i].newData
          delete this.state.dataSource[i].fileList
        }
      }
      this.state.params.hallList = this.state.dataSource;
      this.setState({
        params: this.state.params,
        dataSource: this.state.dataSource,
        loading: true 
      });
    let content = this.state.params; //拿到数据
    if (this.state.params.id) {    //修改的接口 
      fetch("/api/cinema/updateCinema", {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(content)
      })
        .then(function (res) {
          if (!res.ok) {
            message.error("网络不可用！");
          }
          return res.json();
        })
        .then(function (data) {
          if (data.code == 0) {
            message.success("保存成功！");
          } else {
            message.error(data.message);
            if (data.code == 2003) {
              history.push({ pathname: "/" });
            }
          }
        })
        .then(e => {
          this.state.fileList = [];
          this.setState({ loading: false,isfalse: true, esfalse: false });
        });
    } else if(this.state.params.id==undefined) {
      let {params}=this.state;
      //新增的接口
      fetch("/api/cinema/addCinema", {
        method: "post",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(content)  
      })
        .then(function (res) {
          if (!res.ok) {
            message.error("网络不可用！");
          }
          return res.json();
        })
        .then(function (data) {
          if (data.code == 0) {
            params=data.result;
            message.success("保存成功！");
          } else {
            message.error(data.message);
            if (data.code == 2003) {
              history.push({ pathname: "/" });
            }
          }
        })
        .then(e => {
          this.state.fileList = []; 
          this.setState({ loading: false ,isfalse: true, esfalse: false,params:params});
        });
      }
  }
  cencel(type) {
    //修改还是取消
    if (type == 'write') {
        this.getCity(this.state.params.province);
        // this.getSreaList(this.state.params.city);
        this.setState({
            isfalse: false,
            esfalse:true
        });
    } else {
        this.setState({
            isfalse: true,
            esfalse: false,
        });
    }
}

  // inpChange(type,e){ //影院列表获取input值
  //   let columns=this.state.columns
  //   console.log(columns)
  //    for(var i=0;i<columns.length;i++){
  //      if(columns[i].key==type){
  //       columns[i].title=e.target.value
  //      }
  //    }
  // }

  // 关于上传的js;
  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };


  imgChange = ({ file, fileList }, index) => {
    const newDataSource = this.state.dataSource;
    newDataSource[index].fileList = fileList.map((item) => {
      if (item.status === "done") {
        return { uid: item.uid, url: item.response.result } 
      }
      // else if(item.status=="error"){
      //   message.error('上传失败')
      // }
      return item;
    })
    // const fileLists = newDataSource[index].fileList;
    this.setState({ loading: false })
    if (fileList.length > 0 && fileList[0].status === "done") {
      // newDataSource[index].fileList = fileLists ? fileLists : []
      // newDataSource[index].fileList = newDataSource[index].fileList.concat()
      this.setState({
        dataSource: newDataSource
      })
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  setfileList(id, path) {
    if (id != null && path != null) {
      var array = [];
      array.push({ uid: id, url: path })
      return array;
    }

  }

  //点击增加行
  handleAdd = () => { 
    const { count } = this.state;
    const newData = {
    };
    this.state.dataSource.push({ id: (new Date()).valueOf(), newData })
    this.setState({
      count: count + 1
    });

  }
  
  render() {
    const { previewVisible, previewImage, fileList } = this.state;

    let columnss = [
      {
        title: "影厅名称",
        dataIndex: "name",
        key: "name",
        width: "15%",
        render: (text, record, index) => (
          <Input
            disabled={isfalse}
            value={this.state.dataSource[index].name || []}
            placeholder="请输入影厅名称"
            allowClear
            onChange={this.onChange.bind(this, index, "name")}
          />
        )
      },
      {
        title: "影厅编码",
        dataIndex: "code",
        key: "code",
        width: "15%",
        render: (text, record, index) => (
          <Input
            disabled={isfalse}
            value={this.state.dataSource[index].code}
            placeholder="请输入影厅编码"
            allowClear
            onChange={this.onChange.bind(this, index, "code")}
          />
        )
      },
      {
        title: "服务器序列号",
        dataIndex: "serverSerialNumber",
        key: "serverSerialNumber",
        width: "15%",
        render: (text, record, index) => (
          <Input
            disabled={isfalse}
            value={this.state.dataSource[index].serverSerialNumber}
            placeholder="请输入服务器序列号"
            allowClear
            onChange={this.onChange.bind(this, index, "serverSerialNumber")}
          />
        )
      },
      // {
      //   title: "影厅风格",
      //   dataIndex: "type",
      //   key: "type",
      //   width: "15%",
      //   render: (text, record, index) => (
      //     <Select
      //       disabled={isfalse}
      //       allowClear
      //       // value={this.state.dataSource[index].type}
      //       value={this.state.dataSource[index].type == "0" ? "大众厅" : this.state.dataSource[index].type == "1" ? "点播厅" : "请输入影厅风格"}
      //       // placeholder="请输入影厅风格"
      //       onChange={this.valChange.bind(this, index, "type")}
      //     >
      //       <Option value="0">大众厅</Option>
      //       <Option value="1">点播厅</Option>
      //     </Select>
      //   )
      // },
      {
        title: "座位数",
        key: "seatNumber",
        dataIndex: "seatNumber",
        width: "15%",
        render: (text, record, index) => (
          <Input 
            disabled={isfalse}
            value={this.state.dataSource[index].seatNumber}
            placeholder="请输入座位数"
            allowClear
            onChange={this.onChange.bind(this, index, "seatNumber")}
          />
        )
      },
      {
        title: "影厅面积",
        dataIndex: "areas",
        key: "areas",
        width: "15%",
        render: (text, record, index) => (
          <Input 
            disabled={isfalse}
            value={this.state.dataSource[index].areas}
            placeholder="请输入影厅面积"
            allowClear
            onChange={this.onChange.bind(this, index, "areas")}
          />
        )
      },
      {
        title: "影厅效果图",
        dataIndex: "hallPicturePath",
        key: "hallPicturePath",
        width: "15%",
        render: (text, record, index) => (
          //    let imgPath = {
          //     uid: this.state.params.id,
          //     url: this.state.hallPicturePath  //有数据让它默认
          // }
          //  this.state.fileList.push(imgPath);
          //  this.setState({ fileList: this.state.fileList});
          <Upload
            disabled={isfalse}
            listType="picture-card"
            action="/api/cinema/uploadFile"
            // defaultFileList = {this.state.dataSource[index].hallPicturePath}
            onChange={(e) => this.imgChange(e, index)}
            // fileList={this.setfileList(record.id,this.state.dataSource[index].hallPicturePath)}  
            fileList={this.state.dataSource[index].fileList }
            onPreview={this.handlePreview}
            data={{ cinemaId: index, modKeyOrderFilmId: null, type: '1' }}
          >
            {this.state.dataSource[index].fileList && this.state.dataSource[index].fileList.length >= 1 ? null : (
              <span className="ant-upload-text">上传图片</span>
            )}
          </Upload>
        )

      },
      {
        title: "备注",
        dataIndex: "description",
        key: "description",
        width: "15%",
        render: (text, record, index) => (
          <Input
            disabled={isfalse}
            value={this.state.dataSource[index].description}
            placeholder="请输入备注"
            allowClear
            onChange={this.onChange.bind(this, index, "description")}
          />
        )
      },
      {
        title: "操作",
        key: "",
        dataIndex: "",
        render: (text, record, index) => (
          <Button disabled={isfalse} onClick={this.deleteData.bind(this, index)}>删除</Button>
        )
      }
    ];
    // const { previewVisible, previewImage, fileList } = this.state;
    // if (fileList.length > 0 &&fileList[0].status == "done") {
    //   this.state.url = fileList[0].response.result;
    // }
    // if (fileList.length == 0) {
    //   this.state.url = null;
    // }
    const { dataSource } = this.state;
    // const components = {
    //   body: {
    //     row: EditableFormRow,
    //     cell: EditableCell
    //   }
    // };
    const columns = columnss.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });
    let {esfalse, isfalse, cinemaparams } = this.state;
    return (
      <div className="cinemaDetails"
        key={
          this.state.params != null && this.state.params.id == "-1" ? this.state.params.id : this.state.params.id
        }
      >
        <Spin spinning={this.state.loading} tip="Loading...">
          <div>
            <Button
              style={{
                display:
                  this.state.btn_update == true ? "inline-block" : "none",
                marginLeft: 10
              }}
              onClick={this.btnClick.bind(this)}
              type="primary"
              disabled={isfalse}
            >
              保存
            </Button>
            <Button
              style={{
                display: this.state.btn_update == true ? "inline-block" : "none",
                marginLeft: 20
              }}
              onClick={this.cencel.bind(this, 'write')}
              disabled={esfalse}
            >
              修改
            </Button>
            <Button
              style={{
                display:
                  this.state.btn_delete == true ? "inline-block" : "none",
                marginLeft: 20
              }}
              onClick={this.cencel.bind(this, true)}
              disabled={isfalse}
            >
              取消
            </Button>
           
            <Button style={{ marginLeft: 20 }} onClick={this.props.callback.bind(this, "1", null, null)}>返回</Button>
          </div>
          <div style={{ width: "100%", margin: "5px 0 10px 0" }}>
            <span
              style={{
                width: "2px",
                height: "15px",
                background: "red",
                display: "inline-block",
                margin: "10px 10px 0 0"
              }}
            ></span>
            <b>基本信息</b>
          </div>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box"> 影院名称：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.params.name}
                  placeholder="请输入用户名"
                  onChange={this.handleChange.bind(this, "name")}
                />
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">影院编码：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
                <Input
                  style={{ width: 240 }}
                  disabled={isfalse}
                  value={this.state.params.code}
                  onChange={this.handleChange.bind(this, "code")}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box"> 所属省份：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              <Select
                disabled={isfalse}
                value={this.state.params.province}
                style={{  width: 240  }}
                onChange={this.getCity}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.capitals.map((item, index) => {
                  return <Option value={item.name} key={index}>{item.name}</Option>;
                })}
              </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">所属城市：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              <Select
                disabled={isfalse}
                style={{ width: 240 }}
                onChange={this.getSreaList}
                value={this.state.params.city }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {this.state.city.map((item, index) => {
                  return <Option value={item.name} key={index}>{item.name}</Option>;
                })}
              </Select>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">所属城区：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              <Select
                disabled={isfalse}
                style={{ width: 240 }}
                value={this.state.params.area}
                optionFilterProp="children"
                onChange={this.getData}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                
                {this.state.areaList.map((item, index) => {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">所属院线：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              <Select
                disabled={isfalse}
                style={{ width: 240  }}
                value={this.state.params.theaterChainId }
                onChange={this.selectChange.bind(this, "theaterid")}
              >
                {this.state.theatreList &&
                  this.state.theatreList.map((item, index) => {
                    return <Option value={item.id} key={index}>{item.name}</Option>;
                  })}
              </Select>
              </div>
            </Col>
          </Row>
         
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">   详细地址：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              <Input
               style={{ width: 240  }}
                disabled={isfalse}
                value={this.state.params.place }
                placeholder="请输入详细地址"
                onChange={this.handleChange.bind(this, "place")}
              />
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">影投列表：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              <Select
                disabled={isfalse}
                style={{ width: 240 }}
                value={this.state.params.cinemaInvestmentId }
                onChange={this.selectChange1.bind(this, "cinemaInvestmentId")}
              >
                <Option value="">--请选择--</Option>
                {this.state.shadowList &&
                  this.state.shadowList.map((item, index) => {
                    return <Option value={item?item.id:''} key={index}>{item.name}</Option>;
                  })}
              </Select>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
          <Col className="gutter-row" span={12}>
              <div className="gutter-box">政区列表：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              <Select
                disabled={isfalse}
                style={{ width: 240}}
                value={this.state.params.areaId}
                onChange={this.selectChange2.bind(this, "areaId")}
              >
                <Option value="">--请选择--</Option>
                {this.state.administrationList &&
                  this.state.administrationList.map((item, index) => {
                    return <Option value={item?item.id:''} key={index}>{item.name}</Option>;
                  })}
              </Select>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
          <Col className="gutter-row" span={12}>
              <div className="gutter-box">代理商列表：
              <Select
                disabled={isfalse}
                style={{ width: 240}}
                value={this.state.params.agentName}
                onChange={this.selectChange2.bind(this, "agentId")}
              >
                <Option value="">--请选择--</Option>
                {this.state.queryAgentList &&
                  this.state.queryAgentList.map((item, index) => {
                    return <Option value={item?item.id:''} key={index}>{item.name}</Option>;
                  })}
              </Select>
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">经销商列表：
              <Select
                disabled={isfalse}
                style={{ width: 240}}
                value={this.state.params.distributorName }
                onChange={this.selectChange2.bind(this, "distributorId")}
              >
                <Option value="">--请选择--</Option>
                {this.state.queryDistributorList &&
                  this.state.queryDistributorList.map((item, index) => {
                    return <Option value={item?item.id:''} key={index}>{item.name}</Option>;
                  })}
              </Select>
              </div>
            </Col>
          </Row>
          <div style={{ width: "100%", margin: "10px 0 10px 0" }}>
            <span
              style={{
                width: "2px",
                height: "15px",
                background: "red",
                display: "inline-block",
                margin: "10px  10px 0  0"
              }}
            ></span>
            <b>联系人信息</b>
          </div>
          {/* <div style={{ width: "100%", overflow: "hidden" }}>
            <p style={{ float: "left" }}>
              联系人:
              <Input
                width="200"
                disabled={isfalse}
                value={this.state.params.contactsName || ['']}
                placeholder="请输入联系人"
                onChange={this.handleChange.bind(this, "contactsName")}
              />
            </p>
          </div> */}
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">联系人姓名：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              <Input
                style={{ width: 240  }}
                disabled={isfalse}
                value={this.state.params.contactsName }
                placeholder="请输入联系人"
                onChange={this.handleChange.bind(this, "contactsName")}
              />
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">手机号码：<span style={{ color: "#b94a48", fontWeight: 900 }}>*</span>
              <Input
                style={{ width: 240}}
                disabled={isfalse}
                value={this.state.params.contactsPhone }
                placeholder="请输入联系人手机号"
                onChange={this.handleChange.bind(this, "contactsPhone")}
              />
              </div>
            </Col>
          </Row>
          {/* <div style={{ float: "left", width: "100%" }}>
            <p style={{ marginBottom: "10px" }}>
              邮箱:
              <Input
                disabled={isfalse}
                value={this.state.params.mail || ['']}
                placeholder="请输入邮箱"
                onChange={this.handleChange.bind(this, "mail")}
              />
            </p>
          </div> */}
           <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">联系人邮箱：
              <Input
                style={{ width: 240,marginLeft:'12px'  }}
                disabled={isfalse}
                value={this.state.params.mail }
                placeholder="请输入联系人邮箱"
                onChange={this.handleChange.bind(this, "mail")}
              />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="gutter-box">销售负责人：
              <Select
                disabled={isfalse}
                style={{ width: 240}}
                value={this.state.params.sale}
                onChange={this.selectChange2.bind(this, "saleId")}
              >
                <Option value="">--请选择--</Option>
                {this.state.userList &&
                  this.state.userList.map((item, index) => {
                    return <Option value={item.id}>{item.name}</Option>;
                  })}
              </Select>
              </div>
            </Col>
            {/* <Col className="gutter-row" span={12}>
              <div className="gutter-box">手机号码：
              <Input
                style={{ width: 240,marginLeft:'12px'}}
                disabled={isfalse}
                value={this.state.params.saleMobile}
                placeholder="请输入手机号码"
                onChange={this.handleChange.bind(this, "saleMobile")}
              />
              </div>
            </Col> */}
          {/* </Row>
         
          <Row gutter={16}> */}
            <Col className="gutter-row" span={12}>
              <div className="gutter-box"> 商务负责人：
              <Select
                disabled={isfalse}
                style={{ width: 240}}
                value={this.state.params.business}
                onChange={this.selectChange2.bind(this, "businessId")}
              >
                <Option value="">--请选择--</Option>
                {this.state.userListTwo &&
                  this.state.userListTwo.map((item, index) => {
                    return <Option value={item?item.id:''} key={index}>{item.name}</Option>;
                  })}
              </Select>
              </div>
            </Col>
            {/* <Col className="gutter-row" span={12}>
              <div className="gutter-box">手机号码：
              <Input
                style={{ width: 240,marginLeft:'12px'}}
                disabled={isfalse}
                value={this.state.params.businessMobile }
                placeholder="请输入手机号"
                onChange={this.handleChange.bind(this, "businessMobile")}
              />
              </div>
            </Col> */}
          </Row>
          
          <div>
            <Button
              onClick={this.handleAdd}
              type="primary"
              style={{ marginBottom: 16 }}
              disabled={isfalse}
            >
              增行
            </Button>
            <div className="tabbox">
              <Table
                // components={components}
                // rowClassName={() => "editable-row"}
                rowKey={record => record.id}
                bordered
                dataSource={this.state.dataSource}
                columns={columnss}
              />
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}
export default CinemaDetails;

//"proxy":"http://218.241.209.227:9090",
// "proxy": "http://192.168.20.95:8080",

import React from 'react';
import { Table, Spin, Button, message, Tabs, Modal, Select, Input,Upload,Icon} from 'antd';
import history from '../history';
const { Option } = Select;
class DcpOrderExportedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      params: [],
      page: 0,
      pageSize: 10,
      selectedRowKeys: [],
      selectedRow: [],
      total: 0,
      loading: false,
      list: [],
      fileList:[],
      disbtn:true
    };
  }
  componentWillMount() {
    this.onload();
    this.showBtn(this.props.buttonList); //控制按钮显示
  }
  //控制按钮显示
  showBtn(array) {
    for (var i = 0; i < array.length; i++) {
      if (array[i].buttonCode == "btn_search") {
        this.state.btn_search = true
    }
      if (array[i].buttonCode == 'btn_dcp_confirm') {
        this.state.btn_dcp_confirm = true;
      }
    }
  }
  onload(ty) {
    this.setState({ loading: true });
    let array = [];
    let total = 0;
    if(ty=="1"){
      this.state.start=0
      }else{
      this.state.start=this.state.page
      }
    let content = {
      start: this.state.start,
      limit: this.state.pageSize,
      status: '0',
      exportType:'1',
      exportNum: this.state.exportNum
    };
    // console.log(content)
    fetch('/api/exportOrder/queryExportOrderList', {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(content)
    })
      .then(function(res) {
        if (!res.ok) {
          message.error('网络不可用！');
        }
        return res.json();
      })
      .then(function(data) {
        if (data.code == 0) {
          array = data.result;
          total = data.total;
        } else {
          message.error(data.message);
          if (data.code == 2003) {
            history.push({ pathname: '/' });
          }
        }
      })
      .then(e => {
        this.state.params = array;
        this.state.total = total;
        this.state.selectedRowKeys = [];
        this.setState({ loading: false });
      });
  }
  //获取选中值
  onSelectChange(selectedRowKeys, selectedRow) {
    this.state.selectedRow = selectedRow;
    this.state.disbtn=false
    this.setState({ selectedRowKeys });
  }
  //翻页
  pageChange(page, pageSize) {
    page = (page - 1) * pageSize;
    this.state.page = page;
    this.onload();
  }
  //val值
  valChange(type, e) {
    if (type == 'exportNum') {
      this.state.exportNum = e.target.value;
    }
  }
  
   //关于上传的js
   handleCancel = () => this.setState({ previewVisible: false });
   handlePreview = async file => {
       if (!file.url && !file.preview) {
       file.preview = await this.getBase64(file.originFileObj);
       }
       this.setState({
       previewImage: file.url || file.preview,
       previewVisible: true,
       });
   };
   imgChange = ({ fileList ,file}) =>{
    if(fileList[0].status === "done"){
      console.log(file)
      if(file.response.code==-1){
        message.error(file.response.message)
        fileList=[]
        // this.onload()
      }else if(file.response.code==0){
        message.success('确认成功')
        fileList=[]
        this.onload()
      }
    }else if(fileList[0].status == "error"){
      message.error('上传失败')
      fileList=[]
    }
    this.setState({ fileList })
   } ;
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    if(fileList.length>0&&fileList[0].status == "done"){
        this.state.url = fileList[0].response.result
       
    }
    if(fileList.length==0){
        this.state.url = null
    }
    
    const columns = [
      {
        title: '序号',
        render: (text, record, index) => `${index + 1}`
      },
      {
        title: '导出单号',
        dataIndex: 'exportNum',
        key: 'exportNum',
        render: (text, record) => (
          <a
            href="javascript:;"
            onClick={this.props.callback.bind(this, '2', 'details', record)}>
            {text}
          </a>
        )
      },
      {
        title: '导出时间',
        dataIndex: 'exportTime',
        key: 'exportTime'
      },
      {
        title: '导出操作人员',
        dataIndex: 'exportPeople',
        key: 'exportPeople'
      },
      {
        title: '确认状态',
        dataIndex: 'status',
        key: 'status',
        render:(text,record)=>text=="0"?"未确认":"失败"
      }
    ];
    const dataSource = this.state.params;
    const { selectedRowKeys } = this.state;
    const { selectedRow } = this.state;
    const rowSelection = {
      type:"radio", 
      selectedRowKeys,
      selectedRow,
      onChange: this.onSelectChange.bind(this) //获取选中值
    };
    return (
      <div className="dcpOrderExpoerList">
        <Spin spinning={this.state.loading} tip="Loading...">
          <div class="page-content-info">
            <div class="toolbar-group">
              <div class="toolbar-left">
                <div class="form-main">
                  <Input
                    class="form-control"
                    placeholder="请输入导出单号"
                    onChange={this.valChange.bind(this, 'exportNum')}
                  />
                </div>
                  <button
                    style={{display: this.state.btn_search == true ? "inline-block" : "none" }}
                    onClick={this.onload.bind(this,'1')}
                    class="btn btn-default btn-search"
                    type="button">
                    检索
                  </button> 
                 <div className="form-main">
                <Upload
                 style={{ height: '34px',marginLeft:'10px',display: this.state.btn_dcp_confirm == true ? "inline-block" : "none"  }}
                 action="/api/exportOrder/dcpOperation"
                 onChange={(e) => this.imgChange(e)}
                 fileList={fileList}
                 data={{id:this.state.selectedRow.length>0?this.state.selectedRow[0].id:null}}
              >
                
              <Button style={{border:'1px solid #ccc',height:'34px',lineHeight:'34px',display: this.state.btn_dcp_confirm == true ? "inline-block" : "none" }} disabled={this.state.disbtn}>DCP导出单确认</Button>
            
             </Upload>
          </div>
              </div>
              <div class="toolbar-right" style={{ width: '100%' }}>
                <Table
                  size={'small'}
                  rowSelection={rowSelection}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{
                    onChange: this.pageChange.bind(this),
                    total: this.state.total
                  }}
                />
              </div>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default DcpOrderExportedList;
